export const HOME_ROUTE = '/';
export const RH_ROUTE = '/rh'
export const LOGIN_ROUTE = '/login';
export const SIGNUP_ROUTE = '/signup';
export const SIGNUP_LOCATION_ROUTE = '/signup-location';
export const SIGNUP_SUCCESS_ROUTE = '/confirm';
export const SIGNUP_VALIDATION_ROUTE = '/signup/advanced/:username/:token/:type';
export const SIGNUP_CANDIDATURE_ROUTE = '/signup-candidature/:username/:token/:opportunityId';


export const FORGOT_PASSWORD_ROUTE = '/forgot-password';
export const FAQ_ROUTE = '/faq';
export const TERMS_OF_USE_ROUTE = '/terms-of-use';
export const CONTACT_US_ROUTE = '/contact-us';
export const REPORTS_ROUTE = '/reports';
export const PRE_REGISTER_ROUTE = '/pre-register';
export const KANBAN_ROUTE = '/kanban';
export const TALENT_HUNTER_KANBAN_ROUTE = '/talent-hunters-kanban';
export const CARD_DETAILS_ROUTE = '/card-details';
export const CARD_TALENT_ROUTE = '/card-talent';

export const OPPORTUNITIES_ROUTE = '/opportunities';
export const OPPORTUNITY_CREATE_ROUTE = '/opportunity/create';
export const OPPORTUNITY_EDIT_ROUTE = '/opportunity/:id/edit';
export const OPPORTUNITY_COPY_ROUTE = '/opportunity/:id/create';
export const OPPORTUNITY_DETAIL_ROUTE = '/opportunity';
export const OPPORTUNITY_EDIT_MOSIAIC_ROUTE = '/opportunity/:id/mosaic';

export const CANDIDATURE_DETAILS_ROUTE = '/candidature-details/:opportunityId';
export const PROFESSIONAL_CANDIDATURES = '/my-candidatures';

export const COMPATIBLE_PROFESSIONALS_ROUTE = '/professionals';
export const SEARCH_PROFESSIONAL_ROUTE = '/search-professionals';
export const PROFESSIONAL_ROUTE = '/professional';
export const PERSONAL_INFORMATION_ROUTE = '/professional/:id/personal-information';
export const PROFESSIONAL_PROFILE_ROUTE = '/professional/:id/professinal-profile';
export const PROFFESIONAL_SETTINGS_ROUTE = '/professional/:id/settings';
export const PROFESSIONAL_RESET_PASSWORD = '/auth/reset/:id';
export const REGISTER_PROFESSIONAL_RH = '/register-by-rh';
