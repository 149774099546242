import React from "react";
import { Button } from "react-bootstrap";
import { withRouter } from "react-router";
import Loading from "../../../../components/loading";
import MosaicEditView from "../../../../components/mosaic/edit";
import { Opportunity } from "../../../../model/opportunity.model";
import { OPPORTUNITY_DETAIL_ROUTE } from "../../../../providers/routes";
import { AlertService } from "../../../../services/alert.service";
import OpportunityService from "../../../../services/opportunity.service";
import { t } from "../../../../translations/translation.service";
import style from "./index.module.scss";

class EditMosaic extends React.Component<any> {
  footerRef!: any;

  constructor(props: any) {
    super(props);
    this.footerRef = React.createRef();
    this.updateWidth = this.updateWidth.bind(this);
    this.updateMosaic = this.updateMosaic.bind(this);
  }

  updateWidth() {
    const width = this.footerRef.current.parentNode.clientWidth;
    const height = this.footerRef.current.parentNode.clientHeight;
    this.setState({ width, height });
  }

  state = {
    message: undefined as unknown,
    loading: true,
    opportunity: {} as Opportunity,
    mosaic: [] as any[],
    width: 0,
    height: 0,
  };

  componentDidMount() {
    const id = this.props.match.params.id;
    if (id) {
      this.setState({ message: undefined });
      OpportunityService.getOpportunityLite(id)
        .then((resp) => {
          this.setState(
            {
              opportunity: resp.data,
              loading: false,
              mosaic: JSON.parse(JSON.stringify(resp.data.mosaico.escolhidas)),
            },
            () => {
              this.updateWidth();
              window.addEventListener("resize", this.updateWidth);
            }
          );
        })
        .catch((error) => {
          console.error(error);
          this.setState({ message: "Vaga não encontrada" });
          this.setState({ loading: false });
        });
    } else {
      this.setState({ loading: false });
      this.setState({ message: "Vaga não encontrada" });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWidth);
  }

  nextPath(path: string): void {
    this.props.history.push(path);
  }

  cancelChanges(): void {
    this.setState({ mosaic: [] }, () => {
      this.setState({
        mosaic: JSON.parse(
          JSON.stringify(this.state.opportunity.mosaico.escolhidas)
        ),
      });
    });
  }
  
  redirectToHome() {
    this.props.history.push("/");
  }

  async saveChanges(): Promise<void> {
    const opp = this.state.opportunity;
    opp.mosaico.escolhidas = this.state.mosaic;
    opp.mosaico.escolhidas = opp.mosaico.escolhidas.map((item: any) => {
      item.selected = false;
      return item;
    });
    opp.id = opp._id;
    try {
      this.setState({ loading: true }, async () => {
        await OpportunityService.updateMosaic(opp);
        this.nextPath(`${OPPORTUNITY_DETAIL_ROUTE}/${opp.id}`);
      });
    } catch (error) {
      this.setState({ loading: false });
      console.error(error);
      AlertService.error("Houve um problema ao salvar o mosaico atual.");
    }
  }

  updateMosaic(mosaic: any[]): void {
    this.setState({ mosaic });
  }

  render() {
    return (
      <div className="container">
        <Loading show={this.state.loading} />
        {this.state.message && (
          <div style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center"
          }}>
            <h5 className="mt-3 mb-5">{t(this.state.message as string)}</h5>
            <Button
              variant="secondary"
              onClick={this.redirectToHome.bind(this)}
            >
              {t("Voltar para o mural de oportunidades")}
            </Button>
          </div>
        )}
        {this.state.opportunity.titulo && (
          <>
            <div className="row m-0">
              <p className={style["opp-title"] + " col m-0"}>
                {this.state.opportunity.titulo}
              </p>
            </div>
            <div className="row m-0">
              <div className="col-md-9 col-12">
                <div
                  className={`row m-0 pt-3 pb-3 ${style["border-top-details"]}`}
                  style={{ height: "530px", paddingBottom: "15px" }}
                >
                  <div
                    className={style["background-stripped"]}
                    style={{
                      position: "absolute",
                      transform: "scale(" + this.state.width / 500 + ")",
                      transformOrigin: "top left",
                    }}
                  ></div>

                  {this.state.mosaic && this.state.mosaic.length > 0 && (
                    <div
                      style={{
                        position: "relative",
                        width: "100%",
                        maxWidth: "500px",
                      }}
                    >
                      <div
                        ref={this.footerRef}
                        style={{
                          width: "500px",
                          transform: "scale(" + this.state.width / 500 + ")",
                          transformOrigin: "top left",
                        }}
                      >
                        <MosaicEditView
                          mosaic={this.state.mosaic}
                          updateEvent={this.updateMosaic}
                        ></MosaicEditView>
                      </div>
                    </div>
                  )}
                </div>
              </div>{" "}
              <div className="col-12 col-md-3 pb-3">
                <Button
                  className={`${style["button-container"]} ${style["petro"]}`}
                  onClick={() => {
                    this.saveChanges();
                  }}
                >
                  {t("Salvar Mosaico")}
                </Button>
                <Button
                  className={`${style["button-container"]} ${style["petro"]}`}
                  onClick={() => this.cancelChanges()}
                >
                  {t("Cancelar Mudanças")}
                </Button>

                <Button
                  className={`${style["button-container"]} ${style["gray"]} mt-2`}
                  onClick={() =>
                    this.nextPath("../" + this.state.opportunity._id)
                  }
                >
                  {t("Voltar")}
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default withRouter(EditMosaic);
