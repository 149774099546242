import { DefaultTheme } from 'styled-components';

 // FUNÇÃO PARA TROCAR A COR DO TOGGLE
export const theme = (parentTheme: DefaultTheme) => ({
      ...parentTheme,
      palette: {
        // @ts-ignore
        ...parentTheme.palette,
        orange: '#DE701D'
      },
      colors: {
        // @ts-ignore
        ...parentTheme.colors,
        primaryHue: 'orange'
      }
  });

  export const themeRed = (parentTheme: DefaultTheme) => ({
    ...parentTheme,
    palette: {
      // @ts-ignore
      ...parentTheme.palette,
      red: '#e35b66',
    },
    colors: {
      // @ts-ignore
      ...parentTheme.colors,
      primaryHue: 'red',
    }
});

export const themeYellow = (parentTheme: DefaultTheme) => ({
  ...parentTheme,
  palette: {
    // @ts-ignore
    ...parentTheme.palette,
    yellow: '#ffd424',
  },
  colors: {
    // @ts-ignore
    ...parentTheme.colors,
    primaryHue: 'yellow',
  }
});

export const themeOrange = (parentTheme: DefaultTheme) => ({
...parentTheme,
palette: {
  // @ts-ignore
  ...parentTheme.palette,
  orange: '#ed8f1c',
},
colors: {
  // @ts-ignore
  ...parentTheme.colors,
  primaryHue: 'orange',
}
});

export const themeGreen = (parentTheme: DefaultTheme) => ({
  ...parentTheme,
  palette: {
    // @ts-ignore
    ...parentTheme.palette,
    green: '#02a191',
  },
  colors: {
    // @ts-ignore
    ...parentTheme.colors,
    primaryHue: 'green',
  }
  });

export const themeGray = (parentTheme: DefaultTheme) => ({
  ...parentTheme,
  palette: {
    // @ts-ignore
    ...parentTheme.palette,
    gray: '#68737d'
  },
  colors: {
    // @ts-ignore
    ...parentTheme.colors,
    primaryHue: 'gray'
  }
})

export const themeWhite = (parentTheme: DefaultTheme) => ({
  ...parentTheme,
  palette: {
    // @ts-ignore
    ...parentTheme.palette,
    white: '#ffffff'
  },
  colors: {
    // @ts-ignore
    ...parentTheme.colors,
    primaryHue: 'white'
  }
})