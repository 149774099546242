import { GET_VALIDATE_VALUE } from '../actions/actionTypes';

const initialState = {
    validateValue: false
}

export const parecerTecnicoReducer = (state = initialState, action: any) => {
    switch(action.type) {
        case GET_VALIDATE_VALUE:
            return {
                ...state,
                validateValue: action.validateValue
            }
        default:
            return state
    }
} 