import * as React from "react";
import GridLayout from "react-grid-layout";
import style from "./index.module.scss";

export type Props = {
  mosaic: any[];
  scale?: number | 1;
};

export default class MosaicPreview extends React.Component<Props> {
  state = {
    mosaic: [] as any,
  };

  componentDidMount() {
    const mosaic = this.props.mosaic.filter(
      (item) => item.posicao && item.detalhe
    );
    this.setState({ mosaic: mosaic });
  }

  getFontSize(size: string): string {
    switch (size) {
      case "1":
        return "16px";
      case "2":
        return "24px";
      case "3":
        return "40px";
      default:
        return "16px";
    }
  }

  render() {
    return (
      <div style={{ height: Math.round(500 * (this.props.scale || 1)) + "px" }}>
        <GridLayout
          className="layout"
          cols={6}
          rowHeight={125}
          width={500}
          margin={[0, 0]}
          style={{
            transform: "scale(" + this.props.scale + ")",
            transformOrigin: "top left",
            height:'500px',
            width: '500px',
            overflow: 'hidden'
          }}
        >
          {this.state.mosaic.map((item: any, index: number) => (
            <div
              key={index + "a"}
              data-grid={{
                y: item.posicao.row - 1,
                x: item.posicao.col - 1,
                w: item.posicao.sizex,
                h: item.posicao.sizey,
                static: true,
              }}
              style={{
                backgroundColor: item.posicao.color,
                zIndex: 0,
              }}
              className={`${style["pickgridister"]} d-flex align-items-center justify-content-center`}
            >
              <span
                style={{
                  color: "white",
                  fontSize: this.getFontSize(item.posicao.fontsize),
                  textAlign: "center",
                }}
              >
                {item.detalhe.nome}
              </span>
            </div>
          ))}
        </GridLayout>
      </div>
    );
  }
}
