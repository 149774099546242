import {
  CLICK_UPDATE_MEDIA,
  CLICK_UPDATE_START_DATE_RANGE,
  CLICK_UPDATE_END_DATE_RANGE,
  CLICK_UPDATE_FILTER
} from "../actions/actionTypes";

const initialState = {
  startDateFilter: {},
  endDateFilter: {},
  filter: {},
  mediaFilter: {}
};

export const reportsFilterReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case CLICK_UPDATE_START_DATE_RANGE:
      return {
        ...state,
        startDateFilter: action.startDateFilter
      };
    case CLICK_UPDATE_END_DATE_RANGE:
      return {
        ...state,
        endDateFilter: action.endDateFilter
      };
    case CLICK_UPDATE_FILTER:
      return {
        ...state,
        filter: action.filter
      };
    case CLICK_UPDATE_MEDIA:
      return {
        ...state,
        mediaFilter: action.mediaFilter
      };
    default:
      return state;
  }
};
