import {
  GET_EVENT,
  GET_EVENT_COMMENT,
  GET_EVENT_DATE,
  GET_RESPONSIBLE_EVENT,
  GET_EVENT_ATTACHMENTS,
  SAVE_EVENT,
  EDIT_EVENT
} from '../actions/actionTypes';


const initialState = {
  eventType: undefined,
  eventDate: undefined,
  responsibleEvent: undefined,
  eventComment: undefined,
  eventAttachments: undefined,
  saveEvent: undefined,
  editEvent: undefined,
}

export const eventFilter = (state: any = initialState, action: any) => {
  switch(action.type) {
    case GET_EVENT:
      return { ...state, eventType: action.eventType };
    case GET_EVENT_COMMENT:
      return { ...state, eventComment: action.eventComment };
    case GET_EVENT_DATE:
      return { ...state, eventDate: action.eventDate };
    case GET_RESPONSIBLE_EVENT:
      return { ...state, responsibleEvent: action.responsibleEvent };
    case GET_EVENT_ATTACHMENTS:
      return { ...state, eventAttachments: action.eventAttachments };
    case SAVE_EVENT:
      return { ...state, saveEvent: action.saveEvent }
    case EDIT_EVENT:
      return { ...state, editEvent: action.editEvent }
    default:
      return state;
  }
}
