import { t } from "../../../../translations/translation.service";
import { themeGreen, themeOrange, themeRed, themeYellow } from "../../../../util/colorButton";

export enum LevelType{
    None = 'Nenhum',
    Basic = 'Básico',
    Intermediary = 'Intermediário',
    Advanced = 'Avançado'
}

export enum AssessmentType{
    Advicebehavioral = 'Parecer Comportamental',
    JavaDeveloper = 'Desenvolvedor Java',
    TechnicalAdvice = 'Parecer Técnico'
}

export const getItemCaption = (e: any): string => {
    switch (parseInt(e)) {
        default:
        case 0:
        return t(LevelType.None);
        case 1:
        return t(LevelType.Basic);
        case 2:
        return t(LevelType.Intermediary);
        case 3:
        return t(LevelType.Advanced);
    }
};

export const getClassName = (e: any): string => {
    const classThemeRed: any = themeRed;
    const classThemeYellow: any = themeYellow;
    const classThemeOrange: any = themeOrange;
    const classThemeGreen: any = themeGreen;

    switch (parseInt(e)) {
        default:
        case 0:
            return classThemeRed;
        case 1:
            return classThemeYellow;
        case 2:
            return classThemeOrange;
        case 3:
            return classThemeGreen;
    }
};