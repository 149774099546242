import { random as Mosaics }  from "../images/icons/random-mosaic-icons/icons";

export const getRandomMosaic = (title: string) => {
  //it's magic, don't touch it
  let hash = 0, 
  i, 
  chr
  for(i = 0; i < title.length; i++) {
    chr = title.charCodeAt(i);
    hash = ( (hash<< 5 )- hash ) +chr;
    hash |= 0; // Convert to 32bit integer
  }

  const number = Math.round(Math.abs(hash).toString().split('').map(Number).reduce(function (a, b) {
    return (a + b);
  }, 0) % Mosaics.length);

  return Mosaics[number]
};
