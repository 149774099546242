import React from "react";
import "./index.css";
import { Dots } from "@zendeskgarden/react-loaders";
const { zdColorSecondaryOrange400 } = require("@zendeskgarden/css-variables");

class Loading extends React.Component<{show: boolean}> {
  render() {
    return (
      <div id="divLoading" className={this.props.show ? "" : "hide"}>
        <p className="enter-logo-loading" />
        <br />
        <Dots
          key="dots-loading"
          className="loader"
          color={zdColorSecondaryOrange400}
          size="48px"
        />
      </div>
    );
  }
}

export default Loading;
