import { opportunityFilterReducer } from './opportunityFilters';
import { reportsFilterReducer } from './reportsFilters';
import { combineReducers } from 'redux';
import { changeLanguageReducer } from './translationChange';
import { talentFilterReducer } from './talentFilters';
import { parecerTecnicoReducer } from './parecerTecnico';
import { eventFilter } from './eventFilter'

export const Reducers = combineReducers({
  reportsFilterReducer: reportsFilterReducer,
  opportunityFilterReducer: opportunityFilterReducer,
  talentFilterReducer: talentFilterReducer,
  changeLanguageReducer: changeLanguageReducer,
  parecerTecnicoReducer: parecerTecnicoReducer,
  eventFilterReducer: eventFilter,
});