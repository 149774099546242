import React from "react";
import { Row, Col } from "@zendeskgarden/react-grid";
import {
  Field,
  Input,
  Label,
  Toggle,
  Message,
  MediaInput,
  Hint,
} from "@zendeskgarden/react-forms";
import {
  Dropdown,
  Field as DropdownField,
  Item,
  Menu,
  Select,
} from "@zendeskgarden/react-dropdowns";
import { AuthenticationService } from "../../../services/auth.service";
import Modal from "react-bootstrap/Modal";
import { Datepicker } from "@zendeskgarden/react-datepickers";
import { ThemeProvider } from "@zendeskgarden/react-theming";
import { theme } from "../../../util/colorButton";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import Header from "../../../components/header";
import Loading from "../../../components/loading";
import UserService from "../../../services/user.service";
import TranslationService, { t } from "../../../translations/translation.service";
import { UserData } from "../../../model/user.model";
import { ReactComponent as LockIcon } from "@zendeskgarden/svg-icons/src/16/lock-locked-fill.svg";
import { ReactComponent as UnlockIcon } from "@zendeskgarden/svg-icons/src/16/lock-unlocked-fill.svg";
import "./index.css";
import { AlertService } from "../../../services/alert.service";

class PersonalInformation extends React.Component<any> {
  constructor(props: any) {
    super(props);
    this.handleResize = this.handleResize.bind(this);
    this.dateFormat = this.dateFormat.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleLocation = this.handleLocation.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleContact = this.handleContact.bind(this);
    this.handlePcd = this.handlePcd.bind(this);
    this.submitInfo = this.submitInfo.bind(this);
    this.parseDateBr = this.parseDateBr.bind(this);
    this.getCountry = this.getCountry.bind(this);
  }

  state = {
    screenWidth: window.innerWidth,
    redefinePasswordModal: false,
    user: {} as UserData | any,
    dataNascimento: new Date(),
    loading: false,
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
    verify: false,
    showCurrentPassword: false,
    showNewPassword: false,
    showConfirmPassword: false,
  };

  handleResize() {
    this.setState({ screenWidth: window.innerWidth });
  }

  dateFormat() {
    const dateFormatter = new Intl.DateTimeFormat("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    return dateFormatter;
  }

  parseDateBr(inputValue: string) {
    if (inputValue.length === 10) {
      const value = inputValue.split("/");
      const formatDateBR = `${value[1]}/${value[0]}/${value[2]}`;
      const data = new Date(formatDateBR);
      return data;
    }
    return new Date(this.state.user?.dadosPessoais?.dataNascimento || "");
  }

  handleChange(event: any | string) {
    const { target } = event;
    const { name } = target;
    const userInfo = this.state.user;
    const findKey = Object.keys(userInfo).find((key) => key === name);
    const isToggle = Object.keys(event.target).find((key) => key === "checked");
    if (findKey) {
      if (isToggle) {
        userInfo[name] = target.checked;
      } else {
        userInfo[name] = target.value;
      }
      this.setState({
        user: userInfo,
      });
    } else {
      userInfo.dadosPessoais[name] = target.value;
      this.setState({
        user: userInfo,
      });
    }
  }

  handlePcd(event: any) {
    const userInfo = this.state.user;
    if (!userInfo.dadosPessoais.pcdUser) {
      userInfo.dadosPessoais.pcdUser =
        {} as UserData["dadosPessoais"]["pcdUser"];
    }
    userInfo.dadosPessoais.pcdUser.isUserPCD = event.target.checked;
    this.setState({ user: userInfo });
  }

  handleContact(numero: any) {
    let contato = this.state.user.contato;
    if (contato.lista.length > 0) {
      contato.lista[0].contato = numero;
      this.setState({
        user: { ...this.state.user, contato },
      });
    } else {
      contato.lista.push({
        tipo: "Preferencial",
        contato: numero,
        locale: "pt-BR",
      });
      this.setState({
        user: { ...this.state.user, contato },
      });
    }
  }

  handleLocation(data: any) {
    const userInfo = this.state.user;
    geocodeByPlaceId(data.value.place_id).then((code) => {
      const geoLocation = code[0];
      userInfo.dadosPessoais.geolocation = geoLocation;
      this.setState({ user: userInfo });
    });
  }

  handleSelect(item: string) {
    const userInfo = this.state.user;
    userInfo.dadosPessoais.genero = item;
    this.setState({ user: userInfo });
  }

  getCountry(geolocation: any): any {
    return geolocation?.address_components.filter(
        (address: any) =>
        address.types.filter((type: string) => type === "country")[0]
    )[0].short_name;
  }

  checkBlankFields() {
    const { firstName, lastName, email, dadosPessoais } = this.state.user;
    const blankField = (
        firstName === '' ||
        lastName === '' ||
        email === '' ||
        dadosPessoais.geolocation?.formatted_address === undefined
      )
    return blankField
  }

  async submitInfo() {
    this.setState({ loading: true });
    delete this.state.user.password;
    const blankField = this.checkBlankFields()
    if (blankField) {
      this.setState({ loading: false });
      return AlertService.error(
        t("(Nome - Sobrenome - email - Localidade) devem ser preenchidos")
      );
    }
    try {
      const resp = await UserService.updateUser(this.state.user);
      await UserService.changePcdStatus(this.state.user._id, this.state.user.dadosPessoais);
      this.setState({ user: resp.data, loading: false });
      AlertService.success("Informações alteradas com sucesso");
    } catch (err: any) {
      this.setState({ loading: false });
      if (err.response.data.message === "EMAIL_ALREADY_REGISTERED") {
        AlertService.error(t("E-mail já está cadastrado"));
      } else {
        AlertService.error("Não foi possível alterar as informações");
        console.log("erro: ", err.response)
      }
    }
  }

  async changePassword() {
    if (this.state.newPassword === this.state.confirmPassword) {
      this.setState({ verify: true });
      const response = await UserService.changePassword({
        currentPassword: this.state.currentPassword,
        newPassword: this.state.newPassword,
        verifyPassword: this.state.confirmPassword,
      });
      if (response.status !== 400) {
        this.setState({ redefinePasswordModal: false, verify: false });
        AlertService.success("Senha alterada com sucesso!");
      } else {
        AlertService.error("Não foi possível alterar a senha!");
      }
    } else {
      this.setState({ verify: true });
    }
  }

  async componentDidMount() {
    const { id } = this.props.match.params;
    if (AuthenticationService.getUser()._id !== id) {
      this.props.history.push("/login");
    }
    this.setState({ loading: true })
    UserService.getUserWithOpportunities(id)
      .then((resp) => {
        this.setState(
          {
            user: resp.data.user,
          },
          () => {
            const userInfo = this.state.user;
            if (!userInfo.dadosPessoais) {
              userInfo.dadosPessoais = {} as UserData["dadosPessoais"];
            }
            const birthday1 = Date.parse(
              this.state.user?.dadosPessoais?.dataNascimento
            );
            if (birthday1) {
              this.setState({ dataNascimento: new Date(birthday1), loading: false });
            } else {
              this.setState({ dataNascimento: '', loading: false });
            }
          }
        );
      })
      .catch((err) => console.error(err));
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.addEventListener("resize", this.handleResize);
  }

  render() {
    return (
      <div className="pi-container">
        <ThemeProvider theme={theme as any}>
          <Loading show={this.state.loading} />
          <h6 className="main-title mb-4">{t("Informações Pessoais")}</h6>
          <Row className="row justify-content-center pi-container-1">
            <Col>
              <Row className="mb-3 pi-container-1">
                <Col>
                  <Field>
                    <Label style={{ width: "100%" }} className="text-label">
                      <span className="text-label">{t("Nome")}</span>
                      <Input
                        value={this.state.user.firstName}
                        name="firstName"
                        className="mt-2"
                        onChange={this.handleChange}
                      />
                    </Label>
                  </Field>
                </Col>
                <Col>
                  <Field>
                    <Label style={{ width: "100%" }} className="text-label">
                      <span className="text-label">{t("Sobrenome")}</span>
                      <Input
                        name="lastName"
                        value={this.state.user.lastName}
                        className="mt-2"
                        onChange={this.handleChange}
                      />
                    </Label>
                  </Field>
                </Col>
                <Col>
                  <Field>
                    <Label style={{ width: "100%" }}>
                      <span className="text-label">
                        {t("Data de Nascimento")}
                      </span>
                      <Datepicker
                        maxValue={new Date()}
                        value={this.state.dataNascimento}
                        onChange={(date) =>
                          this.setState({ dataNascimento: date }, () => {
                            const userInfo = this.state.user;
                            userInfo.dadosPessoais.dataNascimento = new Date(
                              date
                            ).toISOString();
                            this.setState({ user: userInfo });
                          })
                        }
                        formatDate={(date) => this.dateFormat().format(date)}
                        customParseDate={this.parseDateBr}
                        locale="pt-BR"
                      >
                        <Input className="mt-2" />
                      </Datepicker>
                      <Hint style={{ fontSize: "0.7rem", opacity: 0.8 }}>
                        {t("formato dd/mm/yyyy")}
                      </Hint>
                    </Label>
                  </Field>
                </Col>
              </Row>
              <Row className="mb-3 pi-container-1">
                <Col>
                  <Field>
                    <Label style={{ width: "100%" }} className="text-label">
                      <span className="text-label">E-mail</span>
                      <Input
                        name="email"
                        value={this.state.user.email}
                        className="mt-2"
                        onChange={this.handleChange}
                      />
                    </Label>
                  </Field>
                </Col>
                <Col>
                  <Field>
                    <Label style={{ width: "100%" }}>
                      <p className="text-label mb-2">{t("Localidade")}</p>
                      <GooglePlacesAutocomplete
                        apiKey="AIzaSyC5_B8f8Ug5dmvdYe3QWv6N2YoKszO0gK4"
                        selectProps={{
                          placeholder: this.state.user?.dadosPessoais
                            ?.geolocation?.address_components
                            ? this.state.user?.dadosPessoais?.geolocation
                                ?.address_components[0].long_name
                            : "Select..",
                          value:
                            this.state.user &&
                            this.state.user.dadosPessoais &&
                            this.state.user.dadosPessoais.geolocation &&
                            this.state.user.dadosPessoais.geolocation
                              .formatted_address,
                          onChange: this.handleLocation,
                          noOptionsMessage: () => t("Digite as 3 primeiras letras da sua cidade"),
                        }}
                      />
                    </Label>
                  </Field>
                </Col>
                <Col>
                  <Field>
                    <Label style={{ width: "100%" }} className="text-label">
                      <span className="text-label">{t("Telefone")}</span>
                      <PhoneInput
                        className="mt-2 phone-css"
                        placeholder="  Digite o número"
                        defaultCountry={
                          this.getCountry(
                            this.state.user?.dadosPessoais?.geolocation
                          ) || "BR"
                        }
                        value={
                          this.state.user &&
                          this.state.user.contato &&
                          this.state.user.contato.lista[0] &&
                          this.state.user.contato.lista[0].contato
                        }
                        onChange={this.handleContact}
                      />
                    </Label>
                  </Field>
                </Col>
              </Row>
              <Row
                className="mb-3 pi-container-1"
                style={this.state.screenWidth > 800 ? { width: "68%" } : {}}
              >
                <Col>
                  <Field>
                    <Label style={{ width: "100%" }}>
                      {TranslationService.getCurrentLanguage() === "en-US" && (
                        <span className="text-label">NIF / SSN</span>
                      )}
                      {TranslationService.getCurrentLanguage() === "pt-BR" && (
                        <span className="text-label">CPF</span>
                      )}
                      {TranslationService.getCurrentLanguage() === "pt-PT" && (
                        <span className="text-label">NIF</span>
                      )}
                      <Input
                        name="cpf"
                        onChange={this.handleChange}
                        value={this.state.user?.dadosPessoais?.cpf}
                        className="mt-2"
                      />
                    </Label>
                  </Field>
                </Col>
                <Col>
                  <Field>
                    <Label style={{ width: "100%" }} className="mb-2">
                      <p className="text-label mb-2">{t("Gênero")}</p>
                      <Dropdown
                        selectedItem={t(this.state.user?.dadosPessoais?.genero)}
                        onSelect={this.handleSelect}
                        downshiftProps={{
                          itemToString: (item: any) => item,
                        }}
                      >
                        <DropdownField>
                          <Select>
                            {t(this.state.user?.dadosPessoais?.genero)}
                          </Select>
                        </DropdownField>
                        <Menu>
                          <Item value="Masculino">{t("Masculino")}</Item>
                          <Item value="Feminino">{t("Feminino")}</Item>
                          <Item value="Prefiro não responder">
                            {t("Prefiro não responder")}
                          </Item>
                          <Item value="Outros">{t("Outros")}</Item>
                        </Menu>
                      </Dropdown>
                    </Label>
                  </Field>
                </Col>
              </Row>
            </Col>
          </Row>
          {this.state.screenWidth < 800 && (
            <Row>
              <Col className="mb-2" size={12}>
                <Field>
                  <Toggle
                    checked={
                      this.state.user?.dadosPessoais?.pcdUser?.isUserPCD ||
                      false
                    }
                    onChange={this.handlePcd}
                    name="pcd"
                  >
                    <Label>
                      <span className="pcd-button">
                        {t("Pessoa com Deficiência")}
                      </span>
                    </Label>
                  </Toggle>
                </Field>
                <Field>
                  <Toggle
                    checked={
                      this.state.user?.europeanUnionCitizenship ||
                      false
                    }
                    onChange={this.handleChange}
                    name="europeanUnionCitizenship"
                  >
                    <Label>
                      <span className="pcd-button">
                        {t("Cidadania Europeia")}
                      </span>
                    </Label>
                  </Toggle>
                </Field>
              </Col>
              <Col className="mb-2" size={12}>
                <button
                  className="redefine-button"
                  onClick={() => this.setState({ redefinePasswordModal: true })}
                >
                  {t("Redefinir senha")}
                </button>
              </Col>
              <Col className="mb-2" size={12}>
                <button className="save-button" onClick={this.submitInfo}>
                  {t("Salvar")}
                </button>
              </Col>
            </Row>
          )}
          {this.state.screenWidth > 800 && (
            <Row className="row justify-content-end mt-2">
              <Col size={8}>
                <Field>
                  <Toggle
                    checked={
                      this.state.user?.dadosPessoais?.pcdUser?.isUserPCD ||
                      false
                    }
                    onChange={this.handlePcd}
                    name="pcd"
                  >
                    <Label>
                      <span className="pcd-button">
                        {t("Pessoa com Deficiência")}
                      </span>
                    </Label>
                  </Toggle>
                </Field>
                <Field>
                  <Toggle
                    checked={
                      this.state.user?.europeanUnionCitizenship ||
                      false
                    }
                    onChange={this.handleChange}
                    name="europeanUnionCitizenship"
                  >
                    <Label>
                      <span className="pcd-button">
                        {t("Cidadania Europeia")}
                      </span>
                    </Label>
                  </Toggle>
                </Field>
              </Col>
              <Col size={2.8}>
                <button
                  onClick={() => this.setState({ redefinePasswordModal: true })}
                  className="redefine-button"
                >
                  {t("Redefinir senha")}
                </button>
              </Col>
              <Col size={1.2}>
                <button className="save-button" onClick={this.submitInfo}>
                  {t("Salvar")}
                </button>
              </Col>
            </Row>
          )}
          <Row>
            <Col
              className={
                this.state.screenWidth < 800
                  ? "terms-of-use mt-4"
                  : "offset-md-9 terms-of-use mt-4"
              }
            >
              {TranslationService.getCurrentLanguage() !== "en-US" ? (
                <p>
                  Ao clicar em "Salvar", você aceita os{" "}
                  <a href="/terms-of-use">Termos de uso</a> do Enter
                </p>
              ) : (
                <p>
                  By clicking "Save", you accept the Enter{" "}
                  <a href="/terms-of-use">Terms of Use</a>
                </p>
              )}
            </Col>
          </Row>
          {
            // MODAL PARA REDEFINIR SENHA
            this.state.redefinePasswordModal && (
              <Modal
                size={this.state.screenWidth < 400 ? "sm" : undefined }
                centered
                show={this.state.redefinePasswordModal}
                onHide={() =>
                  this.setState({ redefinePasswordModal: false, verify: false })
                }
              >
                <Modal.Header closeButton>
                  <Header />
                </Modal.Header>
                <Modal.Body>
                  <div>
                    <Col className="offset-md-1 mb-4 modal-title">
                      {t("Redefinir senha")}
                    </Col>
                    <Col className="offset-md-1 mb-3">
                      <Field>
                        <Label
                          style={
                            this.state.screenWidth > 767 &&
                            this.state.screenWidth < 800
                              ? { width: "83%" }
                              : this.state.screenWidth < 800
                              ? { width: "100%" }
                              : { width: "83%" }
                          }
                        >
                          <MediaInput
                            type={
                              this.state.showCurrentPassword
                                ? "text"
                                : "password"
                            }
                            end={
                              this.state.showCurrentPassword ? (
                                <UnlockIcon
                                  cursor={"pointer"}
                                  onClick={() =>
                                    this.setState({
                                      showCurrentPassword: false,
                                    })
                                  }
                                />
                              ) : (
                                <LockIcon
                                  cursor={"pointer"}
                                  onClick={() =>
                                    this.setState({ showCurrentPassword: true })
                                  }
                                />
                              )
                            }
                            placeholder={
                              TranslationService.getCurrentLanguage() ===
                              "en-US"
                                ? "Current Password"
                                : "Senha Atual"
                            }
                            onChange={(event) =>
                              this.setState({
                                currentPassword: event.target.value,
                              })
                            }
                          />
                        </Label>
                      </Field>
                    </Col>
                    <Col className="offset-md-1 mb-3">
                      <Field>
                        <Label
                          style={
                            this.state.screenWidth > 767 &&
                            this.state.screenWidth < 800
                              ? { width: "83%" }
                              : this.state.screenWidth < 800
                              ? { width: "100%" }
                              : { width: "83%" }
                          }
                        >
                          <MediaInput
                            type={
                              this.state.showNewPassword ? "text" : "password"
                            }
                            end={
                              this.state.showNewPassword ? (
                                <UnlockIcon
                                  cursor={"pointer"}
                                  onClick={() =>
                                    this.setState({ showNewPassword: false })
                                  }
                                />
                              ) : (
                                <LockIcon
                                  cursor={"pointer"}
                                  onClick={() =>
                                    this.setState({ showNewPassword: true })
                                  }
                                />
                              )
                            }
                            placeholder={
                              TranslationService.getCurrentLanguage() ===
                              "en-US"
                                ? "New Password"
                                : "Nova Senha"
                            }
                            onChange={(event) =>
                              this.setState({
                                newPassword: event.target.value,
                              })
                            }
                            validation={
                              this.state.verify
                                ? this.state.newPassword ===
                                  this.state.confirmPassword
                                  ? "success"
                                  : "error"
                                : undefined
                            }
                          />
                        </Label>
                      </Field>
                    </Col>
                    <Col className="offset-md-1 mb-3">
                      <Field>
                        <Label
                          style={
                            this.state.screenWidth > 767 &&
                            this.state.screenWidth < 800
                              ? { width: "83%" }
                              : this.state.screenWidth < 800
                              ? { width: "100%" }
                              : { width: "83%" }
                          }
                        >
                          <MediaInput
                            type={
                              this.state.showConfirmPassword
                                ? "text"
                                : "password"
                            }
                            end={
                              this.state.showConfirmPassword ? (
                                <UnlockIcon
                                  cursor={"pointer"}
                                  onClick={() =>
                                    this.setState({
                                      showConfirmPassword: false,
                                    })
                                  }
                                />
                              ) : (
                                <LockIcon
                                  cursor={"pointer"}
                                  onClick={() =>
                                    this.setState({ showConfirmPassword: true })
                                  }
                                />
                              )
                            }
                            placeholder={
                              TranslationService.getCurrentLanguage() ===
                              "en-US"
                                ? "Comfirm"
                                : "Confirmação"
                            }
                            onChange={(event) =>
                              this.setState({
                                confirmPassword: event.target.value,
                              })
                            }
                            validation={
                              this.state.verify
                                ? this.state.newPassword ===
                                  this.state.confirmPassword
                                  ? "success"
                                  : "error"
                                : undefined
                            }
                          />
                          <Message
                            validation={
                              this.state.verify
                                ? this.state.newPassword ===
                                  this.state.confirmPassword
                                  ? "success"
                                  : "error"
                                : undefined
                            }
                          >
                            {this.state.verify
                              ? this.state.newPassword ===
                                this.state.confirmPassword
                                ? "OK"
                                : "Senhas não conferem"
                              : undefined}
                          </Message>
                        </Label>
                      </Field>
                    </Col>
                    <Col className="offset-md-1">
                      <Label
                        style={
                          this.state.screenWidth > 767 &&
                          this.state.screenWidth < 800
                            ? { width: "83%" }
                            : { width: "100%" }
                        }
                      >
                        <button
                          disabled={
                            this.state.newPassword.length < 6 ||
                            this.state.confirmPassword.length < 6 ||
                            this.state.currentPassword === ""
                              ? true
                              : false
                          }
                          className="modal-save-button"
                          style={
                            this.state.newPassword.length < 6 ||
                            this.state.confirmPassword.length < 6 ||
                            this.state.currentPassword === ""
                              ? { backgroundColor: "#B9B9B9" }
                              : { backgroundColor: "#DE701D" }
                          }
                          onClick={this.changePassword.bind(this)}
                        >
                          {t("Redefinir senha")}
                        </button>
                      </Label>
                    </Col>
                  </div>
                </Modal.Body>
                <Modal.Footer className="col justify-content-center mb-1">
                  <Col
                    className={
                      this.state.screenWidth < 800
                        ? ""
                        : "text-center ml-5 mr-5 pl-5 pr-5"
                    }
                  >
                    {TranslationService.getCurrentLanguage() !== "en-US" ? (
                      <p className="privacy-policy">
                        Somos muito cuidadosos com seus dados. Veja nossa{" "}
                        <a href="/terms-of-use">Política de Privacidade</a>
                      </p>
                    ) : (
                      <p className="privacy-policy">
                        We are very careful with your data. See our{" "}
                        <a href="/terms-of-use">Privacy Policy</a>
                      </p>
                    )}
                  </Col>
                </Modal.Footer>
              </Modal>
            )
          }
        </ThemeProvider>
      </div>
    );
  }
}

export default PersonalInformation;
