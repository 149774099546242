import { Field, Hint, Label } from "@zendeskgarden/react-forms";
import { Tooltip } from "@zendeskgarden/react-tooltips";
import { LG, XL } from "@zendeskgarden/react-typography";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { ProgressBar, Step } from "react-step-progress-bar";
import "react-step-progress-bar/styles.css";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { currentLanguageChanged } from "../../../actions";
import Loading from "../../../components/loading";
import CircleProgress from "../../../components/progress";
import {
  CANDIDATURE_DETAILS_ROUTE,
  HOME_ROUTE,
} from "../../../providers/routes";
import { AuthenticationService } from "../../../services/auth.service";
import UserService from "../../../services/user.service";
import { t } from "../../../translations/translation.service";
import "./index.css";

interface IStepProps {
  accomplished: boolean;
  index: number;
  position: number;
}

class ProfessionalCandidatures extends React.Component<any> {
  state = {
    opportunities: [],
    loading: false,
  };

  async componentDidMount() {
    this.setState({ loading: true });
    try {
      const { data } = await UserService.getUserWithOpportunities(
        AuthenticationService.getUser()._id
      );

      data.opportunitiesRelated.allCandidatures.sort((a: any, b: any) => {
        return (new Date(b.createdAt) as any) - (new Date(a.createdAt) as any);
      });

      this.setState({ opportunities: data.opportunitiesRelated.allCandidatures, loading: false });
    } catch (err) {
      console.error(err);
    }
  }

  getCandidateStep(
    stepsCandidato: any,
    faseAtual: number,
    isFinished: boolean,
    isDispensed?: boolean
  ) {
    var rows = [];
    for (var i = 0; i <= 4; i++) {
      if (i === stepsCandidato) {
        rows.push(
          <Step index={i}>
            {({ index }: IStepProps) => (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    marginTop: 30,
                  }}
                >
                  <div className={`indexedStep completeStep`}>
                    <div className={`userStep`} />
                  </div>
                  <div
                    style={{
                      marginTop: 15,
                      color: "white",
                    }}
                  >
                    {this.getStepName(isDispensed ? 5 : index)}
                  </div>
                </div>
              </>
            )}
          </Step>
        );
      } else if (i < stepsCandidato || i <= faseAtual || isFinished) {
        rows.push(
          <Step index={i} accomplished={true}>
            {() => <div className={`indexedStep completeStep`}></div>}
          </Step>
        );
      } else {
        rows.push(
          <Step index={i}>
            {() => <div className={`indexedStep inactiveStep`}></div>}
          </Step>
        );
      }
    }

    return rows;
  }

  getStepName(step: number) {
    switch (step) {
      case 0:
        return t("Recrutado");
      case 1:
        return t("Avaliado");
      case 2:
        return t("Curriculo Selecionado");
      case 3:
        return t("Apresentado");
      case 4:
        return t("Selecionado");
      case 5:
        return t("Dispensado");
      default:
        break;
    }
  }

  getOpportunityStepName(step: number) {
    switch (step) {
      case 0:
        return t("em recrutamento");
      case 1:
        return t("em avaliação");
      case 2:
        return t("em seleção");
      case 3:
        return t("em apresentação");
      case 4:
        return t("encerrada");
      default:
        break;
    }
  }

  getPercentage(faseAtual: number) {
    switch (faseAtual) {
      case 0:
        return 0;
      case 1:
        return 25;
      case 2:
        return 50;
      case 3:
        return 75;
      case 4:
        return 100;
      default:
        return 0;
    }
  }

  adjustCriteria(id: string) {
    this.props.history.push(
      `${CANDIDATURE_DETAILS_ROUTE.replace(":opportunityId", id)}`
    );
  }

  render() {
    return (
      <>
        <Container>
          <Loading show={this.state.loading} />
          {this.state.opportunities && this.state.opportunities.length ? (
            this.state.opportunities.map((opp: any, index) => {
              const isSelected = opp.currentStatus === 6;
              const step = isSelected
                ? 4
                : opp.candidateStep > 0
                ? opp.candidateStep - 1
                : opp.candidateStep;
              const faseAtualOportunidade =
                opp.fase === 0
                  ? opp.fase
                  : opp.isFinished || isSelected
                  ? 4
                  : opp.fase - 1;
              const isDispensed = opp.currentStatus === 3;

              return (
                <Row
                  className="row-info mb-2 d-flex align-items-center"
                  key={index}
                  onClick={() => this.adjustCriteria(opp._id)}
                >
                  <Col
                    sm={10}
                    md={10}
                    lg={10}
                    xl={6}
                    className="d-flex align-items-center mb-4"
                  >
                    <Field className="p-4">
                      <CircleProgress
                        duration={2600}
                        finish={opp.nivelAderencia}
                      />
                    </Field>
                    <Field>
                      <Label>
                        <XL isBold style={{ color: "#fff" }}>
                          {opp.title}
                        </XL>
                      </Label>
                      <Hint>
                        <LG style={{ color: "#fff" }}>
                          {opp.nivelAderencia}% {t("de aderência")}
                        </LG>
                      </Hint>
                    </Field>
                  </Col>
                  <Tooltip
                    size="large"
                    placement="start"
                    content={
                      <span>
                        <p>
                          {t("Seu status é")}{" "}
                          {this.getStepName(isDispensed ? 5 : step)}
                        </p>
                        <p>
                          {t("A oportunidade está")}{" "}
                          {this.getOpportunityStepName(
                            !opp.isFinished ? faseAtualOportunidade : 4
                          )}
                        </p>
                      </span>
                    }
                  >
                    <Col sm={11} md={11} lg={11} xl={5} className="m-4 p-5">
                      <ProgressBar
                        percent={
                          !opp.isFinished
                            ? this.getPercentage(
                                step > faseAtualOportunidade
                                  ? step
                                  : faseAtualOportunidade
                              )
                            : this.getPercentage(4)
                        }
                        filledBackground="white"
                        unfilledBackground="#678098"
                        height={5}
                      >
                        {this.getCandidateStep(
                          step,
                          faseAtualOportunidade,
                          opp.isFinished,
                          isDispensed
                        )}
                      </ProgressBar>
                    </Col>
                  </Tooltip>
                </Row>
              );
            })
          ) : (
            <XL>
              {t("Nâo existem candidaturas atualmente.")} {t("Clique")}{" "}
              <Link to={{ pathname: HOME_ROUTE }}>{t("aqui")}</Link>{" "}
              {t("e veja as oportunidades.")}
            </XL>
          )}
        </Container>
      </>
    );
  }
}

const mapStateToProps = (store: {
  changeLanguageReducer: { currentLanguage: string };
}) => ({
  currentLanguage: store.changeLanguageReducer.currentLanguage,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      currentLanguageChanged,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProfessionalCandidatures));
