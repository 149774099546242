import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import moment from "moment";
import { initReactI18next } from "react-i18next";
import TRANSLATIONS_EN_US  from "./en-US/translations.json";
import STATIC_PROPS_EN_US  from "./en-US/static-props.json";
import TRANSLATIONS_PT_BR from "./pt-BR/translations.json";
import STATIC_PROPS_PT_BR from "./pt-BR/static-props.json";
import TRANSLATIONS_PT_PT from "./pt-PT/translations.json";
import STATIC_PROPS_PT_PT from "./pt-PT/static-props.json";

let app = {} as any;
app.translations = {
  "en-US": {
    translation: Object.assign(STATIC_PROPS_EN_US, TRANSLATIONS_EN_US)
  },
  "pt-BR": {
    translation: Object.assign(STATIC_PROPS_PT_BR, TRANSLATIONS_PT_BR)
  },
  "pt-PT": {
    translation: Object.assign(STATIC_PROPS_PT_PT, TRANSLATIONS_PT_PT)
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: ['en-US', 'pt-PT', 'pt-BR'],
    resources: app.translations,
    interpolation: {
      format: function (value, format, lng) {
        if (value instanceof Date) return moment(value).format(format);
        return value;
      }
    },
    keySeparator: false
  });

export default i18n;
export const getTranslations = () => app.translations;