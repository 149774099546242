import React from "react";
import { Droppable } from "react-beautiful-dnd";
import DraggableHuntersBody from "../draggable-talent";

class DroppableHuntersColumn extends React.Component<any> {
  render() {
    return (
      <Droppable droppableId={this.props.id}>
        {(provided, snapshot) => (
          <DraggableHuntersBody
            provided={provided}
            snapshot={snapshot}
            list={this.props.list}
            id={this.props.id}
            key={this.props.id + 'hunter-body'}
          />
        )}
      </Droppable>
    );
  }
}

export default DroppableHuntersColumn;
