import {
  CLICK_UPDATE_SQUAD,
  CLICK_UPDATE_RESPONSABLE,
  CLICK_UPDATE_FILTER,
  CLICK_UPDATE_MEDIA,
  CLICK_UPDATE_START_DATE_RANGE,
  CLICK_UPDATE_END_DATE_RANGE,
  CLICK_UPDATE_GROUP,
  CHANGE_LANGUAGE,
  CLICK_UPDATE_SORT_LIST,
  CLICK_UPDATE_KEYWORDS,
  GET_BUTTON_HOME_OPPORTINITIES,
  CLICK_UPDATE_DATE_FILTER,
  CLICK_UPDATE_PRIORITY_SORT,
  GET_VALIDATE_VALUE,
  GET_EVENT,
  GET_EVENT_DATE,
  GET_RESPONSIBLE_EVENT,
  GET_EVENT_COMMENT,
  GET_EVENT_ATTACHMENTS,
  SAVE_EVENT,
  EDIT_EVENT
} from "./actionTypes";

//KANBAN
export const squadClickButton = (value: any) => ({
  type: CLICK_UPDATE_SQUAD,
  squadFilter: value,
});

export const responsableClickButton = (value: any) => ({
  type: CLICK_UPDATE_RESPONSABLE,
  responsableFilter: value,
});

export const groupClickButton = (value: any) => ({
  type: CLICK_UPDATE_GROUP,
  groupFilter: value,
});

export const sortListClickButton = (value: any) => ({
  type: CLICK_UPDATE_SORT_LIST,
  sortFilterList: value,
});

export const keywordsClickButton = (value: any) => ({
  type: CLICK_UPDATE_KEYWORDS,
  keywordsList: value,
});

export const dateFilterClickButton = (value: any) => ({
  type: CLICK_UPDATE_DATE_FILTER,
  dateFilter: value,
});

export const sortByPriorityClickButton = (value: any) => ({
  type: CLICK_UPDATE_PRIORITY_SORT,
  priorityButton: value,
})

//REPORTS
export const filterClickButton = (value: any) => ({
  type: CLICK_UPDATE_FILTER,
  filter: value,
});

export const startDateClickButton = (value: any) => ({
  type: CLICK_UPDATE_START_DATE_RANGE,
  startDateFilter: value,
});

export const endDateClickButton = (value: any) => ({
  type: CLICK_UPDATE_END_DATE_RANGE,
  endDateFilter: value,
});

export const mediaClickButton = (value: any) => ({
  type: CLICK_UPDATE_MEDIA,
  mediaFilter: value,
});

export const currentLanguageChanged = (value: any) => ({
  type: CHANGE_LANGUAGE,
  currentLanguage: value,
});

// HOME BUTTONS

export const getButtonOppHome = (value: any) => ({
  type: GET_BUTTON_HOME_OPPORTINITIES,
  currentButton: value,
})

// PARECER TÉCNICO

export const getValidateValue = (value: any) => ({
  type: GET_VALIDATE_VALUE,
  validateValue: value,
})

// HEADER EVENTOS
export const getEvent = (value: any) => ({
  type: GET_EVENT,
  eventType: value,
});

export const getEventDate = (value: any) => ({
  type: GET_EVENT_DATE,
  eventDate: value,
});

export const getResponsibleEvent = (value: any) => ({
  type: GET_RESPONSIBLE_EVENT,
  responsibleEvent: value,
});

export const getEventComment = (value: any) => ({
  type: GET_EVENT_COMMENT,
  eventComment: value,
});

export const getEventAttachments = (value: any) => ({
  type: GET_EVENT_ATTACHMENTS,
  eventAttachments: value,
});

export const saveEvent = (value: any) => ({
  type: SAVE_EVENT,
  saveEvent: value,
});

export const editEvent = (value: any) => ({
  type: EDIT_EVENT,
  editEvent: value,
});
