import { Progress } from "@zendeskgarden/react-loaders";
import { Blockquote, XXL } from "@zendeskgarden/react-typography";
import React from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import DroppableHuntersColumn from "../../components/kanban/droppable-talent";
import HuntersHeader from "../../components/kanban/header-talent";
import ColumnHeader from "../../components/kanban/header/column-header";
import Loading from "../../components/loading";
import amountIcon from "../../images/icons/amount-icon.png";
import UserService from "../../services/user.service";
import { Tooltip } from "@zendeskgarden/react-tooltips";
import { t } from "../../translations/translation.service";
import "./index.css";

const { zdColorSecondaryOrange400 } = require("@zendeskgarden/css-variables");

class TalentHunterKanban extends React.Component<any> {
  state = {
    waitingTalents: [],
    participatingTalents: [],
    finished: [],
    isLoading: false,
    victories: [],
    defeats: [],
    waitingAmount: 0,
    participatingAmout: 0,
    hasTalents: true,
    page: 1,
    totalPerPage: 10,
    totalResults: 0,
    percent: 0, 
    noFilterWaiting: [],
    noFilterParticipating: [],
    noFilterFinished: [],
    getRequest: false,
  };

  getHiredOrQuit = (opportunity: any) => {
    const hired = opportunity.map(
      (opp: any) => {
        const matchHired = opp.inProgressOpportunities.filter((oppInfo: any) => {
          if (oppInfo.currentStatus && oppInfo.currentStatus === 6) {
            oppInfo.displayName = opp.displayName;
            return oppInfo;
          }
          return null;
        });
        return matchHired;
      }
    );
    const quit = opportunity.map(
      (opp: any) => {
        const matchQuit = opp.inProgressOpportunities.filter((oppInfo: any) => {
          if (oppInfo.currentStatus && oppInfo.currentStatus === 5) {
            oppInfo.displayName = opp.displayName;
            return oppInfo;
          }
          return null;
        });
        return matchQuit;
      }
    )
    this.setState({
      victories: hired.flatMap((num: any) => num),
      defeats: quit.flatMap((num: any) => num),
    })
  }

  getResults = async () => {
    this.setState(
      async () => {
        const { responsableFilter, groupFilter, sortFilterList, keywordsList, dateFilter } =
          this.props;
        const { waiting, participating, finished, total } = (
          await UserService.getTalents(
            this.state.totalPerPage,
            this.state.page,
            responsableFilter,
            groupFilter,
            sortFilterList,
            keywordsList,
            dateFilter,
          )
        ).data;
        this.setState(
          {
            waitingTalents: waiting,
            participatingTalents: participating,
            finished: finished,
            waitingAmout: waiting.length - 1,
            participatingAmount: participating.length - 1,
            hasTalents: true,
            totalResults: total,
            percent: 100 / Math.ceil(total / this.state.totalPerPage),
          },
          async () => {
            const totalRequests = Math.ceil(total / this.state.totalPerPage);
            for (let i = 2; i <= totalRequests; i++) {
              let { waiting, participating, finished, total } = (
                await UserService.getTalents(
                  this.state.totalPerPage,
                  i,
                  responsableFilter,
                  groupFilter,
                  sortFilterList,
                  keywordsList,
                  dateFilter
                )
              ).data;
              waiting = this.state.waitingTalents.concat(waiting);
              participating =
                this.state.participatingTalents.concat(participating);
              finished = this.state.finished.concat(finished);
              this.setState({
                waitingTalents: waiting,
                participatingTalents: participating,
                finished: finished,
                waitingAmout: waiting.length - 1,
                participatingAmount: participating.length - 1,
                hasTalents: true,
                totalResults: total,
                percent: (i * 100) / Math.ceil(total / this.state.totalPerPage),
              });
            }
            this.setState({
              percent: 100,
              noFilterWaiting: this.state.waitingTalents,
              noFilterParticipating: this.state.participatingTalents,
              noFilterFinished: this.state.finished,
            }, () => {
              this.getHiredOrQuit(this.state.finished);
            });
          }
        );
      }

    );
  };

  componentDidMount(): void {
    this.getResults();
  }

  // setPage(index: number) {
  //   this.setState({ page: index }, () => this.getResults());
  // }

  componentDidUpdate(prevProps: any) {
    if (
      prevProps.responsableFilter !== this.props.responsableFilter ||
      prevProps.groupFilter !== this.props.groupFilter
    ) {
      // Filtra nos talentos
      let waintingTalentsFiltered: any[],
        participatingTalentsFiltered: any[],
        finishedFiltered: any[];
      if (!this.props.responsableFilter || this.props.responsableFilter?._id === "-1") {
        waintingTalentsFiltered = this.state.noFilterWaiting;
        participatingTalentsFiltered = this.state.noFilterParticipating;
        finishedFiltered = this.state.noFilterFinished;
      } else {
        waintingTalentsFiltered = this.state.noFilterWaiting.filter(
          (talent: any) =>
            talent.talent?.recruiter?._id === this.props.responsableFilter._id
        );
        participatingTalentsFiltered = this.state.noFilterParticipating.filter(
          (talent: any) =>
            talent.talent?.recruiter?._id === this.props.responsableFilter._id
        );
        finishedFiltered = this.state.noFilterFinished.filter(
          (talent: any) =>
            talent.talent?.recruiter?._id === this.props.responsableFilter._id
        );
      }

      if (this.props.groupFilter && this.props.groupFilter !== "Todos os Grupos" && this.props.groupFilter !== "Sem categoria") {
        waintingTalentsFiltered = waintingTalentsFiltered.filter(
          (talent: any) =>
            talent.talent?.category?._id === this.props.groupFilter
        );
        participatingTalentsFiltered = participatingTalentsFiltered.filter(
          (talent: any) =>
            talent.talent?.category?._id === this.props.groupFilter
        );
        finishedFiltered = finishedFiltered.filter(
          (talent: any) =>
            talent.talent?.category?._id === this.props.groupFilter
        );
      }

      if (this.props.groupFilter && this.props.groupFilter === "Sem categoria") {
        waintingTalentsFiltered = waintingTalentsFiltered.filter(
          (talent: any) =>
            talent.talent?.category?._id !== "620e5be4241b6ee8e6382719" &&
            talent.talent?.category?._id !== "620e5be4241b6ee8e638271b" &&
            talent.talent?.category?._id !== "620e5be4241b6ee8e638271a"
        );
        participatingTalentsFiltered = participatingTalentsFiltered.filter(
          (talent: any) =>
          talent.talent?.category?._id !== "620e5be4241b6ee8e6382719" &&
          talent.talent?.category?._id !== "620e5be4241b6ee8e638271b" &&
          talent.talent?.category?._id !== "620e5be4241b6ee8e638271a"
        );
        finishedFiltered = finishedFiltered.filter(
          (talent: any) =>
          talent.talent?.category?._id !== "620e5be4241b6ee8e6382719" &&
          talent.talent?.category?._id !== "620e5be4241b6ee8e638271b" &&
          talent.talent?.category?._id !== "620e5be4241b6ee8e638271a"
        );
      }
      
      this.setState({
        waitingTalents: [],
        participatingTalents: [],
        finished: [],
      }, () => this.setState({
        waitingTalents: waintingTalentsFiltered,
        participatingTalents: participatingTalentsFiltered,
        finished: finishedFiltered,
      }));

    }
    if (prevProps.keywordsList !== this.props.keywordsList || prevProps.sortFilterList !== this.props.sortFilterList) {
      // Buscar na api com o filtro selecionado
      this.setState({ page: 1 }, () => this.getResults());
    }
    if (prevProps.dateFilter !== this.props.dateFilter) {
      if (
        this.props.dateFilter.endCalendarValue !== "Período Específico" &&
        this.props.dateFilter.startCalendarValue !== "Período Específico" &&
        !this.state.getRequest
      ) {
        this.getResults();
        this.setState({ getRequest: true });
      }
      if (
        prevProps.dateFilter?.startCalendarValue ===
          this.props.dateFilter?.startCalendarValue &&
        prevProps.dateFilter?.endCalendarValue !==
          this.props.dateFilter?.endCalendarValue &&
        this.state.getRequest
      ) {
        this.getResults();
      }
    }
  }

  render(): React.ReactNode {
    // const { responsableFilter, groupFilter, sortFilterList, keywordsList } = this.props;
    return (
      <div className="container">
        <Loading show={this.state.isLoading} />
        <HuntersHeader disableSelects={this.state.percent < 99} />
        {/* {this.state.percent > 99 && <HuntersHeader disableSelects={false} />} */}
        {this.state.percent < 99 && (
          <>
            <div className="flex text-align-center mb-2 fade-in">
              <XXL isBold>
                <Blockquote>Aguarde enquanto estamos carregando!</Blockquote>
              </XXL>
            </div>
            <Progress
              size="large"
              color={zdColorSecondaryOrange400}
              value={this.state.percent}
              className="animate-flicker"
            />
          </>
        )}
        {/* <Row className="w-100 pb-4">
          <Pagination
            className="ml-auto"
            totalPages={Math.ceil(
              this.state.totalResults / this.state.totalPerPage
            )}
            currentPage={this.state.page}
            onChange={this.setPage.bind(this)}
          />
        </Row> */}
        <Row className="mx-0">
          <DragDropContext onDragEnd={() => {}}>
            {!this.state.hasTalents && (
              <p className="no-cards-message u-fs-xxl">
                {t("Nenhuma oportunidade aqui.")}
              </p>
            )}

            <div className="waiting column-body col-sm-12 col-md-6 col-lg-4 mb-4">
              <ColumnHeader
                type="waiting"
                title="Fase 1:"
                subtitle={`(${t("Aguardando Oportunidade")})`}
                count={this.state.waitingTalents.length}
              />
              <div className="p-1 ml-2 mr-2 columnOptions d-flex justify-content-between align-items-center count">
                <p className="u-fs-sm u-semibold u-fg-grey-600">
                  {this.state.waitingTalents.length} {t("Profissionais")}
                </p>
                <div>
                  <img src={amountIcon} alt="Quantidade total de vagas" />
                  <span className="ml-1 u-fs-sm u-semibold u-fg-grey-600">
                    {this.state.waitingAmount}
                  </span>
                </div>
              </div>
              <DroppableHuntersColumn
                id="waiting"
                list={this.state.waitingTalents}
              />
            </div>

            <div className="participating column-body col-sm-12 col-md-6 col-lg-4 mb-4">
              <ColumnHeader
                type="participating"
                title="Fase 2:"
                subtitle={`(${t("Participando de Processos")})`}
                count={this.state.participatingTalents.length}
              />
              <div className="p-1 ml-2 mr-2 columnOptions d-flex justify-content-between align-items-center count">
                <p className="u-fs-sm u-semibold u-fg-grey-600">
                  {this.state.participatingTalents.length} {t("Profissionais")}
                </p>
                <div>
                  <img src={amountIcon} alt="Quantidade total de talentos" />
                  <span className="ml-1 u-fs-sm u-semibold u-fg-grey-600">
                    {this.state.participatingAmout}
                  </span>
                </div>
              </div>
              <DroppableHuntersColumn
                id="participating"
                list={this.state.participatingTalents}
              />
            </div>
            <div className="finished column-body col-sm-12 col-lg-4 mb-4">
              <ColumnHeader
                type="finished"
                title="Fase 3:"
                subtitle={`(${t("Contratado ou desistiu")})`}
              />
              <div className="p-1 ml-2 mr-2 columnOptions d-flex justify-content-between align-items-center count">
                <Tooltip
                  size="extra-large"
                  type="light"
                  placement="start-bottom"
                  content={
                    <div className="d-flex">
                      <div>
                        <h1
                          style={{
                            color: '#f39c12',
                            fontSize: '1rem'
                          }}
                        >
                          {t("Contratações")}
                        </h1>
                          {
                            this.state.victories.length > 0 && this.state.victories.map((item: any) => {
                              return (
                                <div style={{ 
                                  borderRight: 'solid 0.5px #f39c12',
                                }}
                                className={"mb-1 d-flex"}
                                >
                                  <div className="mr-2">
                                    <p
                                      className="u-semibold"
                                    >
                                      {t("Profissional")}
                                      {" "}
                                      <span className="u-regular">{item.displayName}</span>
                                    </p>
                                  </div>
                                  <div className="mr-2">
                                    <p className="u-semibold">
                                      {t("Oportunidade")}
                                      {" "}
                                      <span className="u-regular">
                                        {item.title}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              )
                            })
                          }
                      </div>
                      <div>
                        <h1
                          className="ml-2"
                          style={{
                            color: '#f39c12',
                            fontSize: '1rem'
                          }}
                        >
                          {t("Desistência")}
                        </h1>
                          {
                            this.state.defeats.length > 0 && this.state.defeats.map((item: any) => {
                              return (
                                <div className="d-flex">
                                  <div className="mr-2 ml-2">
                                    <p
                                      className="u-semibold"
                                    >
                                      {t("Profissional")}
                                      {" "}
                                      <span className="u-regular">{item.displayName}</span>
                                    </p>
                                  </div>
                                  <div>
                                    <p className="u-semibold">
                                      {t("Oportunidade")}
                                      {" "}
                                      <span className="u-regular">
                                        {item.title}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              )
                            })
                          }
                      </div>
                    </div>
                  }
                >
                  <p className="u-fs-sm u-semibold u-fg-grey-600">
                    {this.state.victories.length} {t("Contratacoes")}{" "}
                    {this.state.defeats.length} {t("Desistencia")}
                  </p>
                </Tooltip>
              </div>
              <DroppableHuntersColumn id="closed" list={this.state.finished} />
            </div>
          </DragDropContext>
        </Row>
        {/* <Row className="w-100 pb-4">
          <Pagination
            className="ml-auto"
            totalPages={Math.ceil(
              this.state.totalResults / this.state.totalPerPage
            )}
            currentPage={this.state.page}
            onChange={this.setPage.bind(this)}
          />
        </Row> */}
      </div>
    );
  }
}

const mapStateToProps = (store: {
  talentFilterReducer: {
    responsableFilter: any;
    groupFilter: any;
    sortFilterList: any;
    keywordsList: any;
    dateFilter: any
  };
}) => ({
  groupFilter: store.talentFilterReducer.groupFilter,
  responsableFilter: store.talentFilterReducer.responsableFilter,
  sortFilterList: store.talentFilterReducer.sortFilterList,
  keywordsList: store.talentFilterReducer.keywordsList,
  dateFilter: store.talentFilterReducer.dateFilter,
});

export default connect(mapStateToProps)(TalentHunterKanban);
