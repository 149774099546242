import { Field, Input, Textarea} from '@zendeskgarden/react-forms';
import axios from 'axios';
import React from 'react';
import Loading from '../../components/loading';
// import SEO from '../../components/seo';
import { AlertService } from '../../services/alert.service';
import TranslationService, { t } from "../../translations/translation.service";
import './index.css';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL_BACKEND,
});

export default class ContactUs extends React.Component<any> {
  state = {
    firstName: '',
    email: '',
    mensagem: '',
    loading: false,
  };

  validateForm() {
    if (
      this.state.firstName === '' ||
      this.state.mensagem === '' ||
      this.state.email === ''
    ) {
      AlertService.success(t('Preencha os campos obrigatórios'));
    } else {
      this.sendMessage();
    }
  }

  async sendMessage() {
    this.setState({ loading: true });
    let data = {
      firstName: this.state.firstName,
      email: this.state.email,
      mensagem: this.state.mensagem,
      activeLanguage: TranslationService.getCurrentLanguage()
    };
    try {
      const submit = await api.post('contactus', data);
      if (submit.status === 200) {
        this.setState({
          loading: false,
          firstName: '',
          email: '',
          mensagem: '',
        });
        AlertService.success(submit.data.message);
      } else {
        this.setState({ loading: false });
      }
    } catch (err: any) {
      this.setState({ loading: false });
      if (err.response) {
        AlertService.error(err.response.data.message);
      }
    }
  }

  render() {
    return (
      <div className="container-sm main-container">
        {/* <SEO title={`Enter - ${t("Fale Conosco")}`} url='https://elumini.enter.jobs/contact-us'/> */}
        <Loading show={this.state.loading} />
        <div className="contactus-header">
          <p>{t("Fale Conosco")}</p>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mb-2">
            <Field>
              <Input
                value={this.state.firstName}
                onChange={(event) =>
                  this.setState({ firstName: event.target.value })
                }
                required
                placeholder={t("Nome")}
              ></Input>
            </Field>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <Field>
              <Input
                value={this.state.email}
                onChange={(event) =>
                  this.setState({ email: event.target.value })
                }
                required
                placeholder={t("Email")}
              ></Input>
            </Field>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12">
            <Field>
              <Textarea
                value={this.state.mensagem}
                onChange={(event) =>
                  this.setState({ mensagem: event.target.value })
                }
                required
                className="mt-2"
                placeholder={t("Deixe aqui a sua mensagem")}
              ></Textarea>
            </Field>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="d-grid gap-2 d-flex justify-content-between">
              <div />
              <button
                onClick={this.validateForm.bind(this)}
                className="btn-enviar me-md-2"
                type="button"
                disabled={
                  this.state.firstName.length === 0 ||
                  this.state.mensagem.length === 0 ||
                  this.state.email.length === 0
                }
              >
                {t("Enviar")}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
