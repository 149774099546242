import React from 'react';
import CardTalentMajor from '../../components/kanban/card-major-talent';
import './index.css';

class CardTalentDetails extends React.Component<any> {


  render() {
    return (
      <div className="container">
        <div></div>
        <div className="m-3">
          <CardTalentMajor />
        </div>
      </div>
    );
  }
}

export default CardTalentDetails;
