import React from "react";
import Fade from "react-reveal/Fade";
import CardTalentMinor from "../card-minor-talent";

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "lightblue" : "",
  padding: 10,
});

class DraggableHuntersBody extends React.Component {
  render() {
    return (
      <div
        ref={this.props.provided.innerRef}
        style={getListStyle(this.props.snapshot.isDraggingOver)}
      >
        {this.props.list.map((item, index) => (
          <Fade appear bottom spy={item} key={item.id + "" + index}>
            <CardTalentMinor
              key={item.id + "%$%"}
              item={item}
              id={this.props.id}
            />
          </Fade>
        ))}
        {this.props.provided.placeholder}
      </div>
    );
  }
}

export default DraggableHuntersBody;
