import React from "react";
import "./index.css";

import xIcon  from "../../../../images/icons/icon_x_red.png";
import checkIcon from "../../../../images/icons/icon_check_green.png";

class ColumnHeader extends React.Component<any> {
  
  render() {
    return (
      <div className={`col column-header ${this.props.type}-header u-ta-left p-2`}>
        <div className="column-content">
          <div className="title">
            <div>
              <span className="mb-0 u-fs-lg">
                {this.props.title}
              </span>
              <div className="u-ta-right">
                {(this.props.victories > 0 || this.props.defeats > 0)  && (
                  <div>
                    <span className="ml-1 u-semibold counter victories-indications"><img src={checkIcon} className="mr-1 ml-1" alt=""/><span className="mr-1">{this.props.victories}</span></span>
                    <span className="ml-1 mr-3 u-semibold counter defeats-indications"><img src={xIcon} className="mr-1 ml-1" alt=""/><span className="mr-1">{this.props.defeats}</span></span>
                  </div>
                )}
              </div>
            </div>
            <p className="u-fs-md mb-0 ">{this.props.subtitle}</p>
          </div>
        </div>
      </div>
    );
  }
}

export default ColumnHeader;
