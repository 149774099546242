import service from "./api.service";

class DictionaryService {
  static getStates = () => {
    return service.get("dictionary/states");
  };
  static getInterests = () => {
    return service.get("dictionary/interests");
  };
  static getCareers = () => {
    return service.get("dictionary/careers");
  };
  static getDictionaryList = () => {
    return service.get("dictionary/list");
  };
  static getDictionary = () => {
    return service.get("dictionary");
  };
  static createTemplate = () => {
    return service.post("dictionary");
  }
  static createTemplateEnUs = () => {
    return service.post("dictionary/en");
  }
}

export default DictionaryService;
