import { Component } from "react";
import { Col, Row } from "@zendeskgarden/react-grid";
import OpportunityCard from "../opportunity-card";
import { HomeOpportunitiesData } from "../../../model/home.model";
import { connect } from 'react-redux';
import "./index.css";
import { t } from "../../../translations/translation.service";
import { ReactComponent as WhatshotIcon } from '../../../images/icons/home/ic_whatshot_24px.svg';
import { AuthenticationService } from "../../../services/auth.service";
import { AnyAction, Dispatch, bindActionCreators } from "redux";
import { getButtonOppHome } from '../../../actions';

class HomeOpportunities extends Component<{ listHome: HomeOpportunitiesData, userId: string, listCompatibleOppHome: any } | any> {
  render() {
    const { getButtonOppHome } = this.props
    return (
      <div>
        {!AuthenticationService.isRh() && this.props.listCompatibleOppHome.length > 0 && (
          <div>
            <h4>
              <button
                className="compatible-opportunities"
                onClick={() => getButtonOppHome({
                  searchForm: {
                    keywords: "",
                    location: { id: "Oportunidades Compatíveis", location: "Oportunidades Compatíveis" },
                    homeOffice: false,
                  }
                })}
              >
                <WhatshotIcon
                  style={{
                    height: '1.1rem',
                    marginBottom: '5px',
                  }}
                /> {t("Oportunidades Compatíveis")}
              </button>
            </h4>
            <div className="container-opp-compatible mb-3">
              {
                this.props.listCompatibleOppHome.map(
                  (opp: any, index: any) => {
                    return index < 2 && (
                      <div className="body-opp-compatible">
                        <OpportunityCard
                          id={opp.id}
                          index={index}
                          key={(index + 10).toString()}
                          title={opp.title}
                          locations={opp.location}
                          homeOffice={opp.homeOffice}
                          // hybrid={opp.hybrid}
                          geolocation={opp.geolocation}
                          isCandidate={opp.isCandidate}
                          isEnrolled={opp.isEnrolled}
                          isHotOpportunity={opp.isCompatibleCandidate}
                          isHotOpportunityLevel={opp.isCompatibleCandidateLevel}
                        />
                      </div>
                    )
                  }
                )
              }
            </div>
          </div>
        )}
        <Row>
          <Col sm={12} lg={8} >
            <h4>
              <button
                className="section-title section-news pl-2"
                onClick={() => getButtonOppHome({
                  searchForm: {
                    keywords: "",
                    location: { id: "Home Office" , location: "Home Office" },
                    homeOffice: false,
                  }
                })}
              >
                #{t("Remotas")}
              </button>
            </h4>
            <Row>
              <Col sm={12} lg={6}>
                {this.props.listHome.recentHomeOfficeOpportunities.map(
                  (opp: any, index: any) =>
                    index < 2 && (
                      <OpportunityCard
                        id={opp.id}
                        index={index}
                        key={(index + 20).toString()}
                        title={opp.title}
                        locations={opp.location}
                        homeOffice={opp.homeOffice}
                        hybrid={opp.hybrid}
                        geolocation={opp.geolocation}
                        isCandidate={opp.isCandidate}
                        isEnrolled={opp.isEnrolled}
                        isHotOpportunity={opp.isCompatibleCandidate}
                        isHotOpportunityLevel={opp.isCompatibleCandidateLevel}
                      />
                    )
                )}
              </Col>
              <Col sm={12} lg={6}>
                {this.props.listHome.recentHomeOfficeOpportunities.map(
                  (opp: any, index: any) =>
                    index >= 2 && (
                      <OpportunityCard
                        id={opp.id}
                        index={index}
                        key={(index + 30).toString()}
                        title={opp.title}
                        locations={opp.location}
                        homeOffice={opp.homeOffice}
                        hybrid={opp.hybrid}
                        geolocation={opp.geolocation}
                        isCandidate={opp.isCandidate}
                        isEnrolled={opp.isEnrolled}
                        isHotOpportunity={opp.isCompatibleCandidate}
                        isHotOpportunityLevel={opp.isCompatibleCandidateLevel}
                      />
                    )
                )}
              </Col>
            </Row>
          </Col>
          <Col sm={12} lg={4} className="pl-2">
            <h4>
              <button
                className="section-title pl-2"
                onClick={() => getButtonOppHome({
                  searchForm: {
                    keywords: "",
                    location: { id: "All Places", location: "Novidades" },
                    homeOffice: true,
                  }
                })}
              >
                #{t("Novidades")}
              </button>
            </h4>
            <Col sm={12}>
              {this.props.listHome.recentPresentialOpportunities.map(
                (opp: any, index: any) => (
                  <OpportunityCard
                    id={opp.id}
                    index={index}
                    key={(index + 40).toString()}
                    title={opp.title}
                    locations={opp.location}
                    homeOffice={opp.homeOffice}
                    hybrid={opp.hybrid}
                    geolocation={opp.geolocation}
                    isCandidate={opp.isCandidate}
                    isEnrolled={opp.isEnrolled}
                    isHotOpportunity={opp.isCompatibleCandidate}
                    isHotOpportunityLevel={opp.isCompatibleCandidateLevel}
                  ></OpportunityCard>
                )
              )}
            </Col>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={12} lg={4} className="p-0">
            <div className="country-title brazil">
              <button
                className="country-title-button"
                onClick={() => getButtonOppHome({
                  searchForm: {
                    keywords: "",
                    location: { id: "Brasil", location: "Brasil" },
                    homeOffice: false,
                  }
                })}
              >
                {t("Brasil")}
              </button>
            </div>
            <Row className="m-0">
              <Col sm={12}>
                {this.props.listHome.brazillianOpportunities.map(
                  (opp: any, index: any) => (
                    <OpportunityCard
                      id={opp.id}
                      index={index}
                      key={(index + 50).toString()}
                      title={opp.title}
                      locations={opp.location}
                      homeOffice={opp.homeOffice}
                      hybrid={opp.hybrid}
                      geolocation={opp.geolocation}
                      isCandidate={opp.isCandidate}
                      isEnrolled={opp.isEnrolled}
                      isHotOpportunity={opp.isCompatibleCandidate}
                      isHotOpportunityLevel={opp.isCompatibleCandidateLevel}
                    ></OpportunityCard>
                  )
                )}
              </Col>
            </Row>
          </Col>
          <Col sm={12} lg={4} className="p-0">
            <div
              className="country-title europe"
            >
            <button
                className="country-title-button"
                onClick={() => getButtonOppHome({
                  searchForm: {
                    keywords: "",
                    location: { id: "Europa", location: "Europa" },
                    homeOffice: false,
                  }
                })}
              >
                {t("Europa")}
              </button>
            </div>
            <Row className="m-0">
              <Col sm={12}>
                {this.props.listHome.europeanOpportunities.map((opp: any, index: any) => (
                  <OpportunityCard
                    id={opp.id}
                    index={index}
                    key={(index + 60).toString()}
                    title={opp.title}
                    locations={opp.location}
                    homeOffice={opp.homeOffice}
                    hybrid={opp.hybrid}
                    geolocation={opp.geolocation}
                    isCandidate={opp.isCandidate}
                    isEnrolled={opp.isEnrolled}
                    isHotOpportunity={opp.isCompatibleCandidate}
                    isHotOpportunityLevel={opp.isCompatibleCandidateLevel}
                  ></OpportunityCard>
                ))}
              </Col>
            </Row>
          </Col>
          <Col sm={12} lg={4} className="p-0">
            <div className="country-title usa">
              <button
                className="country-title-button"
                onClick={() => getButtonOppHome({
                  searchForm: {
                    keywords: "",
                    location: { id: "America do Norte", location: "America do Norte" },
                    homeOffice: false,
                  }
                })}
              >
                {t("EUA / America do Norte")}
              </button>
            </div>
            <Row className="m-0">
              <Col sm={12}>
                {this.props.listHome.northAmericanOpportunities.map(
                  (opp: any, index: any) => (
                    <OpportunityCard
                      id={opp.id}
                      index={index}
                      key={(index + 70).toString()}
                      title={opp.title}
                      locations={opp.location}
                      homeOffice={opp.homeOffice}
                      hybrid={opp.hybrid}
                      geolocation={opp.geolocation}
                      isCandidate={opp.isCandidate}
                      isEnrolled={opp.isEnrolled}
                      isHotOpportunity={opp.isCompatibleCandidate}
                      isHotOpportunityLevel={opp.isCompatibleCandidateLevel}
                    ></OpportunityCard>
                  )
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
  getButtonOppHome
}, dispatch)

export default connect(null, mapDispatchToProps)(HomeOpportunities);
