import { Button } from "react-bootstrap";
import "react-phone-number-input/style.css";
import { t } from "../../../translations/translation.service";
// @ts-ignore
import ReactFilestack from "filestack-react";
import style from "./index.module.scss";
import { Row, Col } from "@zendeskgarden/react-grid";
import { ReactComponent as InfoIcon } from "../../../images/icons/info.svg";

const { REACT_APP_FILESTACK_API } = process.env;
export const tabProfileThree = (self: any): any => {
  return (
    <div className="row">
      <div className="col-12 col-md-8">
        {self.state.policyString.length > 0 && (
          <ReactFilestack
            apikey={REACT_APP_FILESTACK_API}
            componentDisplayMode={{
              type: "button",
              customText: t("Selecione o arquivo clicando aqui."),
              customClass: "c-txt__input py-4",
            }}
            actionOptions={{
              accept: [".jpeg", ".jpg", ".png"]
            }}
            clientOptions={{
              security: {
                policy: self.state.policyString,
                signature: self.state.signatureString,
              }
            }}
            onSuccess={self.onFiles}
          />
        )}
        <Row className="mt-4">
          <Col className={style["text-info-tab3"]}>
            <InfoIcon style={{ fill: "white" }} className="mr-2"/>
            {t(
              "Escolha uma imagem que representa quem você é, faça o upload e clique em salvar."
            )}
          </Col>
        </Row>
      </div>
      <div className="col-12 col-md-4">
        <Row className="mt-3 ml-4">
          {self.state.step === "welcome" ? (
            <Button
              className={style["next-step"]}
              onClick={() => self.next("tab-4")}
            >
              {t("Salvar e continuar")}
            </Button>
          ) : (
            <Button
              className={style["next-step"]}
              onClick={() => self.next("tab-3")}
            >
              {t("Salvar")}
            </Button>
          )}
        </Row>
      </div>
    </div>
  );
};
