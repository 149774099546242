import React from "react";
import { t } from '../../translations/translation.service';
import './index.css'

class BannerCookie extends React.Component {

  state: Readonly<{ cookies: boolean }> = {
    cookies: true,
  };

  addCookie = (): void => {
    let toDay: Date = new Date();
    let nextMonth: Date = new Date(toDay.getTime() + 7 * 24 * 60 * 60 * 4000);
    document.cookie = `name=Enter-Cookie;expires=${nextMonth}`;
    this.setState({ cookies: false });
  }

  checkCookie = (): void => {
    const keys: string[] = document.cookie.split(";");
    const existCookie: string | undefined = keys.find((key) => key.trim() === 'name=Enter-Cookie');
    if (existCookie) {
      this.setState({ cookies: false });
    }
  }

  componentDidMount(): void {
    this.checkCookie();
  }

  render(): React.ReactNode {
    return (
      this.state.cookies
        ? (
            <div className="banner-cookies">
              <div className="logo-enter-cookie"/>
              <p className="text-cookies">
                {t("Nós usamos cookies e outras tecnologias semelhantes para melhorar a sua experiência e recomendar conteúdo de seu interesse. Ao utilizar nossos serviços, você concorda com tal monitoramento. Para mais informações, consulte a nossa")}
                <span
                  className="ml-1"
                  style={{ textDecoration: 'underline'}}
                >
                  <a href="/terms-of-use" target="_blank">
                    {t("nova política de privacidade.")}
                  </a>
                </span>
              </p>
              <button
                onClick={this.addCookie}
                className="cookie-button ml-2"
              >
                {t("Prosseguir")}
              </button>
            </div>
          )
        : <></>
    )
  }
}

export default BannerCookie;
