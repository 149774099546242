import React from "react";
import { t } from "../../translations/translation.service";
import "./index.css";
import { AuthenticationService } from '../../services/auth.service';
// import SEO from "../../components/seo";

export default class Faq extends React.Component<any> {
  render() {
    return (AuthenticationService.isRh()
      ? <div className="container">
        <header className="faq-header">
          <h3>{t("FAQ Recrutamento")}</h3>
        </header>
        <p > </p>
        <p className="faq-rh-titles"><h5>{t("DEFINIÇÃO DOS PERÍODOS")}</h5></p>
        <p> </p>
        <p className="faq-rh-titles">{t("Quando começa e termina o período de Recrutamento de uma vaga?")}</p>
        <p>{t("O período de recrutamento de uma vaga inicia na data de Publicação da oportunidade e se encerra quando é feito o registro positivo do candidato na etapa atual (Recrutamento)")}</p>
        <p> </p>
        <p className="faq-rh-titles">{t("Quando começa e termina o período de Avaliação de uma vaga?")}</p>
        <p>{t("O período dessa etapa inicia no o registro positivo do candidato na etapa anterior (Recrutamento) e encerra no registro positivo do candidato na etapa atual (Avaliação)")}.</p>
        <p> </p>
        <p className="faq-rh-titles">{t("Quando começa e termina o período de Seleção de uma vaga?")}</p>
        <p>{t("O período dessa etapa inicia no registro positivo do candidato na etapa anterior (Avaliação)  e encerra no registro positivo do candidato na etapa atual (Seleção)")}.</p>
        <p> </p>
        <p className="faq-rh-titles">({t("Quando começa e termina o período de Apresentação de uma vaga?")})</p>
        <p>{t("O período dessa etapa inicia no registro positivo do candidato na etapa anterior (Seleção)  e encerra no registro positivo do candidato na etapa atual (Apresentação)")}.</p>
        <p> </p>
        <br />
        <p className="faq-rh-titles"><h5>{t("INDICADORES DE RECRUTAMENTO")}</h5></p>
        <p> </p>
        <p className="faq-rh-titles">{t("Como são calculados os indicadores?")}</p>
        <p>{t("Os indicadores exibidos na página de relatórios são calculados com base na média de tempo que cada oportunidade levou em cada etapa. Como um oportunidade pode ter mais de uma vaga, o tempo que cada vaga levou em cada etapa também é levado em consideração para o cálculo do indicador final")}.</p>
        <p> </p>
        <p className="faq-rh-titles">{t("Como os indicadores são exibidos?")}</p>
        <p>{t("Os indicadores são exibidos na página de Relatórios. De acordo com o período e responsável (ou esquadrão) escolhidos, o relatório mostra a média das oportunidades consolidadas em cada etapa.. Por exemplo: Consolidando o relatório para a recrutadora “X”, no período “Y” de tempo, aparecem 2 oportunidades")}.</p>
        <div className="container-table-faq">
          <div className="header-table-faq">
            <div className="body-table-faq">
              <p className="body-item-faq">{t("Oportunidades")}</p>
              <p className="body-item-faq">{t("Oportunidade A")}</p>
              <p className="body-item-faq">{t("Oportunidade B")}</p>
              <p className="body-item-faq">{t("Indicadores")}</p>
            </div>
            <div className="body-table-faq">
              <p className="body-item-faq">{t("Média de dias até o Recrutamento")}</p>
              <p className="body-item-faq">2</p>
              <p className="body-item-faq">1</p>
              <p className="body-item-faq">(2+1)/2=1,5</p>
            </div>
            <div className="body-table-faq">
              <p className="body-item-faq">{t("Média de dias até a Avaliação")}</p>
              <p className="body-item-faq">1</p>
              <p className="body-item-faq">1</p>
              <p className="body-item-faq">(1+1)/2=1</p>
            </div>
            <div className="body-table-faq">
              <p className="body-item-faq">{t("Média de dias até a Seleção")}</p>
              <p className="body-item-faq">2</p>
              <p className="body-item-faq">3</p>
              <p className="body-item-faq">(2+3)/2=2,5</p>
            </div>
            <div className="body-table-faq">
              <p className="body-item-faq">{t("Média de dias até a Apresentação")}</p>
              <p className="body-item-faq">2</p>
              <p className="body-item-faq">2</p>
              <p className="body-item-faq">(2+2)/2=2</p>
            </div>
            <div className="body-table-faq">
              <p className="body-item-faq">{t("Média de dias até o Encerramento")}</p>
              <p className="body-item-faq">3,3</p>
              <p className="body-item-faq">4</p>
              <p className="body-item-faq">(3,3+4)/2=5,3</p>
            </div>
          </div>
        </div>
        <p> </p>
        <p className="faq-rh-titles">{t("Como é calculada a média de dias até o Recrutamento?")} </p>
        <p>{t("Para calcular a média de dias é preciso saber o período de recrutamento de todas as oportunidades do consolidado.O período de recrutamento de uma oportunidade é a média dos períodos de recrutamento das vagas daquela oportunidade")}.</p>
        <p> </p>
        <p className="faq-rh-titles">{t("Como é calculada a média de dias até a Avaliação?")} </p>
        <p>{t("Para calcular a média de dias é preciso saber o período de avaliação de todas as oportunidades do consolidado. O período de avaliação de uma oportunidade é a média dos períodos de avaliação das vagas da oportunidade")}.</p>
        <p> </p>
        <p className="faq-rh-titles">{t("Como é calculada a média de dias até a Seleção e a média de dias até a Apresentação?")}</p>
        <p>{t("As duas etapas seguintes funcionam da mesma forma")}: {t("acompanhando a corrida dos profissionais candidatos de acordo com o número total de vagas da oportunidade")}.</p>
        <p> </p>
        <p className="faq-rh-titles">{t("O que acontece quando um período inicia e se encerra no mesmo dia?")}</p>
        <p>{t("Imagine que vc publicou a oportunidade registrou o recrutamento positivo de um profissional no dia 01/01. O período de recrutamento desse profissional seria 0. Entretanto, no Enter considera que esta atividade tomou uma parte do seu dia de trabalho e considera esse período como 0,25. Ou seja, 1/4 de dia. Isso se repete para todos os períodos calculados para os indicadores. Se início e encerramento de uma vaga ocorrem no mesmo dia contam 1, considerando que ela ficou ¼ de dia (0,25) em cada etapa")}.</p>
        <p className="faq-rh-titles">
          <br />
          <h5>{t("ENCERRAMENTO DE OPORTUNIDADES")}</h5>
        </p>
        <p> </p>
        <p className="faq-rh-titles">{t("O que significa encerrar uma vaga com vitória?")}</p>
        <p>{t("A vitória de uma vaga pertence a um profissional. A data de registro de uma vitória é a data de quando o profissional teve seu status alterado para Selecionado em sua página de candidatura")}.</p>
        <p> </p>
        <p className="faq-rh-titles">{t("O que significa encerrar uma vaga com derrota?")}</p>
        <p>{t("Para fins de auditoria e controle, sempre que uma recrutadora marca uma derrota no card expandido, o sistema guarda a data da derrota e quem fez a alteração. Entretanto, os encerramentos com derrotas não impactam os indicadores quando a oportunidade é encerrada no Enter. A data de encerramento da oportunidade é usada para calcular o período até o encerramento das vagas que foram derrota")}. </p>
        <p><br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </p>
      </div>
      :
      <div className="container">
        {/* <SEO title='Enter - FAQ' url='https://elumini.enter.jobs/faq'/> */}
        <header className="faq-header">
          <h4>FAQ</h4>
        </header>
        <ul className="faq-items">
          <h4>{t("MOSAICO")}</h4>
          <ol>
            <li>
              <strong>
                {t("Como alterar a cor de uma peca do meu Mosaico?")}
              </strong>
              <ul>
                <li>
                  {t(
                    "Posicione o cursor na peca, clique na palheta de cores do canto superior esquerdo e escolha a cor que quiser."
                  )}
                </li>
              </ul>
            </li>
            <br />
            <li>
              <strong>
                {t("Como alterar o tamanho de uma peça do meu Mosaico?")}
              </strong>
              <ul>
                <li>
                  {t("Posicione o cursor na peça, clique e arraste na seta do canto inferior direito e aumente ou diminua o tamanho.")}
                </li>
              </ul>
            </li>
            <br />
            <li>
              <strong>{t("Como mudar a posição de uma peça do meu Mosaico?")}</strong>
              <ul>
                <li>
                  {t("Posicione o cursor na peça, clique em cima da peça e arraste para aposição que quiser.")}
                </li>
              </ul>
            </li>
            <br />
            <li>
              <strong>{t("Como excluir uma peça do meu Mosaico?")}</strong>
              <ul>
                <li>
                  {t("Posicione o cursor na peça e clique no “X” vermelho que aparece no canto superior direito.")}
                </li>
              </ul>
            </li>
            <br />
            <li>
              <strong>
                {t("Por que não consigo incluir mais peças no meu Mosaico?")}
              </strong>
              <ul>
                <li>
                  {t("O Mosaico representa seu momento profissional - os assuntos com que você mais quer trabalhar e seus interesses pessoais. Por isso ele só pode ter 8 peças. Mas, não se preocupe. Na busca por candidatos, o Enter leva em conta todos os itens do seu Perfil. A diferença é que as peças do Mosaico recebem pontuação extra. Assim, a gente consegue selecionar as oportunidades mais parecidas com seu momento.")}
                </li>
              </ul>
            </li>
            <br />
            <li>
              <strong>
                {t("Quantas peças de cada tipo posso incluir no meu Mosaico?")}
              </strong>
              <ul>
                <li>
                  {t("As restrições são somente para peças de Interesses. Todo mundo tem um interesse ao buscar um lugar para trabalhar. Então é preciso incluir no mínimo 1 e no máximo 3 peças deste tipo.")}
                </li>
              </ul>
            </li>
            <br />
            <li>
              <strong>
                {t("Posso incluir uma foto no Mosaico ou substituir a foto atual?")}
              </strong>
              <ul>
                <li>
                  {t("Sim, na lateral direita da tela do Mosaico existem 2 botões um para você incluir uma foto ou substituir a atual e outro para remover a foto.")}
                </li>
              </ul>
            </li>
          </ol>
          <br />
          <br />
          <h3>{t("ANEXOS")}</h3>
          <ol>
            <li>
              <strong>{t("Como enviar meu currículo?")}</strong>
              <ul>
                <li>
                  {t("Você pode anexar seu currículo ao se candidatar a uma oportunidade. Assim, é possível ter um currículo específico para cada uma de suas candidaturas.")}
                </li>
              </ul>
            </li>
            <br />
            <li>
              <strong>
                {t("Como enviar arquivos (ex. minha monografia, uma carta de recomendação, artigos publicados etc.)")}?
              </strong>
              <ul>
                <li>{t("Você pode anexar documentos na opção “Meu Perfil”.")}</li>
              </ul>
            </li>
          </ol>
          <br />
          <br />
          <h3>{t("OPORTUNIDADES DE TRABALHO")}</h3>
          <ol>
            <li>
              <strong>
                {t("Onde encontro as oportunidades de trabalho no Enter?")}
              </strong>
              <ul>
                <li>
                  {t("No menu superior existe a opção “Oportunidades”. É possível filtrar por área de atuação e estado.")}
                </li>
              </ul>
            </li>
            <br />
            <li>
              <strong>
                {t("Como saber se ainda posso me candidatar a uma oportunidade?")}
              </strong>
              <ul>
                <li>
                  {t("Todas as oportunidades disponíveis para candidaturas aparecem na opção “Oportunidades” do menu superior. É possível filtrar por área de atuação e estado.")}
                </li>
              </ul>
            </li>
          </ol>
        </ul>
      </div>
    )
  }
}

