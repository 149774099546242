import { Component } from "react"
import { Redirect, Route } from "react-router"
import { AuthenticationService } from "../services/auth.service"


export class ProtectedRoute extends Component<any> {
  render() {
    const { component: Component, ...props } = this.props

    return (
      <Route 
        {...props} 
        render={props => (
          (AuthenticationService.isRh()) ?
            <Component {...props} /> :
            <Redirect to='/login' />
        )} 
      />
    )
  }
}