import service from "./api.service";

class CriteriaService {
  static getCriteriaMatchTemplate = () => {
    return service.get("criteria");
  };

  static createCriteriaMatchTemplate = (payload: {
    nome: string;
    termos: [];
  }) => {
    return service.post("criteria", payload);
  };
}

export default CriteriaService;
