import { Col, Row } from "@zendeskgarden/react-grid";
import { Tab, TabList, TabPanel, Tabs } from "@zendeskgarden/react-tabs";
import { Label, Textarea } from "@zendeskgarden/react-forms";
import { ThemeProvider } from "@zendeskgarden/react-theming";
import { Tooltip } from "@zendeskgarden/react-tooltips";
// @ts-ignore
import ReactFilestack from "filestack-react";
import Moment from "moment";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import { geocodeByPlaceId } from "react-google-places-autocomplete";
import "react-phone-number-input/style.css";
import Loading from "../../../components/loading";
import avatar from "../../../images/avatar.jpg";
import { ReactComponent as AccountIconSelected } from "../../../images/icons/profile/ic_account_circle_24px-selected.svg";
import { ReactComponent as AccountIcon } from "../../../images/icons/profile/ic_account_circle_24px.svg";
import { ReactComponent as ChatIconSelected } from "../../../images/icons/profile/ic_chat_bubble_outline_24px-selected.svg";
import { ReactComponent as ChatIcon } from "../../../images/icons/profile/ic_chat_bubble_outline_24px.svg";
import { ReactComponent as DashboardIconSelected } from "../../../images/icons/profile/ic_dashboard_24px-selected.svg";
import { ReactComponent as DashboardIcon } from "../../../images/icons/profile/ic_dashboard_24px.svg";
import { ReactComponent as DirectionsIconSelected } from "../../../images/icons/profile/ic_directions_walk_24px-selected.svg";
import { ReactComponent as DirectionsIcon } from "../../../images/icons/profile/ic_directions_walk_24px.svg";
import { UserData } from "../../../model/user.model";
import { AlertService } from "../../../services/alert.service";
import { AuthenticationService } from "../../../services/auth.service";
import DictionaryService from "../../../services/dictionary.service";
import carreiraService from "../../../services/carreiras.service";
import UserService from "../../../services/user.service";
import { t } from "../../../translations/translation.service";
import { theme } from "../../../util/colorButton";
import style from "./index.module.scss";
import { tabProfileOne } from "./profile-tab-1";
import { tabProfileTwo } from "./profile-tab-2";
import { tabProfileThree } from "./profile-tab-3";
import { tabProfileFour } from "./profile-tab-4";
import { coinType, priceRange } from '../../../util/opportunity';
import DeletedProfileService from "../../../services/deletedProfile.service";
import OpportunityService from "../../../services/opportunity.service";
import AlertSkillTips from "./components/alert-skill-tips";
import FileStackService from "../../../services/filestack.service";
const { REACT_APP_FILESTACK_API } = process.env;

const dateFormat = "DD/MM/YYYY";

class ProfessionalProfile extends React.Component<any> {
  formControlSkillRef: any;
  formControlCoursesRef: any;
  formControlLanguagesRef: any;
  formControlCertificationsRef: any;
  formControlPositionsRef: any;
  formControlVolunteerRef: any;
  mosaicRef!: any;

  constructor(props: any) {
    super(props);
    this.setSelectedTab = this.setSelectedTab.bind(this);
    this.setCarreer = this.setCarreer.bind(this);
    this.deleteCarreer = this.deleteCarreer.bind(this);
    this.setInterest = this.setInterest.bind(this);
    this.deleteInterest = this.deleteInterest.bind(this);
    this.setSkill = this.setSkill.bind(this);
    this.deleteSkill = this.deleteSkill.bind(this);
    this.updateLevelByName = this.updateLevelByName.bind(this);
    this.setCourses = this.setCourses.bind(this);
    this.deleteCourses = this.deleteCourses.bind(this);
    this.setLanguages = this.setLanguages.bind(this);
    this.deleteLanguages = this.deleteLanguages.bind(this);
    this.setPositions = this.setPositions.bind(this);
    this.deletePositions = this.deletePositions.bind(this);
    this.setCertifications = this.setCertifications.bind(this);
    this.deleteCertifications = this.deleteCertifications.bind(this);
    this.deletePositions = this.deletePositions.bind(this);
    this.setVolunteer = this.setVolunteer.bind(this);
    this.deleteVolunteer = this.deleteVolunteer.bind(this);

    this.addOrRemoveInMosaic = this.addOrRemoveInMosaic.bind(this);
    this.selectOrDeselectInMosaic = this.selectOrDeselectInMosaic.bind(this);
    this.includeLocation = this.includeLocation.bind(this);
    this.removeLocation = this.removeLocation.bind(this);
    this.changeLevelIndex = this.changeLevelIndex.bind(this);
    this.formControlSkillRef = React.createRef();
    this.formControlCoursesRef = React.createRef();
    this.formControlLanguagesRef = React.createRef();
    this.formControlCertificationsRef = React.createRef();
    this.formControlPositionsRef = React.createRef();
    this.formControlVolunteerRef = React.createRef();
    this.mosaicRef = React.createRef();
    this.updateMosaic = this.updateMosaic.bind(this);
    this.handleInputIndication = this.handleInputIndication.bind(this);
    this.removeItemFromMosaic = this.removeItemFromMosaic.bind(this);
    this.getRemainsItemCount = this.getRemainsItemCount.bind(this);
    this.handleCheckboxes = this.handleCheckboxes.bind(this);
    this.getContractType = this.getContractType.bind(this);
    this.listSkillTips = this.listSkillTips.bind(this);
    this.policeAndSignatureString = this.policeAndSignatureString.bind(this);
    this.getFileStackPolicy = this.getFileStackPolicy.bind(this);
    this.setUrlImg = this.setUrlImg.bind(this);
  }

  state = {
    step: undefined,
    user: {
      hiringModel: {
        clt: false,
        pj: false,
        others: false,
      },
      opportunityType: {
        remote: false,
        hybrid: false,
        inPerson: false,
      },
    } as UserData,
    editLevelIndex: -1,
    loading: false,
    selectedTab: "tab-1",
    careers: [],
    interests: [],
    // expandedSections: [] as number[],
    mosaicItemsLimit: 7,
    itensRestantes: 0,
    width: 0,
    deleteUser: false,
    priceRangeArray: priceRange,
    coinTypeArray: coinType,
    contractTypeArray: [] as string[],
    showPretensao: false,
    deletedCareer: [] as string[],
    pj: {
      moeda: "-",
      valor: "-",
    },
    clt: {
      moeda: "-",
      valor: "-",
    },
    others: {
      moeda: "-",
      valor: "-",
    },
    comment: '',
    skillTips: [],
    policyString: '' as string,
    signatureString: '' as string,
    policyAndSignatureImg: '' as string,
    policyAndSignature: [] as string[],
  };

  isInValidFields = (self: any): boolean => {
    let isInvalid = false;
    self.state.contractTypeArray.forEach((tipo: string) => {
      if (self.state.user.perfilUsuario.pretensao.tipo[tipo]) {
        if (
          (self.state.user.perfilUsuario.pretensao.tipo[tipo].moeda.includes('BRL' || 'USD' || 'EUR')
            &&
          self.state.user.perfilUsuario.pretensao.tipo[tipo].valor.includes('-'))
           ||
          (self.state.user.perfilUsuario.pretensao.tipo[tipo].moeda.includes('-')
           &&
          self.state.user.perfilUsuario.pretensao.tipo[tipo].valor.trim() !== '-')
        ) {
          isInvalid = true;
          return isInvalid;
        }
      }
    })
    return isInvalid;
  };

  getContractType(contract: any): string[] {
    const contractType: string[] = [];
    for (const index in contract) {
      if (contract[index]) contractType.push(index);
    }
    return contractType;
  }

  async getFileStackPolicy(): Promise<void> {
    try {
      if (
          this.state.user &&
          this.state.user.userImageUrl &&
          this.getNewFileStackUrl(this.state.user.userImageUrl)
        ) {
        const policyUserImg = await FileStackService.getFileStackSecreteCodeToUser(
          this.state.user.userImageUrl, false
        );
        this.setState({ policyAndSignatureImg: policyUserImg.data });
      }
    } catch(err: any) {
      console.log("Erro ao obter credenciais - ERROR: ", err);
    }
  }

  async policeAndSignatureString(): Promise<void> {
    try {
      const policyAndSignature = await FileStackService.getPoliceAndSignature();
      this.setState({
        policyString: policyAndSignature.data.policy,
        signatureString: policyAndSignature.data.signature
      });
    } catch(err: any) {
      console.log("ERROR: ", err);
    }
  }

  reloadPage(): void {
    window.location.reload();
  }

  getNewFileStackUrl(url: string): boolean {
    const domain = url.split('.com/')[0].split('//')[1]
    if (domain !== 'cdn.filestackcontent') {
      return false;
    }
    return true;
  }

  setUrlImg(url: string): string {
    const { policyAndSignatureImg } = this.state;
    const splitedUrl = url.split('.com');
    splitedUrl.splice(1,0, '.com'+policyAndSignatureImg);
    const newUrl = splitedUrl.join('');
    return newUrl;
  }

  async componentDidMount() {
    const { id } = this.props.match.params;
    const params = new URLSearchParams(this.props.location.search);
    const step = params.get("step");
    if (AuthenticationService.getUser()._id !== id) {
      this.props.history.push("/login");
    }
    this.setState({ loading: true });
    await this.policeAndSignatureString();
    await this.getDomains();
    UserService.getUserWithoutOpportunities(id)
      .then((resp: any) => {
        this.setState(
          {
            user: resp.data.user,
            step,
          },
          async () => {
            this.setState({ loading: false });
            this.verifyAmountsOfItems(
              this.state.user.perfilUsuario.mosaico.escolhidas
            );
            await this.getFileStackPolicy();
            this.listSkillTips();

            if (!this.state.user.opportunityType) {
              const opportunityType = {
                remote: false,
                hybrid: false,
                inPerson: false,
              };
              this.state.user.opportunityType = opportunityType;
            }
            if (!this.state.user.hiringModel) {
              const hiringModel = {
                clt: false,
                pj: false,
                others: false,
              };
              this.state.user.hiringModel = hiringModel;
            }
            if (this.state.user.homeOffice) {
              const opportunityType = {
                remote: resp.data.user.homeOffice,
                hybrid: this.state.user.opportunityType.hybrid,
                inPerson: this.state.user.opportunityType.inPerson,
              };
              this.setState({
                user: { ...this.state.user, opportunityType },
              });
            }
            if (!this.state.user.perfilUsuario.pretensao) {
              const perfilUsuario = this.state.user.perfilUsuario;
              perfilUsuario.pretensao = {
                tipo: {
                  pj: {
                    moeda: "-",
                    valor: "-",
                  },
                  clt: {
                    moeda: "-",
                    valor: "-",
                  },
                  others: {
                    moeda: "-",
                    valor: "-",
                  },
                },
              };
              this.setState({
                user: { ...this.state.user, perfilUsuario },
              });
            } else if (
              this.state.user.perfilUsuario.pretensao &&
              !this.state.user.perfilUsuario.pretensao.tipo
            ) {
              const perfilUsuario = this.state.user.perfilUsuario;
              perfilUsuario.pretensao = {
                tipo: {
                  pj: {
                    moeda: "-",
                    valor: "-",
                  },
                  clt: {
                    moeda: resp.data.user.perfilUsuario.pretensao.moeda,
                    valor: resp.data.user.perfilUsuario.pretensao.valor,
                  },
                  others: {
                    moeda: "-",
                    valor: "-",
                  },
                },
              };
              this.setState(
                {
                  user: { ...this.state.user, perfilUsuario },
                },
                () => {
                  this.setState({
                    clt: this.state.user.perfilUsuario.pretensao.tipo.clt,
                  });
                }
              );
            } else {
              if (this.state.user.perfilUsuario.pretensao?.tipo?.pj) {
                this.setState({
                  pj: this.state.user.perfilUsuario.pretensao.tipo.pj,
                });
              }
              if (this.state.user.perfilUsuario.pretensao?.tipo?.clt) {
                this.setState({
                  clt: this.state.user.perfilUsuario.pretensao.tipo.clt,
                });
              }
              if (this.state.user.perfilUsuario.pretensao?.tipo?.others) {
                this.setState({
                  others: this.state.user.perfilUsuario.pretensao.tipo.others,
                });
              }
            }
            this.setState({
              contractTypeArray: this.getContractType(
                this.state.user.hiringModel
              ),
            });
            this.findDeletedCareer();
          }
        );
      })
      .catch((err: any) => console.error(err));
  }

  async componentDidUpdate(_prevProps: any, prevState: any) {
    if (
      prevState.user.opportunityType &&
      prevState.user.opportunityType.remote &&
      prevState.user.opportunityType.remote !==
        this.state.user.opportunityType.remote
    ) {
      this.setState({
        user: {
          ...this.state.user,
          homeOffice: this.state.user.opportunityType.remote,
        },
      });
    }
    if (
      prevState.user.hiringModel &&
      prevState.user.hiringModel !== this.state.user.hiringModel
    ) {
      this.setState({
        contractTypeArray: this.getContractType(this.state.user.hiringModel),
      }, () => {
        if (!this.state.user.hiringModel.pj) {
          const { perfilUsuario } = this.state.user;
          if (perfilUsuario.pretensao?.tipo && perfilUsuario.pretensao?.tipo?.pj) {
            perfilUsuario.pretensao.tipo.pj = { moeda: '-', valor: '-' }
            this.setState({ user: { ...this.state.user, perfilUsuario }})
          }
        }
        if (!this.state.user.hiringModel.clt) {
          const { perfilUsuario } = this.state.user;
          if (perfilUsuario.pretensao?.tipo && perfilUsuario.pretensao?.tipo?.clt) {
            perfilUsuario.pretensao.tipo.clt = { moeda: '-', valor: '-' }
            this.setState({ user: { ...this.state.user, perfilUsuario }})
          }
        }
        if (!this.state.user.hiringModel.others) {
          const { perfilUsuario } = this.state.user;
          if (perfilUsuario.pretensao?.tipo && perfilUsuario.pretensao?.tipo?.others) {
            perfilUsuario.pretensao.tipo.others = { moeda: '-', valor: '-' }
            this.setState({ user: { ...this.state.user, perfilUsuario }})
          }
        }
      });
    }
    if (prevState.user.userImageUrl !== this.state.user.userImageUrl) {
      await this.getFileStackPolicy();
    }
  }

  setSelectedTab($event: string): void {
    this.setState({ selectedTab: $event });
  }

  setCarreer(nomeCaption: string): void {
    this.pushMosaicByNameCaption(nomeCaption, "rh", "carreiras");
  }

  deleteCarreer(index: number): void {
    this.filterMosaicByNameCaption(
      this.getUserProfileMosaicItem("carreiras").splice(index, 1)
    );
  }

  setInterest(nomeCaption: string): void {
    this.pushMosaicByNameCaption(nomeCaption, "attitudes", "euquero");
  }

  deleteInterest(index: number): void {
    this.filterMosaicByNameCaption(
      this.getUserProfileMosaicItem("attitudes").splice(index, 1)
    );
  }

  setSkill(nomeCaption: string): void {
    const nomeCaptionWithoutSpace = nomeCaption.trim();
    this.pushListByNameCaption(nomeCaptionWithoutSpace, "skills", "digomais");
  }

  deleteSkill(index: number): void {
    this.filterListByNameCaption(
      this.getUserProfileList("skills").splice(index, 1)
    );
  }

  setCourses(nomeCaption: string): void {
    this.pushListByNameCaption(nomeCaption, "courses", "digomais");
  }

  deleteCourses(index: number): void {
    this.filterListByNameCaption(
      this.getUserProfileList("courses").splice(index, 1)
    );
  }

  setLanguages(nomeCaption: string): void {
    const nomeCaptionWithoutSpace = nomeCaption.trim();
    this.pushListByNameCaption(nomeCaptionWithoutSpace, "languages", "digomais");
  }

  deleteLanguages(index: number): void {
    this.filterListByNameCaption(
      this.getUserProfileList("languages").splice(index, 1)
    );
  }

  setPositions(nomeCaption: string): void {
    this.pushListByNameCaption(
      nomeCaption,
      "threeCurrentPositions",
      "digomais"
    );
  }

  deletePositions(index: number): void {
    this.filterListByNameCaption(
      this.getUserProfileList("threeCurrentPositions").splice(index, 1)
    );
  }

  setCertifications(nomeCaption: string): void {
    this.pushListByNameCaption(nomeCaption, "certifications", "digomais");
  }

  deleteCertifications(index: number): void {
    this.filterListByNameCaption(
      this.getUserProfileList("certifications").splice(index, 1)
    );
  }

  setVolunteer(nomeCaption: string): void {
    this.pushListByNameCaption(nomeCaption, "volunteer", "digomais");
  }

  deleteVolunteer(index: number): void {
    this.filterListByNameCaption(
      this.getUserProfileList("volunteer").splice(index, 1)
    );
  }

  pushMosaicByNameCaption(
    nomeCaption: string,
    subclassif: string,
    classif: string
  ): void {
    const user = this.state.user;
    if (
      !user.perfilUsuario.mosaico.escolhidas.some(
        (item) => item.detalhe.nomeCaption === nomeCaption
      )
    ) {
      user.perfilUsuario.mosaico.escolhidas.push({
        detalhe: {
          nomeCaption,
          subclassif,
          classif,
          nome: nomeCaption,
        },
      });
      this.setState({ user });
    }
  }

  pushListByNameCaption(
    nomeCaption: string,
    subclassif: string,
    classif: string
  ): void {
    const user = this.state.user;
    if (
      !user.perfilUsuario.digomais.lista.some(
        (item) =>
          item.nomeCaption.toLowerCase() === nomeCaption.toLowerCase() &&
          item.subclassif === subclassif
      )
    ) {
      user.perfilUsuario.digomais.lista.push({
        nomeCaption,
        subclassif,
        classif,
        nome: nomeCaption,
      });
      this.setState({ user });
    }
  }

  findInMosaic(skill: any): boolean {
    const item = this.state.user.perfilUsuario.mosaico.escolhidas.filter(
      (item: any) => item.detalhe.nomeCaption === skill.nomeCaption
    )[0];
    return !!item;
  }

  addOrRemoveInMosaic(
    classif: string,
    subclassif: string,
    nomeCaption: string
  ): void {
    const user = this.state.user;
    const index = user.perfilUsuario.mosaico.escolhidas.findIndex(
      (item) =>
        item.detalhe.classif === classif &&
        item.detalhe.subclassif === subclassif &&
        item.detalhe.nomeCaption === nomeCaption
    );
    if (index > -1) {
      user.perfilUsuario.mosaico.escolhidas.splice(index, 1);
    } else {
      const item = {
        posicao: {},
        detalhe: {
          nome: nomeCaption,
          classif,
          subclassif,
          nomeCaption,
        },
      };
      var rowToAdd = this.canAddInMosaic(item);
      if (rowToAdd) {
        item.posicao = {
          row: rowToAdd,
          col: 1,
          sizex: this.getItemSizeX(item.detalhe.nome),
          sizey: 1,
          color: "#ed961c",
        };
        user.perfilUsuario.mosaico.escolhidas.push(item);
      }
    }
    this.setState({ user });
  }

  getRemainsItemCount(): number {
    return (
      this.state.mosaicItemsLimit -
        this.state.user.perfilUsuario?.mosaico?.escolhidas.filter(
          (item) => item.posicao
        ).length || 0
    );
  }

  canAddInMosaic = (item: any) => {
    if (this.getRemainsItemCount() === 0) {
      return false;
    }
    let add = -1;
    let arrayEmptyCells = [6, 6, 6, 6, 6];
    let sizeX = this.getItemSizeX(item.detalhe.nome);
    this.state.user.perfilUsuario.mosaico.escolhidas.forEach(function (card) {
      if (card.posicao) {
        for (let i = 0; i < card.posicao.sizey; i++) {
          arrayEmptyCells[card.posicao.row - 1 + i] -= card.posicao.sizex;
        }
      }
    });
    for (var i = 0; i < 5; i++) {
      if (arrayEmptyCells[i] >= sizeX) add = i + 1;
    }
    return add;
  };

  getItemSizeX(value: string) {
    var l = value.length;
    if (l <= 12) {
      return 2;
    } else if (l > 12 && l <= 22) {
      return 3;
    } else if (l > 22 && l <= 33) {
      return 4;
    } else if (l > 33 && l <= 45) {
      return 5;
    } else {
      return 6;
    }
  }

  verifyAmountsOfItems(mosaico: any) {
    let mosaicoIndicators = this.getMosaicoIndicators(mosaico);

    const indicatorsTotal =
      parseInt(mosaicoIndicators.euQueroSize) +
      parseInt(mosaicoIndicators.euSouSize) +
      parseInt(mosaicoIndicators.euSeiSize) +
      parseInt(mosaicoIndicators.euDigoMaisSize);

    var qtRestantes = this.state.mosaicItemsLimit - indicatorsTotal;
    const itensRestantes = qtRestantes < 0 ? 0 : qtRestantes;
    this.setState({ itensRestantes });

    if (indicatorsTotal > this.state.mosaicItemsLimit - 1) {
      return false;
    } else {
      return true;
    }
  }

  updateMosaic(mosaic: any) {
    const user = this.state.user;
    user.perfilUsuario.mosaico.escolhidas.map((item) => {
      const find = mosaic.find(
        (i: any) => i.detalhe.nome === item.detalhe.nome
      );
      return find ? find : item;
    });
    this.setState({ user });
  }

  getMosaicoIndicators(mosaico: any): any {
    let euQueroSize = 0,
      euSouSize = 0,
      euSeiSize = 0,
      euDigoMaisSize = 0,
      carreirasSize = 0,
      estadosSize = 0,
      habilidadesSize = 0,
      atitudesSize = 0,
      formacoesSize = 0,
      cargosSize = 0,
      certPremiosSize = 0,
      voluntariadoSize = 0,
      idiomasSize = 0;

    for (var i = 0; i < mosaico.length; i++) {
      switch (mosaico[i].detalhe.classif) {
        case "euquero":
          euQueroSize = euQueroSize + 1;
          break;
        case "eusou":
          euSouSize = euSouSize + 1;
          break;
        case "eusei":
          euSeiSize = euSeiSize + 1;
          break;
        case "digomais":
          euDigoMaisSize = euDigoMaisSize + 1;
          break;
        case "carreiras":
          carreirasSize = carreirasSize + 1;
          break;
        case "estados":
          estadosSize = estadosSize + 1;
          break;
      }
      const subClassif = mosaico[i].detalhe.subclassif;
      if (subClassif.match("skills")) habilidadesSize = habilidadesSize + 1;
      else if (subClassif.match("attitudes")) atitudesSize = atitudesSize + 1;
      else if (subClassif.match("courses|educations"))
        formacoesSize = formacoesSize + 1;
      else if (subClassif.match("threeCurrentPositions|threePastPositions"))
        cargosSize = cargosSize + 1;
      else if (subClassif.match("certifications|honorsAwards"))
        certPremiosSize = certPremiosSize + 1;
      else if (subClassif.match("volunteer"))
        voluntariadoSize = voluntariadoSize + 1;
      else if (subClassif.match("languages")) idiomasSize = idiomasSize + 1;
    }

    return {
      euQueroSize: euQueroSize,
      euSouSize: euSouSize,
      euSeiSize: euSeiSize,
      euDigoMaisSize: euDigoMaisSize,
      carreirasSize: carreirasSize,
      estadosSize: estadosSize,
      idiomasSize: idiomasSize,
      habilidadesSize: habilidadesSize,
      atitudesSize: atitudesSize,
      formacoesSize: formacoesSize,
      cargosSize: cargosSize,
      voluntariadoSize: voluntariadoSize,
      certPremiosSize: certPremiosSize,
    };
  }

  selectOrDeselectInMosaic(
    classif: string,
    subclassif: string,
    nomeCaption: string
  ): void {
    const user = this.state.user;
    user.perfilUsuario.mosaico.escolhidas.map((item) => {
      if (
        item.detalhe.classif === classif &&
        item.detalhe.subclassif === subclassif &&
        item.detalhe.nomeCaption === nomeCaption
      ) {
        if (item.posicao) {
          delete item.posicao;
        } else {
          const row = this.canAddInMosaic(item);
          if (row) {
            item.posicao = {
              row,
              col: 1,
              sizex: this.getItemSizeX(item.detalhe.nome),
              sizey: 1,
              color: "#ed961c",
            };
          }
        }
      }
      return item;
    });
    this.setState({ user });
  }

  filterMosaicByNameCaption(list: any[]): void {
    const user = this.state.user;
    list.forEach((item: any) => {
      user.perfilUsuario.mosaico.escolhidas =
        user.perfilUsuario.mosaico.escolhidas.filter(
          (skill) => skill.detalhe.nome !== item.detalhe.nomeCaption
        );
    });
    this.setState({ user });
  }

  filterListByNameCaption(list: any[]): void {
    const user = this.state.user;
    list.forEach((item: any) => {
      user.perfilUsuario.digomais.lista =
        user.perfilUsuario.digomais.lista.filter(
          (skill) => skill.nome !== item.nomeCaption
        );
    });
    this.setState({ user });
  }

  updateLevelByName(name: string, level: number): void {
    const user = this.state.user;
    user.perfilUsuario.digomais.lista = user.perfilUsuario.digomais.lista.map(
      (skill) => {
        if (skill.nome === name) {
          skill.level = level;
        }
        return skill;
      }
    );
    this.setState({ user });
  }

  async getDomains(): Promise<void> {
    try {
      const domains = await DictionaryService.getDictionary();
      const career = await carreiraService.getAllCarreiras();
      this.setState({
        careers: career.data,
        interests: domains.data.euquero,
      });
    } catch (error) {
      console.error(error);
    }
  }

  includeLocation(data: any) {
    geocodeByPlaceId(data.value.place_id)
      .then((geocode: any) => {
        const user = this.state.user;
        let location = user.perfilUsuario.interestLocations?.slice() || [];
        if (
          !location.some(
            (item) => item.formatted_address === geocode[0].formatted_address
          )
        ) {
          location.push(geocode[0]);
          user.perfilUsuario.interestLocations = location;
          this.setState({ user });
        }
      })
      .catch((error: any) => console.error(error));
  }

  removeLocation = (index: number) => {
    const user = this.state.user;
    let location = user.perfilUsuario.interestLocations.slice();
    location.splice(index, 1);
    user.perfilUsuario.interestLocations = location;
    this.setState({ user });
  };

  changeLevelIndex = (index: number) => {
    if (index === this.state.editLevelIndex) {
      this.setState({ editLevelIndex: -1 });
    } else {
      this.setState({ editLevelIndex: index });
    }
  };

  handleCheckboxes(event: any) {
    const target = event.target;
    const name = target.name;
    const typeModel = ["clt", "pj", "others"];
    if (typeModel.includes(name)) {
      const hiringModel = {
        ...this.state.user.hiringModel,
        [name]: target.checked,
      };
      this.setState({
        user: { ...this.state.user, hiringModel },
      });
    } else {
      const opportunityType = {
        ...this.state.user.opportunityType,
        [name]: target.checked,
      };
      this.setState({
        user: { ...this.state.user, opportunityType },
      });
    }
  }

  async listSkillTips() {
    const data = (await OpportunityService.getCriteriaUnmatch(this.state.user._id)).data;

    if (data && data.length > 0) {
      this.setState({ skillTips: data.map((item: any) => item.nome) });
    }
  }

  next(tabName: string): void {
    this.setState({ selectedTab: tabName });
    const validCompensationExpectation = this.isInValidFields(this);
    if (validCompensationExpectation) {
      AlertService.error(t("Pretensão salarial obrigatória. Os campos moeda e faixa devem ser preenchidos"));
      return;
    }

    UserService.updateUser(this.state.user)
      .then(() => {
        AlertService.success(t("Salvo com sucesso"));
      })
      .catch((error: any) => {
        console.error(error);
        AlertService.error(t("Erro ao tentar atualizar os dados do usuário"));
      });

    this.listSkillTips();
  }

  getUserProfileMosaicItem(classif: string): any[] {
    if (
      this.state.user &&
      this.state.user.perfilUsuario &&
      this.state.user.perfilUsuario.mosaico.escolhidas
    ) {
      return this.state.user.perfilUsuario.mosaico.escolhidas.filter(
        (skill) =>
          skill.detalhe.subclassif === classif ||
          skill.detalhe.classif === classif
      );
    }
    return [];
  }

  getUserProfileList(subclassif: string): any[] {
    return (
      this.state.user.perfilUsuario?.digomais.lista.filter(
        (skill) =>
          skill.subclassif === subclassif || skill.classif === subclassif
      ) || []
    );
  }

  findDeletedCareer(): void {
    const careers = this.getUserProfileMosaicItem("carreiras").filter(
      (carreira: any) => !this.state.careers.some(
          (career: any) => carreira.detalhe.nome === career.nome
        )
      );
    this.setState({ deletedCareer: careers });
  }

  get chatTab(): any {
    return (
      <Tab
        className={`col-12 col-md-3 text-left ${style["tab-profile"]}`}
        item="tab-1"
      >
        {this.state.selectedTab !== "tab-1" && (
          <ChatIcon className="mr-3"></ChatIcon>
        )}
        {this.state.selectedTab === "tab-1" && (
          <ChatIconSelected className="mr-3"></ChatIconSelected>
        )}
        <span>{t("Fale mais sobre você")}</span>
      </Tab>
    );
  }

  get accountTab(): any {
    return (
      <Tab
        className={`col-12 col-md-3 text-left ${style["tab-profile"]}`}
        item="tab-3"
      >
        {this.state.selectedTab !== "tab-3" && (
          <AccountIcon className="mr-3"></AccountIcon>
        )}
        {this.state.selectedTab === "tab-3" && (
          <AccountIconSelected className="mr-3"></AccountIconSelected>
        )}
        <span>{t("Escolha a foto do perfil")}</span>
      </Tab>
    );
  }

  get dashboardTab(): any {
    return (
      <Tab
        className={`col-12 col-md-3 text-left ${style["tab-profile"]}`}
        item="tab-2"
      >
        {this.state.selectedTab !== "tab-2" && (
          <DashboardIcon className="mr-3"></DashboardIcon>
        )}
        {this.state.selectedTab === "tab-2" && (
          <DashboardIconSelected className="mr-3"></DashboardIconSelected>
        )}
        <span>{t("Crie seu mosaico profissional")}</span>
      </Tab>
    );
  }

  get directionsTab(): any {
    return (
      <Tab
        className={`col-12 col-md-3 text-left ${style["tab-profile"]}`}
        item="tab-4"
      >
        {this.state.selectedTab !== "tab-4" && (
          <DirectionsIcon className="mr-3"></DirectionsIcon>
        )}
        {this.state.selectedTab === "tab-4" && (
          <DirectionsIconSelected className="mr-3"></DirectionsIconSelected>
        )}
        <span>{t("Conte como chegou aqui")}</span>
      </Tab>
    );
  }

  // setExpandedSections(expandedSections: number[]): void {
  //   this.setState({ expandedSections });
  // }

  onFiles = ({ filesUploaded }: any) => {
    const userImageUrl = filesUploaded.map(function (item: any) {
      return {
        fileName: item.filename,
        url: item.url,
      };
    })[0].url;
    this.setState({
      user: { ...this.state.user, userImageUrl },
    }, () => {
      UserService.updateUser(this.state.user)
      .then(() => {
        AlertService.success(t("Salvo com sucesso"));
      })
      .catch((error: any) => {
        console.error(error);
        AlertService.error(t("Erro ao tentar atualizar os dados do usuário"));
      });
    });
  };

  handleInputIndication(event: any) {
    const indicadoPor = event.target.value;
    const user = this.state.user;
    user.dadosPessoais.indicadoPor = indicadoPor;
    this.setState({ user });
  }

  removeItemFromMosaic(item: any) {
    if (
      this.getUserProfileMosaicItem("euquero").find(
        (_item) =>
          item.detalhe.nome === _item.detalhe.nome &&
          item.detalhe.classif === _item.detalhe.classif &&
          item.detalhe.subclassif === _item.detalhe.subclassif
      )
    ) {
      this.selectOrDeselectInMosaic(
        item.detalhe.classif,
        item.detalhe.subclassif,
        item.detalhe.nomeCaption
      );
    } else {
      this.addOrRemoveInMosaic(
        item.detalhe.classif,
        item.detalhe.subclassif,
        item.detalhe.nomeCaption
      );
    }
  }

  removeAttachment(index: number): void {
    const attachments = this.state.user.attachments.slice();
    attachments.splice(index, 1);
    this.setState(
      {
        user: { ...this.state.user, attachments },
      },
      () => this.next(this.state.selectedTab)
    );
  }

  async addAttachment({ filesUploaded }: any): Promise<void> {
    let array = filesUploaded.map(function (item: any) {
      return {
        fileName: item.filename,
        url: item.url,
      };
    });
    const attachments = this.state.user.attachments.slice();
    attachments.push(array[0]);
    const policyAndSignature = await FileStackService.getFileStackSecreteCodeToUser(
      array[0].url, false
    );
    this.setState(
      {
        user: { ...this.state.user, attachments, policyAndSignature: policyAndSignature.data },
      },
      () => {
        return (
          this.next(this.state.selectedTab),
          setTimeout(() => {
            this.reloadPage()
          }, 1000)
        )
      }
    );
  }

  addDeletedProfile = async (): Promise<void> => {
    const { displayName, email } = this.state.user;
    const payload = {
      userName: displayName,
      userEmail: email,
      comment: this.state.comment,
      created: new Date(),
    }
    try {
      await DeletedProfileService.addDeletedProfile(payload);
    } catch (error: any) {
      console.log("error ao inserir dados. ERROR: ", error);
    }
  }

  async deleteUser(): Promise<void> {
    try {
      this.setState({ loading: true });
      await this.addDeletedProfile();
      await UserService.deleteUser();
      AuthenticationService.logout();
      window.location.href = "/";
    } catch (error) {
      this.setState({ loading: false, deleteUser: false });
      AlertService.error(
        t(
          "Alguma coisa deu errado e não conseguimos excluir o seu cadastro agora. Você pode tentar de novo em alguns minutos. Pedimos desculpas por isso. =/"
        )
      );
      console.error(error);
    }
  }

  render() {
    return (
      <div className="container">
        <ThemeProvider theme={theme as any}>
          <Loading show={this.state.loading} />
          {/* Mensagem de boas vindas ao logar pelo email de validação */}
          <AlertSkillTips skillTips={this.state.skillTips} />
          {this.state.step === "welcome" && (
            <div className={style["welcome"]}>
              {t(
                "Bem-vind@ ao Enter! Pra encontrarmos as oportunidades que mais combinam com o seu perfil e seu momento atual, conte um pouco mais sobre você."
              )}
            </div>
          )}
          {this.state.step === "finished" && (
            <div className={style["welcome"]}>
              {t(
                'É isso aí! Seu perfil está completo! Edite suas informações sempre que quiser em "Perfil Profissional" e "Cadastro".'
              )}
            </div>
          )}
          {this.state.deletedCareer.length > 0 && (
            <div className={style['update-role-container']}>
              <header>
                {(`Olá ${this.state.user.displayName} parece que algumas funções não fazem mais parte do Enter, por gentileza atualize o campo (Com o que gostaria de trabalhar?)`)}
              </header>
              <section className={style['update-role-section']}>
                {t(`São essas as funções`)}:
                {this.state.deletedCareer.map((career: any, index: number) => (
                  <div
                    className={style['update-role-body']}
                    key={(index * 10).toString()}
                  >
                    {career.detalhe.nome}
                  </div>
                ))}
              </section>
            </div>
          )}
          {(this.state.step === "finished" || !this.state.step) && (
            <div>
              <div className={style["title-professional-profile"]}>
                <p>
                  <span className="h3">{t("Perfil profissional")}</span>
                  {"  "}
                  <span>
                    {t("Atualizado em")}:{" "}
                    {Moment(this.state.user.updated).format(dateFormat)}
                  </span>
                </p>
              </div>
              <Row className={style["info-professional-profile"] + " pt-4"}>
                <Col sm={8}>
                  <div className="d-flex">
                    <div
                      className="rounded-circle mr-4"
                      style={{
                        width: "76px",
                        height: "76px",
                        overflow: "hidden",
                      }}
                    >
                      <img
                        src={
                          this.state.user &&
                          this.state.user.userImageUrl &&
                          this.getNewFileStackUrl(this.state.user.userImageUrl)
                            ? this.setUrlImg(this.state.user.userImageUrl)
                            : this.state.user.userImageUrl
                              ? this.state.user.userImageUrl
                              : avatar
                        }
                        alt={this.state.user.displayName}
                        width="76"
                      />
                    </div>
                    <div>
                      <p className="h4">{this.state.user.displayName}</p>
                      <p className="h6">{this.state.user.email}</p>
                    </div>
                  </div>
                </Col>
                <Col sm={4} className="d-flex">
                  <div className={style["cv-prof-profile-container"]}>
                    <p className="h4" title={t("Incluir novo currículo")}>
                      {t("Curriculo(s)")}
                      {this.state.policyString.length > 0 && (
                        <ReactFilestack
                          apikey={REACT_APP_FILESTACK_API}
                          componentDisplayMode={{
                            type: "button",
                            customText: "+",
                            customClass: style["add-attachment"],
                          }}
                          actionOptions={{
                            accept: [".pdf", ".docx"],
                          }}
                          clientOptions={{
                            security: {
                              policy: this.state.policyString,
                              signature: this.state.signatureString,
                            }
                          }}
                          onSuccess={this.addAttachment.bind(this)}
                        />
                      )}
                    </p>
                    {this.state.user.attachments?.map((attachment, index) => (
                      <Tooltip
                        size="small"
                        placement="start"
                        type="light"
                        content={attachment.fileName}
                        key={attachment.fileName}
                      >
                        <p className="h6" key={attachment.fileName}>
                          {attachment.fileName}
                          {this.state.user.attachments.length > 1 && (
                            <button
                              title={t("Remover currículo")}
                              style={{
                                backgroundColor: "transparent",
                                color: "black",
                                border: "none",
                              }}
                              onClick={() => this.removeAttachment(index)}
                            >
                              &#x2715;
                            </button>
                          )}
                        </p>
                      </Tooltip>
                    ))}
                  </div>
                </Col>
              </Row>
            </div>
          )}

          <Tabs
            selectedItem={this.state.selectedTab}
            onChange={this.setSelectedTab}
          >
            <TabList>
              <Row className="mx-0 mt-4">
                {this.chatTab}
                {this.dashboardTab}
                {this.accountTab}
                {this.directionsTab}
              </Row>
            </TabList>
            <TabPanel
              className={`mb-4 ${style["tab-body"]}`}
              id="tab-1"
              item="tab-1"
            >
              {tabProfileOne(this)}
            </TabPanel>
            <TabPanel className={`mb-4 ${style["tab-body"]}`} item="tab-2">
              {tabProfileTwo(this)}
            </TabPanel>
            <TabPanel className={`mb-4 ${style["tab-body"]}`} item="tab-3">
              {tabProfileThree(this)}
            </TabPanel>
            <TabPanel className={`mb-4 ${style["tab-body"]}`} item="tab-4">
              {tabProfileFour(this)}
            </TabPanel>
          </Tabs>
          {this.state.step !== "welcome" && (
            <Row>
              <span
                className="ml-1 pb-4 btn"
                onClick={() => this.setState({ deleteUser: true })}
              >
                {t("Excluir meu perfil")}
              </span>
            </Row>
          )}
          <Modal
            centered
            show={this.state.deleteUser}
            onHide={() => this.setState({ deleteUser: false })}
          >
            <Modal.Header closeButton className="h5">
              {t("Tem certeza que quer excluir seu cadastro?")}
            </Modal.Header>
            <Modal.Body>
              <Col>
                {t(
                  "Se você excluir seu cadastro agora, não poderá acompanhar o andamento dos seus processos seletivos e vai ter que criar um cadastro novo se quiser se candidatar a uma vaga..."
                )}
              </Col>
              <Col className="mt-2">
                <Label style={{ fontSize: '16px'}}>
                  {t("Deixe nos saber o porquê da exclusão")}:
                </Label>
                <Textarea
                  placeholder={t('Digite aqui o motivo')}
                  focusInset={true}
                  onChange={(event: any) => this.setState({ comment: event.target.value })}
                />
              </Col>
            </Modal.Body>
            <Modal.Footer className="col justify-content-center mb-1">
              <Row className="text-right">
                <Button
                  className={style["delete-user"] + " mx-2 col"}
                  onClick={() => this.deleteUser()}
                >
                  {t("SIM, QUERO EXCLUIR")}
                </Button>
                <Button
                  className={style["cancel-delete-user"] + " mx-2 col"}
                  onClick={() => this.setState({ deleteUser: false })}
                >
                  {t("NÃO, DESISTI")}
                </Button>
              </Row>
            </Modal.Footer>
          </Modal>
        </ThemeProvider>
        {/* <pre>{JSON.stringify(this.state.user, null, 2)}</pre> */}
      </div>
    );
  }
}

export default ProfessionalProfile;
