import React from "react";
import "./index.css";
import {
  Dropdown,
  Menu,
  Item,
  Field,
  Select,
  Label,
  Trigger
} from "@zendeskgarden/react-dropdowns";
import { Row, Col } from '@zendeskgarden/react-grid';
import { DatepickerRange } from '@zendeskgarden/react-datepickers';
import {
  Field as DatepickerField,
  Label as DatepickerLabel,
  Input,
} from "@zendeskgarden/react-forms";
import { Datepicker } from "@zendeskgarden/react-datepickers";
import { Button } from '@zendeskgarden/react-buttons';
import { ReactComponent as ChevronIcon } from '@zendeskgarden/svg-icons/src/16/chevron-down-stroke.svg';
import { connect } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import {
  mediaClickButton,
  filterClickButton,
  startDateClickButton,
  endDateClickButton,
} from "../../../../actions";
import { t } from "../../../../translations/translation.service";
import Moment from 'moment';
import { AlertService } from "../../../../services/alert.service";
import { dateHelper } from '../../../../util/date';
import UserService from "../../../../services/user.service";
import { AuthenticationService } from "../../../../services/auth.service";
import { UserData } from '../../../../model/user.model';
import { ThemeProvider } from "@zendeskgarden/react-theming";
import { themeOrange } from '../../../../util/colorButton';
import Loading from "../../../loading";
import moment from "moment";
import { Modal } from "react-bootstrap";
import DeletedProfileService from "../../../../services/deletedProfile.service";
const { subDays } = require("date-fns");

const dateFormatter = new Intl.DateTimeFormat("pt-BR", {
  month: "2-digit",
  day: "2-digit",
  year: "numeric",
});

const filter = [
  { key: 0, value: t("Responsável") },
  { key: 1, value: t("Esquadrão") },
];

const media = [
  { key: 0, value: t("Dias corridos até a etapa") },
  { key: 1, value: t("Intervalo de dias em cada etapa") },
];

interface responsaveis {
  displayName: string;
  _id: string;
}

interface evaluationsReport {
  _id: string;
  displayName: string;
  recrutamento: number;
  avaliacao: number;
  selecao: number;
  apresentacao: number;
}

class FiltersHeader extends React.Component<any> {
  constructor(props: any) {
    super(props);
    this.validUser = this.validUser.bind(this);
  }
  state = {
    selectedFilter: filter[0],
    selectedMedia: media[0],
    startDate: subDays(new Date(), 30),
    endDate: new Date(),
    startCalendarValue: new Date(),
    endCalendarValue: new Date(new Date().setDate(new Date().getDate() + 7)),
    startCalendarDeletedPerfilValue: new Date(),
    endCalendarDeletedPerfilValue: new Date(new Date().setDate(new Date().getDate() + 7)),
    showCalendar: false,
    responsibles: [] as responsaveis[],
    selectedResponsible: { displayName: "", _id: "" },
    rotated: false,
    user: {} as UserData,
    evaluationsReport: [] as evaluationsReport[],
    message: "inicio",
    loadingProfessionals: false,
    expandedSections: [] as any,
    count: 0,
    showEvaluationModal: false,
    showDeletedUserModal: false,
    deletedProfileResponse: [],
  };

  async componentDidMount() {
    const {
      mediaClickButton,
      filterClickButton,
      startDateClickButton,
      endDateClickButton,
    } = this.props;
    mediaClickButton(this.state.selectedMedia);
    filterClickButton(this.state.selectedFilter);
    startDateClickButton(this.state.startDate);
    endDateClickButton(this.state.endDate);
    let responsibles = await UserService.getResponsables();
    let user = await AuthenticationService.getUser()
    this.setState(
      { responsibles: responsibles.data, user },
      () => {
        if (AuthenticationService.isRhManager()) {
          responsibles.data.unshift({ displayName: "Todos os responsáveis", _id: "Todos os responsáveis" })
          this.setState({
            selectedResponsible: this.state.responsibles[0]
          });
        } else {
          this.state.responsibles.forEach((responsible: any) => {
            if (responsible._id === this.state.user._id) {
              this.setState({
                selectedResponsible: responsible
              })
            }
          });
        }
      }
    );
  }

  async getEvaluations(): Promise<void> {
    this.setState({ loadingProfessionals: true });
    const data = {
      dateStart: this.state.startCalendarValue,
      dateEnd: this.state.endCalendarValue,
      responsible: this.state.selectedResponsible
    }
    try {
      let evaluations = await UserService.getRecruiterEvaluation(this.state.user._id, data);
      const message = evaluations.data.length > 0 ? "" : t("Sem avaliações no período")
      this.setState({ loadingProfessionals: false, message, evaluationsReport: evaluations.data })

      console.log("This State: " + this.state)
    } catch (err: any) {
      console.log(err)
      this.setState({ loadingProfessionals: false })
      AlertService.error(err.response.data.message)
    }
  }

  validUser(id: string): boolean {
    if (AuthenticationService.isRhManager()) {
      return false;
    } else if (this.state.user._id === id){
      return false;
    } else {
      return true;
    }
  }

  parseDateBr(inputValue?: string) {
    if (inputValue?.length === 10) {
      const value = inputValue.split("/");
      const formatDateBR = `${value[1]}/${value[0]}/${value[2]}`;
      const data = new Date(formatDateBR);
      return data;
    }
    return new Date(inputValue || "");
  }

  getDeletedProfile = async (): Promise<void> => {
    const { startCalendarDeletedPerfilValue, endCalendarDeletedPerfilValue } = this.state;

    const deletedProfileData = await DeletedProfileService.getDeletedProfileByDate(
      startCalendarDeletedPerfilValue, endCalendarDeletedPerfilValue
    );
    this.setState({ deletedProfileResponse: deletedProfileData.data })
  }

  render() {
    const {
      mediaClickButton,
      filterClickButton,
      startDateClickButton,
      endDateClickButton,
    } = this.props;

    return (
      <div>
        <Loading show={this.state.loadingProfessionals} />
        <div className="row mt-4 dividing">
          <div>
            <span className="u-fs-lg u-semibold" style={{
              color: "#4F6061"
            }}>
              {t("Indicadores de")}
            </span>
            <h3
              color="#4F6061"
            >
              {t("Recrutamento")}
            </h3>
          </div>
        </div>
        <div>
          <ul className="d-flex">
            <li>
              <button
                onClick={() => this.setState({ showEvaluationModal: true })}
                className="report-list-style"
              >
                &bull; {t("Relatório de avaliações realizadas")}
              </button>
            </li>
            <li>
              <button
                onClick={() => this.setState({ showDeletedUserModal: true })}
                className="report-list-style"
              >
                &bull; {t("Relatório de perfis excluídos")}
              </button>
            </li>
          </ul>
        </div>
        <div>
          <div className="row mt-4">
            <p>
              <span className="u-fs-lg u-semibold" style={{
                color: "#4F6061"
              }}>
                {t("Médias de tempo (em nº de dias)")}
              </span>
            </p>
          </div>
          <div className="row mb-3">
            <div className="col-lg-3 col-md-3 col-sm-12 p-0">
              <Dropdown
                selectedItem={this.state.selectedFilter}
                onSelect={(selectedFilter) => {
                  this.setState({ selectedFilter });
                  filterClickButton(selectedFilter);
                }}
                downshiftProps={{
                  itemToString: (item: { value: any; }) => item && item.value,
                }}
              >
                <Field style={{ width: "100%" }}>
                  <Label>{t("Filtrar por")}:</Label>
                  <Select>{t(this.state.selectedFilter.value)}</Select>
                </Field>
                <Menu>
                  {filter.map((option) => (
                    <Item key={option.key} value={option}>
                      {t(option.value)}
                    </Item>
                  ))}
                </Menu>
              </Dropdown>
            </div>
            <div className="col-lg-2 col-md-3 col-sm-12 pr-0">
              <DatepickerField>
                <DatepickerLabel>{t("Período")}:</DatepickerLabel>
                <Datepicker
                  maxValue={this.state.endDate}
                  value={this.state.startDate}
                  onChange={(startDate) => {
                    let updatedStartDate = Moment(startDate).toDate().setHours(0, 0, 0, 0);
                    let zeroHourEndDate = Moment(this.state.endDate).toDate().setHours(0, 0, 0, 0);

                    if (updatedStartDate > zeroHourEndDate) {
                      AlertService.warn(t("Datas fora da ordem cronologica."));
                      return;
                    }
                    this.setState({ startDate });
                    startDateClickButton(startDate);
                  }}
                  formatDate={(date) => dateFormatter.format(date)}
                >
                  <Input className="pr-2" />
                </Datepicker>
              </DatepickerField>
            </div>
            <div className="col-lg-2 col-md-3 col-sm-12">
              <DatepickerField>
                <DatepickerLabel style={{ color: "#D8DCDE" }}>
                  {t("Até")}:
                </DatepickerLabel>
                <Datepicker
                  minValue={this.state.startDate}
                  maxValue={new Date()}
                  value={this.state.endDate}
                  onChange={(endDate) => {
                    let updatedEndDate = Moment(endDate).toDate().setHours(0, 0, 0, 0);
                    let zeroHourStartDate = Moment(this.state.startDate).toDate().setHours(0, 0, 0, 0);

                    if (updatedEndDate < zeroHourStartDate) {
                      AlertService.warn(t("Datas fora da ordem cronologica."));
                      return;
                    }

                    this.setState({ endDate });
                    endDateClickButton(endDate);
                  }}
                  formatDate={(date) => dateFormatter.format(date)}
                >
                  <Input />
                </Datepicker>
              </DatepickerField>
            </div>
            <div className="col-lg-5 col-md-3 col-sm-12 p-0">
              <Dropdown
                selectedItem={this.state.selectedMedia}
                onSelect={(selectedMedia) => {
                  this.setState({ selectedMedia });
                  mediaClickButton(selectedMedia);
                }}
                downshiftProps={{
                  itemToString: (item: { value: any; }) => item && item.value,
                }}
              >
                <Field style={{ width: "100%" }}>
                  <Label>{t("Exibir médias em")}:</Label>
                  <Select>{t(this.state.selectedMedia.value)}</Select>
                </Field>
                <Menu>
                  {media.map((option) => (
                    <Item key={option.key} value={option}>
                      {t(option.value)}
                    </Item>
                  ))}
                </Menu>
              </Dropdown>
            </div>
          </div>
        </div>
        {this.state.showEvaluationModal && (
          <Modal
            show={this.state.showEvaluationModal}
            onHide={() => this.setState({ showEvaluationModal: false })}
            size="xl"
            centered
          >
            <Modal.Header>
              <h1 className="modal-header-container u-font-family-system">
                {t("Relatório de Avaliações")}
              </h1>
              <button
                onClick={() => this.setState({ showEvaluationModal: false })}
                className="modal-header-container"
                style={{ fontSize: '18px', backgroundColor:'transparent' }}
              >
                &times;
              </button>
            </Modal.Header>
            <Modal.Body>
              <div className="mb-3">
                {
                  this.state.message !== 'inicio' ? (
                    <Col>
                      <Row>
                        <Col className="column-report-evaluation-header">
                          {t("Recrutador")}
                        </Col>
                        <Col className="column-report-evaluation-header">
                          {t("Total de Recrutamentos")}
                        </Col>
                        <Col className="column-report-evaluation-header">
                          {t("Total de Avaliações")}
                        </Col>
                        <Col className="column-report-evaluation-header">
                          {t("Total de Seleções")}
                        </Col>
                        <Col className="column-report-evaluation-header">
                          {t("Total de Apresentações")}
                        </Col>
                      </Row>
                      {
                        this.state.evaluationsReport.length > 0
                        ? this.state.evaluationsReport.map((evaluation: evaluationsReport, index: number) => {
                          console.log(evaluation);
                           return ( 
                              <Row
                                key={(index + 10).toString()}
                              >
                                <Col className="column-report-evaluation-body">
                                  {evaluation.displayName}
                                </Col>
                                <Col className="column-report-evaluation-body">
                                  {evaluation.recrutamento}
                                </Col>
                                <Col className="column-report-evaluation-body">
                                  {evaluation.avaliacao}
                                </Col>
                                <Col className="column-report-evaluation-body">
                                  {evaluation.selecao}
                                </Col>
                                <Col className="column-report-evaluation-body">
                                  {evaluation.apresentacao}
                                </Col>
                              </Row>
                            );
                          })
                        : <Row>
                            <Col className="column-report-evaluation-body">
                              {this.state.message}
                            </Col>
                            <Col className="column-report-evaluation-body">
                              {this.state.message}
                            </Col>
                          </Row>
                      }
                    </Col>
                  )
                    : <p className="model-body-text u-font-family-system">
                        {t("Selecione o Período específico do relatório")}
                      </p>
                }
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="container-content-report">
                <section className="date-range-report">
                  <ThemeProvider theme={themeOrange as any}>
                    <Button
                      disabled={this.props.disableSelects}
                      onClick={() => {
                        this.setState({
                          showCalendar: !this.state.showCalendar,
                        });
                      }}
                      className="button-evaluation-footer mb-1 text-center"
                    >
                      <span>
                        {
                          this.state.count > 0
                          ? `${moment(this.state.startCalendarValue).format("DD-MM-YY")} - 
                            ${moment(this.state.endCalendarValue).format("DD-MM-YY")}`
                          : t("Período Específico")
                        }
                      </span>
                      <Button.EndIcon>
                        
                        <ChevronIcon />
                      </Button.EndIcon>
                    </Button>
                  </ThemeProvider>
                  {this.state.showCalendar && (
                    <div className="container-date-range">
                      <ThemeProvider theme={themeOrange as any}>
                        <button
                          onClick={() => this.setState({
                            showCalendar: false,
                            count: this.state.count + 1
                          })}
                          className="close-button-date-range"
                        >
                          {t("fechar")}
                        </button>
                        <DatepickerRange
                          startValue={this.state.startCalendarValue}
                          endValue={this.state.endCalendarValue}
                          onChange={(change) => {
                            change.startValue &&
                              this.setState({
                                startCalendarValue: change.startValue,
                              });
                            change.endValue &&
                              this.setState({
                                endCalendarValue: change.endValue,
                              });
                          }}
                          formatDate={(date) => dateHelper("pt-Br").format(date)}
                          customParseDate={this.parseDateBr}
                          locale="pt-BR"
                          isCompact
                        >
                          <Row>
                            <Col>
                              <DatepickerField >
                                <DatepickerLabel>{t("Inicio")}</DatepickerLabel>
                                <DatepickerRange.Start>
                                  <Input />
                                </DatepickerRange.Start>
                              </DatepickerField>
                            </Col>
                            <Col>
                              <DatepickerField>
                                <DatepickerLabel>{t("Fim")}</DatepickerLabel>
                                <DatepickerRange.End>
                                  <Input />
                                </DatepickerRange.End>
                              </DatepickerField>
                            </Col>
                          </Row>
                          <Col>
                            <DatepickerRange.Calendar />
                          </Col>
                        </DatepickerRange>
                      </ThemeProvider>
                    </div>
                  )}
                </section>
                <section className="button-evaluation-footer mb-1">
                  <ThemeProvider theme={themeOrange as any}>
                    <Dropdown
                      selectedItem={this.state.selectedResponsible}
                      onSelect={(selectedFilter) => {
                        this.setState({ selectedResponsible: selectedFilter });
                      }}
                      onStateChange={options => Object.hasOwn(options, 'isOpen') && this.setState({rotated: options.isOpen})}
                      downshiftProps={{
                        itemToString: (item: {displayName: string;}) => item && item.displayName,
                      }}
                    >
                      <Trigger>
                        <Button
                          style={{ border: 'none', backgroundColor: 'transparent' }}
                        >
                          {t(this.state.selectedResponsible.displayName)}
                          <Button.EndIcon isRotated={this.state.rotated}>
                            <ChevronIcon />
                          </Button.EndIcon>
                        </Button>
                      </Trigger>
                      <Menu>
                        {this.state.responsibles.map((option) => (
                          <Item
                            key={option._id}
                            value={option}
                            disabled={this.validUser(option._id)}
                          >
                            {t(option.displayName)}
                          </Item>
                        ))}
                      </Menu>
                    </Dropdown>
                  </ThemeProvider>
                </section>
                <section>
                  <ThemeProvider theme={themeOrange as any}>
                    <Button
                      className="button-build-report"
                      onClick={this.getEvaluations.bind(this)}
                    >
                      {t("Gerar Relatório")}
                    </Button>
                  </ThemeProvider>
                </section>
              </div>
            </Modal.Footer>
          </Modal>
        )}
        {this.state.showDeletedUserModal && (
          <Modal
            show={this.state.showDeletedUserModal}
            onHide={() => this.setState({ showDeletedUserModal: false })}
            size="xl"
            centered
          >
          <Modal.Header>
            <h1
              className="modal-header-container u-font-family-system"
              style={{ fontSize: '22px' }}
            >{t("Relatório de perfis excluídos")}</h1>
          </Modal.Header>
          <Modal.Body>
            <h2
              className="modal-header-container u-font-family-system"
              style={{ fontSize: '18px' }}
            >
              {t("Selecione o período do relatório")}
            </h2>
            <div className="container-date-range-deleted-perfil">
              <ThemeProvider theme={themeOrange as any}>
                <DatepickerRange
                  startValue={this.state.startCalendarDeletedPerfilValue}
                  endValue={this.state.endCalendarDeletedPerfilValue}
                  onChange={(change) => {
                    change.startValue &&
                      this.setState({
                        startCalendarDeletedPerfilValue: change.startValue,
                      });
                    change.endValue &&
                      this.setState({
                        endCalendarDeletedPerfilValue: change.endValue,
                      });
                  }}
                  formatDate={(date) => dateHelper("pt-Br").format(date)}
                  customParseDate={this.parseDateBr}
                  locale="pt-BR"
                  isCompact
                >
                  <Row>
                    <Col>
                      <DatepickerField >
                        <DatepickerLabel>{t("Inicio")}</DatepickerLabel>
                        <DatepickerRange.Start>
                          <Input />
                        </DatepickerRange.Start>
                      </DatepickerField>
                    </Col>
                    <Col>
                      <DatepickerField>
                        <DatepickerLabel>{t("Fim")}</DatepickerLabel>
                        <DatepickerRange.End>
                          <Input />
                        </DatepickerRange.End>
                      </DatepickerField>
                    </Col>
                  </Row>
                  <Col>
                    <DatepickerRange.Calendar />
                  </Col>
                </DatepickerRange>
              </ThemeProvider>
            </div>
            <div className="button-build-report-deleted-perfil-container">
              <button
                className="button-build-report-deleted-perfil"
                onClick={this.getDeletedProfile}
              >
                {t("Pesquisar")}
              </button>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {this.state.deletedProfileResponse.length > 0
              ? <div style={{ width: '100%' }}>
                  <h1 style={{ fontSize: '1rem' }}>{t("Total de perfis excluídos")}: {" " + this.state.deletedProfileResponse.length}</h1>
                  {this.state.deletedProfileResponse.map((profile: any) => {
                    return (
                      <Row className="row-deleted-profile-modal">
                        <Col size={4}>
                          <h5 style={{ fontSize: '1rem' }}>{t("Profissional")}</h5>
                          <p>{profile.userName}</p>
                        </Col>
                        <Col size={4}>
                          <h5 style={{ fontSize: '1rem' }}>E-mail</h5>
                          <p>{profile.userEmail}</p>
                        </Col>
                        <Col size={4}>
                          <h5 style={{ fontSize: '1rem' }}>{t("Data")}</h5>
                          <p>{moment(profile.created).format("DD/MM/YYYY")}</p>
                        </Col>
                        <Col size={12}>
                          <h5 style={{ fontSize: '1rem' }}>{t("Comentário")}</h5>
                          <p>{profile.comment}</p>
                        </Col>
                      </Row>
                    )
                  })}
                </div>
              : <div className="initial-state-deleted-profile">
                  <h1
                    style={{ fontSize: '1rem' }}
                  >
                    {t("Total de perfis excluídos")}: {" " + this.state.deletedProfileResponse.length}
                  </h1>
                </div>}
          </Modal.Footer>
        </Modal>
        )}
      </div>
    );
  }
}

const mapStateToProps = (store: {
  reportsFilterReducer: {
    mediaFilter: any;
    filter: any;
    startDateFilter: any;
    endDateFilter: any;
  };
  changeLanguageReducer: { currentLanguage: string };
}) => ({
  mediaFilter: store.reportsFilterReducer.mediaFilter,
  selectedFilter: store.reportsFilterReducer.filter,
  startDate: store.reportsFilterReducer.startDateFilter,
  endDate: store.reportsFilterReducer.endDateFilter,
  currentLanguage: store.changeLanguageReducer.currentLanguage,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      mediaClickButton,
      filterClickButton,
      startDateClickButton,
      endDateClickButton,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(FiltersHeader);
