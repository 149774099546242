import React from "react";
import { connect } from "react-redux";
import Loading from "../../components/loading";
import FiltersHeader from "../../components/reports/headers/filters-header";
import RecruitmentReport from "../../components/reports/recruitment";
import ReportWarning from "../../components/reports/reporit-warning/report-warning";
import service from "../../services/api.service";
import { t } from "../../translations/translation.service";
import "./index.css";

class Reports extends React.Component<any> {
  state = {
    loading: true,
    squads: [],
    responsables: [],
    recruitmentData: [],
  };

  async componentDidUpdate(prevProps: any) {
    if (
      prevProps.filter !== this.props.filter ||
      prevProps.mediaFilter !== this.props.mediaFilter ||
      prevProps.startDateFilter !== this.props.startDateFilter ||
      prevProps.endDateFilter !== this.props.endDateFilter
    ) {
      this.setState({
        loading: true,
      });
      const { filter, mediaFilter, startDateFilter, endDateFilter } =
        this.props;

      let recruitmentData = await service.post("reports/recruitment", {
        filter,
        mediaFilter,
        startDateFilter,
        endDateFilter,
      });
      this.setState({
        loading: false,
        recruitmentData: recruitmentData.data,
      });
    }
  }

  render() {
    const { filter, mediaFilter } = this.props;
    let subtitle =
      mediaFilter.key === 0 ? t("Média de dias até") : t("Média de dias em");
    return (
      // <div className="outside-container">
      <div className="container" style={{ marginLeft: "5%"}}>
        <Loading show={this.state.loading} />
        <FiltersHeader></FiltersHeader>
        <RecruitmentReport
          subtitle={subtitle}
          filter={filter}
          recruitmentData={this.state.recruitmentData}
        ></RecruitmentReport>
        <ReportWarning warnings={this.state.recruitmentData}></ReportWarning>
      </div>
      // </div>
    );
  }
}

const mapStateToProps = (store: any) => ({
  filter: store.reportsFilterReducer.filter,
  startDateFilter: store.reportsFilterReducer.startDateFilter,
  endDateFilter: store.reportsFilterReducer.endDateFilter,
  mediaFilter: store.reportsFilterReducer.mediaFilter,
});

export default connect(mapStateToProps)(Reports);
