import { Field, Label, Message, Range } from "@zendeskgarden/react-forms";
import GoogleMapReact from "google-map-react";
import * as React from "react";
import { Col, Row } from "react-bootstrap";
import { ReactComponent as LocationIcon } from "../../../images/icons/location.svg";
import { t } from "../../../translations/translation.service";
import styles from "./index.module.css";

export type Props = {
  location: any[];
  range: number;
  changeRangeEvent: any;
};

export default class Maps extends React.Component<Props> {
  state = {
    zoomEvent: undefined as any,
    showMap: true,
    zoom: 5,
  };

  getPlacesFromArray = (): string =>
    this.props.location.map((place) => place.formatted_address).join(", ");

  getLocationGeometry = (index: number): any => {
    if (typeof this.props.location[index].geometry.location.lat === 'function') {
      return {
        lat: this.props.location[index].geometry.location.lat(),
        lng: this.props.location[index].geometry.location.lng(),
      };
    } else {
      return {
        lat: this.props.location[index].geometry.location.lat,
        lng: this.props.location[index].geometry.location.lng,
      };
    }
  };

  render() {
    return (
      <div className={styles["map-container"]}>
        <Row className="h-100 p-2">
          <Col sm={12} md={6} style={{ minHeight: "350px" }}>
            {this.state.showMap && (
              <GoogleMapReact
                yesIWantToUseGoogleMapApiInternals={true}
                bootstrapURLKeys={{
                  key: "AIzaSyAzPDf6m__x0VYpUOU8Yzgu48_KJbh2g9U",
                  language: "pt",
                }}
                center={this.getLocationGeometry(
                  this.props.location.length - 1
                )}
                zoom={this.state.zoom}
                onChange={(event) =>
                  this.setState({ zoomEvent: event, zoom: event.zoom })
                }
                onGoogleApiLoaded={({ map, maps }) => {
                  for (let i = 0; i < this.props.location.length; i++) {
                    new google.maps.Circle({
                      strokeColor: "#fd961c",
                      strokeOpacity: 0.8,
                      strokeWeight: 2,
                      fillColor: "#ed961c",
                      fillOpacity: 0.3,
                      map,
                      center: this.getLocationGeometry(i),
                      radius: 1000 * this.props.range,
                    });
                  }
                }}
              >
                {this.props.location.map((location, index) => (
                  <PinMapLocation
                    key={location.place_id}
                    lat={this.getLocationGeometry(index).lat}
                    lng={this.getLocationGeometry(index).lng}
                  />
                ))}
              </GoogleMapReact>
            )}
          </Col>
          <Col sm={12} md={6}>
            <Field>
              <Label>{t("Raio de Busca")}</Label>
              <Range
                step={10}
                max={200}
                value={this.props.range}
                onChange={(event) => {
                  this.props.changeRangeEvent(event.target.value);
                }}
                onLostPointerCapture={() =>
                  this.setState({ showMap: false }, () =>
                    this.setState({ showMap: true })
                  )
                }
              />
              <Message>
                {this.props.range +
                  " " +
                  t("km a partir de ") +
                  " " +
                  this.getPlacesFromArray()}
              </Message>
            </Field>
          </Col>
        </Row>
      </div>
    );
  }
}

class PinMapLocation extends React.Component<any> {
  render() {
    return (
      <div>
        <LocationIcon
          style={{
            transform: "translate(-50%, -100%) scale(1.5)",
            zIndex: 1,
            opacity: 1,
          }}
        ></LocationIcon>
      </div>
    );
  }
}
