import {
  Dropdown,
  Field as DropField,
  Item,
  Menu,
  Select,
} from "@zendeskgarden/react-dropdowns";
import { Col, Row } from "@zendeskgarden/react-grid";
import React from "react";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import {
  groupClickButton,
  keywordsClickButton,
  responsableClickButton,
  sortListClickButton,
  dateFilterClickButton,
} from "../../../actions";
import UserService from "../../../services/user.service";
import { t } from "../../../translations/translation.service";
import ReactChipInput from "../../opportunity/chip-input";
import { DatepickerRange } from '@zendeskgarden/react-datepickers';
import styles from "./index.module.scss";
import { Input, Field, Label as FormLabel, Label } from "@zendeskgarden/react-forms";
import {ReactComponent as CalendarIcon} from '@zendeskgarden/svg-icons/src/16/calendar-stroke.svg';
import { dateHelper } from '../../../util/date';
import moment from "moment";

const groups = [
  { label: t("Todos os Grupos"), value: "Todos os Grupos" },
  {
    label: t("H1 - Desenvolvimento e Arquitetura"),
    value: "620e5be4241b6ee8e6382719",
  },
  { label: t("H2 - Design e Gestão"), value: "620e5be4241b6ee8e638271b" },
  {
    label: t("H3 - Infra, Dados e Testes"),
    value: "620e5be4241b6ee8e638271a",
  },
  {
    label: t("Sem categoria"),
    value: "Sem categoria",
  },
];

const sortKanban = [
  {
    label: t("Data de inclusão no kanban"),
    value: 0,
  },
  {
    label: t("Ordem alfabética crescente"),
    value: 1,
  },
  {
    label: t("Ordem alfabética decrescente"),
    value: 2,
  },
  { label: t("Nota do parecer maior"), value: 3 },
  { label: t("Nota do parecer menor"), value: 4 },
  {
    label: t("Parecer comportamental recente"),
    value: 5,
  },
  {
    label: t("Parecer comportamental antigo"),
    value: 6,
  },
];

interface IHeaderProps {
  disableSelects: boolean;
}
class HeaderHunterKanban extends React.Component<any> {
  state = {
    responsibles: [],
    selectedResponsibles: {} as any,
    keywords: [] as string[],
    groupType: groups[0],
    sorted: sortKanban[0],
    disableSelects: false,
    startCalendarValue: new Date(),
    endCalendarValue: new Date(new Date().setDate(new Date().getDate() + 7)),
    showCalendar: false,
    dateFilter: {
      startCalendarValue: 'Período Específico',
      endCalendarValue: 'Período Específico',
    },
    changeStyle: false,
  };

  constructor(props: IHeaderProps) {
    super(props);
    this.setState({});
  }

  parseDateBr(inputValue?: string) {
    if (inputValue?.length === 10) {
      const value = inputValue.split("/");
      const formatDateBR = `${value[1]}/${value[0]}/${value[2]}`;
      const data = new Date(formatDateBR);
      return data;
    }
    return new Date(inputValue || "");
  }

  async componentDidMount() {
    const { responsableFilter } = this.props;
    let responsibles = await UserService.getResponsables();
    responsibles.data.unshift({
      _id: "-1",
      displayName: t("Todos os Responsáveis"),
    });
    this.setState({
      responsibles: responsibles.data,
      selectedResponsibles:
        responsableFilter && responsableFilter._id
          ? responsableFilter
          : responsibles.data[0],
    });
  }

  addChip = (value: string) => {
    const keywords = this.state.keywords.slice();
    keywords.push(value);
    this.setState({ keywords: keywords });
    //const { keywordsClickButton } = this.props;
    //keywordsClickButton(keywords);
  };

  removeChip = (index: number) => {
    const keywords = this.state.keywords.slice();
    keywords.splice(index, 1);
    this.setState({ keywords });
    //const { keywordsClickButton } = this.props;
    //keywordsClickButton(keywords);
  };

  render() {
    const {
      responsableClickButton,
      groupClickButton,
      sortListClickButton,
      dateFilterClickButton
    } = this.props;
    return (
      <header className={"mb-2"}>
        <h1 className={styles["header-hunter-title"]}>
          {t("Talent Hunters Kanban")}
        </h1>
        <Row>
          <Col sm={8}>
            <section className={styles["header-hunter-body"]}>
              <label className="u-semibold mr-2 mt-2 u-fs-md">
                {t("Filtrar por")}:
              </label>
              <Dropdown
                selectedItem={this.state.groupType}
                onSelect={(type: any) => {
                  this.setState({
                    groupType: type,
                  });
                  groupClickButton(type.value);
                }}
                downshiftProps={{
                  itemToString: (item: any) => item && item.label,
                }}
              >
                <DropField>
                  <Select disabled={this.props.disableSelects}>
                    {this.state.groupType.label}
                  </Select>
                  <Menu style={{ width: "19rem" }}>
                    {groups.map((type: any) => (
                      <Item key={type.value} value={type}>
                        {type.label}
                      </Item>
                    ))}
                  </Menu>
                </DropField>
              </Dropdown>
              <Dropdown
                selectedItem={this.state.selectedResponsibles}
                onSelect={(responsibleSelected: any) => {
                  this.setState({
                    selectedResponsibles: responsibleSelected,
                  });
                  responsableClickButton(responsibleSelected);
                }}
                downshiftProps={{
                  itemToString: (item: any) => item && item.displayName,
                }}
              >
                <DropField>
                  <Select disabled={this.props.disableSelects}>
                    {this.state.selectedResponsibles.displayName}
                  </Select>
                  <Menu style={{ width: "19rem" }}>
                    {this.state.responsibles &&
                      this.state.responsibles.map((responsible: any) => (
                        <Item key={responsible._id} value={responsible}>
                          {responsible.displayName}
                        </Item>
                      ))}
                  </Menu>
                </DropField>
              </Dropdown>
              <Field>
                {this.state.changeStyle && (
                  <Label className={styles["label-date-button"]}>
                    {t("Período Específico")}
                  </Label>
                )}
                <button
                  disabled={this.props.disableSelects}
                  onClick={() => {
                    this.setState({
                      showCalendar: !this.state.showCalendar,
                      changeStyle: !this.state.changeStyle,
                    });
                  }}
                  className={styles["button-date-range"]}
                >
                  {this.state.dateFilter.endCalendarValue !==
                    "Período Específico" &&
                  this.state.dateFilter.startCalendarValue !==
                    "Período Específico" ? (
                    <span className={styles["text-calendar"]}>
                      {moment(this.state.startCalendarValue).format(
                        "DD/MM/YYYY"
                      )}{" "}
                      -{" "}
                      {moment(this.state.endCalendarValue).format("DD/MM/YYYY")}
                    </span>
                  ) : this.state.changeStyle ? (
                    <span className={styles["text-calendar"]}>
                      DD/MM/AAAA-DD/MM/AAAA
                    </span>
                  ) : (
                    <span>{t("Período Específico")}</span>
                  )}
                  <CalendarIcon
                    className="ml-2"
                    style={{ marginTop: "0.7rem" }}
                  />
                </button>
                {this.state.showCalendar && (
                  <div className={styles["container-date-range"]}>
                    <DatepickerRange
                      startValue={this.state.startCalendarValue}
                      endValue={this.state.endCalendarValue}
                      onChange={(change) => {
                        change.startValue &&
                          this.setState(
                            {
                              startCalendarValue: change.startValue,
                            },
                            () => {
                              const startCalendarValue = new Date(
                                this.state.startCalendarValue
                              ).toISOString();
                              this.setState(
                                {
                                  dateFilter: {
                                    ...this.state.dateFilter,
                                    startCalendarValue,
                                  },
                                },
                                () =>
                                  dateFilterClickButton(this.state.dateFilter)
                              );
                            }
                          );
                        change.endValue &&
                          this.setState(
                            {
                              endCalendarValue: change.endValue,
                            },
                            () => {
                              const endCalendarValue = new Date(
                                this.state.endCalendarValue
                              );
                              const addOneDayToEndCalendarValue = new Date(
                                endCalendarValue.setDate(
                                  endCalendarValue.getDate() + 1
                                )
                              ).toISOString();
                              this.setState(
                                {
                                  dateFilter: {
                                    ...this.state.dateFilter,
                                    endCalendarValue:
                                      addOneDayToEndCalendarValue,
                                  },
                                  showCalendar: false,
                                },
                                () => {
                                  dateFilterClickButton(this.state.dateFilter);
                                }
                              );
                            }
                          );
                      }}
                      formatDate={(date) => dateHelper("pt-Br").format(date)}
                      customParseDate={this.parseDateBr}
                      locale="pt-BR"
                      isCompact
                    >
                      <Row>
                        <Col>
                          <Field>
                            <FormLabel>{t("Inicio")}</FormLabel>
                            <DatepickerRange.Start>
                              <Input isCompact />
                            </DatepickerRange.Start>
                          </Field>
                        </Col>
                        <Col>
                          <Field>
                            <FormLabel>{t("Fim")}</FormLabel>
                            <DatepickerRange.End>
                              <Input isCompact />
                            </DatepickerRange.End>
                          </Field>
                        </Col>
                      </Row>
                      <Col>
                        <DatepickerRange.Calendar />
                      </Col>
                    </DatepickerRange>
                  </div>
                )}
              </Field>
            </section>
          </Col>
          <Col sm={4}>
            <section
              className={[
                styles["header-hunter-body"],
                "justify-content-end",
              ].join(" ")}
            >
              <label className="u-semibold mr-2 mt-2 u-fs-md">
                {t("Ordenar por")}:
              </label>
              <div className={styles["container-dropdown"]}>
                <Dropdown
                  selectedItem={this.state.sorted}
                  onSelect={(sort: any) => {
                    this.setState({ sorted: sort });
                    sortListClickButton(sort.value);
                  }}
                  downshiftProps={{
                    itemToString: (item: any) => item && item.label,
                  }}
                >
                  <DropField>
                    <Select disabled={this.props.disableSelects}>
                      {this.state.sorted.label}
                    </Select>
                    <Menu style={{ width: "19rem" }}>
                      {sortKanban.map((item) => (
                        <Item key={item.value} value={item}>
                          {item.label}
                        </Item>
                      ))}
                    </Menu>
                  </DropField>
                </Dropdown>
              </div>
            </section>
          </Col>
          <Col>
            <Row>
              <Col sm={12}>
                <label className="u-semibold mr-2 mt-3 u-fs-md">
                  {t("Buscar palavra-chave")}:
                </label>
              </Col>
              <Col sm={10}>
                <ReactChipInput
                  classes={styles["header-hunter-input-search"]}
                  chips={this.state.keywords}
                  onSubmit={(value: string) => this.addChip(value)}
                  onRemove={(index: number) => this.removeChip(index)}
                />
              </Col>
              <Col sm={2}>
                <Button
                  variant="outline-warning"
                  disabled={this.props.disableSelects}
                  onClick={() => {
                    this.props.keywordsClickButton(this.state.keywords);
                  }}
                  className="w-100"
                  style={{ borderRadius: "4px", padding: "12px 35px" }}
                >
                  {t("Buscar")}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </header>
    );
  }
}

const mapStateToProps = (store: {
  talentFilterReducer: {
    responsableFilter: any;
    groupFilter: any;
    sortFilterList: any;
    keywordsList: any;
    dateFilter: any
  };
}) => ({
  groupFilter: store.talentFilterReducer.groupFilter,
  responsableFilter: store.talentFilterReducer.responsableFilter,
  sortFilterList: store.talentFilterReducer.sortFilterList,
  keywordsList: store.talentFilterReducer.keywordsList,
  dateFilter: store.talentFilterReducer.dateFilter,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      responsableClickButton,
      groupClickButton,
      sortListClickButton,
      keywordsClickButton,
      dateFilterClickButton,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(HeaderHunterKanban);
