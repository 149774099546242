import * as React from "react";
import { CirclePicker } from "react-color";
import GridLayout from "react-grid-layout";
import pickerIcon from "../../../images/icons/colors-icon.png";
import style from "./index.module.scss";

export type Props = {
  mosaic: any[];
  updateEvent: any;
  canDelete?: boolean;
  removeItem?: any;
};

export default class MosaicEditView extends React.Component<Props> {
  updateMosaicPosition(event: any[]): void {
    const mosaic = this.props.mosaic;
    this.props.updateEvent(
      mosaic.map((item: any, index: number) => {
        item.posicao.row = event[index].y + 1;
        item.posicao.col = event[index].x + 1;
        item.posicao.sizex = event[index].w;
        item.posicao.sizey = event[index].h;
        return item;
      })
    );
  }

  handleChangeComplete = (color: any) => {
    const mosaic = this.props.mosaic;
    mosaic.map((item: any) => {
      if (item.selected) {
        item.selected = false;
        item.posicao.color = color.hex;
      }
      return item;
    });
    this.props.updateEvent(mosaic);
  };

  setSelectedToChanteColor(index: number): void {
    const mosaic = this.props.mosaic;
    mosaic.map((item: any) => {
      item.selected = false;
      return item;
    });
    mosaic[index].selected = true;
    this.props.updateEvent(mosaic);
  }

  setFontSize(size: string, index: number): void {
    const mosaic = this.props.mosaic;
    mosaic[index].posicao.fontsize = size;
    this.props.updateEvent(mosaic);
  }

  getFontSize(size: string): string {
    switch (size) {
      case "1":
        return "16px";
      case "2":
        return "24px";
      case "3":
        return "40px";
      default:
        return "16px";
    }
  }

  render() {
    return (
      <>
        {this.props.mosaic.map((item: any, index: number) => (
          <div key={item.toString() + index.toString()}>
            {item.selected && (
              <div
                className={style["picker-gridster"]}
                key={index.toString() + "gridster"}
              >
                <CirclePicker onChangeComplete={this.handleChangeComplete} />
              </div>
            )}
          </div>
        ))}
        <GridLayout
          className="layout"
          cols={6}
          rowHeight={125}
          width={500}
          margin={[0, 0]}
          onLayoutChange={(event: any) => this.updateMosaicPosition(event)}
        >
          {this.props.mosaic
            .filter((item: any) => item.posicao)
            .map((item: any, index: number) => (
              <div
                key={index.toString() + "mosaic"}
                data-grid={{
                  y: item.posicao.row - 1,
                  x: item.posicao.col - 1,
                  w: item.posicao.sizex,
                  h: item.posicao.sizey,
                }}
                style={{
                  backgroundColor: item.posicao.color,
                  zIndex: 0,
                }}
                className={`${style["pickgridister"]} d-flex align-items-center justify-content-center`}
              >
                <span
                  style={{
                    color: "white",
                    fontSize: this.getFontSize(item.posicao.fontsize),
                  }}
                >
                  {item.detalhe.nome}
                </span>
                {this.props.canDelete && (
                  <div
                    className={style["close-item"]}
                    key={index + "button-close"}
                  >
                    <button
                      style={{ fontSize: "16px" }}
                      onClick={() => {
                        this.props.removeItem(item);
                      }}
                    >
                      &#x2715;
                    </button>
                  </div>
                )}
                <button
                  className={style["picker-img"]}
                  onClick={() => this.setSelectedToChanteColor(index)}
                >
                  <img key={index + " img"} src={pickerIcon} alt="picker"></img>
                </button>

                <div className={style["text-size-item"]}>
                  <button
                    style={{ fontSize: "16px" }}
                    onClick={() => {
                      this.setFontSize("1", index);
                    }}
                  >
                    A
                  </button>
                  <button
                    style={{ fontSize: "20px" }}
                    onClick={() => {
                      this.setFontSize("2", index);
                    }}
                  >
                    A
                  </button>
                  <button
                    style={{ fontSize: "24px" }}
                    onClick={() => {
                      this.setFontSize("3", index);
                    }}
                  >
                    A
                  </button>
                </div>
              </div>
            ))}
        </GridLayout>
      </>
    );
  }
}
