import api from "./api.service";
import { AxiosResponse } from 'axios'

class DeletedProfileService {
    static getAllDeletedProfile = async (): Promise<AxiosResponse<any>> => {
      return api.get('/deletedProfile')
    }
    static getDeletedProfileByDate = async (
      start: Date,
      end: Date
    ): Promise<AxiosResponse<any>> => {
      return api.get(`/deletedProfile/byDate?start=${start}&end=${end}`)
    }
    static addDeletedProfile = async (payload: any): Promise<AxiosResponse<any>> => {
      return api.post('/deletedProfile', payload)
    }
}

export default DeletedProfileService;