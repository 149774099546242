import Styled from 'styled-components';

export const ContainerSkills: any = Styled.div.attrs(() => ({
  className: `d-flex flex-column`  
}))`
    @media (max-width: 991px) {
        // flex-direction: column;
        justify-content: center;
    }
`;

export const ContainerDivSkills: any = Styled.div.attrs(() => ({
    className: `d-flex flex-column`  
  }))``;

export const SpanSkill: any = Styled.span.attrs(() => ({
    className: `p-1`

}))`
    font-size: 14px;
    font-weight: 500;
`;

export const MainContainer: any = Styled.div.attrs(() => ({
    className: `d-flex justify-content-around mb-2`
}))`
    @media (max-width: 991px) {
        flex-direction: column;
    }
`;
