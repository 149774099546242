import React from "react";
import { Body, Close, Footer, FooterItem, Header, Modal } from '@zendeskgarden/react-modals';

const { Button } = require('@zendeskgarden/react-buttons');

class DangerModal extends React.Component<any> {
  render() {
    return (
      <Modal onClose={() => this.props.onModalClose()}>
        <Header>{this.props.header}</Header>
        <Body>{this.props.body}</Body>
        <Footer>
          <FooterItem>
            <Button onClick={() => this.props.onModalClose()} basic>Cancelar</Button>
          </FooterItem>
          <FooterItem>
            <Button primary onClick={() => {this.props.confirmAction(); this.props.onModalClose() }} danger={true}>Confirmar</Button>
          </FooterItem>
        </Footer>
        <Close aria-label="Close modal" />
      </Modal>
    );
  }
}

export default DangerModal;
