import {
  Dropdown,
  Field as Field2,
  Item,
  Menu,
  Select
} from '@zendeskgarden/react-dropdowns';
import { Field, Input } from '@zendeskgarden/react-forms';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { currentLanguageChanged } from '../../actions';
import HeaderMobile from '../../components/header';
import TranslationService, { t } from '../../translations/translation.service';
import { AuthenticationService } from '../../services/auth.service';
import { AlertService } from '../../services/alert.service';
import './index.css';

class ForgotPassword extends React.Component<any> {
  state = {
    currentLang: TranslationService.getCurrentLanguage,
    email: '',
    loading: false,
    password: '',
    width: window.innerWidth,
  };

  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  redirectToHome() {
    this.props.history.push('/');
  }

  async componentDidMount() {
    const { currentLanguageChanged } = this.props;
    currentLanguageChanged(this.state.currentLang);
    this.setState({
      currentLang: this.state.currentLang()
    }) 
  }

  componentDidUpdate(prevProps: Readonly<any>, _prevState: Readonly<{}>): void {
    if (
        this.state.width <= 680 &&
        prevProps.currentLanguage !== this.props.currentLanguage
        && typeof(this.props.currentLanguage) !== "function"
      ) {
      this.setState({ currentLang: this.props.currentLanguage});
    }
  }

  async handleClick() {
    const { location } = this.props;
    this.setState({ loading: true });
    try {
      await AuthenticationService.forgotPassword(
        location.state || this.state.email,
        this.state.currentLang
      );
      this.setState({ loading: false });
      AlertService.success(t("Email enviado com sucesso!"));
    } catch (err) {
      console.log("erro", err)
      this.setState({ loading: false });
      AlertService.error(t("Algo deu errado!"));
    }
  }

  handleChange(event: any) {
    const { target } = event;
    this.setState({
      email: target.value,
    })
  }

  render() {
    const { location } = this.props;
    const { width } = this.state;
    const isMobile = width <= 680;
    const { currentLanguageChanged } = this.props;
    const langs = TranslationService.getLanguages();
    if (!isMobile) {
      return (
        <div className="bg-enter">
          <div className="d-flex align-items-center justify-items-center h-100">
            <div className="bg-container">
              <div className="content-login d-flex justify-items-center flex-column justify-content-between">
                <div>
                  <div className="row">
                    <div className="col">
                      <p
                        className="enter-logo"
                        style={{ cursor: 'pointer' }}
                        onClick={this.redirectToHome.bind(this)}
                      />
                    </div>
                    <div className="d-flex align-items-center mr-1">
                      <Dropdown
                        selectedItem={TranslationService.getCurrentLanguage()}
                        onSelect={(selectedLang) => {
                          TranslationService.changeLanguage(selectedLang);
                          this.setState({ currentLang: selectedLang });
                          currentLanguageChanged(selectedLang);
                        }}
                        downshiftProps={{
                          itemToString: (item: any) => item,
                        }}
                      >
                        <Field2>
                          <Select className="u-semibold u-fs-sm border-0">
                            {t(TranslationService.getCurrentLanguage())}
                          </Select>
                        </Field2>
                        <Menu className="u-fs-sm">
                          {langs.map((option: any) => (
                            <Item key={option} value={option}>
                              {t(option)}
                            </Item>
                          ))}
                        </Menu>
                      </Dropdown>
                    </div>
                  </div>
                  <div className="login-body">
                    <h1 className="login-title">{t('Esqueceu a senha?')}</h1>
                    <Field className="mt-4 mb-4">
                      <Input defaultValue={location.state} onChange={this.handleChange.bind(this)} placeholder={t("Informe seu e-mail")}></Input>
                    </Field>
                    <button
                      onClick={this.handleClick.bind(this)}
                      disabled={this.state.loading}
                      className="btn-enviar w-100 mt-2 button-redefinir"
                    >
                      {t('Redefinir senha')}
                    </button>
                  </div>
                </div>
                <div>
                  <p
                    style={{ marginTop: '5rem' }}
                    className="text-center u-fg-grey-500"
                  >
                    {t('Novo no enter?')}{' '}
                    <span className="u-semibold u-fg-grey-600">
                      {t('Cadastre-se')}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <>
          <HeaderMobile />
          <div className="login-body bg-enter p-4 d-flex justify-content-between flex-column">
            <div className="container">
              <h1 className="login-title mt-0 text-light">{t('Esqueceu a senha?')}</h1>
              <Field className="mt-4">
                <Input defaultValue={location.state} onChange={this.handleChange.bind(this)} placeholder={t("E-mail")}></Input>
              </Field>
              <button
                onClick={this.handleClick.bind(this)}
                disabled={this.state.loading}
                className="btn-enviar w-100 mt-4"
              >
                {t('Redefinir senha')}
              </button>
            </div>
            <p
              style={{ marginTop: '5rem' }}
              className="text-center text-light"
            >
              {t('Novo no enter?')}{' '}
              <span className="u-semibold text-light">
                {t('Cadastre-se')}
              </span>
            </p>
          </div>
        </>
      );
    }
  }
}

const mapStateToProps = (store: {
  changeLanguageReducer: { currentLanguage: string };
}) => ({
  currentLanguage: store.changeLanguageReducer.currentLanguage,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      currentLanguageChanged,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ForgotPassword));

