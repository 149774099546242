import { Subject } from "rxjs";

const alertSubject = new Subject();
const defaultId = "default-alert";

export const AlertService = {
  onAlert,
  success,
  error,
  info,
  warn,
  alert,
  clear,
};

export const alertType = {
  success: "success",
  error: "error",
  info: "info",
  warning: "warning",
};

// enable subscribing to alerts observable
function onAlert() {
  return alertSubject.asObservable();
}

// convenience methods
function success(message: any, options?: any) {
  alert({ ...options, type: alertType.success, message });
}

function error(message: any, options?: any) {
  alert({ ...options, type: alertType.error, message });
}

function info(message: any, options?: any) {
  alert({ ...options, type: alertType.info, message });
}

function warn(message: any, options?: any) {
  alert({ ...options, type: alertType.warning, message });
}

// core alert method
function alert(alert: any) {
  alert.id = alert.id || defaultId;
  alertSubject.next(alert);
}

// clear alerts
function clear(id = defaultId) {
  alertSubject.next({ id });
}
