import { Tooltip } from "@zendeskgarden/react-tooltips";
import { Component } from "react";
import { Link } from "react-router-dom";
import avatar from "../../../../images/avatar.jpg";
import avatarDeleted from "../../../../images/avatar-deleted.png";
import iconPan from "../../../../images/icons/ic_pan_tool_24px.svg";
import iconSchedule from "../../../../images/icons/ic_schedule_24px.svg";
import iconStar from "../../../../images/icons/ic_star_border_24px.svg";
import iconTalent from "../../../../images/icons/talent-star.svg";
import UserService from "../../../../services/user.service";
import { OPPORTUNITY_DETAIL_ROUTE, REGISTER_PROFESSIONAL_RH } from '../../../../providers/routes'
import { ReactComponent as Pencil } from "@zendeskgarden/svg-icons/src/16/pencil-stroke.svg";
import { t } from "../../../../translations/translation.service";
import styles from "./index.module.scss";
import Loading from "../../../loading";
import FileStackService from "../../../../services/filestack.service";
import EuropeUnion from '../../../../images/icons/ic_europe_union_24.svg'

class ProfessionalAvatar extends Component<{ user: any }> {
  state ={
    finishedOpportunities: [],
    inProgressOpportunities: [],
    showTooltip: false,
    loading: false,
    policyAndSignatureImg: '' as string,
  }

  async getFileStackPolicyToUserImg(): Promise<void> {
    try {
      if (
        this.props.user &&
        this.props.user.userImageUrl &&
        this.getNewFileStackUrl(this.props.user.userImageUrl)
      ) {
        const policyUserImg = await FileStackService.getFileStackSecreteCode(
          this.props.user.userImageUrl, false
        );
        this.setState({ policyAndSignatureImg: policyUserImg.data });
      }
    } catch(err: any) {
      console.log("Erro ao obter credenciais - ERROR: ", err);
    }
  }

  componentDidMount(): void {
    this.getFileStackPolicyToUserImg()
  }

  getNewFileStackUrl(url: string): boolean {
    const domain = url.split('/')[2];
    if (domain.includes('cdn.file')) {
      return true;
    }
    return false;
  }

  isEvaluationStar = (evaluation: any[]): boolean => {
    let result = false;
    if (evaluation && evaluation.length > 0 && evaluation[evaluation.length -1].resultado >= 8) {
      result = true
    }
    return result;
  };

  userOpportunities(user: any): void {
    this.setState({ loading: true });
    UserService.getUserWithOpportunities(user._id).then((response: any) => {
      this.setState({
        finishedOpportunities: response.data.opportunitiesRelated.finishedOpportunities,
        inProgressOpportunities: response.data.opportunitiesRelated.inProgressOpportunities,
        loading: false,
      });
    }).catch((err: any) => {
      console.error("erro", err.response.data.message)
      this.setState({ loading: false });
    })
  }

  isEvaluationOutOfDate(evaluation: any) {
    let result = false;
    let currenDate = new Date();
    currenDate.setFullYear(currenDate.getFullYear() - 1);
    if (evaluation.length > 0 && new Date(evaluation[evaluation.length -1]?.created) <= currenDate) {
      result = true;
    }
    return result;
  }

  isEvaluationNegative(evaluation: any) {
    let result = false;
    let passingScore = 5;
    const parecerEvaluation = evaluation.filter((document: any) => {
      return document._avaliacaoUsuario === "560999627ada140d006b069f"
    })
    if (parecerEvaluation.length > 0) {
      const lastEvaluation = parecerEvaluation[parecerEvaluation.length -1].resultado;
      result = lastEvaluation < passingScore
    }
    return result;
  }

  formatEmail(email: string) {
    const splitEmail = email.split("@");
    const domain = splitEmail[1];
    const name = splitEmail[0];
    return name.substring(0,2).concat("*********@").concat(domain);
  }

  formatNumber(contacts: [], hasPhoneContactPermission: boolean) {
    return contacts
      .filter((phone: any) => phone.contato !== "")
      .map((phone: any) => {
        let con = phone.contato
          .replace("(", "")
          .replace(")", "")
          .replace("-", "");

        const formated = `(${con.substring(0, 2)}) ${hasPhoneContactPermission ? con.substring(
          2,
          con.length - 4
        ) : Array(con.length - 4).join("*")}-${con.substring(con.length - 4)}`;

        return formated;
      })
      .join("\n")
  }

  setUrlImg(url: string): string {
    const { policyAndSignatureImg } = this.state;
    const splitedUrl = url.split('/');
    const newUrl = `https://${splitedUrl[2]}${policyAndSignatureImg}/${splitedUrl[splitedUrl.length-1]}`
    return newUrl;
  }

  render() {
    const { user } = this.props;
    const emailIndex = user.notificationsSettings.channels.findIndex((channel: any) => channel.name === "E-mail");
    const phoneIndex = user.notificationsSettings.channels.findIndex((channel: any) => channel.name === "Telefone");
    
    const hasEmailContactPermission = emailIndex !== -1 && user.notificationsSettings.isActive && user.notificationsSettings.channels[emailIndex]?.isActive;
    const hasPhoneContactPermission = phoneIndex !== -1 && user.notificationsSettings.isActive && user.notificationsSettings.channels[phoneIndex]?.isActive;

    return (
      <>
        <div className="w-100 row m-0">
          <div
            className=""
            style={{
              position: "relative",
              width: "85%",
              height: "0",
              paddingBottom: "85%",
              zIndex: 0,
              overflow: 'hidden',
              borderRadius: '5px'
            }}
          >
            <img
              className={styles["user-avatar"]}
              src={
                user.userImageUrl &&
                this.getNewFileStackUrl(user.userImageUrl)
                  ? this.setUrlImg(user.userImageUrl)
                  : user.userImageUrl
                    ? user.userImageUrl
                    : (user.deleted ? avatarDeleted: avatar)
                }
              alt={user.displayName}
            />
          </div>
          <div className={styles["user-props"]}>
            {user.hasOwnProperty("avaliacoesUsuario") &&
              this.isEvaluationNegative(user.avaliacoesUsuario) && (
              <Tooltip
                type="light"
                size="large"
                placement="start"
                content={<span>{t("Parecer comportamental abaixo de 5")}</span>}
              >
                <p>
                  <img src={iconPan} alt="pan"></img>
                </p>
              </Tooltip>
            )}
            {this.isEvaluationOutOfDate(user.avaliacoesUsuario) && (
              <Tooltip
                type="light"
                size="large"
                placement="start"
                content={<span>{t("Parecer comportamental expirado")}</span>}
              >
                <p>
                  <img src={iconSchedule} alt="schedule"></img>
                </p>
              </Tooltip>
            )}
            {this.isEvaluationStar(user.avaliacoesUsuario) && !user.talent?.status && (
              <Tooltip
                type="light"
                size="large"
                placement="start"
                content={
                  <span>{t("Parecer comportamental 8 ou superior")}</span>
                }
              >
                <p>
                  <img src={iconStar} alt="star"></img>
                </p>
              </Tooltip>
            )}
            {!user.validRegistration &&
              <Tooltip
                type="light"
                size="large"
                content={<span>{t("Cadastro Incompleto")}</span>}
              >
                <p>{"CI"}</p>
              </Tooltip>
            }
            {user.provider === "linkedin" && <p>{t("in")}</p>}
            {user.preRegister && <p>{t("pré")}</p>}
            {user.dadosPessoais?.pcdUser?.isUserPCD && <p>{t("pcd")}</p>}
            {user.byRH && <p>{t("rh")}</p>}
            {user.talent && user.talent.status && (
              <Tooltip
                type="light"
                size="large"
                placement="start"
                content={
                  <span>{t("Profissional Talentoso")}</span>
                }
              >
                <p>
                  <img src={iconTalent} alt="star" style={{color: 'red'}}></img>
                </p>
              </Tooltip>
            )}
            <Tooltip
              type="light"
              size="large"
              placement="start-bottom"
              content={this.state.showTooltip
                ? <div className="d-flex">
                    <Loading show={this.state.loading} />
                    <section
                      className="mr-2"
                      style={{ width: '13rem'}}
                    >
                      <h1
                        style={{ fontSize: '0.8rem'}}
                      >
                      {t("Processos em andamento")}
                      </h1>
                      <ul>
                        {
                          (
                            this.state.inProgressOpportunities &&
                            this.state.inProgressOpportunities.length > 0 &&
                            this.state.inProgressOpportunities.map(
                              (opp: any) => {
                                return (
                                  <li>
                                    &bull; {" "}
                                    <Link
                                      to={`${OPPORTUNITY_DETAIL_ROUTE}/${opp._id}`}
                                      target="_blank"
                                    >
                                      {opp.title}
                                    </Link>
                                  </li>
                                )
                              }
                            )
                          ) ||
                          (
                            <li>{t("Sem Informação")}</li>
                          )
                        }
                      </ul>
                    </section>
                    <section
                      style={{ width: '13rem'}}
                    >
                      <h1
                        style={{ fontSize: '0.8rem'}}
                      >{t("Processos Finalizados")}</h1>
                      <ul>
                        {
                          (
                            this.state.finishedOpportunities &&
                            this.state.finishedOpportunities.length > 0 &&
                            this.state.finishedOpportunities.map(
                              (opp: any) => {
                                return (
                                  <li>
                                    &bull; {" "}
                                    <Link
                                      to={`${OPPORTUNITY_DETAIL_ROUTE}/${opp._id}`}
                                      target="_blank"
                                    >
                                      {opp.title}
                                    </Link>
                                  </li>
                                )
                              }
                            )
                          ) ||
                          (
                            <li>{t("Sem Informação")}</li>
                          )
                        }
                      </ul>
                    </section>
                  </div>
                : t("Clique aqui para ver os processos do candidato")
              }
            >
              <button
                style={{
                  backgroundColor: "transparent",
                  border: 'none',
                  fontWeight: "bold",
                  color: '#7b8896'
                }}
                onClick={() => {
                  this.setState({ showTooltip: !this.state.showTooltip });
                  this.userOpportunities(user); 
                }}
              >
                {t("PS")}
              </button>
            </Tooltip>
            {this.props.user.registerRh && (
              <Tooltip
                type="light"
                size="large"
                placement="start"
                content={
                  <span>{t("Editar cadastro (RH)")}</span>
                }
              >
                <button
                  className={styles['edit-button-style']}
                >
                  <Link
                    to={`${REGISTER_PROFESSIONAL_RH}/${this.props.user._id}/edit`}
                    target="_blank"
                  >
                    <Pencil />
                  </Link>
                </button>
              </Tooltip>
            )}
            {
              this.props.user.europeanUnionCitizenship && (
                <Tooltip
                type="light"
                size="large"
                placement="start"
                content={
                  <span>{t("Cidadania Europeia")}</span>
                }
                >
                  <img src={EuropeUnion} alt="cidadania europeia imagem"/>
                </Tooltip>
              )
            }
          </div>
        </div>
        <p className={styles["user-name"]}>{user.displayName}</p>
        {user.eluminer && user.eluminer.status ? <span className={styles["eluminer-info"]}>Eluminer</span> : "" }
        <p className={styles["user-info"]}>
          {hasEmailContactPermission 
            ? user.email 
            : this.formatEmail(user.email)
          }
        </p>
        <pre className={styles["user-info"]}>
          {this.formatNumber(user.contato.lista, hasPhoneContactPermission)}
        </pre>
      </>
    );
  }
}

export default ProfessionalAvatar;
