import React from 'react';
import OpportunityService from '../../services/opportunity.service';
import { Progress } from '@zendeskgarden/react-loaders';
import { PALETTE } from '@zendeskgarden/react-theming';
import { Row, Col } from '@zendeskgarden/react-grid';
import { ThemeProvider } from '@zendeskgarden/react-theming';
import { themeGray } from '../../util/colorButton';
import { Field, Label, Radio } from "@zendeskgarden/react-forms";
import iconApprovalGreen from "../../images/icons/approval-green.svg";
import iconApprovalGreen75 from "../../images/icons/approval-green-75.svg";
import iconApprovalYellow75 from "../../images/icons/approval-yellow-75.svg";
import iconApprovalYellow50 from "../../images/icons/approval-yellow-50.svg";
import iconApprovalRed50 from "../../images/icons/approval-red-50.svg";
import iconApprovalRed25 from "../../images/icons/approval-red-25.svg";
import style from './index.module.scss';
import { t } from '../../translations/translation.service';
import Loading from "../loading";

class CandidateFlipCard extends React.Component <{user: any, oppId: string, callback: any}>{

  state = {
    opportunity: {
      criteriosNecessarios: [] as any,
      criteriosDesejaveis: [] as any,
      ondeTrabalhar: [] as any,
      interesses: [] as any,
      areaAtuacao: [] as any,
    },
    isLoading: false,
  }

  constructor(props: any) {
    super(props);
    this.getPaletteColor = this.getPaletteColor.bind(this);
    this.getProgressBarValue = this.getProgressBarValue.bind(this);
    this.getUserInterestLocations = this.getUserInterestLocations.bind(this);
    this.getInterests = this.getInterests.bind(this);
    this.getOccupationArea = this.getOccupationArea.bind(this);
  }

  async componentDidMount() {
    const { oppId } = this.props;
    this.setState({
      isLoading: true 
    })
    const opp = await OpportunityService.getOpportunityLiteKanban(oppId)
    const info = {
      criteriosNecessarios: opp.data.criteriosMatch.filter((criterio: any) => criterio.peso === 3),
      criteriosDesejaveis: opp.data.criteriosMatch.filter((criterio: any) => criterio.peso === 1),
      ondeTrabalhar: opp.data.geolocation,
      interesses: opp.data.atitudes,
      areaAtuacao: opp.data.carreiras
    }
    this.setState({
      opportunity: info,
      isLoading: false,
    })
    this.getUserCriteria();
    this.getUserInterestLocations();
    this.getInterests();
    this.getOccupationArea();
  }

  getPaletteColor(userLevel: number) {
    switch(userLevel) {
      case 3:
        return PALETTE.green[600];
      case 2:
        return PALETTE.yellow[600];
      case 1:
        return PALETTE.red[600];
      default:
        return PALETTE.grey[600];
    }
  }

  getProgressBarValue(userLevel: number) {
    switch(userLevel) {
      case 3:
        return 100;
      case 2:
        return 66;
      case 1:
        return 33;
      default:
        return 0;
    }
  }

  getOccupationArea() {
    const { user } = this.props;
    const areaAtuacao = this.state.opportunity.areaAtuacao;
    user._usuario?.perfilUsuario?.mosaico?.escolhidas.forEach(
      (userCarrera: any) => {
        areaAtuacao.forEach(
          (oppCarrera: any) => {
            if (t(oppCarrera.nome) === t(userCarrera.detalhe.nome)) {
              oppCarrera.hasItem = true;
            }
          }
        );
      }
    );
    this.setState({
      opportunity: { ...this.state.opportunity, areaAtuacao }
    })
  }

  getInterests() {
    const { user } = this.props;
    const interesses = this.state.opportunity.interesses;
    user._usuario?.perfilUsuario?.mosaico?.escolhidas.forEach(
      (interesse: any) => {
        interesses.forEach(
          (oppInterest: any) => {
            if (t(oppInterest.nome) === t(interesse.detalhe.nome)) {
              oppInterest.hasInterest = true;
            }
          }
        );
      }
    );
    this.setState({
      opportunity: { ...this.state.opportunity, interesses }
    })
  }

  checkLocations(location: string, oppLocation: string) {
      const locationsName = location.split(",");
      const oppLocationsName = oppLocation.split(",");
      let checkValue = false;
      locationsName.forEach((local: string) => {
        const localTrim = local.trim();
        oppLocationsName.forEach((oppLocal: string) => {
          const oppLocalTrim = oppLocal.trim();
          if (localTrim.length > 2) {
            if (localTrim === oppLocalTrim) {
              checkValue = true
            }
          }
        });
      });
      return checkValue;
  }

  getUserInterestLocations() {
    const { user } = this.props;
    const ondeTrabalhar = this.state.opportunity.ondeTrabalhar;
    user._usuario?.perfilUsuario?.interestLocations?.forEach(
      (local: any) => {
        ondeTrabalhar.forEach(
          (opplocal: any) => {
            if (local.formatted_address === opplocal.formatted_address) {
              opplocal.hasInterestLocal = true;
            } else {
              const result = this.checkLocations(local.formatted_address, opplocal.formatted_address);
              if (result) {
                opplocal.hasInterestLocal = true;
              }
            }
          }
        );
      }
    );
    this.setState({
      opportunity: { ...this.state.opportunity, ondeTrabalhar },
    });
  }

  getUserCriteria() {
    const { user } = this.props;
    const criteriosNecessarios = this.state.opportunity.criteriosNecessarios;
    const criteriosDesejaveis = this.state.opportunity.criteriosDesejaveis;
    const skillsList = user._usuario?.perfilUsuario?.digomais?.lista;
    const notMatchedSkills = [] as any;


    criteriosNecessarios.forEach((criterio: any) => {
      const nomeCriterioTrim = criterio.nome?.trim();
      const matchedSkill = skillsList?.find((skill: any) => {
        const nomeSkillTrim = skill.nome?.trim();
        return nomeCriterioTrim?.toUpperCase() === nomeSkillTrim?.toUpperCase();
      });
  
      if (matchedSkill) {
        criterio.userLevel = matchedSkill.level ? matchedSkill.level : 1;
      } else {
        notMatchedSkills.push(criterio);
      }
    });

    criteriosDesejaveis.forEach((criterio: any) => {
      const nomeCriterioTrim = criterio.nome?.trim();
      const matchedSkill = skillsList?.find((skill: any) => {
        const nomeSkillTrim = skill.nome?.trim();
        return nomeCriterioTrim?.toUpperCase() === nomeSkillTrim?.toUpperCase();
      });
  
      if (matchedSkill) {
        criterio.userLevel = matchedSkill.level ? matchedSkill.level : 1;
      } else {
        notMatchedSkills.push(criterio);
      }
    });

    if (notMatchedSkills.length > 0 && user?._usuario?.providerData?.skills?.values) {
      const secondSkillsList = user._usuario.providerData.skills.values;
      
      criteriosNecessarios.forEach((criterio: any) => {
        const nomeCriterioTrim = criterio.nome.trim();
        const matchedSkill = secondSkillsList.find((skill: any) => {
          const nomeSkillTrim = skill.skill.name?.trim();
          return nomeCriterioTrim?.toUpperCase() === nomeSkillTrim?.toUpperCase();
        });
  
        if (matchedSkill && criterio.userLevel === undefined) {
          criterio.userLevel = 2;
        }
      });
      
      criteriosDesejaveis.forEach((criterio: any) => {
        const nomeCriterioTrim = criterio.nome.trim();
        const matchedSkill = secondSkillsList.find((skill: any) => {
          const nomeSkillTrim = skill.skill.name.trim();
          return nomeCriterioTrim?.toUpperCase() === nomeSkillTrim?.toUpperCase();
        });
  
        if (matchedSkill && criterio.userLevel === undefined) {
          criterio.userLevel = 2;
        }
      });
    }
    
    this.setState({
      opportunity: { ...this.state.opportunity, criteriosNecessarios, criteriosDesejaveis }
    });
  }

  getLevel(level: number) {
    if (level > 99) {
      return (
        <img
          src={iconApprovalGreen}
          alt="adesão verde"
          className="mr-2"
        />
      )
    }
    if (level >= 75 && level <= 99) {
      return (
        <img
          src={iconApprovalGreen75}
          alt="adesão verde"
          className="mr-2"
        />
      )
    }
    if (level > 50 && level < 75) {
      return (
        <img
          src={iconApprovalYellow75}
          alt="adesão amarela"
          className="mr-2"
        />
      );
    }
    if (level <= 50 && level > 49) {
      return (
        <img
          src={iconApprovalYellow50}
          alt="adesão amarela"
          className="mr-2"
        />
      );
    }
    if (level >= 25 && level < 49) {
      return (
        <img
          src={iconApprovalRed50}
          alt="adesão vermelha"
          className="mr-2"
        />
      );
    }
    if (level < 25) {
      return (
        <img
          src={iconApprovalRed25}
          alt="adesão vermelha"
          className="mr-2"
        />
      );
    }
  }

  render () {
    const { user } = this.props;
    return(
      <div className={style["body-style"]}>
        <Loading show={this.state.isLoading}/>
        <header>
          <button onClick={this.props.callback} type="button" className="close mr-2" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <div className="d-flex justify-content-around">
            <h1 className={`${style["title-name-flip-card"]} mt-4`}>{user._usuario.displayName}</h1>
            <p className={`${style["percentage-style"]} mt-4`}>{this.getLevel(user.nivelAderencia)}{user.nivelAderencia}%</p>
          </div>
        </header>
        <main className={style["main-content-flip-card"]}>
          <section className={style["section-title-flip-card"]}>
            {t("Critérios Necessários")}
            <span className={style["bottom-line-flip-card"]}/>
            {this.state.opportunity.criteriosNecessarios.map(
              (criterios: any) => {
                return (
                  <Row>
                    <Col sm={2}>
                      <Progress
                        color={this.getPaletteColor(criterios.userLevel)}
                        size="medium"
                        value={this.getProgressBarValue(criterios.userLevel)}
                      />
                    </Col>
                    <Col sm={10} className={`${style["content-field-flip-card"]}`}>
                      <p>{criterios.nome}</p>
                    </Col>
                  </Row>
                )
              }
            )}
          </section>
          <section className={style["section-title-flip-card"]}>
            {t("Critérios Desejáveis")}
            <span className={style["bottom-line-flip-card"]}/>
            {this.state.opportunity.criteriosDesejaveis.map(
              (criterios: any) => {
                return (
                  <Row className='mb-3'>
                    <Col sm={2}>
                      <Progress
                        color={this.getPaletteColor(criterios.userLevel)}
                        size="medium"
                        value={this.getProgressBarValue(criterios.userLevel)}
                      />
                    </Col>
                    <Col sm={8} className={`${style["content-field-flip-card"]}`}>
                      {criterios.nome}
                    </Col>
                  </Row>
                );
              }
            )}
          </section>
          <section className={style["section-title-flip-card"]}>
            {t("Onde gostaria de trabalhar")}
            <span className={style["bottom-line-flip-card"]}/>
            {this.state.opportunity.ondeTrabalhar.map(
              (local: any) => {
                return (
                  <ThemeProvider theme={themeGray as any}>
                    <Field className='mb-3'>
                      <Radio
                        disabled={!local.hasInterestLocal}
                        checked={local.hasInterestLocal}
                      >
                        <Label
                          style={{
                            color: '#354A5F',
                            fontWeight: 'lighter',
                            fontSize: '12px'
                          }}
                        >
                          {local.formatted_address}
                        </Label>
                      </Radio>
                    </Field>
                  </ThemeProvider>
                );
              }
            )}
          </section>
          <section className={style["section-title-flip-card"]}>
            {t("Interesses")}
            <span className={style["bottom-line-flip-card"]}/>
            {this.state.opportunity.interesses.map(
              (interesse: any) => {
                return (
                  <ThemeProvider theme={themeGray as any}>
                    <Field className='mb-3'>
                      <Radio
                        disabled={!interesse.hasInterest}
                        checked={interesse.hasInterest}
                      >
                        <Label
                          style={{
                            color: '#354A5F',
                            fontWeight: 'lighter',
                            fontSize: '12px'
                          }}
                        >
                          {t(interesse.nome)}
                        </Label>
                      </Radio>
                    </Field>
                  </ThemeProvider>
                );
              }
            )}
          </section>
          <section className={style["section-title-flip-card"]}>
            {t("Área de Atuação")}
            <span className={style["bottom-line-flip-card"]}/>
            {this.state.opportunity.areaAtuacao.map(
              (area: any) => {
                return (
                  <ThemeProvider theme={themeGray as any}>
                    <Field className='mb-3'>
                      <Radio
                        disabled={!area.hasItem}
                        checked={area.hasItem}
                      >
                        <Label
                          style={{
                            color: '#354A5F',
                            fontWeight: 'lighter',
                            fontSize: '12px'
                          }}
                        >
                          {t(area.nome)}
                        </Label>
                      </Radio>
                    </Field>
                  </ThemeProvider>
                );
              }
            )}
          </section>
        </main>
      </div>
    );
  }
}

export default CandidateFlipCard;
