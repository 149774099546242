import { ThemeProvider } from "@zendeskgarden/react-theming";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Footer from "./components/footer";
import Header from "./components/header";
import Main from "./components/main";
import Navbar from "./components/navbar";
import {
  FORGOT_PASSWORD_ROUTE,
  LOGIN_ROUTE,
  SIGNUP_LOCATION_ROUTE,
  SIGNUP_ROUTE,
  SIGNUP_SUCCESS_ROUTE
} from "./providers/routes";
import { AuthenticationService } from './services/auth.service';
import ReactGA from "react-ga4";
// import { HelmetProvider } from "react-helmet-async";
import BannerCookie from "./components/banner-cookie";

class App extends React.Component<any> {

  componentDidMount() {
    AuthenticationService.isRh() ? this.reactGARecruitment(process.env.REACT_APP_GOOGLE_ANALYTICS_RECRUITMENT) : this.reactGAUser(process.env.REACT_APP_GOOGLE_ANALYTICS_PROFESSIONAL);
  }

  async componentDidUpdate(prevProps: any) {
    if (AuthenticationService.isRh() !== undefined) {
      AuthenticationService.isRh() ? this.reactGARecruitment(process.env.REACT_APP_GOOGLE_ANALYTICS_RECRUITMENT) : this.reactGAUser(process.env.REACT_APP_GOOGLE_ANALYTICS_PROFESSIONAL);
    }

    if (prevProps.currentLanguage !== this.props.currentLanguage) {
      this.setState({
        currentLanguage: this.props.currentLanguage,
      });
    }
  }

  reactGARecruitment = (key: any) => {
    ReactGA.initialize(`${key}`);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }

  reactGAUser = (key: any) => {
    ReactGA.initialize(`${key}`);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }

  render() {
    //Declarar rotas em que o Header, Navbar ou Footer não aparecerão.
    const hideRoutes: string[] = [
      LOGIN_ROUTE,
      SIGNUP_ROUTE,
      SIGNUP_LOCATION_ROUTE,
      SIGNUP_SUCCESS_ROUTE,
      FORGOT_PASSWORD_ROUTE,
      SIGNUP_SUCCESS_ROUTE,
    ];
   
    return (
      <ThemeProvider>
        {/* <HelmetProvider> */}
        {hideRoutes.indexOf(this.props.location.pathname) === -1 && <Header />}
        {hideRoutes.indexOf(this.props.location.pathname) === -1 && <Navbar />}
        <Main />
        <BannerCookie />
        {hideRoutes.indexOf(this.props.location.pathname) === -1 && <Footer />}
        {/* </HelmetProvider> */}
      </ThemeProvider>
    );
  }
}

const mapStateToProps = (store: any) => ({
  filter: store.changeLanguageReducer.currentLanguage,
});

export default connect(mapStateToProps)(withRouter(App));
