import React from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState } from 'draft-js';
import { stateToHTML } from "draft-js-export-html";
import { connect } from "react-redux";
import { AnyAction, Dispatch, bindActionCreators, } from 'redux';
import {
  getEventComment,
  getEventAttachments,
  saveEvent,
  editEvent
} from "../../../actions";
// @ts-ignore
import FilestackReact from "filestack-react";
import HeaderEventBox from "../header-event-box";
import style from './index.module.scss';
import FileStackService from "../../../services/filestack.service";
import { stateFromHTML } from "draft-js-import-html";
import { t } from "../../../translations/translation.service";

class EventBox extends React.Component<any> {
  constructor(props: any) {
    super(props);
    this.policeAndSignatureString = this.policeAndSignatureString.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.saveEvent = this.saveEvent.bind(this);
  }
  state = {
    cvFile: [] as any[],
    policyString: "",
    signatureString: "",
    comment: EditorState.createEmpty(),
    attachments: {},
    feedAttachments: {}
  }

  async policeAndSignatureString(): Promise<void> {
    try {
      const policyAndSignature = await FileStackService.getPoliceAndSignature();
      this.setState({
        policyString: policyAndSignature.data.policy,
        signatureString: policyAndSignature.data.signature
      });
    } catch (err: any) {
      console.log("ERROR: ", err);
    }
  }

  buttonEvent() {
    if (Object.keys(this.props.editEventByRh).length > 0) {
      return (
        <button
          className={[
            style["buttons-editor"],
            style["save-color"]
          ].join(" ")}
          onClick={this.saveEvent}
        >
          {t("Editar")}
        </button>
      )
    } else {
      return (
        <button
          className={[
            style["buttons-editor"],
            style["save-color"]
          ].join(" ")}
          onClick={this.saveEvent}
        >
          {t("salvar")}
        </button>
      )
    }
  }

  async componentDidMount(): Promise<void> {
    const { editEventByRh } = this.props;
    if (Object.keys(editEventByRh).length > 0) {
      const comment = stateFromHTML(editEventByRh.comment)
      this.setState({
        comment: EditorState.createWithContent(comment),
      }, () => getEventComment(comment));
    }
    await this.policeAndSignatureString();
  }

  async getFile({ filesUploaded }: any) {
    const { getEventAttachments } = this.props;
    let array = filesUploaded.map(function (item: any) {
      return {
        fileName: item.filename,
        url: item.url,
      };
    });

    const policyAndSignature = (await FileStackService.getFileStackSecreteCodeToUser(
      array[0].url, false
    )).data;

    const feedFile =  {
      fileName: array[0].fileName,
      url: array[0].url,
      comment: "",
      content: ""
    };

    this.setState({
      cvFile: array[0],
      attachments: policyAndSignature,
      feedAttachments: feedFile
    }, () => {
      getEventAttachments({ feedFile: this.state.feedAttachments,  attachments: this.state.attachments, cvFiles: this.state.cvFile })
    });
    
  }

  handleDescriptionChange(event: any) {
    const { getEventComment } = this.props;
    getEventComment(stateToHTML(event.getCurrentContent()))
    this.setState({
      comment: event,
    });
  }

  async saveEvent() {
    const {
      eventType,
      eventDate,
      responsibleEvent,
      eventComment,
      eventAttachments,
      saveEvent,
      editEventByRh,
      editEvent,
      feedAttachmentsEvent
    } = this.props;

    const data = {
      responsible: responsibleEvent,
      event: eventType,
      date: eventDate,
      comment: eventComment ? eventComment : "<p><br></p>",
      attachments: eventAttachments,
      feedAttachments: feedAttachmentsEvent
    }
    if (Object.keys(editEventByRh).length > 0) {
      editEvent(data);
      this.props.clearEventInfo({});
    } else {
      saveEvent(data);
    }
    this.props.openModal(false);
    this.props.updateFeed(true);
  }

  render() {
    return (
      <div className={style["container-event-box"]}>
        <HeaderEventBox editEventByRh={this.props.editEventByRh} />
        <Editor
          editorState={this.state.comment}
          wrapperClassName={style["wrapper-editor-text"]}
          editorClassName={"pl-3 pr-3"}
          onEditorStateChange={this.handleDescriptionChange}
        />
        <div className={style["container-buttons-editor"]}>          
          {this.buttonEvent()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store: {
  eventFilterReducer: {
    eventType: any,
    eventDate: any,
    responsibleEvent: any,
    eventComment: any,
    eventAttachments: any,
    feedAttachments: any,
  };
}) => ({
  eventType: store.eventFilterReducer.eventType,
  eventDate: store.eventFilterReducer.eventDate,
  responsibleEvent: store.eventFilterReducer.responsibleEvent,
  eventComment: store.eventFilterReducer.eventComment,
  eventAttachments: store.eventFilterReducer.eventAttachments
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      getEventComment,
      getEventAttachments,
      saveEvent,
      editEvent,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(EventBox);