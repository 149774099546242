import { Datepicker } from "@zendeskgarden/react-datepickers";
import {
  Dropdown,
  Field as FieldDropdown,
  Item,
  Menu,
  Select,
} from "@zendeskgarden/react-dropdowns";
import {
  Toggle,
  Label,
  Input,
  Field,
  Field as ToggleField,
  Message
} from "@zendeskgarden/react-forms";
import { ThemeProvider } from "@zendeskgarden/react-theming";
import { theme } from "../../../util/colorButton";
import { Col, Row } from "@zendeskgarden/react-grid";
import { EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import React from "react";
import { connect } from "react-redux";
import Loading from "../../../components/loading";
import { AccordionContainer } from "@zendeskgarden/container-accordion";
import ReactComponents from "../../../images/enter-loading.png";
import UserService from "../../../services/user.service";
import { setUtcZero } from "../../../util/date";
import Moment from "moment";
import { UserData } from "../../../model/user.model";
import { ReactComponent as UserIcon } from "../../../images/icons/ic_done_24px.svg";
import { ReactComponent as TooltipInfo } from "../../../images/icons/info.svg";
import { Tooltip } from "@zendeskgarden/react-tooltips";
import {
  Opportunity,
  OpportunityFields,
  Steps,
} from "../../../model/opportunity.model";
import {
  getOpStatus,
  getOpCapture,
  getOpSource,
  getCurrentObject,
  getInfoStep,
} from "../../../util/opportunity";
import { t } from "../../../translations/translation.service";
import "./index.css";
import OpportunityService from "../../../services/opportunity.service";
import { AlertService } from "../../../services/alert.service";
import { AuthenticationService } from "../../../services/auth.service";
import { Link } from "react-router-dom";
import ReactGA from 'react-ga4';
import FileStackService from "../../../services/filestack.service";

const dateFormatter = new Intl.DateTimeFormat("pt-BR", {
  month: "2-digit",
  day: "2-digit",
  year: "numeric",
});

const OpportunityStatus: OpportunityFields[] = getOpStatus();
const OpportunityCapture: OpportunityFields[] = getOpCapture();
const OpportunitySource: OpportunityFields[] = getOpSource();

class OpportunityProgress extends React.Component<any> {
  state = {
    loading: true,
    opportunity: {} as Opportunity,
    user: {} as UserData,
    sections: [] as Steps[],
    status: {} as OpportunityFields,
    capture: {} as OpportunityFields,
    source: {} as OpportunityFields,
    dateVictory: new Date(),
    calendarDateStart: new Date(),
    isInvalidDate: false,
    policyAndSignatureImg: '' as string,
  };

  constructor(props: any) {
    super(props);
    this.changeProfessionalInfo = this.changeProfessionalInfo.bind(this);
    this.clear = this.clear.bind(this);
    this.parseDateBr = this.parseDateBr.bind(this);
  }

  async componentDidMount() {
    const oppPeriods = await OpportunityService.getOpportunityDates(this.props.match.params.opportunityId);
    const { data } = await OpportunityService.getOpWithCandidate(
      this.props.match.params.opportunityId,
      this.props.match.params.professionalId
    );
    const indexCapture = getCurrentObject(
      OpportunityCapture,
      data.vaga.candidatos[0].capture
    );
    const indexSource = getCurrentObject(
      OpportunitySource,
      data.vaga.candidatos[0].source
    );

    const steps = data.vaga.candidatos[0].steps;
    steps.map((item: any) => {
      if (item.details && item.details !== "") {
        let contentState = stateFromHTML(item.details);
        item.details = EditorState.createWithContent(contentState);
      }
      return item;
    });
    this.setState({
      opportunity: data.vaga,
      user: data.candidato,
      sections: steps,
      loading: false,
      status: OpportunityStatus[data.vaga.candidatos[0].status.current],
      capture: OpportunityCapture[indexCapture],
      source: OpportunitySource[indexSource],
      dateVictory: new Date(data.vaga.candidatos[0].dateVictory),
      calendarDateStart: this.setTimeZoneDefaut(new Date(oppPeriods.data.periods[0].dates[0].end)),
    }, () => this.getFileStackPolicyToUserImg());
  }

  async getFileStackPolicyToUserImg(): Promise<void> {
    try {
      if (
        this.state.user &&
        this.state.user.userImageUrl &&
        this.getNewFileStackUrl(this.state.user.userImageUrl)
      ) {
        const policyUserImg = await FileStackService.getFileStackSecreteCode(
          this.state.user.userImageUrl, false
        );
        this.setState({ policyAndSignatureImg: policyUserImg.data });
      }
    } catch(err: any) {
      console.log("Erro ao obter credenciais - ERROR: ", err);
    }
  }

  getNewFileStackUrl(url: string): boolean {
    const domain = url.split('/')[2];
    if (domain.includes('cdn.file')) {
      return true;
    }
    return false;
  }

  setUrlImg(url: string): string {
    const { policyAndSignatureImg } = this.state;
    const splitedUrl = url.split('/');
    const newUrl = `https://${splitedUrl[2]}${policyAndSignatureImg}/${splitedUrl[splitedUrl.length-1]}`
    return newUrl;
  }

  clear(key: any) {
    this.setState((previousState: any) => {
      let sections = [...previousState.sections];
      if (sections[key].user) {
        sections.forEach((section, index) => {
          if (index >= key) {
            section.date = undefined;
            section.meetsTheRequirements = undefined;
            section.details = "";
          }
        })
        return { sections };
      } else {
        sections[key] = {
          _id: sections[key]._id,
          key: key,
        };
        return { sections };
      }
    }, () => {
      this.changeStepCandidate(key);
    });

  }

  selectProfessionalEvent() {
    ReactGA.event({
      category: "Recruitment-actions",
      action: "selecionar profissional",
      label: "rh",
    })
  }

  reactVictoryEvent() {
    ReactGA.event({
      category: 'Recruitment-actions',
      action: "Vitória na oportunidade",
      label: "rh",
    })
  }

  async changeProfessionalInfo() {
    this.setState({ loading: true });
    try {
      const obj = {
        candidate: this.state.user._id,
        status: this.state.status.id,
        capture: this.state.capture?.id ? this.state.capture.id : "",
        source: this.state.source?.id ? this.state.source.id : "",
        dateVictory:
          this.state.status.id === 6
            ? setUtcZero(this.state.dateVictory)
            : null,
      };

      this.changeStepCandidate(0);

      await OpportunityService.changeInfoCandidate(
        this.state.opportunity._id,
        obj
      );
      if (this.state.status.id === 6) {
        this.selectProfessionalEvent();
        this.reactVictoryEvent();
        await UserService.becomeProfessionalEluminer(this.state.user._id);
      }

      this.setState({ loading: false });

      this.state.status.id === 6
        ? AlertService.success(
            t("O Candidato foi selecionado para a oportunidade!")
          )
        : AlertService.success(
            t("Informações do candidato alteradas com sucesso!")
          );
    } catch (err) {
      AlertService.error(t("Não foi possível concluir a operação."));
    }
  }

  setTimeZoneDefaut = (date: Date): Date => {
    date?.setHours(12, 0, 0, 0);
    date = new Date(
      date.valueOf() - date.getTimezoneOffset() * 60000
    );
    return date;
  };

  getOpportunityStep(step: number) {
    if (this.state.opportunity.isPublic) {
      if (
        this.state.opportunity.quantidadeVagas === 0 ||
        this.state.opportunity.victories !== 0 ||
        this.state.opportunity.defeats !== 0
      )
        return t("Encerrada");
      switch (step) {
        case 0:
          return t("Recrutamento");
        case 1:
          return t("Recrutamento");
        case 2:
          return t("Avaliação");
        case 3:
          return t("Seleção");
        case 4:
          return t("Apresentação");
      }
    } else {
      return t("Revisão");
    }
  }

  async changeStepCandidate(step: number) {
    this.setState({ loading: true });
    try {
      if (this.state.opportunity.label === "2") {
        this.setState({ loading: false }, () =>
          AlertService.warn(
            t(
              "Esta oportunidade está inativa. Para fazer alterações na candidatura do profissional, ative a oportunidade antes."
            )
          )
        );
        return;
      }

      const { date, key, meetsTheRequirements } = this.state.sections[step];
      var stepsReq: any = [];

      if (!date || meetsTheRequirements === undefined) {
        this.setState({
          loading: false
        })
        AlertService.warn("Necessario o preenchimento de data em resposta positiva.");
        return;
      }

      this.state.sections.forEach((item: Steps) => {
        let step = { ...item, details: "" };
        if (item.details && item.details !== "") {
          step.details = stateToHTML(item.details.getCurrentContent());
        }

        if (step.user && step.user._id) step.user = step.user._id;
        if (step.key === key) {
          if (date) {
            step.date = new Date(date);
            step.date = this.setTimeZoneDefaut(step.date);
          } else {
            delete step.date;
          }
          step.user = AuthenticationService.getUser()._id;
          step.meetsTheRequirements = meetsTheRequirements;
        } 
        stepsReq.push(step);
      });

      const obj = {
        candidate: this.state.user._id,
        steps: stepsReq,
      };
      await OpportunityService.changeStepCandidate(
        this.state.opportunity._id,
        obj
      );
      this.setState({ loading: false });
      AlertService.success(t("Fases da candidatura salvas com sucesso!"));
    } catch (err) {
      this.setState({ loading: false });
      AlertService.error(t("Ocorreu algum erro. Tente novamente mais tarde."));
      console.error(err);
    }
  }

  parseDateBr(inputValue: string) {
    if (inputValue.length === 10) {
      const value = inputValue.split("/");
      const formatDateBR = `${value[1]}/${value[0]}/${value[2]}`;
      const data = new Date(formatDateBR);
      this.setState({ isInvalidDate: false })
      return data;
    }
    return new Date("");
  }

  getFinalStep = (section: any) => {
    let finalStep: any = {};
    for (let index = section.length -1; index >= 0; index --) {
      if (section[index].meetsTheRequirements === 0) {
        finalStep = section[index];
        return finalStep;
      }
    }
  }

  isInvalid(date: Date) {
    const finalDate = this.getFinalStep(this.state.sections);
    const dateVictory = new Date(date).setHours(0,0,0,0)
    const dateLastStep = new Date(finalDate.date).setHours(0,0,0,0)
    const toDay = new Date(new Date()).setHours(0,0,0,0)
    if (dateVictory < dateLastStep || dateVictory > toDay) {
      this.setState({ isInvalidDate: true })
      return true;
    }
    this.setState({ isInvalidDate: false })
    return false;
  }

  changeDate(date: Date, previousStep: any, nextStep: any, index: number, key: number) {
    const updatedDate = Moment(date).toDate().setHours(0, 0, 0, 0);
    if (
      (nextStep?.date && updatedDate > Moment(nextStep?.date).toDate().setHours(0, 0, 0, 0)) ||
      (previousStep?.date && index !== 1 && updatedDate < Moment(previousStep?.date).toDate().setHours(0, 0, 0, 0)) ||
      (updatedDate > Moment().toDate().setHours(0, 0, 0, 0))
    ) return;
                                   
    this.setState((previousState: any) => {
      let sections = [
        ...previousState.sections,
      ];
      sections[key] = {
        ...sections[key],
        date: date,
      };
      return { sections };
    });
  }

  render() {
    if (this.state.loading) return <Loading show={this.state.loading} />;   
    return (
      <div className="container">
        <Row className="container mt-4 d-flex align-items-center justify-content-between">
          <span className="h3 u-semibold">
            {t("Candidatura")}: {this.state.opportunity.titulo}
          </span>
          <span className="u-fs-sm u-semibold">
            Status:{" "}
                  {this.state.opportunity.isFinished
                    ? t("Finalizada")
                    : this.state.opportunity.isPublic
                    ? t("Publicada")
                    : t("Não publicada")}
                  &nbsp;|&nbsp;
            {t("Etapa")}:{" "}
            {this.getOpportunityStep(this.state.opportunity.fase)} |{" "}
            {t("Ativa em")}: {Moment(this.state.calendarDateStart).format("DD/MM/YYYY")} |
            ID: {this.state.opportunity?.idOpportunity}
          </span>
        </Row>
        <hr style={{ borderColor: "#E1E4E7" }} />
        <Row className="mt-4 d-flex align-items-center">
          <Col xl={4} lg={12} md={12} sm={12}>
            <div className="professional-title d-flex align-items-center mb-4">
            <img
              src={
                this.state.user.userImageUrl &&
                this.getNewFileStackUrl(this.state.user.userImageUrl)
                  ? this.setUrlImg(this.state.user.userImageUrl)
                  : this.state.user.userImageUrl
                    ? this.state.user.userImageUrl
                    : (ReactComponents)
                }
              alt={this.state.user.displayName}
            />
              <div className="professional-info ml-4">
                <Link
                  to={`/professional/${this.state.user._id}`}
                  className="h4 u-semibold mb-0"
                >
                  {this.state.user.displayName}
                </Link>
                <p className="u-fs-md">{this.state.user.email}</p>
              </div>
            </div>
          </Col>
          {!(this.state.status.id === 6) && <Col xl={2} />}
          <Col className="mt-4" xl={2} lg={12} md={12} sm={12}>
            <ThemeProvider theme={theme as any}>
              <Dropdown
                selectedItem={this.state.capture}
                onSelect={(value) =>
                  this.setState({
                    capture: value,
                  })
                }
                downshiftProps={{
                  itemToString: (item: OpportunityFields) => item && item.value,
                }}
              >
                <FieldDropdown>
                  <Label>{t("Fonte da Vaga")}:</Label>
                  <Select>{this.state.capture?.value}</Select>
                </FieldDropdown>
                <Menu>
                  {OpportunityCapture.map((option) => (
                    <Item key={option.id} value={option}>
                      {t(option.value)}
                    </Item>
                  ))}
                </Menu>
              </Dropdown>
            </ThemeProvider>
          </Col>
          <Col className="mt-4" xl={2} lg={12} md={12} sm={12}>
            <ThemeProvider theme={theme as any}>
              <Dropdown
                selectedItem={this.state.source}
                onSelect={(value) =>
                  this.setState({
                    source: value,
                  })
                }
                downshiftProps={{
                  itemToString: (item: OpportunityFields) => item && item.value,
                }}
              >
                <FieldDropdown>
                  <Label>{t("Canal")}:</Label>
                  <Select>{this.state.source?.value}</Select>
                </FieldDropdown>
                <Menu>
                  {OpportunitySource.map((option) => (
                    <Item key={option.id} value={option}>
                      {t(option.value)}
                    </Item>
                  ))}
                </Menu>
              </Dropdown>
            </ThemeProvider>
          </Col>
          <Col className="mt-4" xl={2} lg={12} md={12} sm={12}>
            <ThemeProvider theme={theme as any}>
              <Dropdown
                selectedItem={this.state.status}
                onSelect={(value) =>
                  this.setState({
                    status: value,
                  })
                }
                downshiftProps={{
                  itemToString: (item: OpportunityFields) => item && item.value,
                }}
              >
                <FieldDropdown className="mb-1 filho-1">
                  <div className="d-flex justify-content-between">
                    <Label className="mt-2">{t("Status Candidato")}:</Label>
                    <span className="mb-2">
                      <Tooltip
                        type="light"
                        size="large"
                        content={
                          t(
                            `Mantenha esses campos atualizados!
                            Assim outros recrutadores podem acessar o status do profissional na tela do currículo.`
                          )
                        }
                      >
                        <TooltipInfo
                          style={{ fill: "#8e8e8e" }}
                          className="mt-2 ml-1"
                        />
                      </Tooltip>
                    </span>
                  </div>
                  <Select>{t(this.state.status.value)}</Select>
                </FieldDropdown>
                <Menu>
                  {OpportunityStatus.map((option) => (
                    <Item key={option.id} value={option}>
                      {t(option.value)}
                    </Item>
                  ))}
                </Menu>
              </Dropdown>
            </ThemeProvider>
          </Col>
          {this.state.status.id === 6 && (
            <Col className="mt-4" xl={2} lg={12} md={12} sm={12}>
              <ThemeProvider theme={theme as any}>
                <Label className="mb-3">{t("Data da Seleção")}:</Label>
                <span>
                  <Datepicker
                    minValue={Moment(this.getFinalStep(this.state.sections).date).toDate() || new Date()}
                    maxValue={new Date()}
                    value={this.state.dateVictory < setUtcZero(Moment(this.state.calendarDateStart).toDate()) ? undefined : this.state.dateVictory}
                    onChange={(date) => {
                      if (this.isInvalid(date)) return
                      this.setState({
                        dateVictory: date,
                      })
                    }}
                    onClick={() => this.setState({ isInvalidDate: false })}
                    formatDate={(date) => dateFormatter.format(date)}
                    customParseDate={this.parseDateBr}
                    isCompact
                    locale="pt-BR"
                  >
                    <Input validation={this.state.isInvalidDate ? "error" : undefined} />
                  </Datepicker>
                </span>
              </ThemeProvider>
            </Col>
          )}
          {this.state.isInvalidDate && (
            <Col style={{ display: "flex",  justifyContent: "end"}}>
              <Message validation="error" >
                {t("Você deve escolher uma data entre")} {`${Moment(this.state.sections[4]?.date).format("DD/MM/YYYY")} ${t("e")} ${Moment(new Date()).format("DD/MM/YYYY")}`}
              </Message>
            </Col>
          )}
        </Row>
        <div className="d-flex justify-content-end mt-4">
          <button
            onClick={this.changeProfessionalInfo}
            style={{
              background: "#E6A243 0% 0% no-repeat padding-box",
              borderRadius: "15px",
              border: "none",
            }}
          >
            <span
              style={{
                color: "#FFFFFF",
                display: "inline-block",
                padding: ".25rem 2rem .25rem 2rem",
              }}
            >
              {t("Salvar")}
            </span>
          </button>
        </div>
        <Row className="mt-2">
          <Col xl={1} />
          <Col className="mt-4" xl={10} lg={12} md={12} sm={12}>
            <AccordionContainer expandable={true} collapsible={true}>
              {({
                getHeaderProps,
                getTriggerProps,
                getPanelProps,
                expandedSections,
                disabledSections,
              }) => (
                <>
                  <div className="mb-4" style={{ width: "100%" }}>
                    {this.state.sections.map((item: any, index: number) => {
                      if (item.key === 0) return null;
                      const disabled = disabledSections.indexOf(index) !== -1;
                      const hidden = expandedSections.indexOf(index) === -1;
                      const info = getInfoStep(item.key);

                      let previousStepWithDate = {} as Steps;
                      let nextStepWithDate = {} as Steps;

                      for (let i = 0; i < this.state.sections?.length; i++) {
                        if (i < index && this.state.sections[i].date) {
                          previousStepWithDate = this.state.sections[i];
                          if (previousStepWithDate.date) {
                            previousStepWithDate.date = this.setTimeZoneDefaut(new Date(previousStepWithDate.date));
                          }
                        }
                        if (i > index && this.state.sections[i].date && !nextStepWithDate.date) {
                          nextStepWithDate = this.state.sections[i];
                          if (nextStepWithDate.date) {
                            nextStepWithDate.date = this.setTimeZoneDefaut(new Date(nextStepWithDate.date));
                          }
                        }
                      }

                      return (
                        <div key={index}>
                          <div
                            {...getHeaderProps({ role: null, ariaLevel: null })}
                          >
                            <button
                              className="mt-2"
                              {...getTriggerProps({
                                index,
                                role: null,
                                tabIndex: null,
                                disabled,
                                style: {
                                  width: "100%",
                                  padding: ".75rem",
                                  border: "none",
                                  borderRadius: "4px",
                                  textAlign: "inherit",
                                  background:
                                    "#34495D 0% 0% no-repeat padding-box",
                                  boxShadow: "2px 2px 6px #00000029",
                                },
                              })}
                            >
                              <Row className="container m-0 p-0">
                                <Col className="d-flex justify-content-between pr-2">
                                  <span
                                    className="step-title"
                                    style={info?.sectionColor}
                                  >
                                    {info?.sectionTitle}
                                  </span>
                                  <div className="d-flex align-items-center">
                                    <span className="text-light section-step">
                                      {info?.sectionStep}
                                    </span>
                                    {item.meetsTheRequirements === 0 ? (
                                      <span className="user-icon">
                                        <UserIcon style={{ opacity: 1 }} />
                                      </span>
                                    ) : (
                                      <Field
                                        className="ml-4"
                                        style={{ width: "20px" }}
                                      />
                                    )}
                                  </div>
                                </Col>
                              </Row>
                            </button>
                          </div>
                          <section
                            className="sectionStep"
                            {...getPanelProps({
                              index,
                              role: null,
                              hidden,
                            })}
                          >
                            <Row>
                              <Col xl={7} lg={6} md={6} sm={12}>
                                <Field>
                                  <Label className="accordion-font mt-2">
                                    {info?.professionalStep}
                                  </Label>
                                  <ThemeProvider theme={theme as any}>
                                    <ToggleField className="mt-2 d-flex toggle-candidate">
                                      <Label className="accordion-font mr-2">
                                        {t("Não")}
                                      </Label>
                                      <Toggle
                                        onChange={(event) =>
                                          this.setState(
                                            (previousState: any) => {
                                              let sections = [
                                                ...previousState.sections,
                                              ];
                                              sections[item.key] = {
                                                ...sections[item.key],
                                                meetsTheRequirements: event
                                                  .target.checked
                                                  ? 0
                                                  : 1,
                                              };
                                              return { sections };
                                            }
                                          )
                                        }
                                        checked={
                                          item.meetsTheRequirements === 0
                                        }
                                      >
                                        <Label className="home-office">
                                          {/* <span className="accordion-font"> */}
                                            {/* {t("Sim")} */}
                                          {/* </span> */}
                                        </Label>
                                      </Toggle>
                                      <Label className="accordion-font">
                                        {t("Sim")}
                                      </Label>
                                    </ToggleField>
                                  </ThemeProvider>
                                </Field>
                              </Col>
                              <Col xl={3} lg={6} md={6} sm={8}>
                                <Field className="d-flex align-items-center">
                                  <Label
                                    className="mt-2 mr-2 accordion-font"
                                    style={{
                                      flexShrink: 0,
                                      fontSize: "0.75rem",
                                    }}
                                  >
                                    {t("Data do Registro")}
                                  </Label>
                                  <Datepicker
                                    minValue={item.key === 1 || !previousStepWithDate.date ? Moment(this.state.calendarDateStart).toDate() : Moment(previousStepWithDate?.date).toDate()}
                                    maxValue={!nextStepWithDate.date ? Moment().toDate() : Moment(nextStepWithDate.date).toDate()}
                                    onChange={(date) => this.changeDate(date, previousStepWithDate, nextStepWithDate, index, item.key)}
                                    value={
                                      (item.date && Moment(item.date).toDate())
                                    }
                                    formatDate={(date) =>
                                      dateFormatter.format(date)
                                    }
                                    customParseDate={this.parseDateBr}
                                    locale="pt-BR"
                                    isCompact
                                  >
                                    <Input isCompact />
                                  </Datepicker>
                                </Field>
                              </Col>
                              <Col xl={2} lg={12} md={12} sm={12} />
                            </Row>
                            <Row className="mt-2">
                              <Col xl={10} lg={12} md={12} sm={12}>
                                  <Editor
                                    editorState={item.details}
                                    wrapperClassName={"wrapper"}
                                    editorClassName={"pl-3 pr-3"}
                                    onEditorStateChange={(event) => {
                                      this.setState((previousState: any) => {
                                        let sections = [
                                          ...previousState.sections,
                                        ];
                                        sections[item.key] = {
                                          ...sections[item.key],
                                          details: event,
                                        };
                                        return { sections };
                                      });
                                    }}
                                  />
                              </Col>
                              <Col
                                className="mt-auto pt-2 d-flex justify-content-between"
                                xl={2}
                                lg={2}
                                md={2}
                                sm={2}
                              >
                                <button
                                  className="mr-2"
                                  onClick={() =>
                                    this.changeStepCandidate(item.key)
                                  }
                                  style={{
                                    background: "transparent",
                                    borderRadius: "15px",
                                    border: "1px solid #E6A243",
                                  }}
                                >
                                  <span
                                    className="save-button-opp-progress"
                                  >
                                    {t("Salvar")}
                                  </span>
                                </button>
                                <button
                                  onClick={() => this.clear(item.key)}
                                  style={{
                                    border: "none",
                                    background: "transparent",
                                  }}
                                >
                                  <span
                                    className="clean-button-opp-progress"
                                  >
                                    {t("Limpar")}
                                  </span>
                                </button>
                              </Col>
                            </Row>
                          </section>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </AccordionContainer>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (store: any) => ({
  filter: store.reportsFilterReducer.filter,
  startDateFilter: store.reportsFilterReducer.startDateFilter,
  endDateFilter: store.reportsFilterReducer.endDateFilter,
  mediaFilter: store.reportsFilterReducer.mediaFilter,
});

export default connect(mapStateToProps)(OpportunityProgress);
