import {
  Dropdown,
  Field as Field2,
  Item,
  Menu,
  Select
} from "@zendeskgarden/react-dropdowns";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { currentLanguageChanged } from "../../actions";
import HeaderMobile from "../../components/header";
import { SIGNUP_ROUTE, TERMS_OF_USE_ROUTE } from "../../providers/routes";
import { AlertService } from "../../services/alert.service";
import { AuthenticationService } from "../../services/auth.service";
import TranslationService, { t } from "../../translations/translation.service";
import "./index.css";

let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty("--vh", `${vh}px`);

class SignupSuccess extends React.Component<any> {
  state = {
    currentLang: TranslationService.getCurrentLanguage,
    email: "",
    width: window.innerWidth,
  };

  componenDidUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  redirectToHome() {
    this.props.history.push("/");
  }

  async componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
    const { currentLanguageChanged } = this.props;
    const email = this.props.location.data;
    if (!email) {
      this.props.history.push(SIGNUP_ROUTE);
    } else {
      this.setState({ email });
      currentLanguageChanged(this.state.currentLang);
    }
    // AuthenticationService.resendSignupMail("germano.carvalho@extreme.digital", TranslationService.getCurrentLanguage());
  }

  async resendTokenEmail (): Promise<void> {
    try {
      await AuthenticationService.resendSignupMail(this.state.email,  TranslationService.getCurrentLanguage());
      AlertService.success(
        t("Email reenviado com sucesso!")
      );
    } catch (error) {
      AlertService.error(
        t("Sistema indisponível, por favor tente novamente mais tarde.")
      );
      console.error(error);
    }
  }

  render() {
    const { width } = this.state;
    const isMobile = width <= 680;
    const { currentLanguageChanged } = this.props;
    currentLanguageChanged(this.state.currentLang);
    const langs = TranslationService.getLanguages();

    if (!isMobile) {
      return (
        <div className="bg-enter">
          <div className="d-flex align-items-center justify-items-center h-100">
            <div className="bg-container">
              <div className="content-signup d-flex justify-content-between justify-items-center flex-column">
                <div>
                  <div className="row">
                    <div className="col">
                      <p
                        className="enter-logo"
                        style={{ cursor: "pointer" }}
                        onClick={this.redirectToHome.bind(this)}
                      />
                    </div>
                    <div className="d-flex align-items-center mr-1">
                      <Dropdown
                        selectedItem={TranslationService.getCurrentLanguage()}
                        onSelect={(selectedLang) => {
                          TranslationService.changeLanguage(selectedLang);
                          this.setState({ currentLang: selectedLang });
                          currentLanguageChanged(selectedLang);
                        }}
                        downshiftProps={{
                          itemToString: (item: any) => item,
                        }}
                      >
                        <Field2>
                          <Select className="u-semibold u-fs-sm border-0">
                            {t(TranslationService.getCurrentLanguage())}
                          </Select>
                        </Field2>
                        <Menu className="u-fs-sm">
                          {langs.map((option: any) => (
                            <Item key={option} value={option}>
                              {t(option)}
                            </Item>
                          ))}
                        </Menu>
                      </Dropdown>
                    </div>
                  </div>

                  <p className="text-center great-title">
                    {t("É muito bom ter você no Enter!")}
                  </p>

                  <p className="text-center u-fs-md u-fg-grey-700 u-semibold mt-2 mx-5">
                    {t(
                      "Confirme seu cadastro clicando no link enviado para o seu email!"
                    )}
                  </p>

                  <p className="mt-30 text-center u-fs-md u-fg-grey-500 mb-0 mx-5">
                    {t("Não recebeu a mensagem?")}
                  </p>
                  <p className="text-center u-fs-md u-fg-grey-500 mt-0 mx-5">
                    <span
                      onClick={() => this.resendTokenEmail()}
                      className="u-semibold u-fg-grey-500 cursor-pointer"
                    >
                      {t(" Clica aqui")}
                    </span>
                    {t(" que a gente envia de novo.")}
                  </p>
                </div>
              </div>
              <div
                className="position-absolute text-light"
                style={{ width: "480px" }}
              >
                <p className="text-center u-fs-md mt-3 mb-0 px-5">
                  {t("Somos muito cuidadosos com seus dados.")}
                </p>
                <p className="text-center u-fs-md mt-0 px-5">
                  {t("Veja nossa ")}
                  <a href={TERMS_OF_USE_ROUTE} className="u-semibold">
                    {t("Política de Privacidade")}
                  </a>
                </p>
              </div>
            </div>
          </div>

          {/* <pre>{JSON.stringify(this.state, null, 2)}</pre> */}
        </div>
      );
    } else {
      return (
        <>
          <HeaderMobile />
          <div className="login-body bg-enter p-4 d-flex justify-content-between flex-column title-color-mobile">
            <div className="container">
              <p className="text-center great-title title-color-mobile">
                {t("É muito bom ter você no Enter!")}
              </p>

              <p className="text-center u-fs-md  u-semibold mt-2">
                {t(
                  "Confirme seu cadastro clicando no link enviado para o seu email!"
                )}
              </p>

              <p className="mt-20 text-center u-fs-md mb-0">
                {t("Não recebeu a mensagem?")}
              </p>
              <p className="text-center u-fs-md mt-0">
                <span
                  onClick={() => this.resendTokenEmail()}
                  className="u-semibold u-fg-grey-500 cursor-pointer mr-1"
                >
                  {t(" Clica aqui")}
                </span>
                {t(" que a gente envia de novo.")}
              </p>
            </div>
            <div className="text-light">
              <p className="text-center u-fs-md mb-0 px-2">
                {t("Somos muito cuidadosos com seus dados.")}
              </p>
              <p className="text-center u-fs-md mt-0 px-5">
                {t("Veja nossa ")}
                <a href={TERMS_OF_USE_ROUTE} className="u-semibold">
                  {t("Política de Privacidade")}
                </a>
              </p>
            </div>
          </div>
          {/* <pre>{JSON.stringify(this.state, null, 2)}</pre> */}
        </>
      );
    }
  }
}

const mapStateToProps = (store: {
  changeLanguageReducer: { currentLanguage: string };
}) => ({
  currentLanguage: store.changeLanguageReducer.currentLanguage,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      currentLanguageChanged,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SignupSuccess));
