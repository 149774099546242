import {
  CLICK_UPDATE_SQUAD,
  GET_BUTTON_HOME_OPPORTINITIES,
  CLICK_UPDATE_RESPONSABLE,
  CLICK_UPDATE_PRIORITY_SORT
} from "../actions/actionTypes";

const initialState = {
  squadFilter: {},
  responsableFilter: {},
  groupFilter: {},
  sortFilterList: {},
  keywordsList: {},
  currentButton: {},
  priorityButton: {},
};

export const opportunityFilterReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case CLICK_UPDATE_SQUAD:
      return {
        ...state,
        squadFilter: action.squadFilter
      };
    case GET_BUTTON_HOME_OPPORTINITIES:
      return {
        ...state,
        currentButton: action.currentButton,
      }
    case CLICK_UPDATE_RESPONSABLE:
      return {
        ...state,
        responsableFilter: action.responsableFilter,
      }
    case CLICK_UPDATE_PRIORITY_SORT:
      return{
        ...state,
        priorityButton: action.priorityButton
      }
    default:
      return state;
  }
};
