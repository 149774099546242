import { Field, Hint, Label, Range } from "@zendeskgarden/react-forms";
import { ThemeProvider } from "@zendeskgarden/react-theming";
import { LG, MD, XXL } from "@zendeskgarden/react-typography";
import { Tooltip } from '@zendeskgarden/react-tooltips';
// @ts-ignore
import ReactFilestack from "filestack-react";
import React from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { currentLanguageChanged } from "../../../actions";
import Loading from "../../../components/loading";
import CircleProgress from "../../../components/progress";
import { ReactComponent as DoneIcon } from "../../../images/icons/ic_done_24px.svg";
import { ReactComponent as XIcon } from "../../../images/icons/ic_x.svg";
import { ReactComponent as InfoIcon } from "../../../images/icons/info.svg";
import { Opportunity } from "../../../model/opportunity.model";
import { UserData } from "../../../model/user.model";
import { PROFESSIONAL_PROFILE_ROUTE } from "../../../providers/routes";
import { AlertService } from "../../../services/alert.service";
import api from "../../../services/api.service";
import { AuthenticationService } from "../../../services/auth.service";
import DocumentsService from "../../../services/documents.service";
// import { ReactComponent as UploadIcon } from "../../../images/icons/ic_file_upload_24px.svg";
import OpportunityService from "../../../services/opportunity.service";
import UserService from "../../../services/user.service";
import { t } from "../../../translations/translation.service";
import {
  themeGreen, themeOrange, themeRed,
  themeYellow
} from "../../../util/colorButton";
import "./index.css";
import FileStackService from "../../../services/filestack.service";

const apikey = process.env.REACT_APP_FILESTACK_API;

class CandidatureDetails extends React.Component<any> {
  state = {
    opportunity: {} as Opportunity,
    user: {} as UserData,
    criteriosMatch: [] as any,
    loading: true,
    value: 1850,
    notificationCandidatura: this.props.location?.state?.notification,
    notificationAtualiza: this.props.location?.state?.notificationAtualiza,
    fromSignup: false,
    attachments: [] as any,
    fileUpload: [] as any,
    showModalUpload: false,
    userOpportunity: {} as UserData,
    comments: "",
    policyString: '' as string,
    signatureString: '' as string,
    policyAndSignature: '' as string,
  };

  constructor(props: any) {
    super(props);
    this.getVaga = this.getVaga.bind(this);
    this.shouldBeNotificated = this.shouldBeNotificated.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onFiles = this.onFiles.bind(this);
    this.uploadCvFile = this.uploadCvFile.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleNextPage = this.handleNextPage.bind(this);
    this.policeAndSignatureString = this.policeAndSignatureString.bind(this);
  }

  async policeAndSignatureString(): Promise<void> {
    try {
      const policyAndSignature = await FileStackService.getPoliceAndSignature();
      this.setState({
        policyString: policyAndSignature.data.policy,
        signatureString: policyAndSignature.data.signature
      });
    } catch(err: any) {
      console.log("ERROR: ", err);
    }
  }

  async componentDidMount() {
    await this.policeAndSignatureString();
    try {
      //Verifica se o usuário vem da candidatura sem cadastro
      const params = new URLSearchParams(this.props.location.search);
      const fromSignup = params.get("fromSignup");

      if(fromSignup) this.setState({ fromSignup: true });

      this.getVaga();
    } catch (err) {
      console.log(err);
      this.setState({ loading: false });
    }
  }

  async getVaga() {
    const { opportunityId } = this.props.match.params;
    this.shouldBeNotificated(opportunityId);
    const { data } = await OpportunityService.getOpportunityLiteKanban(
      opportunityId
    );

    const dataUser = await UserService.getUser(
      AuthenticationService.getUser()._id
    );

    //Verificando se o user é inscrito ou candidato.
    const user =
      data.candidatos.length > 0
        ? data.candidatos[0]
        : data.candidatosTriados.length > 0 && data.candidatosTriados[0];

    //Iniciando busca por critério do Match no perfil do usuário
    data.criteriosMatch.forEach((criterioVaga: any) => {
      var found = false;
      criterioVaga.level = 0;

      dataUser.data.perfilUsuario?.digomais?.lista?.forEach(
        (criterioUser: any) => {
          if (
            !criterioVaga.level &&
            criterioUser.nome.toUpperCase() === criterioVaga.nome.toUpperCase()
          ) {
            found = true;
            criterioVaga.level = criterioUser.level ? criterioUser.level : 1;
            if(!criterioUser.level) {
              criterioUser.level = 1;
            }
          } else {
            criterioVaga.termos.forEach((termo: any) => {
              if (
                !criterioVaga.level &&
                criterioUser.nome.toUpperCase() === termo.toUpperCase()
              ) {
                found = true;
                criterioVaga.level = criterioUser.level
                  ? criterioUser.level
                  : 1;
              } else if (found === false) {
                criterioVaga.level = 0;
              }
            });
          }
        }
      );
      criterioVaga.color = this.getClassName(criterioVaga.level);
      criterioVaga.caption = this.getItemCaption(criterioVaga.level);
      criterioVaga.hasModification = false;
    });

    this.setState({
      opportunity: data,
      user: dataUser.data,
      userOpportunity: user,
      criteriosMatch: data.criteriosMatch,
      attachments: user._usuario.attachments,
    }, async () => {
      try {
        await UserService.updateUser(this.state.user);
      } catch (err) {
        console.log("Erro --->", err)
      }
    });
    this.setState({ loading: false });
  }

  shouldBeNotificated(notification: any) {
    if (notification) {
      setTimeout(() => {
        this.setState({
          notificationCandidatura: false,
          notificationAtualiza: false,
        });
      }, 8000);
    }
  }

  handleChange = (e: any, index: any) => {
    // 1. Make a shallow copy of the items
    let criteriosMatch: any = [...this.state.criteriosMatch];
    // 2. Make a shallow copy of the item you want to mutate
    let item: any = criteriosMatch[index];
    // 3. Replace the property you're intested in
    item.level = parseInt(e.target.value);
    item.color = this.getClassName(item.level);
    item.caption = this.getItemCaption(item.level);

    item.hasModification = true;
    // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
    criteriosMatch[index] = item;
    // 5. Set the state to our new copy
    this.setState({ criteriosMatch });
  };

  getClassName = (e: any): string => {
    const theme1: any = themeRed;
    const theme2: any = themeYellow;
    const theme3: any = themeOrange;
    const theme4: any = themeGreen;

    switch (parseInt(e)) {
      case 0:
        return theme1;
      case 1:
        return theme2;
      case 2:
        return theme3;
      case 3:
        return theme4;
      default:
        return theme1;
    }
  };

  getItemCaption = (e: any): string => {
    switch (parseInt(e)) {
      case 0:
        return t("Não encontrado no seu currículo");
      case 1:
        return t("Básico");
      case 2:
        return t("Intermediário");
      case 3:
        return t("Avançado");
      default:
        return t("Não encontrado no seu currículo");
    }
  };

  removeAttachment = (id: any) => {
    this.setState({ loading: true });
    try {
      this.deleteCv(id);
      AlertService.success("Currículo removido com sucesso.");
      this.setState({ loading: false });
    } catch (err) {
      console.error(err);
      AlertService.error("Não foi possível remover o seu currículo.");
      this.setState({ loading: false });
    }
  };

  async deleteCv(idCV: string) {
    const data = {
      id: this.state.user._id,
      cvId: idCV,
    };
    try {
      const resp = await DocumentsService.deleteDocument(data);
      if (resp) {
        this.setState({
          attachments: resp.data,
        });
      }
    } catch (err) {
      console.error(err);
    }
  }

  async onFiles({ filesUploaded }: any) {
    let array = filesUploaded.map(function (item: any) {
      return {
        fileName: item.filename,
        url: item.url,
      };
    });

    const policyAndSignature = (await FileStackService.getFileStackSecreteCodeToUser(
      array[0].url, false
    )).data;

    this.setState({ fileUpload: array[0], showModalUpload: true, policyAndSignature });
  }

  async uploadCvFile() {
    const data = {
      _id: this.state.user._id,
      file: this.state.fileUpload,
      comment: this.state.comments,
      policyAndSignature: this.state.policyAndSignature
    };
    try {
      const resp = await api.post("documents/file/uploadCv", data);
      if (resp) {
        this.setState({
          comment: "",
          showModalUpload: false,
          attachments: resp.data,
          disabledButton: false,
        });

        AlertService.success("Currículo adicionado com sucesso!");
      }
    } catch (err) {
      console.error(err);
      this.setState({
        showModal: false,
        comment: "",
        disabledButton: false,
      });
      AlertService.error("Erro ao anexar o currículo. Tente novamente!");
    }
  }

  async handleSubmit() {
    this.setState({ loading: true });
    var user = this.state.user;
    var hasModification = false;
    this.state.criteriosMatch.forEach((criterioVaga: any) => {
      if (criterioVaga.hasModification) {
        hasModification = true;
        if (criterioVaga.level > 0) {
          var found = false;
          user.perfilUsuario.digomais.lista.forEach((criterioUser: any) => {
            if (
              criterioVaga.nome.toUpperCase() ===
              criterioUser.nome.toUpperCase()
            ) {
              found = true;
              criterioUser.level = criterioVaga.level;
            } else {
              criterioVaga.termos.forEach((termo: any) => {
                if (
                  criterioUser.subclassif === "skills" &&
                  criterioUser.nome.toUpperCase() === termo.toUpperCase()
                ) {
                  found = true;
                  criterioUser.level = criterioVaga.level;
                }
              });
            }
          });
          if (!found) {
            user.perfilUsuario.digomais.lista.push({
              nome: criterioVaga.nome,
              classif: "digomais",
              subclassif: "skills",
              nomeCaption: criterioVaga.nome,
              level: criterioVaga.level,
            });
          }
        } else if (criterioVaga.level === 0) {
          user.perfilUsuario.digomais.lista.forEach((criterioUser: any) => {
            if (
              criterioUser.nome.toUpperCase() ===
              criterioVaga.nome.toUpperCase()
            ) {
              var index =
                user.perfilUsuario.digomais.lista.indexOf(criterioUser);
              user.perfilUsuario.digomais.lista.splice(index, 1);
            } else {
              criterioVaga.termos.forEach((termo: any) => {
                if (
                  criterioUser.subclassif === "skills" &&
                  criterioUser.nome.toUpperCase() === termo.toUpperCase()
                ) {
                  var index2 =
                    user.perfilUsuario.digomais.lista.indexOf(criterioUser);
                  user.perfilUsuario.digomais.lista.splice(index2, 1);
                }
              });
            }
          });
        }
      }
    });

    if (hasModification) {
      try {
        user.lastUpdateSentToQueue = true;
        await UserService.updateUser(user);
        // AlertService.success("Candidatura atualizada com sucesso!");

        const teste = setInterval(async () => {
          const res = await UserService.getStatusUserMatch(user._id);        
          if (res.data === "completed") {
            clearInterval(teste);
            window.location.reload();
          } else {
            console.log("Repetindo...");
          }
        }, 3000);
      } catch (err) {
        console.error(err);
      }
    } else {
      setInterval(() => {
        window.location.reload()
      }, 1000);
    }
  }

  handleNextPage() {
    this.props.history.push({
      pathname: `${PROFESSIONAL_PROFILE_ROUTE.replace(
        ":id",
        this.state.user._id
      )}`,
      search: "?step=welcome",
      idFromOppCandidature: this.state.user._id,
    });
  }

  render() {
    const { opportunity } = this.state;
    const { userOpportunity } = this.state;
    if (this.state.loading) return <Loading show={this.state.loading} />;
    return (
      <>
        <div className="container-md">
          {this.state.notificationCandidatura && (
            <Row className="bg-notification mb-4">
              <Field className="p-4 d-flex justify-content-between align-items-center w-100">
                <LG style={{ color: "#008c74", fontWeight: 400 }}>
                  {t(`Sua candidatura foi realizada! Ajuste seu nível de
                  conhecimento nos parâmetros à seguir e aumente suas chances.`)}
                </LG>
                <XIcon
                  role="button"
                  onClick={() => this.setState({ notification: false })}
                />
              </Field>
            </Row>
          )}
          {this.state.notificationAtualiza && (
            <Row className="bg-notification mb-4">
              <Field className="p-4 d-flex justify-content-between align-items-center w-100">
                <LG style={{ color: "#008c74", fontWeight: 400 }}>
                  {t("Candidatura atualizada com sucesso!")}
                </LG>
                <XIcon
                  role="button"
                  onClick={() => this.setState({ notification: false })}
                />
              </Field>
            </Row>
          )}
          {/* Informações do Candidato/Vaga */}
          <Row className="header-info candidate-info align-items-center justify-content-between mb-4 p-4">
            <Col
              lg={4}
              className="d-flex w-100 justify-content-center align-items-center percentage"
            >
              <Field className="percentage">
                <CircleProgress
                  duration={3000}
                  finish={userOpportunity.nivelAderencia}
                />
              </Field>
              <Field className="ml-4">
                <Label>
                  <XXL isBold style={{ color: "#fff" }}>
                    {opportunity.titulo}
                  </XXL>
                </Label>
                <Hint className="d-flex">
                  <LG style={{ color: "#fff", fontSize: "1rem"}} className="mt-1">
                    {userOpportunity.nivelAderencia}% {t("de aderência")}
                  </LG>
                  <Tooltip
                    content={t("O percentual de aderencia é baseado nos parametros encontrados em seu currículo e nas informacoes do Perfil Profissional.")}
                    type="light"
                  >
                    <InfoIcon
                      className="icon-tooltip ml-1"
                      style={{ fill: "white" }}
                    />
                  </Tooltip>
                </Hint>
              </Field>
            </Col>
            <Col
              lg={4}
              className="d-flex justify-content-center align-items-center"
            >
              <Field className="curriculum d-flex w-100 flex-column">
                {userOpportunity &&
                  this.state.attachments.map((curriculo: any, index: number) => (
                    <div key={index}>
                      <Row className="d-flex p-2 justify-content-between align-items-center">
                        <Field className="d-flex align-items-center">
                          <DoneIcon className="mr-2" />
                          <a href={curriculo.url} target="_blank" rel="noreferrer">
                            <MD>{curriculo.fileName}</MD>
                          </a>
                        </Field>
                        <Field>
                          <XIcon
                            role="button"
                            onClick={() => this.removeAttachment(curriculo._id)}
                          />
                        </Field>
                      </Row>
                    </div>
                  ))}
              </Field>
              <Field className="ml-2">
                <Label>
                {this.state.policyString.length > 0 && (
                  <ReactFilestack
                    actionOptions={{
                      accept: [".pdf", ".docx"],
                    }}
                    apikey={apikey}
                    componentDisplayMode={{
                      customText: "+",
                      customClass: "add-cv-candidatures-details",
                    }}
                    clientOptions={{
                      security: {
                        policy: this.state.policyString,
                        signature: this.state.signatureString,
                      }
                    }}
                    onSuccess={this.onFiles}
                  />
                )}
                </Label>
              </Field>
            </Col>
          </Row>
        </div>
        <div className="container-md parameters p-4 mb-2">
          {/* Parâmetros Necessários */}
          <Row>
            <Col className="d-flex header-parameters mt-4">
              <XXL>{t("Parâmetros Necessários")}</XXL>
              <Tooltip 
                content={t("Indispensáveis para participar do processo seletivo;")}
                type="light"
              >
                <InfoIcon className="icon-tooltip" />
              </Tooltip>
            </Col>
          </Row>
          <Row className="necessary-parameters">
            {this.state.criteriosMatch &&
              this.state.criteriosMatch.map((criterio: any, index: any) => {
                if (criterio.peso === 3) {
                  return (
                    <Col key={index} className="parameter" xs={12} xl={3}>
                      <Label>{criterio.nome}</Label>
                      <Hint>{criterio.caption}</Hint>
                      <ThemeProvider theme={criterio.color}>
                        <Range
                          value={criterio.level}
                          onChange={(e) => this.handleChange(e, index)}
                          step={1}
                          max={3}
                        />
                      </ThemeProvider>
                    </Col>
                  );
                } else {
                  return "";
                }
              })}
          </Row>
          {/* Parâmetros Desejáveis */}
          <Row>
            <Col className="d-flex header-parameters mt-4">
              <XXL>{t("Parâmetros Desejáveis")}</XXL>
              <Tooltip
                content={t("Dispensáveis para o processo, mas importantes diferenciais.")}
                type="light"
              >
                <InfoIcon className="icon-tooltip" />
              </Tooltip>
            </Col>
          </Row>
          <Row className="necessary-parameters">
            {this.state.criteriosMatch &&
              this.state.criteriosMatch.map((criterio: any, index: any) => {
                if (criterio.peso === 2 || criterio.peso === 1) {
                  return (
                    <Col key={index} className="parameter" xs={12} xl={3}>
                      <Label>{criterio.nome}</Label>
                      <Hint>{criterio.caption}</Hint>
                      <ThemeProvider theme={criterio.color}>
                        <Range
                          value={criterio.level}
                          onChange={(e) => this.handleChange(e, index)}
                          step={1}
                          max={3}
                        />
                      </ThemeProvider>
                    </Col>
                  );
                } else {
                  return "";
                }
              })}
          </Row>
          <Row className="justify-content-end">
            <Button onClick={this.handleSubmit} className="button-orange">
              {t("Salvar")}
            </Button>
          </Row>
        </div>
        <div className="container-md mt-2 mb-4">
          <Row className="w-100 justify-content-between m-0">
            <Link to={{ pathname: "/my-candidatures" }}>
              <LG className="text-underline">{t("Ver minhas candidaturas")}</LG>
            </Link>
            {this.state.fromSignup && <LG
              onClick={() => this.handleNextPage()}
              className="text-underline"
            >
              {t("Completar meu perfil")}
            </LG>}
          </Row>
        </div>
        <Modal
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.showModalUpload}
          onHide={() => {
            this.setState({
              showModalUpload: false
            })
          }}
        >
          <Modal.Header className="d-flex justify-content-center">
            <h4>{t("Arquivo enviado com sucesso")}!</h4>
          </Modal.Header>
          <Modal.Body className="d-flex flex-column">
            <p className="text-center">
              {t("Gostaria de adicionar uma descrição ao arquivo")}?
            </p>
            <label>
              <input
                className="form-control"
                type="text"
                value={this.state.comments}
                name="commentField"
                placeholder={t("Descrição")}
                onChange={(e) => this.setState({ comments: e.target.value })}
              />
            </label>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-around">
            <Button
              variant="secondary"
              onClick={this.uploadCvFile.bind(this)}
              style={{ width: "35%" }}
            >
              {t("Cancelar")}
            </Button>
            <Button
              variant="primary"
              onClick={this.uploadCvFile.bind(this)}
              style={{ width: "35%" }}
            >
              {t("Adicionar")}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (store: {
  changeLanguageReducer: { currentLanguage: string };
}) => ({
  currentLanguage: store.changeLanguageReducer.currentLanguage,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      currentLanguageChanged,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CandidatureDetails));
