import * as React from "react";
import { ReactComponent as CloseIcon } from "./baseline-close-24px.svg";
import styles from "./index.module.css";

export type Props = {
  index: number;
  onRemove: Function;
  chip: string;
};

export default class PillChip extends React.Component<Props> {
  render() {
    return (
      <div className={[styles["chip-input"] , styles["cursor-pointer"]].join(" ")}>
        <div className={[styles["chip"], styles["show"]].join(" ")}>
          {this.props.chip}
          <CloseIcon className={[styles["close"], styles["cursor-pointer"]].join(" ")} onClick={() => this.props.onRemove(this.props.index)} />
        </div>
      </div>
    );
  }
}
