import React from 'react';
import { t } from '../../translations/translation.service'
import './index.css';

export default class Footer extends React.Component<any> {
  render() {
    return (
      <footer className="text-white mt-auto">
        <div className="bg-footer-1">
          <section className="container container-fluid padding-sec">
            <div className="row">
              <div className="col-lg-3 col-md-6 mb-4 mb-md-0  d-flex flex-column footer-elements">
                <header className="header-footer">
                  {t("Sobre nós")}
                </header>
                <blockquote className="blockquote">
                  <p className="mb-0">
                    {t("A Elumini é consultoria de TI com escritórios no Brasil, Europa e EUA que possui um olhar diferenciado para os seus colaboradores em todo o mundo.")}
                  </p>
                </blockquote>
              </div>
              <div className="col-lg-3 col-md-6 mb-4 mb-md-0  d-flex flex-column footer-elements">
                <header className="header-footer">
                  Great Place to Work®
                </header>
                <blockquote className="blockquote">
                  <p className="mb-0">
                    {t('A Elumini é certificada Great Place to Work® e desde 2011 está na lista das “Melhores Empresas para Trabalhar em TI”.')}
                  </p>
                </blockquote>
              </div>
              <div className="col-lg-3 col-md-6 mb-4 mb-md-0 d-flex flex-column footer-elements">
                <header className="header-footer">
                  High Performance Development (HiPD)
                </header>
                <blockquote className="blockquote">
                  <p className="mb-0">
                    {t("Nosso exclusivo programa de mentoring e apoio técnico a profissionais Elumini onde consultores e especialistas oferecem workshops, apoiam pesquisas e a aplicação de boas práticas.")}
                  </p>
                </blockquote>
              </div>
              <div className="col-lg-3 col-md-6 mb-4 mb-md-0 footer-elements">
                <ul className="list-unstyled mb-0 mt-2 links-footer">
                  <li>
                    <a target="_blank" rel="noreferrer" href="https://www.elumini.com.br/empresa/" className="text-white">
                      {t("Sobre")}
                    </a>
                  </li>
                  <li>
                    <a href="/contact-us" className="text-white">
                      {t("Fale Conosco")}
                    </a>
                  </li>
                  <li>
                    <a href="/terms-of-use" className="text-white">
                      {t("Termos de Uso")}
                    </a>
                  </li>
                  <li>
                    <a href="/faq" className="text-white">
                      FAQ
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </section>
        </div>
        <div className="bg-footer-2 text-center p-3">Enter Elumini © 2021</div>
      </footer>
    );
  }
}
