import { Col, Row } from "@zendeskgarden/react-grid";
import { FooterItem } from "@zendeskgarden/react-modals";
import Modal from "react-bootstrap/Modal";
import { Component } from "react";
import { Button } from "react-bootstrap";
import { AlertService } from "../../../../services/alert.service";
import OpportunityService from "../../../../services/opportunity.service";
import TranslationService, {
  t,
} from "../../../../translations/translation.service";
import Loading from "../../../loading";
import ProfessionalAvatar from "../avatar-info";
import CandidateFlipCard from '../../../flip-card';
import styles from "./index.module.scss";

class ProfessionalCardCompatible extends Component<{
  user: any;
  opportunity: { id: string; title: string; isFinished: boolean };
  history: any;
}> {
  state = {
    inviteModal: false,
    inscribeModal: false,
    selectedId: "",
    opportunities: [] as any[],
    loading: false,
    currentLang: TranslationService.getCurrentLanguage,
    processType: "",
    flip: true,
  };

  constructor(props: any) {
    super(props);
    this.setFlip = this.setFlip.bind(this);
  }

  emailIndex = this.props.user?._usuario?.notificationsSettings?.channels?.findIndex((channel: any) => channel.name === "E-mail");

  private permissionToEmail =
    this.emailIndex !== -1 &&
    this.props.user._usuario?.notificationsSettings?.isActive &&
    this.props.user._usuario.notificationsSettings.channels[this.emailIndex]?.isActive;

  isEvaluationStar = (evaluation: any[]): boolean => {
    let result = false;
    if (evaluation && evaluation.length > 0) {
      evaluation.forEach((item: any) => {
        if (item.resultado >= 8) {
          result = true;
        }
      });
    }
    return result;
  };

  isEvaluationUpdated(evaluation: any): any {
    let result = false;
    if (evaluation && evaluation.length > 0) {
      let currenDate = new Date();
      currenDate.setFullYear(currenDate.getFullYear() - 1);
      evaluation.forEach((item: any) => {
        if (new Date(item.created) > currenDate) {
          result = true;
        }
      });
    }
    return result;
  }

  isEvaluationOutOfDate(evaluation: any): any {
    let result = false;
    if (evaluation && evaluation.length > 0) {
      let currenDate = new Date();
      currenDate.setFullYear(currenDate.getFullYear() - 1);
      evaluation.forEach((item: any) => {
        if (new Date(item.created) <= currenDate) {
          result = true;
        }
      });
    }
    return result;
  }

  isEvaluationNegative(evaluation: any): any {
    let result = false;
    if (evaluation && evaluation.length > 0) {
      evaluation.forEach((item: any) => {
        if (item.resultado === 0) {
          result = true;
        }
      });
    }
    return result;
  }

  getOpportunities(processType: string) {
    this.setState({ loading: true, processType }, async () => {
      let opps = (await OpportunityService.getActiveOpportunities()).data;
      this.setState({
        inscribeModal: true,
        opportunities: opps,
        loading: false,
      });
    });
  }

  setVisible(visible: boolean): void {
    this.setState({ visible });
  }

  async sendInviteToOpportunity() {
    this.setState({ loading: true });
    try {
      if (!this.permissionToEmail)
        throw new Error("Sistema sem permissao para convite.");

      const obj = {
        profissionalId: this.props.user._usuario._id,
        profissionalEmail: this.props.user._usuario.email,
        profissionalNome: this.props.user._usuario.displayName,
        profissionalTel: null,
        activeLanguage: this.state.currentLang(),
        notificationsSettings: this.props.user._usuario.notificationsSettings,
      };

      if (
        this.props.user._usuario.contato &&
        this.props.user._usuario.contato.lista &&
        this.props.user._usuario.contato.lista.length > 0
      ) {
        for (
          var k = 0;
          k < this.props.user._usuario.contato.lista.length;
          k++
        ) {
          if (
            this.props.user._usuario.contato.lista[k].tipo === "Celular" &&
            obj.profissionalTel === null
          ) {
            obj.profissionalTel =
              this.props.user._usuario.contato.lista[k].contato;
            this.convidarProfissional(obj);
            break;
          }
          if (
            k === this.props.user._usuario.contato.lista.length - 1 &&
            obj.profissionalTel === null
          ) {
            this.convidarProfissional(obj);
          }
        }
      } else {
        this.convidarProfissional(obj);
      }
    } catch (err) {
      this.setState({ inviteModal: false }, () => {
        AlertService.error(err);
      });
    }
  }

  async convidarProfissional(obj: any) {
    await OpportunityService.sendInviteToOpportunity(
      this.props.opportunity.id,
      obj
    );
    this.setState({ inviteModal: false, loading: false }, () => {
      AlertService.success(t("Convite enviado com sucesso!"));
    });
  }

  async becomeCandidateByRh() {
    this.setState({loading: true}, async () => {
      try {
        let triageRecruitmentOptions;
  
        if (
          this.props.user.steps &&
          this.props.user.steps.length &&
          this.props.user.capture &&
          this.props.user.source
        ) {
          triageRecruitmentOptions = {
            details: "",
            date: new Date(Date.now()),
            capture: this.props.user.capture,
            source: this.props.user.source,
          };
        } else {
          triageRecruitmentOptions = {
            details: "",
            date: new Date(Date.now()),
            capture: "",
            source: "",
          };
        }
  
        const obj = {
          activeLanguage: this.state.currentLang(),
          _usuario: this.props.user._usuario,
          triageRecruitmentOptions: triageRecruitmentOptions,
          conversionType: this.state.processType,
          byRH: true,
        };
        const resp = await OpportunityService.becomeCandidateByRh(
          this.state.selectedId,
          obj
        );
        this.setState({ inscribeModal: false, loading: false }, () => {
          AlertService.success(t(resp.data.message));
        });
      } catch (error) {
        console.error(error);
        AlertService.error(error);
        this.setState({ loading: false });
      }
    })
    
  }

  setFlip() {
    this.setState({ flip: !this.state.flip });
  }

  render() {
    let { user } = this.props;
    const professionalElumini = user._usuario?.eluminer?.status;
    if (user) {
      if (!user._usuario) {
        user._usuario = { deleted: true };
      }
      user._usuario.level = user.nivelAderencia;
      user._usuario.byRH = user.byRH;
    } else {
      return <></>;
    }
    return (
      <Col sm={12} md={6} xl={4} key={user.id}>
        <Loading show={this.state.loading} />
        <div className={styles["compatible-container-flip-card"]}>
          {this.state.flip ?
            <div className={professionalElumini ? styles["user-card-eliminer-compatible"] : styles["user-card"]}>
              <ProfessionalAvatar
                user={user._usuario}
                key={user._usuario.id}
                callback={this.setFlip}
              ></ProfessionalAvatar>
              {!user._usuario.deleted && (
                <>
                  <div className="w-100">
                    <Button
                      className={styles["button-gray"]}
                      onClick={() =>
                        window.open(`/professional/${user._usuario._id}`, "_blank")
                      }
                    >
                      {t("Ver currículo")}
                    </Button>
                    <Button
                      className={styles["button-gray"]}
                      onClick={() => this.setState({ inviteModal: true })}
                      disabled={!this.permissionToEmail}
                    >
                      {t("Convidar p/ processo")}
                    </Button>
                    <Button
                      className={styles["button-gray"]}
                      onClick={() => this.getOpportunities("inscribe")}
                    >
                      {t("Inscrever")}
                    </Button>
                    <Button
                      className={styles["button-orange"]}
                      onClick={() => this.getOpportunities("candidate")}
                    >
                      {t("Tornar candidato")}
                    </Button>
                  </div>
                </>
              )}
              {user._usuario.deleted && (
                <p
                  className="text-center mt-3 mb-2 u-fs-md u-semibold"
                  style={{ color: "#797979", height: '138px' }}
                >
                  {t("Cadastro excluído")}
                </p>
              )}
              {
                // MODAL DE INSCRIÇÃO
                this.state.inscribeModal && (
                  <Modal
                    scrollable
                    show={this.state.inscribeModal}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    onHide={() => this.setState({ inscribeModal: false })}
                  >
                    <Modal.Header>
                      <Col>
                        <Row>
                          <Col size={12} className="mb-2">
                            {t("Em que oportunidade você quer inscrever @ profissional?")}
                            <button onClick={() => this.setState({ inscribeModal: false })} type="button" className="close" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </Col>
                          <Col size={12}>
                            <h4 style={{ color: '#ed961c' }}>{t("Lembre-se de que el@ será notificad@ por e-mail.")}</h4>
                          </Col>
                        </Row>
                        <Row>
                          <p className="u-semibold mt-4">{t("Oportunidade atual")}</p>
                          <Button
                            disabled={this.props.opportunity.isFinished}
                            className={
                              this.state.selectedId === this.props.opportunity.id
                                ? styles["modal-button-selected"]
                                : styles["modal-button-opp"]
                            }
                            onClick={() =>
                              this.setState({ selectedId: this.props.opportunity.id })
                            }
                          >
                            {this.props.opportunity.title}
                          </Button>
                        </Row>
                      </Col>
                    </Modal.Header>
                    <Modal.Body className={styles["style-scroll-bar-compatible"]}>
                      <p className="u-semibold mt-2">{t("Outras oportunidades")}</p>
                      {this.state.opportunities.map((opp) => (
                        <Button
                          key={opp.id}
                          className={
                            (this.state.selectedId === opp._id
                              ? styles["modal-button-selected"]
                              : styles["modal-button-opp"]) + " mt-2"
                          }
                          onClick={() => this.setState({ selectedId: opp._id })}
                        >
                          {opp.titulo}
                        </Button>
                      ))}
                    </Modal.Body>
                    <Modal.Footer>
                      <FooterItem>
                        <Button
                          disabled={this.state.selectedId === ""}
                          variant="outline-warning"
                          onClick={() => this.becomeCandidateByRh()}
                          style={{ borderRadius: "20px", padding: "3px 25px" }}
                        >
                          {t("Salvar")}
                        </Button>
                      </FooterItem>
                    </Modal.Footer>
                  </Modal>
                )
              }

              {
                // MODAL DE CONVITE P/ OPORTUNIDADE
                this.state.inviteModal && (
                  <Modal
                    show={this.state.inviteModal}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    onHide={() => this.setState({ inviteModal: false })}
                  >
                    <Modal.Header></Modal.Header>
                    <Modal.Body>
                      {t("Você quer convidar ") +
                        this.props.user._usuario.displayName +
                        t(" para a oportunidade?")}
                    </Modal.Body>
                    <Modal.Footer>
                      <FooterItem>
                        <Button
                          className={styles["modal-button-next"]}
                          variant="outline-warning"
                          onClick={() => this.sendInviteToOpportunity()}
                          style={{ borderRadius: "20px", padding: "3px 30px" }}
                        >
                          {t("Convidar")}
                        </Button>
                      </FooterItem>
                    </Modal.Footer>
                  </Modal>
                )
              }

              {/* <pre>{JSON.stringify(user, null, 2)}</pre> */}
            </div>
            :
            <div className={styles["compatible-back-flip-card"]}>
              <CandidateFlipCard user={user} oppId={this.props.opportunity.id} callback={this.setFlip}/>
            </div>
          }
        </div>
      </Col>
    );
  }
}

export default ProfessionalCardCompatible;
