import {
  Dropdown,
  Field,
  Item,
  Menu,
  Select
} from "@zendeskgarden/react-dropdowns";
import {
  Checkbox, Field as FieldForm,
  Label as LabelForm
} from "@zendeskgarden/react-forms";
import { Col, Row } from "@zendeskgarden/react-grid";
import { Tooltip } from "@zendeskgarden/react-tooltips";
import { Button, Form, FormControl } from "react-bootstrap";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import "react-phone-number-input/style.css";
import PillChip from "../../../components/opportunity/pill";
import PillChipSkill from "../../../components/opportunity/pillchip-skill";
import { DictionaryDomain } from "../../../model/domain.model";
import { t } from "../../../translations/translation.service";
import style from "./index.module.scss";
import { theme } from "../../../util/colorButton";
import { ThemeProvider } from '@zendeskgarden/react-theming';
import Modal from "react-bootstrap/Modal";

export const tabProfileOne = (self: any): any => {
  return (
    <div> 
      <Row>
        <Col sm={12} md={12}>
          <Tooltip
            type="light"
            size="large"
            placement="top-start"
            content={t(
              "Desenvolvimento, Infraestrutura, UX... Indique as opções que mais têm a ver com você. Escolha quantas quiser!"
            )}
          >
            <div className="d-flex">
              <div className={style["field-professional-profile"]}>
                <Dropdown
                  onSelect={self.setCarreer}
                  downshiftProps={{
                    itemToString: (item: DictionaryDomain) => item && item.nome,
                  }}
                >
                  <Field>
                    <Select>{t("Com o que gostaria de trabalhar?")}</Select>
                  </Field>
                  <Menu>
                    {self.state.careers.map((option: DictionaryDomain) => (
                      <Item key={option._id} value={option.nome}>
                        {t(option.nome)}
                      </Item>
                    ))}
                  </Menu>
                </Dropdown>
                {self
                  .getUserProfileMosaicItem("carreiras")
                  .map((carreer: any, index: number) => (
                    <PillChip
                      key={index.toString() + carreer.detalhe.nome}
                      chip={t(carreer.detalhe.nome)}
                      index={index}
                      onRemove={self.deleteCarreer}
                    ></PillChip>
                  ))}
              </div>
            </div>
          </Tooltip>
          <Tooltip
            type="light"
            size="large"
            placement="top-start"
            content={t(
              "Saber onde você gostaria de trabalhar nos ajuda a escolher oportunidades dentro de um raio especifico e alinhado com seu estilo de vida"
            )}
          >
            <div>
              <div className="d-flex">
                <div className={`${style["field-professional-location"]} mt-2`}>
                  <div>
                    <FieldForm>
                      <LabelForm className={`${style["location-label"]} mt-1`}>
                        <GooglePlacesAutocomplete
                          apiKey="AIzaSyC5_B8f8Ug5dmvdYe3QWv6N2YoKszO0gK4"
                          minLengthAutocomplete={3}
                          selectProps={{
                            value: "",
                            onChange: self.includeLocation,
                            placeholder: t("Onde gostaria de trabalhar"),
                            noOptionsMessage: () => t("Digite as 3 primeiras letras da sua cidade"),
                          }}
                        />
                      </LabelForm>
                    </FieldForm>
                    {/* <FieldForm className="m-3">
                      <Toggle
                        name="homeOffice"
                        checked={
                          self.state.user ? self.state.user.homeOffice : false
                        }
                        onChange={self.handleHomeOffice}
                      >
                        <LabelForm
                          className={`${style["home-office"]} ${
                            self.state.user.homeOffice ? style["active"] : ""
                          }`}
                        >
                          <span style={{ fontWeight: 700 }}>Home Office</span>
                        </LabelForm>
                      </Toggle>
                    </FieldForm> */}
                  </div>
                  <div className={style["location-container"]}>
                    {self.state.user.perfilUsuario?.interestLocations?.map(
                      (location: any, index: number) => (
                        <PillChip
                          key={index.toString() + location.formatted_address}
                          chip={location.formatted_address}
                          index={index}
                          onRemove={self.removeLocation}
                        ></PillChip>
                      )
                    )}
                  </div>
                  <div className={`${style["container-professional-info"]}`}>
                    <div>
                      <p className={`${style["field-professional-profile"]} pb-0`}>
                        {t("Tipo de oportunidade")}:
                      </p>
                      <section className={style["checkbox-button-style"]}>
                        <FieldForm className="mt-2 ml-3">
                          <Checkbox
                            checked={
                              self.state.user && self.state.user.opportunityType
                                ? self.state.user.opportunityType.hybrid
                                : false
                            }
                            name="hybrid"
                            onChange={self.handleCheckboxes}
                          >
                            <LabelForm className={style["home-office"]}>
                              {t("Híbrida")}
                            </LabelForm>
                          </Checkbox>
                        </FieldForm>
                        <FieldForm className="mt-2 ml-3">
                          <Checkbox
                            checked={
                              self.state.user && self.state.user.opportunityType
                                ? self.state.user.opportunityType.remote
                                : false
                            }
                            name="remote"
                            onChange={self.handleCheckboxes}
                          >
                            <LabelForm className={style["home-office"]}>
                              {t("Remota")}
                            </LabelForm>
                          </Checkbox>
                        </FieldForm>
                        <FieldForm className="mt-2 ml-3">
                          <Checkbox
                            checked={
                              self.state.user && self.state.user.opportunityType
                                ? self.state.user.opportunityType.inPerson
                                : false
                            }
                            name="inPerson"
                            onChange={self.handleCheckboxes}
                          >
                            <LabelForm className={style["home-office"]}>
                              {t("Presencial")}
                            </LabelForm>
                          </Checkbox>
                        </FieldForm>
                      </section>
                    </div>
                    <div>
                      <p className={`${style["field-professional-profile"]} pb-0`}>
                        {t("modelo de contratação")}:
                      </p>
                      <section className={style["checkbox-button-style"]}>
                        <FieldForm className="mt-2 ml-3">
                          <Checkbox
                            checked={
                              self.state.user && self.state.user.hiringModel
                                ? self.state.user.hiringModel.clt
                                : false
                            }
                            name="clt"
                            onChange={self.handleCheckboxes}
                          >
                            <LabelForm className={style["home-office"]}>
                              {t("CLT")}
                            </LabelForm>
                          </Checkbox>
                        </FieldForm>
                        <FieldForm className="mt-2 ml-3">
                          <Checkbox
                            checked={
                              self.state.user && self.state.user.hiringModel
                                ? self.state.user.hiringModel.pj
                                : false
                            }
                            name="pj"
                            onChange={self.handleCheckboxes}
                          >
                            <LabelForm className={style["home-office"]}>
                              {t("PJ")}
                            </LabelForm>
                          </Checkbox>
                        </FieldForm>
                        <FieldForm className="mt-2 ml-3">
                          <Checkbox
                            checked={
                              self.state.user && self.state.user.hiringModel
                                ? self.state.user.hiringModel.others
                                : false
                            }
                            name="others"
                            onChange={self.handleCheckboxes}
                          >
                            <LabelForm className={style["home-office"]}>
                              {t("Outros")}
                            </LabelForm>
                          </Checkbox>
                        </FieldForm>
                      </section>
                    </div>
                    <div className="mr-3">
                      <ThemeProvider theme={theme as any}>
                        <p className="mt-2 ml-2">{t("Pretensão Salarial")}:</p>
                        <button
                          onClick={() => self.setState(
                            { showPretensao: !self.state.showPretensao }
                            )
                          }
                          className={style["salary-expectation-style"]}
                        >
                          {
                            self.state.contractTypeArray.map((tipo: string, index: number) => {
                              return (
                                <p 
                                  className={style["professional-profile-info-title"]}
                                  key={`${tipo}${index}`}
                                >
                                  {tipo.toUpperCase()}:{" "}
                                  <span className={style["professional-profile-info-content"]}>
                                  {`${self.state.user.perfilUsuario?.pretensao?.tipo[tipo].moeda} - ${self.state.user.perfilUsuario?.pretensao?.tipo[tipo].valor}`} 
                                  </span>
                                </p>
                              );
                            })
                          }
                        </button>
                      </ThemeProvider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Tooltip>

          <Tooltip
            type="light"
            size="large"
            placement="top-start"
            content={t(
              "Indique quais são seus interesses pessoais atualmente. Isso aumenta muito as chances de encontrarmos oportunidades certas pra você!"
            )}
          >
            <div className="d-flex">
              <div className={`${style["field-professional-profile"]} mt-2`}>
                <Dropdown
                  onSelect={self.setInterest}
                  downshiftProps={{
                    itemToString: (item: DictionaryDomain) => item && item.nome,
                  }}
                >
                  <Field>
                    <Select>{t("Quais seus principais interesses?")}</Select>
                  </Field>
                  <Menu>
                    {self.state.interests.map((option: DictionaryDomain) => (
                      <Item key={option._id} value={option.nome}>
                        {t(option.nome)}
                      </Item>
                    ))}
                  </Menu>
                </Dropdown>
                {self
                  .getUserProfileMosaicItem("euquero")
                  .map((interest: any, index: number) => (
                    <PillChip
                      key={index.toString() + interest.detalhe.nome}
                      chip={t(interest.detalhe.nome)}
                      index={index}
                      onRemove={self.deleteInterest}
                    ></PillChip>
                  ))}
              </div>
            </div>
          </Tooltip>

          <Tooltip
            type="light"
            size="large"
            placement="top-start"
            content={t(
              'Conte-nos sobre suas principais competências e clique em "Informações Complementares" para aumentar ainda mais o seu percentual de aderência as oportunidades!'
            )}
          >
            <div className="d-flex">
              <div className={`${style["field-professional-profile"]} mt-2`}>
                <Form
                  className="custom-form-control"
                  onSubmit={(e: any) => {
                    e.preventDefault();
                    self.setSkill(self.formControlSkillRef.current.value);
                    self.formControlSkillRef.current.value = "";
                  }}
                  noValidate
                >
                  <FormControl
                    ref={self.formControlSkillRef}
                    name="chipInput"
                    placeholder={t("E suas principais competências?")}
                    aria-label="Chip Input"
                    className={[
                      "m-0",
                      "border-0",
                      style["no-focus"],
                      style["skill-input"],
                      "mb-0",
                    ].join(" ")}
                    onFocus={() => self.setState({ focused: true })}
                    onBlur={() => self.setState({ focused: false })}
                  />
                </Form>
                {self
                  .getUserProfileList("skills")
                  .map((skill: any, index: number) => (
                    <PillChipSkill
                      key={index.toString() + skill.nome}
                      chip={skill.nome}
                      level={skill.level}
                      index={index}
                      onRemove={self.deleteSkill}
                      changeLevel={self.updateLevelByName}
                      changeLevelIndex={self.changeLevelIndex}
                      editLevel={self.state.editLevelIndex === index}
                    ></PillChipSkill>
                  ))}
              </div>
            </div>
          </Tooltip>
          <div className={`${style["field-professional-profile"]} mt-2`}>
            <Form
              className="custom-form-control"
              onSubmit={(e: any) => {
                e.preventDefault();
                self.setCourses(self.formControlCoursesRef.current.value);
                self.formControlCoursesRef.current.value = "";
              }}
              noValidate
            >
              <FormControl
                ref={self.formControlCoursesRef}
                name="chipInput"
                placeholder={t("Formação")}
                aria-label="Chip Input"
                className={[
                  "m-0",
                  "border-0",
                  style["no-focus"],
                  style["skill-input"],
                  "mb-0",
                ].join(" ")}
                onFocus={() => self.setState({ focused: true })}
                onBlur={() => self.setState({ focused: false })}
              />
            </Form>
            {self
              .getUserProfileList("courses")
              .map((skill: any, index: number) => (
                <PillChip
                  key={index.toString() + skill.nome}
                  chip={skill.nome}
                  index={index}
                  onRemove={self.deleteCourses}
                ></PillChip>
              ))}
          </div>
          <div className={`${style["field-professional-profile"]} mt-2`}>
            <Form
              className="custom-form-control"
              onSubmit={(e: any) => {
                e.preventDefault();
                self.setLanguages(
                  self.formControlLanguagesRef.current.value
                );
                self.formControlLanguagesRef.current.value = "";
              }}
              noValidate
            >
              <FormControl
                ref={self.formControlLanguagesRef}
                placeholder={t("Idiomas")}
                className={[
                  "m-0",
                  "border-0",
                  style["no-focus"],
                  style["skill-input"],
                  "mb-0",
                ].join(" ")}
              />
            </Form>
            {self
              .getUserProfileList("languages")
              .map((skill: any, index: number) => (
                <PillChipSkill
                  key={index.toString() + skill.nome}
                  chip={skill.nome}
                  level={skill.level}
                  index={index}
                  onRemove={self.deleteLanguages}
                  changeLevel={self.updateLevelByName}
                  changeLevelIndex={self.changeLevelIndex}
                  editLevel={self.state.editLevelIndex === index}
                ></PillChipSkill>
              ))}
          </div>
          <div className={`${style["field-professional-profile"]} mt-2`}>
            <Form
              className="custom-form-control"
              onSubmit={(e: any) => {
                e.preventDefault();
                self.setCertifications(
                  self.formControlCertificationsRef.current.value
                );
                self.formControlCertificationsRef.current.value = "";
              }}
              noValidate
            >
              <FormControl
                ref={self.formControlCertificationsRef}
                placeholder={t("Certificações e prêmios")}
                aria-label="Chip Input"
                className={[
                  "m-0",
                  "border-0",
                  style["no-focus"],
                  style["skill-input"],
                  "mb-0",
                ].join(" ")}
                onFocus={() => self.setState({ focused: true })}
                onBlur={() => self.setState({ focused: false })}
              />
            </Form>
            {self
              .getUserProfileList("certifications")
              .map((skill: any, index: number) => (
                <PillChip
                  key={index.toString() + skill.nome}
                  chip={skill.nome}
                  index={index}
                  onRemove={self.deleteCertifications}
                ></PillChip>
              ))}
          </div>
          <div className={`${style["field-professional-profile"]} mt-2`}>
            <Form
              className="custom-form-control"
              onSubmit={(e: any) => {
                e.preventDefault();
                self.setPositions(
                  self.formControlPositionsRef.current.value
                );
                self.formControlPositionsRef.current.value = "";
              }}
              noValidate
            >
              <FormControl
                ref={self.formControlPositionsRef}
                placeholder={t("Experiencias profissionais")}
                aria-label="Chip Input"
                className={[
                  "m-0",
                  "border-0",
                  style["no-focus"],
                  style["skill-input"],
                  "mb-0",
                ].join(" ")}
                onFocus={() => self.setState({ focused: true })}
                onBlur={() => self.setState({ focused: false })}
              />
            </Form>
            {self
              .getUserProfileList("threeCurrentPositions")
              .map((skill: any, index: number) => (
                <PillChip
                  key={index.toString() + skill.nome}
                  chip={skill.nome}
                  index={index}
                  onRemove={self.deletePositions}
                ></PillChip>
              ))}
          </div>
          <div className={`${style["field-professional-profile"]} mt-2`}>
            <Form
              className="custom-form-control"
              onSubmit={(e: any) => {
                e.preventDefault();
                self.setVolunteer(
                  self.formControlVolunteerRef.current.value
                );
                self.formControlVolunteerRef.current.value = "";
              }}
              noValidate
            >
              <FormControl
                ref={self.formControlVolunteerRef}
                name="chipInput"
                placeholder={t("Voluntariado")}
                aria-label="Chip Input"
                className={[
                  "m-0",
                  "border-0",
                  style["no-focus"],
                  style["skill-input"],
                  "mb-0",
                ].join(" ")}
                onFocus={() => self.setState({ focused: true })}
                onBlur={() => self.setState({ focused: false })}
              />
            </Form>
            {self
              .getUserProfileList("volunteer")
              .map((skill: any, index: number) => (
                <PillChip
                  key={index.toString() + skill.nome}
                  chip={skill.nome}
                  index={index}
                  onRemove={self.deleteVolunteer}
                ></PillChip>
              ))}
          </div>
        </Col>
        <Col sm={12}>
          <div className="d-flex flex-column h-100">
            <div className="mb-auto"></div>
            <Row className="w-100 d-flex justify-content-end mt-2">
              {self.state.step === "welcome" ? (
                <Button
                  className={style["next-step"]}
                  onClick={() => self.next("tab-2")}
                >
                  {t("Salvar e continuar")}
                </Button>
              ) : (
                <Button
                  className={style["next-step"]}
                  onClick={() => self.next("tab-1")}
                >
                  {t("Salvar")}
                </Button>
              )}
            </Row>
          </div>
        </Col>
      </Row>
      {
        self.state.showPretensao &&
        <Modal
          show={self.state.showPretensao}
          size="lg"
          centered
          onHide={() => self.setState({ showPretensao: false })}
        >
          <Modal.Header>
            <LabelForm
              className="mt-2"
              style={{
                fontSize: '1rem',
                textAlign: 'center'
              }}
            >
              {t("Escolha a pretensão conforme o tipo de contratação")}
            </LabelForm>
            <button
              onClick={() => self.setState({ showPretensao: false })}
              className={style['tab1-close-modal-pretensao']}
            >
              &times;
            </button>
          </Modal.Header>
          <Modal.Body>
            {self.state.contractTypeArray.map((tipo: string) => {
              return (
                <ThemeProvider theme={theme as any}>
                  <LabelForm className="mt-2 ml-2">{tipo.toUpperCase()}:</LabelForm>
                  <div className={style["coin-container-pro-profile"]}>
                    <div className={`${style["coin-field"]}`}>
                      <Dropdown
                        selectedItem={self.state[tipo].moeda}
                        onSelect={(value: any) => {
                          const perfilUsuario = self.state.user.perfilUsuario;
                          perfilUsuario.pretensao.tipo[tipo].moeda = value.label;
                          self.setState({
                            [tipo]: {...self.state[tipo], moeda: value.label},
                            user: {
                              ...self.state.user, perfilUsuario
                            }
                          })
                        }
                        }
                        downshiftProps={{
                          itemToString: (item: any) => item && item.label,
                        }}

                      >
                        <Field className={style["select-button-prof-profile"]} >
                          <Select>
                            <label className={style["label-select-prof-profile"]}>
                              {t("Moeda")}:
                            </label>
                            <p className="mt-3">
                              {tipo === "pj" && self.state.pj.moeda}
                              {tipo === "clt" && self.state.clt.moeda}
                              {tipo === "others" && self.state.others.moeda}
                            </p>
                          </Select>
                        </Field>
                        <Menu popperModifiers={{ preventOverflow: { boundariesElement: 'viewport' } }}>
                          {self.state.coinTypeArray.map((item: any) => (
                            <Item className="d-flex" key={item.value} value={item}>
                              {item.label}
                            </Item>
                          ))}
                        </Menu>
                      </Dropdown>
                    </div>
                    <div className={`${style["price-field"]}`}>
                      <Dropdown
                        selectedItem={self.state[tipo].valor}
                        onSelect={(value: any) => {
                          const perfilUsuario = self.state.user.perfilUsuario;
                          perfilUsuario.pretensao.tipo[tipo].valor = value.label;
                          self.setState({
                            [tipo]: {...self.state[tipo], valor: value.value},
                            user: {
                              ...self.state.user, perfilUsuario
                            }
                          })
                        }
                        }
                        downshiftProps={{
                          itemToString: (item: any) => item && item.label,
                        }}
                      >
                        <Field className={style["select-button-prof-profile"]}>
                          <Select>
                            <label className={style["label-select-prof-profile"]}>
                              {t("Faixa")}:
                            </label>
                            <p className="mt-3">
                              {tipo === "pj" && self.state.pj.valor}
                              {tipo === "clt" && self.state.clt.valor}
                              {tipo === "others" && self.state.others.valor}
                            </p>
                          </Select>
                        </Field>
                        <Menu popperModifiers={{ preventOverflow: { boundariesElement: 'viewport' } }}>
                          {self.state.priceRangeArray.map((item: any) => (
                            <Item className="d-flex" key={item.value} value={item}>
                              {item.label}
                            </Item>
                          ))}
                        </Menu>
                      </Dropdown>
                    </div>
                  </div>
                </ThemeProvider>
              );
            })}
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex flex-column h-100">
              <div className="mb-auto"></div>
              <Row className="w-100 d-flex justify-content-end mt-2">
                <Button
                  className={style["next-step"]}
                  onClick={() => {
                    self.next("tab-1");
                    self.setState({ showPretensao: false })
                  }}
                >
                  {t("Salvar")}
                </Button>
              </Row>
            </div>
          </Modal.Footer>
        </Modal>
      }
    </div>
  );
};
