import React from "react";
import "./index.css";
import {
  Dropdown,
  Menu,
  Item,
  Field,
  Select,
} from "@zendeskgarden/react-dropdowns";
import service from "../../../../services/api.service";
import { connect } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import {
  squadClickButton,
  responsableClickButton,
  sortByPriorityClickButton
} from "../../../../actions";
import { t } from "../../../../translations/translation.service";
import UserService from "../../../../services/user.service";
import { PriorityDomain } from '../../../../model/domain.model';

const priorityList = [
  { label: 'Mais recente', value: 4 },
  { label: 'Alta', value: 1 },
  { label: 'Média', value: 2 },
  { label: 'Baixa', value: 3 }
]

class FiltersHeader extends React.Component<any> {
  state = {
    squads: [],
    responsables: [],
    selectedSquad: {} as any,
    selectedResp: {} as any,
    selectedPriority: priorityList[0],
  };

  async componentDidMount() {
    let squads = await service.get("squad");
    squads.data.unshift({ _id: "-1", name: t("Todos os Esquadrões") });
    let responsables = await UserService.getResponsables();
    responsables.data.unshift({
      _id: "-1",
      displayName: t("Todos os Responsáveis"),
    });
    const { squadFilter, responsableFilter, sortByPriorityClickButton } = this.props;
    this.setState({
      squads: squads.data,
      responsables: responsables.data,
      selectedSquad: squadFilter._id ? squadFilter : squads.data[0],
      selectedResp: responsableFilter._id
        ? responsableFilter
        : responsables.data[0],
    });
    sortByPriorityClickButton(priorityList[0])
  }
  render() {
    const {
      squadClickButton,
      responsableClickButton,
      currentLanguage,
      sortByPriorityClickButton
    } = this.props;
    return (
      <div className="body-container">
        {currentLanguage === "en-US" ? (
          <div>
            <span className="u-fs-md u-semibold ">Opportinities</span>
            <br></br>
            <span className="u-fs-lg u-semibold">Progress</span>
        </div>
        ) : (
          <div>
            <span className="u-fs-md u-semibold ">Andamento das</span>
            <br></br>
            <span className="u-fs-lg u-semibold">Oportunidades</span>
          </div>
        )}
        <div className="drop-container">
          <div>
            <label className="u-semibold u-fs-md">{t("Ordenar por")}:</label>
            <Dropdown
              selectedItem={this.state.selectedPriority.label}
              onSelect={(selectedPriority: PriorityDomain) => {
                this.setState({ selectedPriority });
                sortByPriorityClickButton(selectedPriority)
              }}
              downshiftProps={{
                itemToString: (item: PriorityDomain) => item && item.label
              }}
            >
              <Field>
                <Select>
                  {this.state.selectedPriority.label}
                </Select>
                <Menu>
                  {priorityList.map((option: PriorityDomain) => (
                      <Item key={option.label} value={option}>
                        {option.label}
                      </Item>
                    )
                  )}
                </Menu>
              </Field>
            </Dropdown>
          </div>
          <div>
            <label className="u-semibold u-fs-md">{t("Filtrar por")}:</label>
            <Dropdown
              selectedItem={t(this.state.selectedSquad)}
              onSelect={(selectedSquad) => {
                this.setState({ selectedSquad });
                squadClickButton(selectedSquad);
              }}
              downshiftProps={{
                itemToString: (item: any) => item && item.name,
              }}
            >
              <Field>
                <Select>
                  {this.state.selectedSquad
                    ? t(this.state.selectedSquad.name)
                    : undefined}
                </Select>
              </Field>
              <Menu>
                {this.state.squads.map((option: any) => (
                  <Item key={option._id} value={option}>
                    {t(option.name)}
                  </Item>
                ))}
              </Menu>
            </Dropdown>
            <Dropdown
              selectedItem={t(this.state.selectedResp)}
              onSelect={(selectedResp) => {
                this.setState({ selectedResp });
                responsableClickButton(selectedResp);
              }}
              downshiftProps={{
                itemToString: (item: any) => item && item.displayName,
              }}
            >
              <Field>
                <Select>{t(this.state.selectedResp.displayName)}</Select>
              </Field>
              <Menu>
                {this.state.responsables &&
                  this.state.responsables.map((option: any) => (
                    <Item key={option._id} value={option}>
                      {t(option.displayName)}
                    </Item>
                  ))}
              </Menu>
            </Dropdown>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store: {
  opportunityFilterReducer: {
    squadFilter: any;
    responsableFilter: any,
    priorityButton: any
  };
  changeLanguageReducer: { currentLanguage: string };
}) => ({
  squadFilter: store.opportunityFilterReducer.squadFilter,
  responsableFilter: store.opportunityFilterReducer.responsableFilter,
  currentLanguage: store.changeLanguageReducer.currentLanguage,
  priorityButton: store.opportunityFilterReducer.priorityButton
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({
    squadClickButton,
    responsableClickButton,
    sortByPriorityClickButton,
  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FiltersHeader);
