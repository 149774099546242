import { Row } from "@zendeskgarden/react-grid";
import { Skeleton } from "@zendeskgarden/react-loaders";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { OPPORTUNITY_DETAIL_ROUTE } from "../../../providers/routes";
import { ReactComponent as WhatshotIcon } from '../../../images/icons/home/ic_whatshot_24px.svg';
import { ReactComponent as PersonPinCircle } from '../../../images/icons/home/ic_person_pin_circle_24px.svg';
import { Tooltip } from "@zendeskgarden/react-tooltips";
import "./index.css";
import TranslationService, { t } from '../../../translations/translation.service';
import { random as Mosaics } from "../../../images/icons/random-mosaic-icons/icons";

class OpportunityCard extends Component<any> {

  icon = (title: string) => {
    //it's magic, don't touch it
    if (title) {
      let hash = 0,
        i,
        chr;
      for (i = 0; i < title.length; i++) {
        chr = title.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0; // Convert to 32bit integer
      }
  
      const number = Math.round(
        Math.abs(hash)
          .toString()
          .split("")
          .map(Number)
          .reduce(function (a, b) {
            return a + b;
          }, 0) % Mosaics.length
      );
      return Mosaics[number];
    } else {
      return Mosaics[0];
    }
  };

  truncateTitle(str: string) {
    return str.length > 27 ? str.substr(0, 27).concat("...") : str;
  }

  render() {
    return (
      <div
        className={
          this.props.isCandidate || this.props.isEnrolled
            ? "opp-card-body grey-background"
            : "opp-card-body"
        }
        onClick={
          this.props.isDisable
            ? undefined
            : () =>
                this.props.history.push(
                  `${OPPORTUNITY_DETAIL_ROUTE}/${this.props.id}`
                )
        }
      >
        <Row className="m-0">
          <div className="m-1" style={{ overflow: "hidden" }}>
            {!this.props.title && <Skeleton height="70px" width="70px" />}
            {this.props.title && (
              <img
                className={"img-fade-in-" + this.props.index}
                src={this.icon(this.props.title)}
                width={70}
                height={70}
                alt={this.props.title}
              ></img>
            )}
          </div>
          <div className="col" style={{ overflow: "hidden" }}>
            {!this.props.title && (
              <React.Fragment>
                <Skeleton className="mt-1" height="18px" />{" "}
                <Skeleton className="mt-1" height="18px" width="75%" />
              </React.Fragment>
            )}
            {this.props.title && (
              <p
                className={
                  "mb-1 u-fs-lg truncateText u-semibold img-fade-in-" +
                  this.props.index
                }
                id="opp-name-found"
              >
                {this.props.title}
              </p>
            )}
            {!this.props.title && <Skeleton width={"50%"} height="15px" />}
            {this.props.title && (
              <p
                className={
                  "mb-1 truncateText u-fs-md img-fade-in-" + this.props.index
                }
              >
                {this.props.locations?.join(" | ")}
                {this.props.homeOffice && this.props.hybrid
                  ? `${t("Híbrida")} | Home Office`
                  : this.props.homeOffice
                  ? "Home Office"
                  : this.props.hybrid
                  ? t("Híbrida")
                  : ""}
                {(this.props.homeOffice || this.props.hybrid) &&
                this.props.geolocation?.length > 0
                  ? " | "
                  : ""}
                {this.props.geolocation
                  ?.map((geo: any) => geo.formatted_address)
                  .join(" | ")}
              </p>
            )}
          </div>
          {(this.props.isCandidate || this.props.isEnrolled) && (
            <Tooltip
              size="large"
              placement="start"
              content={
                <span>
                  {TranslationService.getCurrentLanguage() === "en-US"
                    ? "You already applied!"
                    : "Você já se candidatou!"}
                </span>
              }
            >
              <div className="tag-container">
                <div className="tag-content-position gray">
                  <div className="tag-content">
                    <PersonPinCircle
                      style={{
                        color: "white",
                        fontSize: 30,
                      }}
                    />
                  </div>
                </div>
              </div>
            </Tooltip>
          )}
          {this.props.isHotOpportunity &&
            this.props.isHotOpportunityLevel > 70 &&
            (!this.props.isCandidate || !this.props.isEnrolled) && (
              <Tooltip
                size="large"
                placement="start"
                content={
                  <span>
                    {TranslationService.getCurrentLanguage() === "en-US"
                      ? "Highly compatible!"
                      : "Altamente compatível!"}
                  </span>
                }
              >
                <div className="tag-container">
                  <div className="tag-content-position orange">
                    <div className="tag-content">
                      <WhatshotIcon
                        style={{
                          color: "white",
                          fontSize: 30,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Tooltip>
            )}
        </Row>
      </div>
    );
  }
}

export default withRouter(OpportunityCard);
