import {
  Dropdown,
  Field as DropField,
  Item,
  Menu,
  Select,
  Message
} from "@zendeskgarden/react-dropdowns";
import { Field, Label, Toggle } from "@zendeskgarden/react-forms";
import { Col, Row } from "@zendeskgarden/react-grid";
import { Tooltip } from "@zendeskgarden/react-tooltips";
import React from "react";
import { Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import editIcon from "../../../images/icons/edit-icon.svg";
import { ReactComponent as LocationIcon } from "../../../images/icons/location.svg";
import { AlertService } from "../../../services/alert.service";
import service from "../../../services/api.service";
import UserService from "../../../services/user.service";
import { coinType, priceRange } from '../../../util/opportunity';
import Modal from "react-bootstrap/Modal";
import { ThemeProvider } from '@zendeskgarden/react-theming';
import { theme } from "../../../util/colorButton";
import { t } from "../../../translations/translation.service";
import Loading from "../../loading";
import "./index.css";

class CardTalentMajor extends React.Component<any> {
  state = {
    squads: [],
    selectedGroup: {} as any,
    selectedResp: {} as any,
    loading: true,
    talent: {} as any,
    contractTypeArray: [] as string[],
    groups: [],
    responsables: [],
    hiredOrQuit: false,
    showPretensao: false,
    setGroupError: false,
    setGroupSuccess: false,
  };

  getContractType(contract: any): string[] {
    const contractType: string[] = [];
    for (const index in contract) {
      if (contract[index]) contractType.push(index);
    }
    return contractType;
  }

  isInValidFields = (self: any): boolean => {
    let isInvalid = false;
    self.state.contractTypeArray.forEach((tipo: string) => {
      if (self.state.talent.perfilUsuario.pretensao.tipo[tipo]) {
        if (
          (self.state.talent.perfilUsuario.pretensao.tipo[tipo].moeda.includes('BRL' || 'USD' || 'EUR')
            &&
          self.state.talent.perfilUsuario.pretensao.tipo[tipo].valor.includes('-'))
           ||
          (self.state.talent.perfilUsuario.pretensao.tipo[tipo].moeda.includes('-')
           &&
          self.state.talent.perfilUsuario.pretensao.tipo[tipo].valor.trim() !== '-')
        ) {
          isInvalid = true;
          return isInvalid;
        }
      }
    });
    return isInvalid;
  };

  async componentDidMount() {
    const id = this.props.match.params.id;
    try {
      const talent = (await UserService.getTalent(id)).data;
      const groups = (await service.get("talent")).data;
      const responsables = (await UserService.getResponsables()).data;
      const selectedGroup = talent.talent.category;
      const selectedResp = responsables.find(
        (resp: any) => resp._id === talent.talent.recruiter
      );
      if(!talent.hiringModel) {
        talent.hiringModel = {
          clt: true,
          others: true,
          pj: true,
        }
      };
      if (talent.homeOffice) {
        if (!talent.perfilUsuario.interestLocations) {
          talent.perfilUsuario.interestLocations = [];
        }
        talent.perfilUsuario.interestLocations.push({
          formatted_address: "Home Office",
        });
      }
      this.setState({
        loading: false,
        talent,
        groups,
        responsables,
        selectedResp,
        selectedGroup,
        hiredOrQuit: talent.talent.hiredOrQuit,
        contractTypeArray: this.getContractType(talent.hiringModel)
      }, () => {
        const perfilUsuario = this.state.talent.perfilUsuario;
      if (!perfilUsuario.pretensao) {
        const setPretensao = {
          tipo: {
            pj: {
              moeda: "-",
              valor: "-",
            },
            clt: {
              moeda: "-",
              valor: "-",
            },
            others: {
              moeda: "-",
              valor: "-",
            }
          }
        }
        perfilUsuario.pretensao = setPretensao
        this.setState({ talent: { ...this.state.talent, ...perfilUsuario.pretensao} });
      } else if (perfilUsuario.pretensao && !perfilUsuario.pretensao.tipo) {
        this.setState({ talent: { ...this.state.talent, 
          perfilUsuario: {
            ...perfilUsuario,
            pretensao: {
              tipo: {
                pj: {
                  moeda: "-",
                  valor: "-",
                },
                clt: {
                  moeda: perfilUsuario.pretensao.moeda,
                  valor: perfilUsuario.pretensao.valor,
                },
                others: {
                  moeda: "-",
                  valor: "-",
                }
              }
            }
        } } });
      } else {
        this.setState({ talent: { ...this.state.talent, perfilUsuario } })
      }
      });
    } catch (error) {
      console.error(error);
      this.setState({ loading: false });
    }
  }

  async updateSquad(squadId: any, opportunityId: any) {
    let data = { squadId, opportunityId };
    this.setState({ loading: true });
    try {
      await service.post("talent/update-squad", data);
      this.setState({ loading: false });
    } catch (error) {
      console.error(error);
      this.setState({ loading: false });
    }
  }

  async updateResponsable(userId: any, opportunityId: any) {
    let data = { userId, opportunityId };
    this.setState({ loading: true });
    try {
      await service.post("talent/update-responsable", data);
      this.setState({ loading: false });
    } catch (error) {
      this.setState({ loading: false });
      console.error(error);
    }
  }

  changeCssStyle(text: string): string | null {
    if (!text) return null;
    const changeStyle = text
      .replaceAll("background-color:", "#")
      .replaceAll("color", "#");
    return changeStyle;
  }

  truncate(str: string) {
    return str && str.length > 600 ? str.substring(0, 600) + "..." : str;
  }

  getValidationMessage() {
    return (
      this.state.setGroupError
        ? 'Esse campo precisa ser preenchido'
        : this.state.setGroupSuccess
        ? 'ok'
        : ''
    )
  }

  async save(): Promise<void> {
    const validCompensationExpectation = this.isInValidFields(this);
    if (validCompensationExpectation) {
      AlertService.error(t("Pretensão salarial obrigatória. Os campos moeda e faixa devem ser preenchidos"));
      return;
    }

    const {
      talent,
      selectedGroup,
      selectedResp,
      hiredOrQuit,
    } = this.state;
    try {
      const response = await UserService.updateTalent(talent._id, {
        talent,
        selectedGroup,
        selectedResp,
        hiredOrQuit,
      });
      AlertService.success(response.data.message);
    } catch (error: any) {
      console.error(error.response);
      this.setState({ setGroupError: true })
      AlertService.error(error.response.data.message);
    }
  }

  render() {
    let talent = this.state.talent;
    // talent.avaliacoesUsuario = [talent.avaliacoesUsuario[talent.avaliacoesUsuario.length -1]];
    let qty = [];
    for (let i = 0; i <= 10; i++) {
      qty.push(
        <option style={{ color: "#000" }} key={i} value={i}>
          {i}
        </option>
      );
    }
    return (
      <div
        className={`card border-left-${talent?.talent?.category?._id} shadow py-2 p-4`}
      >
        <Loading show={this.state.loading} />
        {talent.displayName === undefined && !this.state.loading && (
          <h2>{t("Profissional não encontrado")}</h2>
        )}
        {talent.displayName !== undefined && (
          <div>
            <div
              className={`squad-color-${talent.talent.category?._id} u-fs-md mb-1`}
            >
              {talent.talent.category
                ? talent.talent.category?.name +
                  " - " +
                  talent.talent.category?.description
                : "Sem categoria"}

              <button
                type="button"
                onClick={this.props.history.goBack}
                className="close"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="d-flex col-md-8 col-sm-12 pl-0">
              <div className="u-fs-lg">
                <a
                  className="formControl talent-name"
                  rel="noopener noreferrer"
                  target="_blank"
                  href={`/professional/${this.state.talent._id}`}
                >
                  {talent.displayName}
                  <img src={editIcon} className="ml-1 mb-1" alt="" />
                </a>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-9 col-sm-12 u-fs-sm pr-3">
                <div className="row">
                  {talent.highCompatibleOpportunities?.length > 0 && (
                    <div className="col-12 col-md-4">
                      <div className="pb-2">
                        <span className="chip-feature-major">
                          Alta Compatibilidade
                        </span>
                      </div>
                      {talent.highCompatibleOpportunities?.map(
                        (element: any, index: number) => {
                          return index < 3 ? (
                            <p
                              className="mt-1 mb-0 compatibles-labels"
                              key={element.id + "#%" + index}
                            >
                              {element.title}
                            </p>
                          ) : (
                            ""
                          );
                        }
                      )}
                      {talent.highCompatibleOpportunities?.length > 2 && (
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          className="more-opps"
                          href={`/professional/${talent._id}`}
                        >
                          {t("+ oportunidades compatíveis")}
                        </a>
                      )}
                    </div>
                  )}
                  <div className="col-12 col-md-4">
                    <p className="current-talent-opps mt-4 mt-md-0">
                      {t("Oportunidades Atuais")}
                    </p>
                    {talent.opportunitiesRelated.inProgressOpportunities?.map(
                      (element: any, index: number) => (
                        <Tooltip
                          size="large"
                          placement="top-end"
                          zIndex={1}
                          key={index + "*7"}
                          content={
                            <>
                              <p className="mb-1">{element.title}</p>
                              <p className="mb-0">
                                ID: {element.idOpportunity}
                              </p>
                              <p className="mb-0">
                                {t("Cliente")}: {element.cliente}
                              </p>
                            </>
                          }
                        >
                          <div
                            className="mt-1 mb-0 compatibles-labels d-flex align-items-center"
                            style={{ height: "30px" }}
                            key={element.id + "#" + index}
                          >
                            <div className="pr-2">
                              {["•", "•", "•", "•", "•"].map((step, index) => {
                                return index <= element.candidateStep * 1 ? (
                                  <span
                                    key={index + "&&"}
                                    className="talent-stepper current"
                                  >
                                    •
                                  </span>
                                ) : (
                                  <span
                                    className="talent-stepper"
                                    key={index + "¬¬"}
                                  >
                                    •
                                  </span>
                                );
                              })}
                            </div>
                            <span className="text-truncate">
                              {element.title}
                            </span>
                          </div>
                        </Tooltip>
                      )
                    )}
                  </div>
                  <div className="col-12 col-md-4">
                    <p className="current-talent-opps mt-4 mt-md-0">
                      {t("Oportunidades Anteriores")}
                    </p>
                    {talent.opportunitiesRelated.finishedOpportunities?.map(
                      (element: any, index: number) => (
                        <div
                          className="mt-1 mb-0 compatibles-labels d-flex align-items-center"
                          key={element.id + "#" + index}
                        >
                          <span className="text-truncate">{element.title}</span>
                        </div>
                      )
                    )}
                  </div>
                  <div className="col-12">
                    {talent.avaliacoesUsuario?.map(
                      (avaliacao: any, index: number) => {
                        if (!avaliacao.conteudo || avaliacao.opportunity !== undefined) {
                          return (<></>);
                        }
                        const newText = this.changeCssStyle(avaliacao.conteudo);
                        let htmlParse;
                        if (newText !== null) {
                          htmlParse = new DOMParser().parseFromString(
                            newText,
                            "text/html"
                          );
                        } else {
                          htmlParse = new DOMParser().parseFromString(
                            t("Sem informação"),
                            "text/html"
                          );
                        }
                        const parseDataAvaliacao = Date.parse(
                          avaliacao.created
                        );
                        const date = new Date(parseDataAvaliacao);
                        return (
                          <div
                            className="parecer-comportamental-header p-3 mt-3"
                            key={index + "=+"}
                          >
                            <Row>
                              <Col
                                size={6}
                                style={{ borderBottom: "solid 1px #869baf" }}
                                className="pb-2"
                              >
                                <span>{`Nota: ${avaliacao.resultado}`}</span>
                              </Col>
                              <Col
                                size={6}
                                style={{ borderBottom: "solid 1px #869baf" }}
                                className="pb-2 text-right"
                              >
                                <span>
                                  {`Data: ${date.getDate()}/${
                                    date.getMonth() + 1
                                  }/${date.getUTCFullYear()} | ${
                                    avaliacao.user?.displayName
                                  }`}
                                </span>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="pt-2">
                                <section
                                  className="text-behavioral"
                                  dangerouslySetInnerHTML={{
                                    __html: htmlParse.body.innerHTML,
                                  }}
                                />
                              </Col>
                            </Row>
                          </div>
                        );
                      }
                    )}
                  </div>
                  <div className="col-12 pt-3">
                    <Field className="text-right align-items-center">
                      <Toggle
                        checked={this.state.hiredOrQuit}
                        name="talent"
                        onChange={(e) =>
                          this.setState({ hiredOrQuit: e.target.checked })
                        }
                      >
                        <Label
                          style={{
                            color: "#707070",
                            fontWeight: "normal",
                          }}
                        >{`${t("Desistiu")}`}</Label>
                      </Toggle>
                    </Field>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-12">
                <div>
                  <p className="current-talent-opps">{t("Contato")}</p>
                  <p className="compatibles-labels">{talent.email}</p>
                  {talent.contato?.lista.map((item: any, index: number) => (
                    <div key={index + "¢"}>
                      <p className="compatibles-labels">
                        {item.tipo}: {item.contato}
                      </p>
                    </div>
                  ))}
                </div>
                <div>
                  <p className="current-talent-opps">
                    {t("Pretensão salarial")}
                  </p>
                  <div className="row">
                    <button
                      onClick={() => this.setState({
                        showPretensao: true
                      })
                      }
                      className="talent-salary-expectation-style"
                    >
                      { 
                        this.state.talent.perfilUsuario?.pretensao &&
                        this.state.talent.perfilUsuario?.pretensao?.tipo &&
                        this.state.contractTypeArray.map((tipo: string, index: number) => {
                          return (
                            (
                              tipo === "pj" ||
                              tipo === "clt" ||
                              tipo === "others"
                            ) && (
                              <p key={(index + 10).toString()} className="talent-professional-profile-info-title">
                                {tipo.toUpperCase()}:{" "}
                                <span className="talent-professional-profile-info-content">
                                {`${this.state.talent.perfilUsuario.pretensao?.tipo[tipo]?.moeda} - 
                                ${this.state.talent.perfilUsuario.pretensao?.tipo[tipo]?.valor}`} 
                                </span>
                              </p>
                            )
                          );
                        })
                      }
                    </button>
                    <div className="col-12">
                      <p className="text-left w-100 compatibles-labels mb-1 mt-2">
                        <LocationIcon
                          className="mr-1"
                          style={{ width: 15, height: 15 }}
                        />
                        {this.state.talent.perfilUsuario?.interestLocations?.map(
                          (location: any, index: number) => (
                            <span key={index * 3 + "%"}>
                              {location.formatted_address}{" "}
                              {index !==
                              this.state.talent.perfilUsuario?.interestLocations
                                .length -
                                1
                                ? "/"
                                : ""}{" "}
                            </span>
                          )
                        )}
                      </p>
                      <p className="text-left w-100">
                        <span className="hunted-days">
                          {this.state.talent.huntingTime} {t("dias")}
                        </span>
                      </p>
                    </div>
                    <div className="col-4">
                      <label>{t("Grupo")}</label>
                      <Dropdown
                        selectedItem={this.state.selectedGroup}
                        onSelect={(selectedGroup: any) => {
                          this.setState({ selectedGroup, setGroupError: false, setGroupSuccess: true });
                        }}
                        downshiftProps={{
                          itemToString: (item: any) => item && item.name,
                        }}
                      >
                        <DropField>
                          <Select
                            style={{ minWidth: "80px", width: "100%" }}
                            validation={
                              this.state.setGroupError
                                ? 'error'
                                : this.state.setGroupSuccess
                                ? 'success'
                                : undefined }
                          >
                            {this.state.selectedGroup?.name}
                          </Select>
                          <Message
                            className="alert-message-group"
                            validation={
                              this.state.setGroupError
                                ? 'error'
                                : this.state.setGroupSuccess
                                ? 'success'
                                : undefined
                              }
                            >
                              {this.getValidationMessage()}
                            </Message>
                          <Menu style={{ width: '10rem'}}>
                            {this.state.groups.map((group: any) => (
                              <Item key={group._id + "##"} value={group}>
                                {group.name}
                              </Item>
                            ))}
                          </Menu>
                        </DropField>
                      </Dropdown>
                    </div>
                    <div className="col-8">
                      <label>{t("Responsável")}</label>
                      <Dropdown
                        selectedItem={this.state.selectedResp}
                        onSelect={(selectedResp) => {
                          const talent = this.state.talent;
                          talent.talent.recruiter = selectedResp._id;
                          this.setState({ selectedResp, talent });
                        }}
                        downshiftProps={{
                          itemToString: (item: any) => item && item.displayName,
                        }}
                      >
                        <DropField className="w-100">
                          <Select style={{ minWidth: "80px", width: "100%" }}>
                            {this.state.selectedResp?.displayName}
                          </Select>
                          <Menu style={{ width: '15rem'}}>
                            {this.state.responsables.map((option: any) => (
                              <Item key={option._id + "$$"} value={option}>
                                {option.displayName}
                              </Item>
                            ))}
                          </Menu>
                        </DropField>
                      </Dropdown>
                    </div>
                    <div className="col-12 mt-3 text-right">
                      <Button
                        variant="outline-warning"
                        onClick={() => this.save()}
                        style={{ borderRadius: "20px", padding: "3px 25px" }}
                      >
                        {t("Salvar")}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {
        this.state.showPretensao &&
        <Modal
          show={this.state.showPretensao}
          size="lg"
          centered
          onHide={() => this.setState({ showPretensao: false })}
        >
          <Modal.Header>
            <Label
              style={{
                fontSize: '1rem',
                textAlign: 'center'
              }}
            >
              {t("Escolha a pretensão conforme o tipo de contratação")}
            </Label>
            <button
              onClick={() => this.setState({ showPretensao: false })}
              className='view-close-modal-pretensao'
            >
              &times;
            </button>
          </Modal.Header>
          <Modal.Body>
            {this.state.contractTypeArray.map((tipo: string, index: number) => {
              return (
                (
                  tipo === "pj" ||
                  tipo === "clt" ||
                  tipo === "others"
                ) && (
                <ThemeProvider key={(index + 20).toString()} theme={theme as any}>
                  <Label className="mt-2 ml-2">{tipo.toUpperCase()}:</Label>
                  <div className="talent-coin-container-pro-profile">
                    <div className="talent-coin-field">
                      <Dropdown
                        selectedItem={this.state.talent.perfilUsuario.pretensao.tipo[tipo].moeda}
                        onSelect={(value: any) => {
                          const perfilUsuario = this.state.talent.perfilUsuario;
                          perfilUsuario.pretensao.tipo[tipo].moeda = value.label;
                          this.setState({
                            talent: { ...this.state.talent, perfilUsuario }
                          })
                        }
                        }
                        downshiftProps={{
                          itemToString: (item: any) => item && item.label,
                        }}

                      >
                        <DropField className="talent-select-button-prof-profile">
                          <Select>
                            <label className="talent-label-select-prof-profile">
                              {t("Moeda")}:
                            </label>
                            <p className="mt-3">
                              {tipo === "pj" && this.state.talent.perfilUsuario.pretensao.tipo.pj.moeda}
                              {tipo === "clt" && this.state.talent.perfilUsuario.pretensao.tipo.clt.moeda}
                              {tipo === "others" && this.state.talent.perfilUsuario.pretensao.tipo.others.moeda}
                            </p>
                          </Select>
                        </DropField>
                        <Menu popperModifiers={{ preventOverflow: { boundariesElement: 'viewport' } }}>
                          {coinType.map((item: any) => (
                            <Item className="d-flex" key={item.value} value={item}>
                              {item.label}
                            </Item>
                          ))}
                        </Menu>
                      </Dropdown>
                    </div>
                    <div className="talent-price-field">
                      <Dropdown
                        selectedItem={this.state.talent.perfilUsuario.pretensao.tipo[tipo].valor}
                        onSelect={(value: any) => {
                          const perfilUsuario = this.state.talent.perfilUsuario;
                          perfilUsuario.pretensao.tipo[tipo].valor = value.label;
                          this.setState({
                            talent: { ...this.state.talent, perfilUsuario }
                          })
                        }
                        }
                        downshiftProps={{
                          itemToString: (item: any) => item && item.label,
                        }}
                      >
                        <DropField className="talent-select-button-prof-profile">
                          <Select>
                            <label className="talent-label-select-prof-profile">
                              {t("Faixa")}:
                            </label>
                            <p className="mt-3">
                              {tipo === "pj" && this.state.talent.perfilUsuario.pretensao.tipo.pj.valor}
                              {tipo === "clt" && this.state.talent.perfilUsuario.pretensao.tipo.clt.valor}
                              {tipo === "others" && this.state.talent.perfilUsuario.pretensao.tipo.others.valor}
                            </p>
                          </Select>
                        </DropField>
                        <Menu popperModifiers={{ preventOverflow: { boundariesElement: 'viewport' } }}>
                          {priceRange.map((item: any) => (
                            <Item className="d-flex" key={item.value} value={item}>
                              {item.label}
                            </Item>
                          ))}
                        </Menu>
                      </Dropdown>
                    </div>
                  </div>
                </ThemeProvider>
                )
              );
            })}
          </Modal.Body>
        </Modal>
      }
      </div>
    );
  }
}

export default withRouter(CardTalentMajor);
