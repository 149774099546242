import { Alert, Title } from "@zendeskgarden/react-notifications";
import { Component } from "react";
import TranslationService, { t } from "../../../../../translations/translation.service";

class AlertSkillTips extends Component<{ skillTips: any[] }> {

  limitSkillTips(skillTips: string[]) {
    const skills = skillTips.slice(0, 3);
    const last = skills.pop();
    const letter = TranslationService.getCurrentLanguage() === "en-US"
      ? "and" : "e";

    let skillFormated = (skills && skills.length > 0) 
      ? ` ${letter} ${last}` : last;

    return skills.join(', ') + ` ${skillFormated}`;
  }

  render() {
    return (this.props.skillTips && this.props.skillTips.length > 0
        ? (
           <Alert className="mb-3" type="info">
            <Title className="mb-2">Info</Title>
            {t("Olá, recebemos o seu CV!")}<br/>
            {t("A maioria das vagas aderentes ao seu perfil também pedem ")}
            {this.limitSkillTips(this.props.skillTips)}
            {t(", mas não identidicamos esse termo no seu cadastro. Que tal checar?")}<br/>
            {t("Ainda não possui essa skill? Talvez seja um bom momento para pensar nisso!")}
          </Alert>
        ) : <></>)   
  }
}

export default AlertSkillTips;