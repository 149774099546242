import React from "react";
import { withRouter } from "react-router";
import Loading from "../../components/loading";
import { UserData } from "../../model/user.model";
import {
  CANDIDATURE_DETAILS_ROUTE,
  HOME_ROUTE,
  PROFESSIONAL_PROFILE_ROUTE,
} from "../../providers/routes";
import { AlertService } from "../../services/alert.service";
import { AuthenticationService } from "../../services/auth.service";
import OpportunityService from "../../services/opportunity.service";
import TranslationService, { t } from "../../translations/translation.service";
import "./index.css";

let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty("--vh", `${vh}px`);

class SignupValidation extends React.Component<any> {
  state = {
    currentLang: TranslationService.getCurrentLanguage,
  };

  redirectToHome() {
    this.props.history.push("/");
  }

  async componentDidMount() {
    AuthenticationService.logout();
    const { username, token, type } = this.props.match.params;
    if (type === "default") {
      AuthenticationService.validateSignup(username, token)
        .then((data: { user: UserData; token: string }) =>
          setTimeout(
            () =>
              (window.location.href =
                PROFESSIONAL_PROFILE_ROUTE.replace(":id", data.user._id) +
                "?step=welcome"),
            1000
          )
        )
        .catch((error: any) => {
          if (error.response.data) {
            console.error(error);
            switch (error.response.data.message) {
              case "USER_NOTFOUND":
                AlertService.error(t("Usuário não encontrado"));
                break;
              default:
                AlertService.error(t("Erro interno ao validar email."));
                break;
            }
          }
          this.props.history.push(HOME_ROUTE);
        })
        .finally(() => {
          this.setState({ loadingSubmit: false });
        });
    } else {
      const { opportunityId } = this.props.match.params;
      try {
        const activeLanguage = TranslationService.getCurrentLanguage();
        const newUserAdvanced = {
          activeLanguage: activeLanguage,
          password: token,
          username: username,
          perfilUsuario: {
            mosaico: {
              escolhidas: [],
            },
            digomais: {
              lista: [],
            },
          },
          homeOffice: true,
        };
        const data = await AuthenticationService.signupAdvanced(
          newUserAdvanced
        );
        const objData = {
          activeLanguage: activeLanguage,
          attachmentUrl: null,
          indicadoPor: null,
          _usuario: {
            _id: data.user._id,
          },
        };
        await OpportunityService.professionalCandidature(
          opportunityId,
          objData
        );
        setTimeout(() => (window.location.href =
          CANDIDATURE_DETAILS_ROUTE.replace(":opportunityId", opportunityId) +
          "?fromSignup=true") , 1500);
      } catch (err) {
        AuthenticationService.logout();
        console.log(err);
        window.location.href = HOME_ROUTE;
      } finally {
        this.setState({ loadingSubmit: false });
      }
    }
  }

  render() {
    return (
      <>
        <Loading show={true}></Loading>
      </>
    );
  }
}

export default withRouter(SignupValidation);

//TODO: 
//Mostar mensagem ao usuario em caso de falha e retornar para o Home;
//Remover dados nulos caso o fluxo falhe; - feito