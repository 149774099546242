import service from './api.service';
import { CarreiraDomain } from '../model/domain.model';

class carreiraService {
  static getAllCarreiras = () => {
    return service.get('carreiras')
  }
  static createCarreira = (userId: string, carreiras: CarreiraDomain[]) => {
    return service.post(`carreiras/${userId}`, carreiras)
  }
  static deleteCarreira = (carreiraId: string) => {
    return service.delete(`carreiras/${carreiraId}`);
  }
}

export default carreiraService;