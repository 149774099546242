import React from "react";
import "./index.css";
import { Avatar } from "@zendeskgarden/react-avatars";
import service from "../../../../services/api.service";
import Moment from "moment";
import { AuthenticationService } from "../../../../services/auth.service";
import DangerModal from "../../../modal/danger";
import { Opportunity } from "../../../../model/opportunity.model";
import { t } from "../../../../translations/translation.service";

const dateFormat = "HH:mm - DD/MM/YYYY ";

class CommentComponent extends React.Component<{ opportunity: Opportunity }> {
  state = {
    comment: "",
    comments: [] as Array<any>,
    user: undefined as any,
    currentEditId: undefined,
    currentEditMessage: undefined,
    isModalVisible: false,
    opportunity: {} as any,
    disableButton: false
  };

  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    if (
      nextProps.opportunity !== prevState.opportunity &&
      nextProps.opportunity._id
    ) {
      return { opportunity: nextProps.opportunity };
    }
    return null;
  }

  componentDidMount() {
    this.setState({ opportunity: this.props.opportunity });
  }

  onModalClose = () => this.setState({ isModalVisible: false });

  async componentDidUpdate(prevProps: any, prevState: any) {
    if (prevProps.opportunity !== this.state.opportunity) {
      const sessionUser = AuthenticationService.getUser();
      this.setState({
        comments: this.state.opportunity.comments,
        user: sessionUser,
      });
    }
  }

  constructor(props: any) {
    super(props);
    this.addComment = this.addComment.bind(this);
    this.handleCommentChange = this.handleCommentChange.bind(this);
  }

  handleCommentChange(event: any) {
    this.setState({ comment: event.target.value });
  }

  editComment(comment: any) {
    this.setState({
      currentEditId: comment._id,
      currentEditMessage: comment.message,
    });
  }

  async addComment() {
    this.setState({ disableButton: true});
    let data = {
      message: this.state.comment,
      opportunityId: this.props.opportunity._id,
    };
    try {
      const comments = await service.post("opportunity/comment", data);
      this.setState({ comments: comments.data, comment: "", disableButton: false });
    } catch (error) {
      this.setState({ disableButton: false });
      console.error(error);
    }
  }

  async updateComment() {
    let data = {
      message: this.state.currentEditMessage,
      commentId: this.state.currentEditId,
      opportunityId: this.props.opportunity._id,
    };
    try {
      await service.put("opportunity/comment", data);
      let comments = this.state.comments;
      let index = comments.map((e: any) => e._id).indexOf(data.commentId);
      comments[index].message = data?.message;
      comments[index].updatedAt = new Date();
      this.setState({
        comments: comments,
        currentEditMessage: undefined,
        currentEditId: undefined,
      });
    } catch (error) {
      console.error(error);
    }
  }

  async deleteComment() {
    let data = {
      commentId: this.state.currentEditId,
      opportunityId: this.props.opportunity._id,
    };
    try {
      await service.delete("opportunity/comment", { data });
      let comments = this.state.comments;
      let index = comments.map((e) => e._id).indexOf(data.commentId);
      comments.splice(index, 1);
      this.setState({
        comments: comments,
        currentEditMessage: undefined,
        currentEditId: undefined,
      });
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    return (
      <div className="ui comments">
        <p className="dividing u-fs-lg">{t("Comentários")}</p>
        {!(this.state.comments.length > 0) && (
          <p className="u-semibold u-fs-md">
            {t("Esta oportunidade ainda não possui nenhum comentário")}.
          </p>
        )}
        {this.state.comments.map((comment, index) => (
          <div key={index} className="comment">
            <div className="avatar">
              <Avatar
                size="small"
                title={
                  comment.user ? comment.user.displayName : "User Not Found"
                }
                style={{ border: "1px solid #4F6061" }}
              >
                <img
                  alt={comment.user.displayName}
                  src={comment.user.userImageUrl}
                ></img>
              </Avatar>
            </div>
            <div className="content">
              <b className="author">{comment.user.displayName}</b>
              <div className="metadata">
                <div>
                  {comment.updatedAt
                    ? Moment(comment.updatedAt).format(dateFormat)
                    : Moment(comment.createdAt).format(dateFormat)}
                </div>
                {this.state.user._id === comment.user._id &&
                  this.state.currentEditId !== comment._id && (
                    <div>
                      <button
                        className="c-btn c-btn--anchor u-mb-sm"
                        type="button"
                        onClick={() => this.editComment(comment)}
                      >
                        {" "}
                        {t("Editar")}
                      </button>
                    </div>
                  )}
              </div>
              {this.state.currentEditId === comment._id ? (
                <div>
                  <textarea
                    className="textarea c-txt__input c-txt__input--area is-resizable"
                    value={this.state.currentEditMessage}
                    onChange={(event) => {
                      this.setState({ currentEditMessage: event.target.value });
                    }}
                  ></textarea>
                  <button
                    className="c-btn c-btn--anchor u-mb-sm mt-2 mr-2"
                    onClick={() => this.updateComment()}
                  >
                    {t("Salvar")}
                  </button>
                  ·
                  <button
                    className="c-btn c-btn--anchor u-mb-sm c-tag--red mt-2 ml-2"
                    onClick={() => this.setState({ isModalVisible: true })}
                  >
                    {t("Excluir")}
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      this.setState({
                        currentEditId: undefined,
                        currentEditMessage: undefined,
                      });
                    }}
                    className="close"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              ) : (
                <div className="text">{comment.message}</div>
              )}
            </div>
          </div>
        ))}
        <div className="mt-4">
          <div>
            <textarea
              className={`${
                this.state.comment ? "textarea" : ""
              } c-txt__input c-txt__input--area is-resizable`}
              value={this.state.comment}
              onChange={this.handleCommentChange}
              placeholder={t("Adicionar Comentário...")}
            ></textarea>
          </div>
          {this.state.comment && (
            <button
              disabled={this.state.disableButton}
              className="c-btn c-btn--primary mt-2"
              onClick={this.addComment}
            >
              {t("Adicionar")}
            </button>
          )}
        </div>
        {this.state.isModalVisible && (
          <DangerModal
            onModalClose={this.onModalClose}
            header="Excluir este comentário?"
            body="Uma vez excluído, este comentário não poderá mais ser recuperado."
            confirmAction={this.deleteComment.bind(this)}
          ></DangerModal>
        )}
      </div>
    );
  }
}
export default CommentComponent;
