import {
  Dropdown,
  Field as FieldDrop,
  Item,
  Menu,
  Select,
  Autocomplete,
} from "@zendeskgarden/react-dropdowns";
import {
  Field,
  Field as ToggleField,
  Label,
  MediaInput,
  Toggle,
} from "@zendeskgarden/react-forms";
import { ThemeProvider } from '@zendeskgarden/react-theming';
import { theme } from "../../../util/colorButton";
import { Col, Row } from "@zendeskgarden/react-grid";
import React from "react";
import OpportunityCard from "../../../components/home/opportunity-card";
import Loading from "../../../components/loading";
import { ReactComponent as Opp } from "../../../images/icons/jobs.svg";
import { ReactComponent as LocationIcon } from "../../../images/icons/location.svg";
import { OpportunityCardData } from "../../../model/home.model";
import { Location } from "../../../model/location.model";
import DictionaryService from "../../../services/dictionary.service";
import OpportunityService from "../../../services/opportunity.service";
import { CursorPagination } from "@zendeskgarden/react-pagination";
import { t } from "../../../translations/translation.service";
import "./index.css";

interface IStatus {
  key: string;
  value: string;
}

const Status: IStatus[] = [
  {
    key: "published",
    value: t("Publicadas"),
  },
  {
    key: "unpublished",
    value: t("Não Publicadas"),
  },
  {
    key: "finalized",
    value: t("Encerradas"),
  },
];

export default class Opportunities extends React.Component<any> {
  state = {
    locations: [] as Location[],
    loading: false,
    searchForm: {
      keywords: "",
      location: { id: "All Places", location: t("All Places") },
      client: t("Todos os Clientes"),
      career: t("Todas as Carreiras"),
      motive: t("Todos os Motivos"),
      homeOffice: false,
      status: Status[0],
    },
    searchResults: null as unknown,
    resultsFiltered: null as unknown,
    pageContent: null as unknown,
    clients: [] as string[],
    clientSelected: "",
    careers: [] as string[],
    careerSelected: "",
    motives: [] as string[],
    motiveSelected: "",
    inputValue: "",
    selectedItem: "",
    matchingOptions: [] as string[],
    locationsName: [] as string[],
    currentPage: 1,
    resultLength: 0,
    oppLength: 0,
    encerradasAreLoaded: false,
  };

  constructor(props: any) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    const locations = await (await DictionaryService.getStates()).data;
    const locationsName = locations.map((localName: any) => localName.location)
    locations.unshift({ id: "All Places" , location: "All Places" });
    locationsName.unshift("All Places");
    this.setState({
      locations,
      locationsName: locationsName,
      matchingOptions: locationsName,
      selectedItem: locationsName[0]
    });
    this.getResults();
  }

  async componentDidUpdate(_prevProps: any, prevState: any) {
    if (prevState.inputValue !== this.state.inputValue) {
      this.filterMatchingOptionsRef(this.state.inputValue)
    }
  }

  filterMatchingOptionsRef(value: string) {
    const matchedOptions = this.state.locationsName.filter(
      option => option.trim().toLowerCase().indexOf(value.trim().toLowerCase()) !== -1
    );
    this.setState({
      matchingOptions: matchedOptions
    })
  }

  handleInputChange(event: any) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      searchForm: { ...this.state.searchForm, [name]: value },
    });
  }

  async handleSubmit(event: any) {
    event.preventDefault();
    await this.getResults();
    if (this.state.searchForm.status.value === "Encerradas") {
      this.setState({
        encerradasAreLoaded: true,
      })
    } else {
      this.setState({
        encerradasAreLoaded: false,
      })
    }
  }

  handleMotiveSelection(motive: string): void {
    this.setState({
      motiveSelected: motive,
    });
  }

  async getResults() {
    this.setState({ loading: true });
    const findLocation = this.state.locations.find((key) => {
      return key.location === this.state.selectedItem
    })
    const location = this.state.searchForm.homeOffice && !findLocation?.id
      ? { id: "Home Office", ...findLocation }
      : this.state.searchForm.homeOffice ? { id: "Home Office" } : findLocation;
    const searchResults = (
      await OpportunityService.searchOpportunitiesRh(
        this.state.searchForm.keywords,
        location,
        this.state.searchForm.status.key
      )
    ).data.map((opp) => {
      opp.careers =
        opp.careers && opp.careers.length > 0 ? opp.careers[0].nome : undefined;
      return opp;
    });

    const clients = searchResults
      .map((opp) => {
        return opp.client !== "" ? opp.client : undefined;
      })
      .filter((item, pos, self) => {
        return !!item && self.indexOf(item) === pos;
      })
      .sort();

      const motives = searchResults
      .map((opp) => {
        return  opp.motive;
      })
      .filter((item, pos, self) => {
        return !!item && self.indexOf(item) === pos;
      })
      .sort();

    const careers = searchResults
      .map((opp) => {
        return opp.careers;
      })
      .filter((item, pos, self) => {
        return !!item && self.indexOf(item) === pos;
      })
      .sort();
    this.setState({
      clients,
      searchResults,
      resultsFiltered: searchResults,
      loading: false,
      careers,
      motives,
    }, () => {
      this.setState({
        resultLength: Math.ceil(searchResults.length / 36),
        oppLength: searchResults.length
      });
      this.pagination();
    })
  }

  applyFilter() {
    this.setState({
      resultsFiltered: (this.state.searchResults as []).filter((opp: any) => {
        let clientMatch: any[] | boolean = true;
        let careerMatch = true;
        let motiveMatch = true;
        if (
          this.state.clientSelected === "All Clients" ||
          this.state.clientSelected === t("Todos os Clientes")
        ) {
          clientMatch = this.state.careers;
        } else if (this.state.clientSelected !== "") {
          clientMatch = opp.client === this.state.clientSelected;
        }
        if (
          this.state.careerSelected === "All Careers" ||
          this.state.careerSelected === t("Todas as Carreiras")
        ) {
          careerMatch = opp.careers;
        } else if (this.state.careerSelected !== "") {
          careerMatch = opp.careers === this.state.careerSelected;
        }
        if (
          this.state.motiveSelected === "All Motives" ||
          this.state.motiveSelected === t("Todos os Motivos")
        ) {
          motiveMatch = opp;
        } else if (this.state.motiveSelected !== "") {
          motiveMatch = opp.motive === this.state.motiveSelected;
        }
        return clientMatch && careerMatch && motiveMatch;
      }),
    }, () => {
      this.setState({
        resultLength: Math.ceil((this.state.resultsFiltered as OpportunityCardData[]).length / 36),
        currentPage: 1,
        oppLength: (this.state.resultsFiltered as OpportunityCardData[]).length
      });
      this.pagination();
    });
  }

  onFirst() {
    this.setState({ currentPage: 1 }, () => {
      this.pagination()
    });
  }

  onLast() {
    this.setState({ currentPage: this.state.resultLength }, () => {
      this.pagination()
    })
  }

  onNext() {
    if (this.state.currentPage < this.state.resultLength ) {
      this.setState({ currentPage: this.state.currentPage + 1 }, () => {
        this.pagination()
    });
    }
  }

  onPrevious() {
    if (this.state.currentPage > 1) {
      this.setState({ currentPage: this.state.currentPage - 1 }, () => {
        this.pagination()
    })
    }
  }

  pagination() {
    let contentNumber = 36;
    let arrayCopy: any = [...this.state.resultsFiltered as OpportunityCardData[]];
    let pageContent = arrayCopy.splice((this.state.currentPage - 1) * contentNumber, contentNumber);
    this.setState({ pageContent });
  }

  render() {
    return (
      <div className="container">
        <Loading show={this.state.loading} />
        <form onSubmit={this.handleSubmit}>
          <div className="opp-filter">
            <Row>
              <Col>
                <p className="u-fs-lg u-semibold" style={{ color: "white" }}>
                  {t("Encontrar Oportunidades")}
                </p>
              </Col>
            </Row>
            <Row>
              <Col sm={12} lg={5}>
                <Field className="input-search">
                  <MediaInput
                    placeholder={t("Oportunidade")}
                    start={<Opp style={{ width: 23, height: 23 }} />}
                    value={this.state.searchForm.keywords}
                    name="keywords"
                    onChange={this.handleInputChange}
                  />
                </Field>
              </Col>
              <Col sm={12} lg={3}>
                <Dropdown
                  selectedItem={this.state.selectedItem}
                  inputValue={this.state.inputValue}
                  onInputValueChange={(value) =>
                    this.setState({
                      inputValue: value
                    })}
                  onSelect={(value) =>
                    this.setState({
                      selectedItem: value
                    })
                  }
                  downshiftProps={{ defaultHighlightedIndex: 0 }}
                >
                  <FieldDrop className="input-search">
                    <Autocomplete
                      start={<LocationIcon style={{ width: 23, height: 23 }} />}
                    >
                      {t(this.state.selectedItem)}
                    </Autocomplete>
                  </FieldDrop>
                  <Menu>
                    {this.state.matchingOptions.length ? (
                      this.state.matchingOptions.map(option => (
                        <Item key={option} value={option}>
                          <span>{t(option)}</span>
                        </Item>
                      ))
                    ) : (
                      <Item disabled>{t("Nenhum resultado encontrado")}</Item>
                    )}
                  </Menu>
                </Dropdown>
              </Col>
              <Col sm={12} lg={2}>
                <Dropdown
                  selectedItem={this.state.searchForm.status}
                  onSelect={(value) =>
                    this.setState({
                      searchForm: {
                        ...this.state.searchForm,
                        status: value,
                      },
                    })
                  }
                  downshiftProps={{
                    itemToString: (item: IStatus) => item && item.value,
                  }}
                >
                  <FieldDrop className="input-search">
                    <Select>{t(this.state.searchForm.status.value)}</Select>
                  </FieldDrop>
                  <Menu>
                    {Status.map((option) => (
                      <Item key={option.key} value={option}>
                        {t(option.value)}
                      </Item>
                    ))}
                  </Menu>
                </Dropdown>
                <ThemeProvider theme={theme as any}>
                  <ToggleField className="text-right">
                    <Toggle name="homeOffice" onChange={this.handleInputChange}>
                      <Label
                        className={`home-office ${
                          this.state.searchForm.homeOffice ? "active" : ""
                        }`}
                      >
                        <span style={{ fontWeight: 700 }}>Home Office</span>
                      </Label>
                    </Toggle>
                  </ToggleField>
                </ThemeProvider>
              </Col>
              <Col sm={12} lg={2}>
                <button type="submit" className="w-100 find-button">
                  {t("Encontrar")}
                </button>
              </Col>
            </Row>
          </div>
          <div className="text-right mb-4"></div>
        </form>

        <div className="w-100 mb-5">
          <div className="u-fs-lg u-semibold search-result-box d-flex justify-content-between">
            <span>{t("Resultado da busca")}:</span>
            <span style={{ color: "#354A5F"}}>{`${this.state.oppLength} oportunidades encontradas`}</span>
          </div>
          <Row className="mt-2">
            <Col className="ml-auto" sm={12} lg={3}>
              <Dropdown
                selectedItem={this.state.clientSelected}
                onSelect={(value) => {
                  this.setState(
                    {
                      clientSelected: value,
                      searchForm: {
                        ...this.state.searchForm,
                        client: value,
                      },
                      currentPage: 1,
                    },
                    () => {
                      this.applyFilter();
                      this.pagination();
                    }
                  );
                }}
                downshiftProps={{
                  itemToString: (item: string) => item,
                }}
              >
                <FieldDrop className="input-search result-filter">
                  <Select>
                    <label className="info-select-label">
                      {t("Filtrar por Cliente")}
                    </label>
                    <p className="u-fs-md mt-2" style={{ color: "white" }}>
                      {t(this.state.searchForm.client)}
                    </p>
                  </Select>
                </FieldDrop>
                <Menu>
                  <Item key="all" value={t("Todos os Clientes")}>
                    {t("Todos os Clientes")}
                  </Item>
                  {this.state.clients.map((option, index) => (
                    <Item key={index} value={option}>
                      {option}
                    </Item>
                  ))}
                </Menu>
              </Dropdown>
            </Col>
            <Col sm={12} lg={3}>
              <Dropdown
                selectedItem={this.state.careerSelected}
                onSelect={(value) => {
                  this.setState(
                    {
                      careerSelected: value,
                      searchForm: {
                        ...this.state.searchForm,
                        career: value,
                      },
                      currentPage: 1,
                    },
                    () => this.applyFilter()
                  );
                }}
                downshiftProps={{
                  itemToString: (item: string) => item,
                }}
              >
                <FieldDrop className="input-search result-filter">
                  <Select>
                    <label className="info-select-label">
                      {t("Áreas de Atuação")}
                    </label>
                    <p className="u-fs-md mt-2" style={{ color: "white" }}>
                      {t(this.state.searchForm.career)}
                    </p>
                  </Select>
                </FieldDrop>
                <Menu>
                  <Item key="all" value={t("Todas as Carreiras")}>
                    {t("Todas as Carreiras")}
                  </Item>
                  {this.state.careers.map((option, index) => (
                    <Item key={index} value={option}>
                      {t(option)}
                    </Item>
                  ))}
                </Menu>
              </Dropdown>
            </Col>
            {
              this.state.encerradasAreLoaded &&
              <Col sm={12} lg={3}>
                <Dropdown
                  selectedItem={this.state.motiveSelected}
                  onSelect={(value) => {
                    this.setState(
                      {
                        motiveSelected: value,
                        searchForm: {
                          ...this.state.searchForm,
                          motive: value,
                        },
                        currentPage: 1,
                      },
                      () => this.applyFilter()
                    );
                  }}
                  downshiftProps={{
                    itemToString: (item: string) => item,
                  }}
                >
                  <FieldDrop className="input-search result-filter">
                    <Select>
                      <label className="info-select-label">
                        {t("Motivo do Encerramento")}
                      </label>
                      <p className="u-fs-md mt-2" style={{ color: "white" }}>
                        {t(this.state.searchForm.motive)}
                      </p>
                    </Select>
                  </FieldDrop>
                  <Menu>
                    <Item key="all" value={t("Todos os Motivos")}>
                      {t("Todos os Motivos")}
                    </Item>
                    {this.state.motives.map((option, index) => (
                      <Item 
                        key={index} 
                        value={option} 
                        onSelect={() => this.handleMotiveSelection(option)}>
                        {t(option)}
                      </Item>
                    ))}
                  </Menu>
                </Dropdown>
              </Col>
            }
            <Col>
              <CursorPagination
                style={{ justifyContent: "end" }}
                aria-label="Cursor pagination"
              >
                <CursorPagination.First
                  onClick={this.onFirst.bind(this)}
                  disabled={this.state.currentPage === 1}
                />
                <CursorPagination.Previous
                  onClick={this.onPrevious.bind(this)}
                  disabled={this.state.currentPage === 1}
                />
                <span className="pagination-number">
                  {this.state.currentPage}
                </span>
                <CursorPagination.Next
                  onClick={this.onNext.bind(this)}
                  disabled={this.state.currentPage === this.state.resultLength}
                />
                <CursorPagination.Last
                  onClick={this.onLast.bind(this)}
                  disabled={this.state.currentPage === this.state.resultLength}
                />
                <span className="pagination-number-last">
                  {("de " + this.state.resultLength)}
                </span>
              </CursorPagination>
            </Col>
          </Row>
        </div>
        {this.state.resultsFiltered && (
          <div className="row">
            {(this.state.pageContent as OpportunityCardData[])?.map(
              (opp, index) => (
                <div key={index} className="col-12 col-sm-6 col-md-4 pl-2 pr-2">
                  <OpportunityCard
                    id={opp.id}
                    index={0}
                    key={index}
                    title={opp.title}
                    locations={opp.location}
                    homeOffice={opp.homeOffice}
                    geolocation={opp.geolocation}
                  ></OpportunityCard>
                </div>
              )
            )}
          </div>
        )}
      </div>
    );
  }
}
