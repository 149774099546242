import { Avatar } from "@zendeskgarden/react-avatars";
import { Skeleton } from "@zendeskgarden/react-loaders";
import { Tooltip } from "@zendeskgarden/react-tooltips";
import React from "react";
import { Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { ReactComponent as LocationIcon } from "../../../images/icons/location.svg";
import { CARD_TALENT_ROUTE, KANBAN_ROUTE } from "../../../providers/routes";
import UserService from "../../../services/user.service";
import { t } from "../../../translations/translation.service";
import "./index.css";
import FileStackService from "../../../services/filestack.service";

class CardTalentMinor extends React.Component<any> {
  id: string = this.props.id;
  item: any = this.props.item;
  state = {
    highCompatibleOpportunities: [] as any[],
    loading: false,
    policyAndSignatureImg: '' as string,
  };

  async getFileStackPolicyToUserImg(): Promise<void> {
    if (
        this.item &&
        this.item.talent &&
        this.item.talent.recruiter &&
        this.item.talent.recruiter.userImageUrl
      ) {
      const validURL = this.item.talent.recruiter.userImageUrl;
      try {
        if (this.getNewFileStackUrl(validURL)) {
          const policyUserImg = await FileStackService.getFileStackSecreteCode(
            validURL, false
          );
          this.setState({ policyAndSignatureImg: policyUserImg.data });
        }
      } catch(err: any) {
        console.log("Erro ao obter credenciais - ERROR: ", err);
      }
    }
  }

  setUrlImg(url: string): string {
    const { policyAndSignatureImg } = this.state;
    const splitedUrl = url.split('.com');
    splitedUrl.splice(1,0, '.com'+policyAndSignatureImg);
    const newUrl = splitedUrl.join('');
    return newUrl;
  }

  getNewFileStackUrl(url: string): boolean {
    const domain = url.split('.com/')[0].split('//')[1]
    if (domain !== 'cdn.filestackcontent') {
      return false;
    }
    return true;
  }

  async componentDidMount(): Promise<void> {
    await this.getFileStackPolicyToUserImg();
  }

  componentDidUpdate(prevProps: Readonly<any>, _prevState: Readonly<{}>): void {
    if (prevProps.item !== this.props.item) {
      this.item = this.props.item
    }
  }

  handleClick = () => {
    this.props.history.push(
      `${KANBAN_ROUTE}${CARD_TALENT_ROUTE}/${this.item._id}`
    );
  };

  verificarCompatibilidade = async (id: string) => {
    this.setState({ loading: true });
    const highCompatibleOpportunities = (
      await UserService.getHighCompatiblesByTalent(id)
    ).data;
    this.setState({ highCompatibleOpportunities, loading: false });
  };

  getLanguageLevel = (level: number) => {
    switch(level) {
      case 1:
        return t("Básico");
      case 2:
        return t("Intermediário");
      case 3:
        return t("Avançado");
      default:
        return t("Básico");
    }
  }

  render() {
    this.handleClick = this.handleClick.bind(this);
    const squadColor = this.item.talent?.category?._id;
    return (
      <summary
        id={this.item.id}
        style={{ cursor: "default" }}
        className={`card border-left-${squadColor} shadow h-100 py-2 mb-3`}
      >
        <div className={`squad-color-${squadColor} u-fs-sm mb-1 mt-2 ml-2`}>
          {this.item.talent?.category
            ? this.item.talent?.category.name +
              " - " +
              t(this.item.talent?.category.description)
            : t("Sem categoria")}
        </div>
        <div className="u-fs-lg u-semibold mb-1 ml-2">
          <Tooltip
            size="large"
            type="light"
            placement="top-end"
            content={
              <div>
                <span
                  style={{
                    textDecoration: 'underline',
                    fontWeight: 'bold',
                  }}
                >
                  {t("Pretensão")}:
                </span>
                <p>
                  {this.item.perfilUsuario?.pretensao?.moeda || t("Sem informação")} {" "} {this.item.perfilUsuario?.pretensao?.valor || " "}
                </p>
                <span
                  style={{
                    textDecoration: 'underline',
                    fontWeight: 'bold',
                  }}
                >
                  {t("Idiomas")}:
                </span>
                <ul>
                  {this.item.perfilUsuario?.digomais?.lista.map(
                    (info: any) => {
                      if (info.subclassif === "languages") {
                        const level = this.getLanguageLevel(info.level);
                        return <li>{info.nome} | {level}</li>
                      }
                      return <></>
                    }
                  )}
                </ul>
                <span
                  style={{
                    textDecoration: 'underline',
                    fontWeight: 'bold',
                  }}
                >
                  {t("Modelo de contratação")}:
                </span>
                <ul>
                  {this.item.hiringModel?.clt && <li>CLT</li>}
                  {this.item.hiringModel?.pj && <li>PJ</li>}
                  {this.item.hiringModel?.others && <li>{t("OUTROS")}</li>}
                </ul>
                <span
                  style={{
                    textDecoration: 'underline',
                    fontWeight: 'bold',
                  }}
                >
                  {t("Tipo de oportunidade")}:
                </span>
                <ul>
                  {this.item.opportunityType?.remote && <li>{t("Remota")}</li>}
                  {this.item.opportunityType?.hybrid && <li>{t("Híbrida")}</li>}
                  {this.item.opportunityType?.inPerson && <li>{t("Presencial")}</li>}
                </ul>
              </div>
            }
          >
            <a
              className="formControl talent-name u-fs-lg"
              rel="noopener noreferrer"
              href={`${KANBAN_ROUTE}${CARD_TALENT_ROUTE}/${this.item._id}`}
            >
              {this.item.displayName}
              {/*  */}
            </a>
          </Tooltip>
          {this.item.perfilUsuario?.interestLocations &&
            this.item.perfilUsuario?.interestLocations.length > 0 && (
              <Tooltip
                size="large"
                placement="top-end"
                content={
                  <>
                    <p className="mb-1">
                      {this.item.perfilUsuario?.interestLocations?.map(
                        (location: any, index: number) => (
                          <span
                            key={
                              index + " address: " + location.formatted_address
                            }
                          >
                            {location.formatted_address}{" "}
                            {index !==
                            this.item.perfilUsuario?.interestLocations.length -
                              1
                              ? "/"
                              : ""}{" "}
                          </span>
                        )
                      )}
                    </p>
                  </>
                }
              >
                <LocationIcon
                  className="ml-2"
                  style={{ width: 15, height: 15 }}
                />
              </Tooltip>
            )}
          <span className="hunted-days ml-2">
            {this.item.huntingTime} {t("dias")}
          </span>
        </div>

        <div className="row mr-0" style={{ minHeight: "80px" }}>
          <div className="col-6">
            <div className="d-flex align-items-end h-100 w-100">
              <div className="w-100 mb-2">
                {this.state.loading && <div className="ml-2">
                  <Skeleton className="mt-1" height="18px" />{" "}
                  <Skeleton className="mt-1" height="18px" width="75%" />
                  <Skeleton className="mt-1" height="18px" width="85%" />
                </div>}
                {this.state.highCompatibleOpportunities.length === 0 &&
                  !this.state.loading && (
                    <Button
                      variant="outline-warning"
                      className="ml-2 mb-n2 u-fs-sm"
                      onClick={() =>
                        this.verificarCompatibilidade(this.item._id)
                      }
                      style={{
                        borderRadius: "20px",
                        padding: "3px",
                        width: "150px",
                      }}
                    >
                      {t("Verificar Compatibilidade")}
                    </Button>
                  )}
                <div className="u-fs-sm ml-2 mt-2">
                  {this.state.highCompatibleOpportunities?.map(
                    (element: any, index: number) => {
                      return (
                        <p className="m-0" key={element.id + "&" + index}>
                          {element.title}
                        </p>
                      );
                    }
                  )}
                </div>
                {this.state.highCompatibleOpportunities?.length > 0 && (
                  <div className="pt-2 pb-1">
                    <span className="chip-feature u-fs-sm">
                      Alta Compatibilidade
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-6 pl-0">
            <div className="d-flex align-items-end h-100 w-100">
              <div className="w-100 mb-2">
                {this.item.inProgressOpportunities?.map(
                  (element: any, index: number) => (
                    <Tooltip
                      size="large"
                      placement="top-end"
                      zIndex={1}
                      key={index + "*7"}
                      content={
                        <>
                          <p className="mb-1">{element.title}</p>
                          <p className="mb-0">ID: {element.idOpportunity}</p>
                          <p className="mb-0">
                            {t("Cliente")}: {element.cliente}
                          </p>
                        </>
                      }
                    >
                      <div
                        className="mt-1 mb-0 compatibles-labels d-flex align-items-center"
                        style={{ height: "20px" }}
                        key={element.id + "#" + index}
                      >
                        <div className="pr-2">
                          {
                            element.currentStatus && element.currentStatus === 6 && (
                              <span className="talent-stepper victory">•</span>
                              )
                          }
                          {
                            element.currentStatus && element.currentStatus === 5 && (
                              <span className="talent-stepper defeat">•</span>
                              )
                          }
                          {["•", "•", "•", "•", "•"].map((step, index) => {
                            return index <= element.candidateStep * 1 ? (
                              <span
                                key={index + "&&"}
                                className="talent-stepper current"
                              >
                                •
                              </span>
                            ) : (
                              <span
                                className="talent-stepper"
                                key={index + "¬¬"}
                              >
                                •
                              </span>
                            );
                          })}
                        </div>
                        <span className="text-truncate">{element.title}</span>
                      </div>
                    </Tooltip>
                  )
                )}
              </div>
            </div>
          </div>
        </div>

        <div
          className="u-position-absolute mb-2 d-flex"
          style={{ top: "10px", right: "10px" }}
        >
          <div className="u-fs-small">
            <Avatar
              className="ml-2"
              size="small"
              style={{ border: "1px solid #4F6061" }}
            >
              <img
                src={
                  this.item.talent &&
                  this.item.talent.recruiter && 
                  this.item.talent.recruiter.userImageUrl &&
                  this.getNewFileStackUrl(this.item.talent.recruiter.userImageUrl)
                    ? this.setUrlImg(this.item.talent.recruiter.userImageUrl)
                    : this.item.talent?.recruiter?.userImageUrl
                }
                alt=""
              />
            </Avatar>
          </div>
        </div>
      </summary>
    );
  }
}

export default withRouter(CardTalentMinor);
