import i18n, { getTranslations } from './i18n';

export const t = (text: string) => {
    return (i18n.t(text));
};

export default class TranslationService {

    static getCurrentLanguage = (): string => i18n.language;

    static getLanguages = () => i18n.languages.filter((lang) => {
        let isinTranslationsModule = false;
        const current = TranslationService.getCurrentLanguage();
        Object.keys(getTranslations()).forEach(key => {
            if (key === lang && key !== current) {
                isinTranslationsModule = true;
            }
        })
        return isinTranslationsModule;
    });

    static dateFormat = (formatter: string, date: { date: Date }) => {
        return (i18n.t(formatter, date));
    };

    static changeLanguage = (lang: string) => i18n.changeLanguage(lang);
}
