import { t } from '../translations/translation.service';
import { OpportunityFields } from '../model/opportunity.model';

export const coinType = [
  { label: "  -  ", value: "  -  " },
  { label: "BRL", value: "BRL" },
  { label: "USD", value: "USD" },
  { label: "EUR", value: "EUR" },
];

export const priceRange = [
  { label: "  -  ", value: "  -  " },
  { label: "500 a 1k", value: "500 a 1k" },
  { label: "1 a 3k", value: "1 a 3k" },
  { label: "3 a 5k", value: "3 a 5k" },
  { label: "5 a 7k", value: "5 a 7k" },
  { label: "7 a 9k", value: "7 a 9k" },
  { label: "9 a 11k", value: "9 a 11k" },
  { label: "11 a 13k", value: "11 a 13k" },
  { label: "13 a 15k", value: "13 a 15k" },
  { label: "15 a 17k", value: "15 a 17k" },
  { label: "17 a 19k", value: "17 a 19k" },
  { label: "19 a 21k", value: "19 a 21k" },
  { label: "21 a 25k", value: "21 a 25k" },
  { label: "25 a 30k", value: "25 a 30k" },
  { label: "30k +", value: "30k +" },
];

export const getOpStatus = () => {
  const opStatus: OpportunityFields[] = [
    { value: t('Registered'), id: 0 },
    { value: t('Competing'), id: 1 },
    { value: t('Reserved'), id: 2 },
    { value: t('Dismissed'), id: 3 },
    { value: t('Refused'), id: 4 },
    { value: t('Quit'), id: 5 },
    { value: t('Selected'), id: 6 },
  ];
  return opStatus;
};

export const getOpCapture = () => {
  const opCapture: OpportunityFields[] = [
    { value: t('Indicação'), id: 'Indicação' },
    { value: t('Divulgação'), id: 'Divulgação' },
    { value: t('Busca'), id: 'Busca' },
  ];
  return opCapture;
};

export const getOpSource = () => {
  const opSource: OpportunityFields[] = [
    { value: t('Enter'), id: 'Enter' },
    { value: t('Ceviu'), id: 'Ceviu' },
    { value: t('Apinfo'), id: 'Apinfo' },
    { value: t('Catho'), id: 'Catho' },
    { value: t('Cliente'), id: 'Cliente' },
    { value: t('Eluminer'), id: 'Eluminer' },
    { value: t('Lista Yahoo'), id: 'Lista Yahoo' },
    { value: t('LinkedIn'), id: 'LinkedIn' },
    { value: t('e-Panelinha'), id: 'e-Panelinha' },
    { value: t('E-Mail'), id: 'E-Mail' },
    { value: t('Outros'), id: 'Outros' },
  ];
  return opSource;
};

export const getCurrentObject = (
  items: OpportunityFields[],
  id: number | string,
) => {
  return items.findIndex((x) => x.id === id);
};

export const showTitleLinkedinFn = (title: string, opportunityId: string): string => {
  const titleLinkedin = `${title} (${opportunityId})`;
  return titleLinkedin;
}

export const whatsAppOportunity = () => {
  const message = t("Olá! Acho que essa oportunidade de trabalho tem tudo a ver com você e resolvi compartilhar ");
  const encoded = encodeURIComponent(window.location.href);
  return 'https://wa.me/?text=' + message + encoded;
}

export const getInfoStep = (key: number) => {
  switch (key) {
    case 1:
      return {
        sectionTitle: t("Recrutamento"),
        sectionStep: t("Análise do CV pela Elumini"),
        sectionColor: {
          "--background-color": "#ED961C",
        } as React.CSSProperties,
        professionalStep: t("O profissional será entrevistado pela Elumini?"),
      };
    case 2:
      return {
        sectionTitle: t("Avaliação"),
        sectionStep: t("Entrevista pela Elumini"),
        sectionColor: {
          "--background-color": "#97539B",
        } as React.CSSProperties,
        professionalStep: t("O currículo será enviado pelo cliente?"),
      };
    case 3:
      return {
        sectionTitle: t("Seleção"),
        sectionStep: t("Análise do CV pelo Cliente"),
        sectionColor: {
          "--background-color": "#0076AA",
        } as React.CSSProperties,
        professionalStep: t("O profissional será entrevistado pelo cliente?"),
      };
    case 4:
      return {
        sectionTitle: t("Apresentação"),
        sectionStep: t("Entrevista pelo Cliente"),
        sectionColor: {
          "--background-color": "#00967C",
        } as React.CSSProperties,
        professionalStep: t("O profissional foi aprovado pelo cliente?"),
      };
    default:
      break;
  }
};

export const period = [
  { name: "Mensal", _id: "Mensal" },
  { name: "Trimestral", _id: "Trimestral"},
  { name: "Semestral", _id: "Semestral"},
  { name: "Anual", _id: "Anual"},
];

export const monthly = ['Jan','Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dec'];
export const quarterly = ['Jan-Mar', 'Abr-Jun', 'Jul-Set', 'Out-Dec'];
export const semester = ['Jan-jun', 'Jul-Dec'];
