import {
  CLICK_UPDATE_GROUP,
  CLICK_UPDATE_KEYWORDS,
  CLICK_UPDATE_RESPONSABLE,
  CLICK_UPDATE_SORT_LIST,
  CLICK_UPDATE_DATE_FILTER,
} from "../actions/actionTypes";

const initialState = {
  responsableFilter: undefined,
  groupFilter: undefined,
  sortFilterList: undefined,
  keywordsList: undefined,
  dateFilter: undefined,
};

export const talentFilterReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case CLICK_UPDATE_RESPONSABLE:
      return {
        ...state,
        responsableFilter: action.responsableFilter
      };
    case CLICK_UPDATE_GROUP:
      return {
        ...state,
        groupFilter: action.groupFilter
      };
    case CLICK_UPDATE_SORT_LIST:
      return {
        ...state,
        sortFilterList: action.sortFilterList,
      }
    case CLICK_UPDATE_KEYWORDS:
      return {
        ...state,
        keywordsList: action.keywordsList,
      }
    case CLICK_UPDATE_DATE_FILTER:
      return {
        ...state,
        dateFilter: action.dateFilter,
      }
    default:
      return state;
  }
};
