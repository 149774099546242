import { Range } from "@zendeskgarden/react-forms";
import { Tooltip } from "@zendeskgarden/react-tooltips";
import * as React from "react";
import { t } from "../../../translations/translation.service";
import { ReactComponent as CloseIcon } from "../pill/baseline-close-24px.svg";
import styles from "./index.module.css";

export type Props = {
  index: number;
  editLevel: boolean;
  onRemove: Function;
  chip: string;
  level: number;
  changeLevel: Function;
  changeLevelIndex: Function;
};

export default class PillChipSkill extends React.Component<Props> {

  mapSkillLevel = (level: number): string => {
    switch (level) {
      case 1:
        return t("Básico");
      case 2:
        return t("Intermediário");
      case 3:
        return t("Avançado");
      default:
        return t("Básico");
    }
  }

  render() {
    return (
      <div className={[styles["chip-input"], styles["cursor-pointer"]].join(" ")}>
        <div className={[styles["chip"], styles["show"], styles["skill"]].join(" ")}>
          <Tooltip
            type="dark"
            size="large"
            placement="top"
            content={<span>{t("Clique para alterar")}</span>}
          >
            <div className="d-flex">
              <div onClick={() => this.props.changeLevelIndex(this.props.index)}>
                <b>{this.props.chip}</b>
                &nbsp;|&nbsp;{this.mapSkillLevel(this.props.level)}
              </div>
              <CloseIcon className={[styles["close"], styles["cursor-pointer"]].join(" ")} onClick={() => this.props.onRemove(this.props.index)} />
            </div>
          </Tooltip>
          {this.props.editLevel && (<Range
            value={this.props.level - 1 || 0}
            onChange={(e) => this.props.changeLevel(this.props.chip, Number(e.target.value) + 1)}
            step={1}
            max={2}
            style={{paddingRight: '6px'}}
          />)}
        </div>
      </div>
    );
  }
}
