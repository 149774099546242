import React from "react";
import { Link } from "react-router-dom";
import { t } from "../../../translations/translation.service";
import Moment from "moment";
const dateFormat = "DD/MM/YYYY";

class ReportWarning extends React.Component<any> {
  setTimeZoneDefaut = (date: Date): Date => {
    date?.setHours(12, 0, 0, 0);
    date = new Date(
      date.valueOf() - date.getTimezoneOffset() * 60000
    );
    return date;
  };
  
  render() {
    return (
      <div>
        {this.props.warnings?.map((recruit: any) =>
          recruit.warnings.map((warning: any, index: number) => (
            <div
              key={index + "@#"}
              className=" bg-danger my-3 text-white p-3"
              style={{ borderRadius: "5px" }}
            >
              <Link
                to={{
                  pathname: `/opportunity/${warning.oppId}/professionals`,
                  state: "candidates",
                }}
                className="u-fs-md"
                rel="noopener noreferrer"
                target="_blank"
                style={{color:'white'}}
              >
                Oportinidade: {warning.oppname}<br></br>Candidato(a){" "}
                <b>{warning.candidate}</b> com data inválida na finalização do processo
                de <b>{t(warning.step)}</b> - Data de Inicio:{" "}
                {Moment(this.setTimeZoneDefaut(new Date(warning.startDate))).format(dateFormat)} | Data de
                término: {Moment(this.setTimeZoneDefaut(new Date(warning.endDate))).format(dateFormat)}
              </Link>
            </div>
          ))
        )}
      </div>
    );
  }
}

export default ReportWarning;
