import styled from "styled-components";
import { Col, Row } from "@zendeskgarden/react-grid";
// import { Accordion } from "@zendeskgarden/react-accordions";

// export const AccordionPanel = styled(Accordion.Panel).attrs(() => ({
//     className: 'parecer-comportamental-header'
// }))``;

export const AccordionPanel = styled.div.attrs(() => ({
    className: 'parecer-comportamental-header'
}))``;

export const AccordionRow = styled(Row)`
    padding: 10px;
`;

export const AccordionColHeader = styled(Col).attrs(() => ({
    size: 12,
    className: 'pb-2 text-right'
}))`
    border-bottom: solid 1px #869baf;
`;

export const ColSection = styled.section`
    padding-left: 10px;
`;

export const ColParagraph = styled.p`
    text-align: center;
    text-decoration: underline;
`;

export const ColParagraphDescription = styled.p`
    text-align: center;
`;

export const LineBottom = styled.div`
    border-bottom: solid 1px #869baf;
    margin-bottom: 2px;
`