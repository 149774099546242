import React from "react";
//import { Draggable } from "react-beautiful-dnd";
import CardMinor from "../card-minor";
import Fade from "react-reveal/Fade";

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "lightblue" : "",
  padding: 10
});

class DraggableBody extends React.Component {

  render() {

    return (
      <div
        ref={this.props.provided.innerRef}
        style={getListStyle(this.props.snapshot.isDraggingOver)}
      >
        {this.props.list.map((item, index) => (
          <Fade appear bottom spy={item} key={item.id}>
            <CardMinor key={item.id} item={item} id={this.props.id}/> 
          </Fade>
          /* <Draggable key={item.id} draggableId={item.id} index={index}>
            {(provided, snapshot) => (
              <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                <CardMinor item={item} />
              </div>
            )}
          </Draggable> */
        ))}
        {this.props.provided.placeholder}
      </div>
    );
  }
}

export default DraggableBody;
