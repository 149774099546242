import React from "react";
import { Link } from "react-router-dom";
import "./index.css";
import ColumnHeader from "../headers/column-header";
import { AccordionContainer } from "@zendeskgarden/container-accordion";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Moment from "moment";
import { Opportunity } from "../../../model/opportunity.model";
import { t } from "../../../translations/translation.service";
import { Tooltip as ZendeskTooltip } from "@zendeskgarden/react-tooltips";


const dateFormat = "DD/MM/YYYY";

const Accordion = ({
  expandable = true,
  collapsible = true,
  sections = [],
} = {}) => (
  <AccordionContainer expandable={expandable} collapsible={collapsible}>
    {({
      getHeaderProps,
      getTriggerProps,
      getPanelProps,
      expandedSections,
      disabledSections,
    }) => (
      <>
        <div style={{ width: "100%" }} className="accordion">
          {sections.map((item: any, index: number) => {
            const disabled = disabledSections.indexOf(index) !== -1;
            const hidden = expandedSections.indexOf(index) === -1;

            return (
              <div key={index}>
                <div {...getHeaderProps({ role: null, ariaLevel: null })}>
                  <button
                    {...getTriggerProps({
                      index,
                      role: null,
                      tabIndex: null,
                      disabled,
                      style: { width: "100%", textAlign: "inherit", paddingRight: 0, paddingLeft: 0, borderWidth: 0 },
                    })}
                  >
                    <div
                      className="d-flex flex-wrap"
                    >
                      <div className="col-lg-2 p-0">
                        <div className="p-0 col-12">
                          <div className="p-2 pt-4 column-header odd-cell u-ta-center">
                            <div style={{
                              justifyContent: "flex-start",
                              display: "flex",
                            }}>
                              <span className="content-value u-fs-lg pl-2">
                                {item.name}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-10 p-0">
                        <div className="row m-0">
                          <div className="recruitment p-0 col-sm-4 col-md-3 col-lg-2">
                            <div className="p-2 pt-4 column-header odd-cell u-ta-center">
                              <div className="">
                                <span className="content-value u-semibold u-fs-lg">
                                  {item.recruitment}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="evaluation p-0 col-sm-4 col-md-3 col-lg-2">
                            <div className="p-2 pt-4 column-header even-cell u-ta-center">
                              <div className="">
                                <span className="content-value u-semibold u-fs-lg">
                                  {item.evaluation}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="selection p-0 col-sm-4 col-md-3 col-lg-2">
                            <div className="p-2 pt-4 column-header odd-cell u-ta-center">
                              <div className="">
                                <span className="content-value u-semibold u-fs-lg">
                                  {item.selection}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="interview p-0 col-sm-4 col-md-3 col-lg-2">
                            <div className="p-2 pt-4 column-header even-cell u-ta-center">
                              <div className="">
                                <span className="content-value u-semibold u-fs-lg">
                                  {item.interview}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="closed p-0 col-sm-4 col-md-3 col-lg-2">
                            <div className="p-2 pt-4 column-header odd-cell u-ta-center">
                              <div className="">
                                <span className="content-value u-semibold u-fs-lg">
                                  {item.closed}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="p-0 col-sm-4 col-md-3 col-lg-2" style={{
                            backgroundColor: "#212529"
                          }}>
                            <div className="p-2 pt-4 column-header even-cell u-ta-center">
                              <div className="">
                                <span className="content-value u-semibold u-fs-lg" style={{
                                  color: "#fefefe"
                                }}>
                                  {item.opportunities.length}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </button>
                </div>
                <section
                  {...getPanelProps({
                    index,
                    role: null,
                    hidden,
                  })}
                >
                  {item.opportunities.map((opportunity: Opportunity, index2: number) => (
                    <div key={index2} className="row">
                      <div className="col-lg-2 p-0 pl-2">
                        <div className="p-0 col-12">
                          <div className="p-0 column-header u-ta-left">
                            <div className="">
                              <Link
                                to={{
                                  pathname: `/opportunity/${opportunity._id}/professionals`,
                                  state: "candidates"
                                }}
                                className="formControl u-fs-sm pl-2"
                                rel="noopener noreferrer"
                                // target="_blank"
                              >
                                <ZendeskTooltip
                                  type="light"
                                  size="large"
                                  placement="top"
                                  zIndex={1}
                                  key={index + "*7"}
                                  content={
                                    <>
                                      <p className="mb-0">
                                        ID: {opportunity.idOpportunity}
                                      </p>
                                    </>
                                  }
                                >
                                  <p className="pl-4">{opportunity.titulo} ({opportunity.quantidadeVagas === 0 || opportunity.isFinished ? "Encerrada" : opportunity.label === "1" ? "Ativa" : "Inativa"})</p>
                                </ZendeskTooltip>
                                {/* <a className="formControl" onClick={this.props.history.push(`/kanban/card-details/${opportunity._id}`)}> */}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* {console.log("opportunity.media ==========>", opportunity.media)} */}
                      <div className="col-lg-10 p-0 pr-2">
                        <div className="row m-0">
                          <div className="recruitment p-0 col-sm-4 col-md-3 col-lg-2">
                            <div className="p-0 pt-4 column-header  u-ta-center">
                              <div className="">
                                <span className="content-value u-semibold u-fs-sm"></span>
                                <span className="content-value u-fs-lg">
                                  {opportunity.fase === 0 || opportunity.fase === 1 ? opportunity.quantidadeVagas === 0 || opportunity.isFinished ? 'Encerrada' : 'Em andamento' : opportunity.media.recruitment}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="evaluation p-0 col-sm-4 col-md-3 col-lg-2">
                            <div className="p-0 pt-4 column-header  u-ta-center">
                              <div className="">
                                <span className="content-value u-semibold u-fs-sm"></span>
                                <span className="content-value u-fs-lg">
                                  {opportunity.fase === 2 ? opportunity.quantidadeVagas === 0 || opportunity.isFinished ? 'Encerrada' : 'Em andamento' : opportunity.media.evaluation}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="selection p-0 col-sm-4 col-md-3 col-lg-2">
                            <div className="p-0 pt-4 column-header  u-ta-center">
                              <div className="">
                                <span className="content-value u-semibold u-fs-sm"></span>
                                <span className="content-value u-fs-lg">
                                  {opportunity.fase === 3 ? opportunity.quantidadeVagas === 0 || opportunity.isFinished ? 'Encerrada' : 'Em andamento' : opportunity.media.selection}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="interview p-0 col-sm-4 col-md-3 col-lg-2">
                            <div className="p-0 pt-4 column-header  u-ta-center">
                              <div className="">
                                <span className="content-value u-semibold u-fs-sm"></span>
                                <span className="content-value u-fs-lg">
                                  {opportunity.fase === 4 && opportunity.media.closed === 0 ? opportunity.quantidadeVagas === 0 || opportunity.isFinished ? 'Encerrada' : 'Em andamento' : opportunity.media.interview}
                                </span>
                              </div>
                            </div>
                          </div>
                                  
                          <div className="closed p-0 col-sm-4 col-md-3 col-lg-2">
                            <div className="p-0 pt-4 column-header  u-ta-center">
                              <div className="">
                                <span className="content-value u-semibold u-fs-sm"></span>
                                <span className="content-value u-fs-lg">
                                  {opportunity.media.closed}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="opportunitiesLength p-0 col-sm-4 col-md-3 col-lg-2">
                            <div className="p-0 pt-1 column-header  u-ta-center">
                              <OverlayTrigger
                                key={opportunity.id}
                                placement={"left"}
                                overlay={
                                  <Tooltip id={`tooltip-${opportunity.id}`}>
                                    <div className="pt-2 pb-2 pl-3 pr-3">
                                      <p className="u-fs-sm mb-1">
                                        {t("Vitórias")} ({opportunity.victories.length}
                                        )
                                      </p>
                                      {opportunity.victories.map(
                                        (date: any, index: number) => (
                                          <p
                                            className="u-fs-sm mb-1"
                                            key={index}
                                          >
                                            {Moment(date).format(dateFormat)}
                                          </p>
                                        )
                                      )}
                                    </div>
                                  </Tooltip>
                                }
                              >
                                <div>
                                  <span className="content-value u-fs-sm">
                                    {t("Posições a preencher")} -{" "}
                                    {opportunity.quantidadeVagas}
                                  </span>
                                  <br></br>
                                  <span className="content-value u-fs-sm">
                                    {t("Vitória")}(s) - {opportunity.victories.length}
                                  </span>
                                  <br></br>
                                  <span className="content-value u-fs-sm">
                                    {t("Derrota")}(s) - {opportunity.defeats}
                                  </span>
                                </div>
                              </OverlayTrigger>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </section>
              </div>
            );
          })}
        </div>
      </>
    )}
  </AccordionContainer>
);

Accordion.bind(this);

class RecruitmentReport extends React.Component<any> {
  render() {
    const { recruitmentData } = this.props;
    return (
      // https://garden.zendesk.com/react-containers/storybook/?path=/story/accordion-container--useaccordion
      <div className="row mt-1">
        <div className="col-lg-2">
          <div className="row">
            <div className="p-0 col-12">
              <div className="col column-header responsable-header u-ta-left">
                <div className="column-content">
                  <div className="title responsable">
                    <p className="u-semibold u-fs-lg mb-0 ">
                      {this.props.filter && t(this.props.filter.value)}
                    </p>
                  </div>
                </div>
              </div>
              {/* {recruitmentData.map((item, index) => (
                <div key={index} className="p-0 pt-4 column-header odd-cell u-ta-center  dividing-2px">
                  <div className="">
                    <span className="content-value u-semibold u-fs-lg">{item.name}</span>
                  </div>
                </div>
              ))} */}
            </div>
          </div>
        </div>
        <div className="col-lg-10">
          <div className="row">
            <div className="recruitment p-0 col-sm-4 col-md-3 col-lg-2">
              <ColumnHeader
                type="recruitment"
                subtitle={this.props.subtitle}
                title={t("Recrutamento")}
              />
              {/* {recruitmentData.map((item, index) => (
                <div key={index} className="p-0 pt-4 column-header odd-cell u-ta-center  dividing-2px">
                  <div className="">
                    <span className="content-value u-semibold u-fs-xl">{item.recruitment}</span>
                  </div>
                </div>
              ))} */}
            </div>

            <div className="evaluation p-0 col-sm-4 col-md-3 col-lg-2">
              <ColumnHeader
                type="evaluation"
                subtitle={this.props.subtitle}
                title={t("Avaliação")}
              />
              {/* {recruitmentData.map((item, index) => (
                <div key={index} className="p-0 pt-4 column-header even-cell u-ta-center  dividing-2px">
                  <div className="">
                    <span className="content-value u-semibold u-fs-xl">{item.evaluation}</span>
                  </div>
                </div>
              ))} */}
            </div>

            <div className="selection p-0 col-sm-4 col-md-3 col-lg-2">
              <ColumnHeader
                type="selection"
                subtitle={this.props.subtitle}
                title={t("Seleção")}
              />
              {/* {recruitmentData.map((item, index) => (
                <div key={index} className="p-0 pt-4 column-header odd-cell u-ta-center  dividing-2px">
                  <div className="">
                    <span className="content-value u-semibold u-fs-xl">{item.selection}</span>
                  </div>
                </div>
              ))} */}
            </div>

            <div className="interview p-0 col-sm-4 col-md-3 col-lg-2">
              <ColumnHeader
                type="interview"
                subtitle={this.props.subtitle}
                title={t("Apresentação")}
              />
              {/* {recruitmentData.map((item, index) => (
                <div key={index} className="p-0 pt-4 column-header even-cell u-ta-center  dividing-2px">
                  <div className="">
                    <span className="content-value u-semibold u-fs-xl">{item.interview}</span>
                  </div>
                </div>
              ))} */}
            </div>

            <div className="closed p-0 col-sm-4 col-md-3 col-lg-2">
              <ColumnHeader
                type="closed"
                subtitle={this.props.subtitle}
                title={t("Encerramento")}
              />
              {/* {recruitmentData.map((item, index) => (
                <div key={index} className="p-0 pt-4 column-header odd-cell u-ta-center  dividing-2px">
                  <div className="">
                    <span className="content-value u-semibold u-fs-xl">{item.closed}</span>
                  </div>
                </div>
              ))} */}
            </div>

            <div className="opportunitiesLength p-0 col-sm-4 col-md-3 col-lg-2">
              <ColumnHeader
                type="opportunitiesLength"
                subtitle={t("Total de oportunidades ")}
                title={t("no período")}
              />
              {/* {recruitmentData.map((item, index) => (
                <div key={index} className="p-0 pt-4 column-header even-cell u-ta-center  dividing-2px">
                  <div className="">
                    <span className="content-value u-semibold u-fs-xl">{item.opportunities.length}</span>
                  </div>
                </div>
              ))} */}
            </div>
          </div>
        </div>

        <Accordion
          expandable={true}
          collapsible={true}
          sections={recruitmentData}
        />
      </div>
    );
  }
}

export default RecruitmentReport;
