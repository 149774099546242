import { Col } from "@zendeskgarden/react-grid";
import { Component } from "react";
import { Row } from "react-bootstrap";
import { LG, Span } from "@zendeskgarden/react-typography";
import { t } from "../../../../../../translations/translation.service";
import { Label, Hint, Range, Field, Textarea, Input, Message } from "@zendeskgarden/react-forms";
import OpportunityService from "../../../../../../services/opportunity.service";
import { avaliacaoUsuario } from "../../../../../../model/user.model";
import { ContainerSkills, ContainerDivSkills, SpanSkill, MainContainer } from './styles';
import { Datepicker } from "@zendeskgarden/react-datepickers";
import { AuthenticationService } from "../../../../../../services/auth.service";
import { dateHelper } from "../../../../../../util/date";
import { AnyAction, Dispatch, bindActionCreators } from "redux";
import { getValidateValue } from '../../../../../../actions'
import { connect } from "react-redux";

const dateFormatter = dateHelper("pt-BR");

class OpportunityCriteria extends Component<{
  opportunityId: string;
  userId: string | undefined;
  avaliacao?: any;
  dataAvaliationCriteria: any,
  editCriteria?: avaliacaoUsuario,
  getValidateValue: any;
  validateValue: boolean;
}> {
  state = {
    criteria: [] as any,
    avaliation: {
      avaliacao: this.props.avaliacao,
      opportunity: undefined,
      user: AuthenticationService.getUser(),
      conteudo: "",
      created: new Date(),
      _id: this.props.userId,
      resultado: 0,
    },
  };

  constructor(props: any) {
    super(props);
    this.handleChangeCriteria = this.handleChangeCriteria.bind(this);
    this.handleDate = this.handleDate.bind(this);
  }

  async componentDidMount() {
    if (this.props.editCriteria) {
      const { opportunity: { avaliationCriteria } } = this.props.editCriteria;
      const created = new Date(this.props.editCriteria.created)
      this.setState({
        avaliation: {...this.props.editCriteria, created},
        criteria: avaliationCriteria
      }, () => {
        this.props.dataAvaliationCriteria(this.state.avaliation);
      })
    } else {
      const { data } = await OpportunityService.getOpportunityLiteKanban(this.props.opportunityId);
      this.setState({ criteria: data?.criteriosMatch }, () => {
        const avaliationCriteria = this.setOpportunity(this.state.criteria);
        const opportunity = {
          id: this.props.opportunityId,
          avaliationCriteria,
        }
        this.setState({
          avaliation: {
            ...this.state.avaliation, opportunity
          }
        })
      });
    }
  }

  handleDate = (date: Date): void => {
    this.setState({
      avaliation: { ...this.state.avaliation, created: date },
    }, () => {
      this.props.dataAvaliationCriteria(this.state.avaliation);
    });
  }

  handleChangeCriteria = (e: any) => {
    const name = e.target.name;
    this.setState({
      avaliation: {
        ...this.state.avaliation, [name]: e.target.value
      }
    }, () => {
      if (name !== "resultado") {
        if (this.state.avaliation.conteudo.length > 0) {
          this.props.getValidateValue(false);
        } else {
          this.props.getValidateValue(true);
        }
      }
      this.props.dataAvaliationCriteria(this.state.avaliation);
    })
  };

  setOpportunity(criteria: any): any {
    const avaliationCriteria = criteria.map((item: any) => {
      let properties = {
        name: item.hasOwnProperty("name") ? item.name : item.nome,
        peso: item.peso,
      };

      return properties;
    })
    return avaliationCriteria;
  }

  render() {
    const { validateValue } = this.props;
    return (<>
      {this.props.editCriteria ? <></> : (
        <Row className="mb-1">
          <Col textAlign="center">
            <Field>
              <LG>
                <Span isBold>Avaliação escolhida:</Span>{" "}
                <Span>Parecer Técnico</Span>{" "}
              </LG>
            </Field>
          </Col>
        </Row>
      )}
      <MainContainer>
        <ContainerDivSkills>
          <SpanSkill>
            <u>{t("Parâmetros Necessários")}</u>
          </SpanSkill>
          <ContainerSkills>
            {this.state.criteria.map((criterion: any, index: any) => {
              if (criterion.peso === 3) {
                return (
                  <SpanSkill key={index}>
                    <Hint>
                      {
                        criterion.hasOwnProperty("name")
                        ? criterion.name
                        : criterion.nome
                      }
                    </Hint>
                  </SpanSkill>
                );
              } else { return ""; }
            })}
          </ContainerSkills>
        </ContainerDivSkills>
        <ContainerDivSkills>
          <SpanSkill>
            <u>{t("Parâmetros Desejáveis")}</u>
          </SpanSkill>
          <ContainerSkills>
            {this.state.criteria.map((criterion: any, index: any) => {
              if (criterion.peso === 2 || criterion.peso === 1) {
                return (
                  <SpanSkill key={index}>
                    <Hint className="hint-button">
                      {
                        criterion.hasOwnProperty("name")
                        ? criterion.name
                          : criterion.nome
                      }
                    </Hint>
                  </SpanSkill>
                );
              } else { return ""; }
            })}
          </ContainerSkills>
        </ContainerDivSkills>
      </MainContainer>
      <Field className="mb-4">
        <Label>{t("Data da avaliação")}</Label>
        <Datepicker
          value={this.state.avaliation.created}
          onChange={this.handleDate}
          formatDate={(date) => dateFormatter.format(date)}
          isCompact
        >
          <Input isCompact />
        </Datepicker>
      </Field>
      <Field className="mb-4">
        <Label>Nota</Label>
        <Hint>
          O usuário tem nota {this.state.avaliation.resultado} nessa
          avaliação.
        </Hint>
        <Range
          step={1}
          max={10}
          value={this.state.avaliation.resultado}
          name="resultado"
          onChange={(e) => this.handleChangeCriteria(e)}
        />
      </Field>
      <Field>
        <Label>Avaliação</Label>
        <Textarea
          value={this.state.avaliation.conteudo}
          onChange={(e) => this.handleChangeCriteria(e)}
          name="conteudo"
          minRows={2}
          maxRows={12}
          validation={
            validateValue &&
            this.state.avaliation.conteudo.length < 1
              ? "error"
              : undefined
          }
        />
        {
          validateValue &&
          this.state.avaliation.conteudo.length < 1 && (
            <Message
              validation={validateValue ? "error" : undefined}
            >
              {t("Preencha o campo Avaliação")}
            </Message>
          )
        }
      </Field>
    </>)   
  }
}

const mapStateToProps = (store: {
  parecerTecnicoReducer: { validateValue: boolean };
}) => ({
  validateValue: store.parecerTecnicoReducer.validateValue,
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators(
  {
    getValidateValue,
  },
  dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(OpportunityCriteria);