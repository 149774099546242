import { Field, Input } from "@zendeskgarden/react-forms";
import { Row } from "@zendeskgarden/react-grid";
import { Button } from "react-bootstrap";
import "react-phone-number-input/style.css";
import { t } from "../../../translations/translation.service";
import style from "./index.module.scss";

export const tabProfileFour = (self: any): any => {
  return (
    <div className="row">
      <div className="col-12 col-md-8">
        <Field className="mt-2">
          <Input
            onChange={self.handleInputIndication}
            name="indicadoPor"
            placeholder={t("Vim por indicação de")}
            value={self.state.user.dadosPessoais?.indicadoPor || ''}
          ></Input>
        </Field>
      </div>
      <div className="col-12 col-md-4">
        <p className="mb-auto" style={{ minHeight: "400px" }}>
          {t(
            "Quando um@ profissional vem através de indicação e é contratado, gostamos de agradecer a pessoa que indicou. Caso alguém tenha te indicado, conta pra gente?"
          )}
        </p>
        <Row className="w-100 d-flex justify-content-end">
          {self.state.step === "welcome" ? (
            <Button
              className={style["next-step"]}
              onClick={() => {
                self.next("tab-1");
                self.setState({ step: 'finished' });
              }}
            >
              {t("Finalizar")}
            </Button>
          ) : (
            <Button
              className={style["next-step"]}
              onClick={() => self.next("tab-4")}
            >
              {t("Salvar")}
            </Button>
          )}
        </Row>
      </div>
    </div>
  );
};
