import {
  CHANGE_LANGUAGE
} from "../actions/actionTypes";

const initialState = {
  currentLanguage: ""
};

export const changeLanguageReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      return {
        ...state,
        currentLanguage: action.currentLanguage
      };
    default:
      return state;
  }
};
