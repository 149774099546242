import React from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { connect } from "react-redux";
import DroppableColumn from "../../components/kanban/droppable";
import ColumnHeader from "../../components/kanban/header/column-header";
import FiltersHeader from "../../components/kanban/header/filters-header";
import Loading from "../../components/loading";
import amountIcon from "../../images/icons/amount-icon.png";
import { Opportunity, originalOpportunities } from "../../model/opportunity.model";
import OpportunityService from "../../services/opportunity.service";
import { t } from "../../translations/translation.service";
import "./index.css";
import { Modal } from "react-bootstrap";
import moment from "moment";


const reorder = (list: any, startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
/**
 * Moves an item from one list to another list.
 */
const move = (
  source: any,
  destination: any,
  droppableSource: any,
  droppableDestination: any
) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {} as any;
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

class Kanban extends React.Component<any> {
  recruitment: any;
  revision: any;
  inProgress: any;
  evaluation: any;
  selection: any;
  interview: any;
  closed: any;

  state = {
    revision: [],
    recruitment: [],
    evaluation: [],
    selection: [],
    interview: [],
    originalOpportunities: {} as originalOpportunities,
    closed: [],
    loading: true,
    published: [],
    inProgress: [],
    victories: 0,
    defeats: 0,
    hasOpportunities: false,
    recruitmentAmount: 0,
    revisionAmount: 0,
    evaluationAmount: 0,
    selectionAmount: 0,
    interviewAmount: 0,
    isCreated: false,
    openModalVictories: false,
  } as any;

  async componentDidMount() {
    this.setState({ loading: true });
    await this.createKanban();
    this.setState({ loading: false });
    this.updateKanban();
  }

  componentDidUpdate(prevProps: any) {
    if ( this.state.isCreated &&
      (prevProps.squadFilter !== this.props.squadFilter ||
      prevProps.responsableFilter !== this.props.responsableFilter ||
      prevProps.priorityButton !== this.props.priorityButton)
    ) {
      this.updateKanban();
    }
  }

  highPrioritySort(a: Opportunity, b: Opportunity) {
    return a.selectedPriority.value - b.selectedPriority.value;
  }

  lowPrioritySort(a: Opportunity, b: Opportunity) {
    return b.selectedPriority.value - a.selectedPriority.value;
  }

  mediumPrioritySort(opportunities: Array<Opportunity>): Array<Opportunity> {
    const mediumPriority: Array<Opportunity> = [];
    const otherPriority: Array<Opportunity> = [];
    opportunities.forEach((opportunity: Opportunity) => {
      if(opportunity.selectedPriority.value === 2) {
        mediumPriority.push(opportunity);
      } else {
        otherPriority.push(opportunity);
      }
    });
    return [...mediumPriority, ...otherPriority]
  }

  async createKanban() {
    this.recruitment = [];
    this.revision = [];
    this.inProgress = [];
    this.closed = [];
    let recruitmentAmount: number = 0;
    let revisionAmount: number = 0;
    let victories: number = 0;
    let defeats: number = 0;

    const recruitment = (await OpportunityService.getOppRecruitment()).data; // Serão redirecionadas como em recrutamento
    const revision = (await OpportunityService.getOppRevision()).data; // Serão redirecionadas como em revisão
    const inProgress = (await OpportunityService.getOpInProgress()).data;

    recruitment.forEach((opportunity: Opportunity) => {
      if (opportunity.showInKanban) {
        let opp = {
          id: opportunity._id,
          title: opportunity.titulo,
          client:
            opportunity.cliente === ""
              ? "Cliente Não Informado"
              : opportunity.cliente,
          responsable: opportunity.user,
          amount: opportunity.quantidadeVagas,
          step: opportunity.fase,
          label: opportunity.label,
          squad: opportunity.squad,
          victories: opportunity.victories,
          defeats: opportunity.defeats,
          periodInCurrentStep: opportunity.periodInCurrentStep,
          selectedPriority: opportunity.selectedPriority,
          created: opportunity.created,
        };
        this.recruitment.push(opp);
        recruitmentAmount += opportunity.quantidadeVagas;
      }
    });

    revision.forEach((opportunity: Opportunity) => {
      if (
        opportunity.quantidadeVagas === 0 &&
        (opportunity.victories > 0 || opportunity.defeats > 0)
      ) {
        this.closed.push(opportunity);
        if (opportunity.victories > 0) {
          victories += opportunity.victories;
        }
        if (opportunity.defeats > 0) {
          defeats += opportunity.defeats;
        }
      } else {
        if (opportunity.victories > 0 || opportunity.defeats > 0) {
          this.closed.push(opportunity);
          if (opportunity.victories > 0) {
            victories += opportunity.victories;
          }
          if (opportunity.defeats > 0) {
            defeats += opportunity.defeats;
          }
        } else {
          let opp = {
            id: opportunity._id,
            title: opportunity.titulo,
            client:
              opportunity.cliente === ""
                ? "Cliente Não Informado"
                : opportunity.cliente,
            responsable: opportunity.user,
            amount: opportunity.quantidadeVagas,
            step: opportunity.fase,
            label: opportunity.label,
            squad: opportunity.squad,
            victories: opportunity.victories,
            defeats: opportunity.defeats,
            periodInCurrentStep: opportunity.periodInCurrentStep,
            selectedPriority: opportunity.selectedPriority,
            created: opportunity.created,
          };
          this.revision.push(opp);
          revisionAmount += opportunity.quantidadeVagas;
        }
      }
    });

    inProgress.forEach((opportunity: Opportunity) => {
      if (opportunity.showInKanban) {
        let opp = {
          id: opportunity._id,
          title: opportunity.titulo,
          client:
            opportunity.cliente === ""
              ? "Cliente Não Informado"
              : opportunity.cliente,
          responsable: opportunity.user,
          amount: opportunity.quantidadeVagas,
          step: opportunity.fase,
          label: opportunity.label,
          squad: opportunity.squad,
          victories: opportunity.victories,
          defeats: opportunity.defeats,
          periodInCurrentStep: opportunity.periodInCurrentStep,
          selectedPriority: opportunity.selectedPriority,
          created: opportunity.created,
          candidatos: opportunity.candidatos,
          datesDefeats: opportunity.datesDefeats,
        };
        if (
          opportunity.quantidadeVagas === 0 &&
          (opportunity.victories > 0 || opportunity.defeats > 0)
        ) {
          this.closed.push(opp);
          if (opportunity.victories > 0) {
            victories += opportunity.victories;
          }
          if (opportunity.defeats > 0) {
            defeats += opportunity.defeats;
          }
        } else {
          if (opportunity.victories > 0 || opportunity.defeats > 0) {
            if(opportunity.quantidadeVagas > 0) {
              this.inProgress.push(opp);
            }
            this.closed.push(opp);
            if (opportunity.victories > 0) {
              victories += opportunity.victories;
            }
            if (opportunity.defeats > 0) {
              defeats += opportunity.defeats;
            }
          } else {
            this.inProgress.push(opp);
          }
        }
      }
    });

    this.setState({
      revision: this.revision,
      recruitment: this.recruitment,
      inProgress: this.inProgress,
      closed: this.closed,
      originalOpportunities: {
        revision: this.revision,
        recruitment: this.recruitment,
        inProgress: this.inProgress,
        closed: this.closed
      },
      recruitmentAmount: recruitmentAmount,
      revisionAmount: revisionAmount,
      victories: victories,
      defeats: defeats,
      isCreated: true,
    });
  }

  updateKanban() {
    console.log('update kanban começou');
    const { squadFilter, responsableFilter, priorityButton } = this.props;
    let opportunities = {
      revision: this.state.originalOpportunities.revision,
      recruitment: this.state.originalOpportunities.recruitment,
      inProgress: this.state.originalOpportunities.inProgress,
      closed: this.state.originalOpportunities.closed
    };

    this.applyFilters(opportunities, squadFilter, responsableFilter, priorityButton);
    this.applySteps(
      opportunities.revision,
      opportunities.recruitment,
      opportunities.inProgress,
      opportunities.closed
    );
  }

  applyFilters = (
    filtered: any,
    squadFilter: any,
    responsableFilter: any,
    priorityButton: any
  ) => {
    filtered.revision = filtered.revision && filtered.revision.filter((opp: any) => {
      if (
        squadFilter._id &&
        squadFilter._id !== "-1" &&
        (!responsableFilter._id || responsableFilter._id === "-1")
      ) {
        return opp.squad && opp.squad._id === squadFilter._id;
      }
      if (
        (!squadFilter._id || squadFilter._id === "-1") &&
        responsableFilter._id &&
        responsableFilter._id !== "-1"
      ) {
        return opp.responsable?._id === responsableFilter._id;
      }
      if (
        squadFilter._id &&
        squadFilter._id !== "-1" &&
        responsableFilter._id &&
        responsableFilter._id !== "-1"
      ) {
        return (
          opp.squad && opp.squad._id === squadFilter._id &&
          opp.responsable._id === responsableFilter._id
        );
      }
      return true;
    });
    filtered.recruitment = filtered.recruitment && filtered.recruitment.filter((opp: any) => {
      if (
        squadFilter._id &&
        squadFilter._id !== "-1" &&
        (!responsableFilter._id || responsableFilter._id === "-1")
      ) {
        return opp.squad && opp.squad._id === squadFilter._id;
      }
      if (
        (!squadFilter._id || squadFilter._id === "-1") &&
        responsableFilter._id &&
        responsableFilter._id !== "-1"
      ) {
        return opp.responsable?._id === responsableFilter._id;
      }
      if (
        squadFilter._id &&
        squadFilter._id !== "-1" &&
        responsableFilter._id &&
        responsableFilter._id !== "-1"
      ) {
        return (
          opp.squad && opp.squad._id === squadFilter._id &&
          opp.responsable._id === responsableFilter._id
        );
      }
      return true;
    });
    filtered.inProgress = filtered.inProgress && filtered.inProgress.filter((opp: any) => {
      if (
        squadFilter._id &&
        squadFilter._id !== "-1" &&
        (!responsableFilter._id || responsableFilter._id === "-1")
      ) {
        return opp.squad && opp.squad._id === squadFilter._id;
      }
      if (
        (!squadFilter._id || squadFilter._id === "-1") &&
        responsableFilter._id &&
        responsableFilter._id !== "-1"
      ) {
        return opp.responsable?._id === responsableFilter._id;
      }
      if (
        squadFilter._id &&
        squadFilter._id !== "-1" &&
        responsableFilter._id &&
        responsableFilter._id !== "-1"
      ) {
        return (
          opp.squad && opp.squad._id === squadFilter._id &&
          opp.responsable._id === responsableFilter._id
        );
      }
      return true;
    });
    filtered.closed = filtered.closed && filtered.closed.filter((opp: any) => {
      if (
        squadFilter._id &&
        squadFilter._id !== "-1" &&
        (!responsableFilter._id || responsableFilter._id === "-1")
      ) {
        return opp.squad && opp.squad._id === squadFilter._id;
      }
      if (
        (!squadFilter._id || squadFilter._id === "-1") &&
        responsableFilter._id &&
        responsableFilter._id !== "-1"
      ) {
        return opp.responsable?._id === responsableFilter._id;
      }
      if (
        squadFilter._id &&
        squadFilter._id !== "-1" &&
        responsableFilter._id &&
        responsableFilter._id !== "-1"
      ) {
        return (
          opp.squad && opp.squad._id === squadFilter._id &&
          opp.responsable._id === responsableFilter._id
        );
      }
      return true;
    });
    switch(priorityButton.value) {
      case 1:
        return (
          filtered.revision.sort(this.highPrioritySort),
          filtered.recruitment.sort(this.highPrioritySort),
          filtered.inProgress.sort(this.highPrioritySort),
          filtered.closed.sort(this.highPrioritySort)
        )
      case 2:
        return (
          filtered.revision = this.mediumPrioritySort(filtered.revision),
          filtered.recruitment = this.mediumPrioritySort(filtered.recruitment),
          filtered.inProgress = this.mediumPrioritySort(filtered.inProgress),
          filtered.closed = this.mediumPrioritySort(filtered.closed)
        )
      case 3:
        return (
          filtered.revision.sort(this.lowPrioritySort),
          filtered.recruitment.sort(this.lowPrioritySort),
          filtered.inProgress.sort(this.lowPrioritySort),
          filtered.closed.sort(this.lowPrioritySort)
        )
      default:
        return true;
    }
  };

  applySteps = (revision: any, recruitment: any, inProgress: any, closed: any) => {
    this.revision = revision;
    this.recruitment = recruitment;
    this.evaluation = [];
    this.selection = [];
    this.interview = [];
    this.closed = closed;
    let revisionAmount = revision && revision.reduce( function(tot: number, opp: any) {
      return tot + opp.amount;
    }, 0);
    let recruitmentAmount = recruitment && recruitment.reduce( function(tot: number, opp: any) {
      return tot + opp.amount;
    }, 0);
    let evaluationAmount: number = 0;
    let selectionAmount: number = 0;
    let interviewAmount: number = 0;
    let inactiveEvaluation: number = 0;
    let inactiveSelection: number = 0;
    let inactiveInterview: number = 0;
    let inactiveClose: number = 0;
    let inactiveRevision: number = 0;
    let inactiveRecruitment: number = 0;
    let victories = this.state.victories;
    let defeats = this.state.victories;

    inProgress &&inProgress.forEach((opp: any) => {
        switch (opp.step) {
          // case 0: //aberto
          //   this.recruitment.push(opp);
          //   break;
          // case 1: //triagem
          //   this.recruitment.push(opp);
          //   break;
          case 2: //Avaliacao
            this.evaluation.push(opp);
            evaluationAmount += opp.amount;
            if(opp.label === "2") inactiveEvaluation ++;
            break;
          case 3: //Seleção
            this.selection.push(opp);
            selectionAmount += opp.amount;
            if(opp.label === "2") inactiveSelection ++;
            break;
          case 4: //Apresentação
            this.interview.push(opp);
            interviewAmount += opp.amount;
            if(opp.label === "2") inactiveInterview ++;
            break;
          default:
            break;
        }
    });

    const opportunityInfoVictory = this.closed.map((opp: any) => {
      const victories = opp.candidatos.filter((candidato: any) => {
        return candidato.dateVictory !== null;
      })
      let info = {
        title: opp.title,
        squad: opp.squad && opp.squad.name ? opp.squad.name : "Não informado",
        victory: victories.map((victory: any) => victory.dateVictory),
      };
      return info;
    });

    const opportunityInfoDefeat = this.closed.map((opp: any) => {
      const defeat = opp.datesDefeats.filter((date: any) =>  date);
      if (defeat.length > 0) {
        let info = {
          title: opp.title,
          squad: opp.squad && opp.squad.name ? opp.squad.name : "Não informado",
          defeats: defeat
        };
        return info;
      }
      return undefined;
    });

    const filterOpportunityInfoVictory = opportunityInfoVictory.filter((opp: any) => opp.victory.length > 0)
    const filterOpportunityInfoDefeat = opportunityInfoDefeat.filter((opp: any) => opp !== undefined)

    this.closed.forEach((opp: any) => {
      if (opp.label === "2") {
        inactiveClose ++;
      }
    })

    this.revision.forEach((opp: any) => {
      if (opp.label === "2") {
        inactiveRevision ++;
      }
    });

    this.recruitment.forEach((opp: any) => {
      if (opp.label === "2") {
        inactiveRecruitment ++;
      }
    }); 
    
    this.setState({
      revision: this.revision,
      recruitment: this.recruitment,
      evaluation: this.evaluation,
      selection: this.selection,
      interview: this.interview,
      closed: this.closed,
      loading: false,
      victories: victories,
      defeats: defeats,
      revisionAmount: revisionAmount,
      recruitmentAmount: recruitmentAmount,
      evaluationAmount: evaluationAmount,
      selectionAmount: selectionAmount,
      interviewAmount: interviewAmount,
      hasOpportunities:
        revision.length > 0 || recruitment.length > 0 || inProgress.length > 0,
      inactiveEvaluation: inactiveEvaluation,
      inactiveSelection: inactiveSelection,
      inactiveInterview: inactiveInterview,
      inactiveRecruitment: inactiveRecruitment,
      inactiveRevision: inactiveRevision,
      inactiveClose: inactiveClose,
      allVictories: filterOpportunityInfoVictory,
      allDefeats: filterOpportunityInfoDefeat
    });
  };

  getList = (id: any) => this.state[id];

  onDragEnd = (result: any) => {
    const { source, destination } = result;
    // dropped outside the list
    if (!destination) {
      return;
    }
    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        this.getList(source.droppableId),
        source.index,
        destination.index
      );
      let state = { [destination.droppableId]: items };
      this.setState(state);
    } else {
      const _result = move(
        this.getList(source.droppableId),
        this.getList(destination.droppableId),
        source,
        destination
      );
      this.setState({ [source.droppableId]: _result[source.droppableId] });
      this.setState({
        [destination.droppableId]: _result[destination.droppableId],
      });
    }
  };

  render() {
    return (
      <div className="container">
        <Loading show={this.state.loading} />
        <FiltersHeader/>
        <div className="row mt-1">
          <DragDropContext
            onDragEnd={this.onDragEnd}
          >
            {!this.state.hasOpportunities && (
              <p className="no-cards-message u-fs-xxl">
                {t("Nenhuma oportunidade aqui.")}
              </p>
            )}

            <div className="revision column-body col-sm-4 col-md-3 col-lg-2">
              <ColumnHeader
                type="revision"
                title={t("Revisão")}
                count={this.state.revision.length}
              />
              <div className="p-1 ml-2 mr-2 columnOptions d-flex justify-content-between align-items-center count">
                <p className="u-fs-sm u-semibold u-fg-grey-600">
                  {this.state.revision.length} {t("oportunidades")}
                </p>
                <div>
                  <img src={amountIcon} alt="Quantidade total de vagas" />
                  <span className="ml-1 u-fs-sm u-semibold u-fg-grey-600">
                    {this.state.revisionAmount}
                  </span>
                </div>
              </div>
              <div
                className="p-1 ml-2 mr-2"
              >
                <span
                  className="u-fs-sm u-semibold u-fg-grey-600"
                >
                  {this.state.inactiveRevision + " "} {t("Inativa")}
                </span>
              </div>
              <DroppableColumn id="revision" list={this.state.revision} />
            </div>

            <div className="recruitment column-body col-sm-4 col-md-3 col-lg-2">
              <ColumnHeader
                type="recruitment"
                subtitle={`(${t("Análise do CV pela Elumini")})`}
                title={t("Recrutamento")}
                count={this.state.recruitment.length}
              />
              <div className="p-1 ml-2 mr-2 columnOptions d-flex justify-content-between align-items-center count">
                <p className="u-fs-sm u-semibold u-fg-grey-600">
                  {this.state.recruitment.length} {t("oportunidades")}
                </p>
                <div>
                  <img src={amountIcon} alt="Quantidade total de vagas" />
                  <span className="ml-1 u-fs-sm u-semibold u-fg-grey-600">
                    {this.state.recruitmentAmount}
                  </span>
                </div>
                
              </div>
              <div
                className="p-1 ml-2 mr-2"
              >
                <span
                  className="u-fs-sm u-semibold u-fg-grey-600"
                >
                  {this.state.inactiveRecruitment + " "} {t("Inativa")}
                </span>
              </div>
              <DroppableColumn id="recruitment" list={this.state.recruitment} />
            </div>

            <div className="evaluation column-body col-sm-4 col-md-3 col-lg-2">
              <ColumnHeader
                type="evaluation"
                subtitle={`(${t("Entrevista pela Elumini")})`}
                title={t("Avaliação")}
                count={this.state.evaluation.length}
              />
              <div className="p-1 ml-2 mr-2 columnOptions d-flex justify-content-between align-items-center count">
                <p className="u-fs-sm u-semibold u-fg-grey-600">
                  {this.state.evaluation.length} {t("oportunidades")}
                </p>
                <div>
                  <img src={amountIcon} alt="Quantidade total de vagas" />
                  <span className="ml-1 u-fs-sm u-semibold u-fg-grey-600">
                    {this.state.evaluationAmount}
                  </span>
                </div>
              </div>
              <div
                className="p-1 ml-2 mr-2"
              >
                <span
                  className="u-fs-sm u-semibold u-fg-grey-600"
                >
                  {this.state.inactiveEvaluation + " "} {t("Inativa")}
                </span>
              </div>
              <DroppableColumn id="evaluation" list={this.state.evaluation} />
            </div>

            <div className="selection column-body col-sm-4 col-md-3 col-lg-2">
              <ColumnHeader
                type="selection"
                subtitle={`(${t("Análise do CV pelo Cliente")})`}
                title={t("Seleção")}
                count={this.state.selection.length}
              />
              <div className="p-1 ml-2 mr-2 columnOptions d-flex justify-content-between align-items-center count">
                <p className="u-fs-sm u-semibold u-fg-grey-600">
                  {this.state.selection.length} {t("oportunidades")}
                </p>
                <div>
                  <img src={amountIcon} alt="Quantidade total de vagas" />
                  <span className="ml-1 u-fs-sm u-semibold u-fg-grey-600">
                    {this.state.selectionAmount}
                  </span>
                </div>
              </div>
              <div
                className="p-1 ml-2 mr-2"
              >
                <span
                  className="u-fs-sm u-semibold u-fg-grey-600"
                >
                  {this.state.inactiveSelection + " "} {t("Inativa")}
                </span>
              </div>
              <DroppableColumn id="selection" list={this.state.selection} />
            </div>

            <div className="interview column-body col-sm-4 col-md-3 col-lg-2">
              <ColumnHeader
                type="interview"
                subtitle={`(${t("Entrevista pelo Cliente")})`}
                title={t("Apresentação")}
                count={this.state.interview.length}
              />
              <div className="p-1 ml-2 mr-2 columnOptions d-flex justify-content-between align-items-center count">
                <p className="u-fs-sm u-semibold u-fg-grey-600">
                  {this.state.interview.length} {t("oportunidades")}
                </p>
                <div>
                  <img src={amountIcon} alt="Quantidade total de vagas" />
                  <span className="ml-1 u-fs-sm u-semibold u-fg-grey-600">
                    {this.state.interviewAmount}
                  </span>
                </div>
              </div>
              <div
                className="p-1 ml-2 mr-2"
              >
                <span
                  className="u-fs-sm u-semibold u-fg-grey-600"
                >
                  {this.state.inactiveInterview + " "} {t("Inativa")}
                </span>
              </div>
              <DroppableColumn id="interview" list={this.state.interview} />
            </div>

            <div className="closed column-body col-sm-4 col-md-3 col-lg-2">
              <ColumnHeader
                type="closed"
                title={t("Posições Encerradas")}
                victories={this.state.victories}
                defeats={this.state.defeats}
              />
              <div className="p-1 ml-2 mr-2 columnOptions d-flex justify-content-between align-items-center count">
                <p className="u-fs-sm u-semibold u-fg-grey-600">
                  {this.state.closed.length} {t("oportunidades")}
                </p>
              </div>
              <div
                className="p-1 ml-2 mr-2"
                style={{display:"flex", justifyContent:"space-between"}}
              >
                <span
                  className="u-fs-sm u-semibold u-fg-grey-600"
                >
                  {this.state.inactiveClose + " "} {t("Inativa")}
                </span>
                <button
                  className="button-victory-style"
                  onClick={ () => this.setState({
                    openModalVictories: !this.state.openModalVictories
                  })}
                >
                  {"Vit/Der"}
                </button>
              </div>
              <DroppableColumn id="closed" list={this.state.closed} />
            </div>
          </DragDropContext>
          {this.state.openModalVictories &&
            <Modal
              show={this.state.openModalVictories}
              size="lg"
              centered
              onHide={() => this.setState({ openModalVictories: false })}
          >
            <Modal.Header>
                <h1 style={{margin: "0 auto", fontSize: "24px"}}>Vitórias e Derrotas</h1>
                <button
                  style={{backgroundColor: "transparent", border: "none"}}
                  onClick={() => this.setState({ openModalVictories: false})}
                >
                  &times;
                </button>
            </Modal.Header>
            <Modal.Body>
              <div style={{display: "flex", justifyContent: "center"}}>
                <div style={{ fontSize: "12px"}}>
                  <h2 style={{ fontSize: "16px"}}>Vitórias</h2>
                  {this.state.allVictories.map((victory: any) => {
                    return(
                      victory.victory.map((date: any)=>{
                        return(
                          <div className="body-victory-defeat">
                            <span>{victory.title}</span><br/>
                            <span className="u-fs-sm u-semibold">{`Esquadrão (${victory.squad})`}</span><br/>
                            <span className="u-fs-sm u-semibold u-fg-grey-600">
                              {`${moment(date).format("DD-MM-YY")} (Vitória) `}{`(${victory.victory.length})`}
                            </span>
                          </div>
                        );
                      })
                    )
                  })}
                </div>
                <div style={{ fontSize: "12px"}}>
                  <h2 style={{ fontSize: "16px"}}>Derrotas</h2>
                  {
                    this.state.allDefeats.map((defeat: any) => {
                      return(
                        defeat.defeats.map((date:any) =>{
                          return(
                            <div className="body-victory-defeat">
                              <span>{defeat.title}</span><br/>
                              <span className="u-fs-sm u-semibold">{`Esquadrão (${defeat.squad})`}</span><br/>
                              <span className="u-fs-sm u-semibold u-fg-grey-600">
                                {moment(date.date).format("DD-MM-YY")} (Derrota) {`(${defeat.defeats.length})`}
                              </span>
                            </div>
                          );
                        })
                      )
                    })
                  }
                </div>
              </div>
            </Modal.Body>
          </Modal>
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store: any) => ({
  squadFilter: store.opportunityFilterReducer.squadFilter,
  responsableFilter: store.opportunityFilterReducer.responsableFilter,
  priorityButton: store.opportunityFilterReducer.priorityButton
});

export default connect(mapStateToProps)(Kanban);
