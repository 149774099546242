import React from 'react';
import './index.css';
import Moment from 'moment';
import { t } from '../../../../translations/translation.service';
const dateFormat = 'DD/MM/YYYY';

class PeriodsComponent extends React.Component<any> {
  render() {
    const opportunity = this.props.opportunity;
    const style = this.props.style;
    if (opportunity.periods) {
      return (
        <div className="col-md-12 col-sm-12">
          {opportunity.periods.periods.map((item:any, index: number) => (
            <div className={style ? "" : "row"} key={index} style={style ? { border: '1px solid black', display: 'flex', width: '100%' }: { border: '1px solid white' }}>
              <div
                  className={`${item.name}-header p-0 col-md-4`}
                  style={
                    style
                    ?
                      index === 0
                        ?
                          { backgroundColor: '#e74c3c',  color: 'white', width: '40%'}
                        :
                        index === 1
                          ?
                            { backgroundColor: '#f39c12',  color: 'white', width: '40%'}
                          :
                          index === 2
                            ?
                              { backgroundColor: '#9b59b6',  color: 'white', width: '40%'}
                            :
                              index === 3
                                ?
                                  { backgroundColor: '#3498db',  color: 'white', width: '40%'}
                                :
                                  { backgroundColor: '#2ecc71',  color: 'white', width: '40%'}
                    :
                      {}
                  }
                >
                <div className="p-0 pb-1 pt-1 u-ta-center">
                  <div className="title">
                    <div>
                      {item.name === "recruitment" ? (
                        <span className="mb-0 u-fs-sm">
                          {t(item.title)} ({opportunity.candidatos.length})
                        </span>
                      ) : item.name !== "publication" ? (
                        <span className="mb-0 u-fs-sm">
                          {t(item.title)} ({item.quantity})
                        </span>
                      ) : (
                        <span className="mb-0 u-fs-sm">{t(item.title)}</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-0 pb-1 pt-1 col-md-8 u-ta-center" style={style ? {background: '#f5f5f5', width: '60%'} : { background: '#f5f5f5'}}>
                {item.dates.map((item2: any, index2: number) => (
                      <div key={index2}>
                        {/* <span className="content-value u-fs-sm"> {item2.start ? Moment(item2.start).format(dateFormat) : ''}  </span> */}
                        {item2.start && item2.end && item2.isActive && <>
                          <span className="content-value u-fs-sm">
                            {Moment(item2.start).format(dateFormat)} {t("até")} {Moment(item2.end).format(dateFormat)}
                            </span>
                        </>}
                        {item2.start && !item2.end && !item2.isActive && <>
                          <span className="content-value u-fs-sm">
                            {t("Inativa de")} { Moment(item2.start).format(dateFormat)} {t("a perder de vista")}
                            </span>
                        </>}
                        {item2.start && item2.end && !item2.isActive && <>
                          <span className="content-value u-fs-sm">
                            ({t("Inativa de")} {Moment(item2.start).format(dateFormat)} {t("até")} {Moment(item2.end).format(dateFormat)})
                            </span>
                        </>}
                        {item2.start && !item2.end && item2.isActive && <>
                          <span className="content-value u-fs-sm">
                            {Moment(item2.start).format(dateFormat)} {t("a")} ...
                            </span>
                        </>}
                        {/* <span className="content-value u-fs-sm"></span> */}
                      </div>
                    ))}
              </div>
            </div>
          ))}
            <div className={style ? "" : "row"} style={style? { border: '1px solid white', display: 'flex', width: '100%' } : { border: '1px solid white' }}>
              <div className={`closed-header p-0 col-md-4`} style={style ? { backgroundColor: '#1abc9c', color: 'white', width: '40%' } : {}}>
                <div className="p-0 pb-1 pt-1 u-ta-center">
                  <div className="title">
                    <div>
                    <span className="mb-0 u-fs-sm">{t("Encerramento")} ({ opportunity.periods.victories.dates.length})</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-0 pb-1 pt-1 col-md-8 u-ta-center" style={ style ? {background: '#f5f5f5', width: '60%'} : { background: '#f5f5f5'}}>
                {opportunity.periods.victories.dates.map((item: any, index: number) => (
                  <div key={index}>
                    <span className="content-value u-fs-sm"> {item.dateVictory ? Moment(item.dateVictory).format(dateFormat) + '  (Vitória)' : ''}</span>
                  </div>
                ))}
                {opportunity.periods.defeats.dates.map((item: any, index: number) => (
                  <div key={index}>
                    <span className="content-value u-fs-sm"> {item.date ? Moment(item.date).format(dateFormat) + '  (Derrota)' : ''}</span>
                  </div>
                ))}
              </div>
            </div>
          
        </div>
      );
    } else {
      return '';
    }
  }
}
export default PeriodsComponent;
