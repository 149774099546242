import { Row } from "@zendeskgarden/react-grid";
import { Button, Col } from "react-bootstrap";
import "react-phone-number-input/style.css";
import MosaicEditView from "../../../components/mosaic/edit";
import PillChipSelectable from "../../../components/opportunity/pill-selectable";
import { ReactComponent as InfoIcon } from '../../../images/icons/info.svg';
import { t } from "../../../translations/translation.service";
import style from "./index.module.scss";

export const tabProfileTwo = (self: any): any => {
  return (
    <div className="row">
      <div className="col">
        <div className={`${style["field-professional-profile"]} mt-2`}>
          <p className={style["title-mosaic-detail"]}>
            {t("Quais seus principais interesses?")}
          </p>
          {self
            .getUserProfileMosaicItem("euquero")
            .map((interest: any, index: number) => (
              <PillChipSelectable
                key={index.toString() + interest.detalhe.nomeCaption}
                nomeCaption={t(interest.detalhe.nomeCaption)}
                classif={interest.detalhe.classif}
                subclassif={interest.detalhe.subclassif}
                index={index}
                onRemove={self.selectOrDeselectInMosaic}
                selected={interest.posicao}
              ></PillChipSelectable>
            ))}
        </div>
        <div className={`${style["field-professional-profile"]} mt-2`}>
          <p className={style["title-mosaic-detail"]}>
            {t("E suas principais competências?")}
          </p>
          {self
            .getUserProfileList("skills")
            .map((skill: any, index: number) => (
              <PillChipSelectable
                key={index.toString() + skill.nomeCaption}
                nomeCaption={skill.nomeCaption}
                classif={skill.classif}
                subclassif={skill.subclassif}
                index={index}
                onRemove={self.addOrRemoveInMosaic}
                selected={self.findInMosaic(skill)}
              ></PillChipSelectable>
            ))}
        </div>
        <div className={`${style["field-professional-profile"]} mt-2`}>
          <p className={style["title-mosaic-detail"]}>{t("Formação")}</p>
          {self
            .getUserProfileList("courses")
            .map((skill: any, index: number) => (
              <PillChipSelectable
                key={index.toString() + skill.nomeCaption}
                nomeCaption={skill.nomeCaption}
                classif={skill.classif}
                subclassif={skill.subclassif}
                index={index}
                onRemove={self.addOrRemoveInMosaic}
                selected={self.findInMosaic(skill)}
              ></PillChipSelectable>
            ))}
        </div>
        <div className={`${style["field-professional-profile"]} mt-2`}>
          <p className={style["title-mosaic-detail"]}>{t("Idiomas")}</p>
          {self
            .getUserProfileList("languages")
            .map((skill: any, index: number) => (
              <PillChipSelectable
                key={index.toString() + skill.nomeCaption}
                nomeCaption={skill.nomeCaption}
                classif={skill.classif}
                subclassif={skill.subclassif}
                index={index}
                onRemove={self.addOrRemoveInMosaic}
                selected={self.findInMosaic(skill)}
              ></PillChipSelectable>
            ))}
        </div>
        <div className={`${style["field-professional-profile"]} mt-2`}>
          <p className={style["title-mosaic-detail"]}>
            {t("Certificações e prêmios")}
          </p>
          {self
            .getUserProfileList("certifications")
            .map((skill: any, index: number) => (
              <PillChipSelectable
                key={index.toString() + skill.nomeCaption}
                nomeCaption={skill.nomeCaption}
                classif={skill.classif}
                subclassif={skill.subclassif}
                index={index}
                onRemove={self.addOrRemoveInMosaic}
                selected={self.findInMosaic(skill)}
              ></PillChipSelectable>
            ))}
      </div>
        <Row className="mt-4">
          <Col sm={1}>
            <InfoIcon style={{ fill: "white" }} />
          </Col>
          <Col sm={11} className={style["text-info-tab2"]}>
            {t(
              `O mosaico representa o seu momento atual e deve resumir o que você mais valoriza hoje. 
              São ao todo 7 peças, sendo pelo menos uma do grupo “Interesses” e outra de “Competências”.
              Clique nos termos à esqueda para excluir ou incluir novas peças e mude suas cores e tamanhos pra deixá-lo com a sua cara!`
            )}
          </Col>
        </Row>
      </div>
      <div className="col-auto">
        <div className="d-flex flex-column h-100 w-100">
          <div className="mb-auto">
            <div
              className={`row m-0 pt-3 pb-3`}
              style={{ height: "530px", paddingBottom: "15px" }}
            >
              <div
                className={style["background-stripped"]}
                style={{
                  position: "absolute",

                  transformOrigin: "top left",
                }}
              ></div>

              {self.state.user.perfilUsuario &&
                self.state.user.perfilUsuario.mosaico.escolhidas.length > 0 && (
                  <div
                    style={{
                      position: "relative",
                      width: "100%",
                      maxWidth: "500px",
                    }}
                  >
                    <div
                      ref={self.mosaicRef}
                      style={{
                        width: "500px",
                        transformOrigin: "top left",
                      }}
                    >
                      {self.getRemainsItemCount() !== 7 && (
                        <MosaicEditView
                          mosaic={self.state.user.perfilUsuario.mosaico.escolhidas.filter(
                            (i: any) => i.posicao
                          )}
                          updateEvent={self.updateMosaic}
                          canDelete={true}
                          removeItem={self.removeItemFromMosaic}
                        ></MosaicEditView>
                      )}
                    </div>
                  </div>
                )}
            </div>
          </div>
            <p className="text-right mr-1">
              {t("Peças usadas") + ": " +
                ( 7 - self.getRemainsItemCount()) +
                t(" de 7")}
            </p>
          <Row className="w-100 d-flex justify-content-end">
            {self.state.step === "welcome" ? (
              <Button
                className={style["next-step"]}
                onClick={() => self.next("tab-3")}
              >
                {t("Salvar e continuar")}
              </Button>
            ) : (
              <Button
                className={style["next-step"]}
                onClick={() => self.next("tab-2")}
              >
                {t("Salvar")}
              </Button>
            )}
          </Row>
        </div>
      </div>
    </div>
  );
};
