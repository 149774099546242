import React from "react";
import { Grid, Row, Col } from "@zendeskgarden/react-grid";
import { Dropdown, Field, Item, Menu, Select } from "@zendeskgarden/react-dropdowns";
import { Input} from "@zendeskgarden/react-forms";
import { Datepicker } from '@zendeskgarden/react-datepickers';
import { connect } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import {
  getEvent,
  getEventDate,
  getResponsibleEvent,
  getEventComment
} from "../../../actions";
import EventService from "../../../services/events.service";
import UserService from "../../../services/user.service";
import Loading from "../../loading";
import "./index.css";

class HeaderEventBox extends React.Component<any> {
  constructor(props: any) {
    super(props);
  }

  state = {
    selectedResp: {} as any,
    responsibles: [],
    selectedEvent: {} as any,
    dateEvent: new Date(),
    events: [],
    loading: false,
  }
  
  async componentDidMount(): Promise<void> {
    const { getEvent, getEventDate, getResponsibleEvent } = this.props;
    this.setState({ loading: true } )
    const allEvents = await EventService.getAllEvents(); 
    const responsibles = await UserService.getResponsables();
    if (Object.keys(this.props.editEventByRh).length > 0) {
      const { recruiter, eventType, created } = this.props.editEventByRh;
      const responsibleIndex = responsibles.data.findIndex(
        (user: any) => user._id === recruiter._id
      );
      const eventIndex = allEvents.data.findIndex(
        (event: any) => event._id === eventType._id
      );
      
      this.setState({
        responsibles: responsibles.data,
        selectedResp: responsibles.data[responsibleIndex],
        events: allEvents.data,
        selectedEvent: allEvents.data[eventIndex],
        dateEvent: new Date(created)
      }, () => {
        getResponsibleEvent(this.state.selectedResp);
        getEvent(this.state.selectedEvent);
        getEventDate(this.state.dateEvent);
        this.setState({ loading: false });
      })
    } else {
      this.setState({
        responsibles: responsibles.data,
        selectedResp: responsibles.data[0],
        events: allEvents.data,
        selectedEvent: allEvents.data[0]
      }, () => {
        getResponsibleEvent(this.state.selectedResp);
        getEvent(this.state.selectedEvent);
        getEventDate(this.state.dateEvent);
        this.setState({ loading: false });
      })
    }
  }
  render() {
    const { getEvent, getEventDate, getResponsibleEvent } = this.props
    return(
      <Grid>
        <Loading show={this.state.loading}/>
        <Row className="header-body">
          <Col>
            <Dropdown
              selectedItem={this.state.selectedResp}
              onSelect={(selectedResp) => {
                this.setState({ selectedResp });
                getResponsibleEvent(selectedResp);
              }}
              downshiftProps={{
                itemToString: (item: any) => item && item.displayName,
              }}
            >
              <Field>
                <Select isCompact className="u-semibold u-fs-sm">
                  {this.state.selectedResp?.displayName}
                </Select>
              </Field>
              <Menu className="u-fs-sm">
                {this.state.responsibles.map((option: any) => (
                  <Item key={option._id} value={option}>
                    {option.displayName}
                  </Item>
                ))}
              </Menu>
            </Dropdown>
          </Col>
          <Col>
            <Dropdown
              selectedItem={this.state.selectedEvent}
              onSelect={(selectedEvent) => {
                this.setState({ selectedEvent });
                getEvent(selectedEvent);
              }}
              downshiftProps={{
                itemToString: (item: any) => item && item.name,
              }}
            >
              <Field>
                <Select isCompact className="u-semibold u-fs-sm">
                  {this.state.selectedEvent.name}
                </Select>
              </Field>
              <Menu className="u-fs-sm">
                {this.state.events.map((option: any) => (
                  <Item key={option._id} value={option}>
                    {option.name}
                  </Item>
                ))}
              </Menu>
            </Dropdown>
          </Col>
          <Col style={{ display: 'flex'}}>
            data:
            <Datepicker
              // eventsEnabled
              placement="top-end"
              value={this.state.dateEvent}
              onChange={(e) =>
                {
                  this.setState({ dateEvent: e });
                  getEventDate(e)
                }
              }
              isCompact
            >
              <Input isCompact />
            </Datepicker>
          </Col>
        </Row>
      </Grid>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      getEvent,
      getEventDate,
      getResponsibleEvent,
      getEventComment
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(HeaderEventBox);