import { Col, Row } from "@zendeskgarden/react-grid";
import { FooterItem } from "@zendeskgarden/react-modals";
import Modal from "react-bootstrap/Modal";
import { Field, Checkbox, Label } from "@zendeskgarden/react-forms";
import Moment from "moment";
import { Component } from "react";
import { Button } from "react-bootstrap";
import { AlertService } from "../../../../services/alert.service";
import OpportunityService from "../../../../services/opportunity.service";
import TranslationService, {
  t,
} from "../../../../translations/translation.service";
import Loading from "../../../loading";
import ProfessionalAvatar from "../avatar-info";
import styles from "./index.module.scss";
import UserService from "../../../../services/user.service";
import CandidateFlipCard from '../../../flip-card';


const dateFormat = "DD/MM/YYYY";

class ProfessionalCardEnrolled extends Component<{
  user: any;
  opportunity: { id: string; title: string };
  history: any;
  level: string;
}> {
  state = {
    usuarioDispensado: false,
    sendEmail: false,
    dismissModal: false,
    inscribeModal: false,
    selectedId: "",
    opportunities: [] as any[],
    loading: false,
    currentLang: TranslationService.getCurrentLanguage,
    processType: "",
    flip: true,
  };

  constructor(props: any) {
    super(props);
    this.setFlip = this.setFlip.bind(this);
  }

  setFlip() {
    this.setState({ flip: !this.state.flip });
  }

  isEvaluationStar = (evaluation: any[]): boolean => {
    let result = false;
    if (evaluation && evaluation.length > 0) {
      evaluation.forEach((item: any) => {
        if (item.resultado >= 8) {
          result = true;
        }
      });
    }
    return result;
  };

  isEvaluationUpdated(evaluation: any) {
    let result = false;
    if (evaluation && evaluation.length > 0) {
      let currenDate = new Date();
      currenDate.setFullYear(currenDate.getFullYear() - 1);
      evaluation.forEach((item: any) => {
        if (new Date(item.created) > currenDate) {
          result = true;
        }
      });
    }
    return result;
  }

  isEvaluationOutOfDate(evaluation: any) {
    let result = false;
    if (evaluation && evaluation.length > 0) {
      let currenDate = new Date();
      currenDate.setFullYear(currenDate.getFullYear() - 1);
      evaluation.forEach((item: any) => {
        if (new Date(item.created) <= currenDate) {
          result = true;
        }
      });
    }
    return result;
  }

  isEvaluationNegative(evaluation: any) {
    let result = false;
    if (evaluation && evaluation.length > 0) {
      evaluation.forEach((item: any) => {
        if (item.resultado === 0) {
          result = true;
        }
      });
    }
    return result;
  }

  getOpportunities(processType: string) {
    this.setState({ loading: true, processType }, async () => {
      let opps = (await OpportunityService.getActiveOpportunities()).data;
      this.setState({
        inscribeModal: true,
        opportunities: opps,
        loading: false,
      });
    });
  }

  async dispenseCandidate() {
    this.setState({ loading: true });
    try {
      const obj = {
        _usuario: this.props.user._usuario,
        type: "dispensar-trido",
        activeLanguage: this.props.user._usuario.dadosPessoais.preferredLanguage
          ? this.props.user._usuario.dadosPessoais.preferredLanguage
          : this.state.currentLang(),
        sendDispensedMail: this.state.sendEmail,
        byRh: this.props.user.byRH,
      };
      await OpportunityService.dispenseCandidate(
        this.props.opportunity.id,
        obj
      );
      this.setState(
        {
          loading: false,
          dismissModal: false,
          usuarioDispensado: true,
        },
        () =>
          AlertService.success(
            this.state.sendEmail
              ? t("Email enviado com sucesso")
              : t("Candidato dispensado com sucesso")
          )
      );
    } catch (err) {
      this.setState({
        loading: false,
        dismissModal: false,
      });
      AlertService.error(t("Erro"));
    }
  }

  async becomeCandidateByRh() {
    this.setState({ loading: true }, async () => {
      try {
        let triageRecruitmentOptions;
        if (
          this.props.user.steps &&
          this.props.user.steps.length &&
          this.props.user.capture &&
          this.props.user.source
        ) {
          triageRecruitmentOptions = {
            details: "",
            date: new Date(Date.now()),
            capture: this.props.user.capture,
            source: this.props.user.source,
          };
        } else {
          triageRecruitmentOptions = {
            details: "",
            date: new Date(Date.now()),
            capture: "",
            source: "",
          };
        }

        const obj = {
          activeLanguage: this.state.currentLang(),
          _usuario: this.props.user._usuario,
          triageRecruitmentOptions: triageRecruitmentOptions,
          conversionType: this.state.processType,
          byRH: true,
        };
        const resp = await OpportunityService.becomeCandidateByRh(
          this.state.selectedId,
          obj
        );
        this.setState({ inscribeModal: false, loading: false }, () => {
          AlertService.success(t(resp.data.message));
        });
      } catch (error) {
        console.error(error);
        AlertService.error(error);
        this.setState({ loading: false });
      }
    });
  }

  async handleEmailSend(user: any, opp: any) {
    this.setState({ loading: true });

    try {
      const response = await UserService.sendConfirmationEmail(user._usuario._id, opp.id, opp.title);
      if (response.status !== 200) {
        AlertService.warn("Erro no envio de email.");
      }

      AlertService.success("O pre-cadastro foi realizado com sucesso e o profissional receberá um email de notificação com os próximos passos.");
    } catch (err) {
      AlertService.warn("Erro no envio de email.");
    } finally {
      this.setState({ loading: false });
    }
  }

  render() {
    let { user, opportunity, history, level } = this.props;
    const professionalElumini = user._usuario?.eluminer?.status;
    if (user) {
      if (!user._usuario) {
        user._usuario = { deleted: true };
      }
      user._usuario.level = user.nivelAderencia;
      user._usuario.byRH = user.byRH;
    } else {
      return <></>;
    }
    return (
      <Col sm={12} md={6} xl={4} key={user.id}>
        <Loading show={this.state.loading} />
        <div className={styles["enrolled-container-flip-card"]}>
          {this.state.flip ?
            <div className={professionalElumini ? styles["user-card-eluminer-enrolled"] : styles["user-card"] }>
              <ProfessionalAvatar
                user={user._usuario}
                callback={this.setFlip}
                history={history}
                oppId={opportunity.id}
                level={level}
              />
              {!user._usuario.deleted && (
                <>
                  <div className="w-100">
                    <Button
                      className={styles["button-gray"]}
                      onClick={() =>
                        window.open(`/professional/${user._usuario._id}`, "_blank")
                      }
                    >
                      {t("Ver currículo")}
                    </Button>
                    {user.status?.current !== 1 && (
                      <>
                        <Button
                          className={styles["button-orange"]}
                          onClick={() => this.getOpportunities("candidate")}
                        >
                          {t("Tornar candidato")}
                        </Button>
                        <Button
                          disabled={
                            (user.status && user.status.current === 1) ||
                            this.state.usuarioDispensado
                          }
                          className={styles["button-white"]}
                          onClick={() => this.setState({ dismissModal: true })}
                        >
                          {t("Dispensar")}
                        </Button>
                        <Button
                          className={styles["button-white"]}
                          onClick={() => this.handleEmailSend(user, opportunity)}
                          disabled={!user._usuario.preRegister}
                        >
                          {t("Reenviar Email Pré-cadastro")}
                        </Button>
                      </>
                    )}
                    {user.status?.current === 1 && (
                      <>
                        <p
                          className="text-center u-fs-md u-semibold"
                          style={{ margin: "12px 0 66px", color: "#797979" }}
                        >
                          {t("Dispensado")}
                        </p>
                      </>
                    )}
                  </div>
                  <p
                    className="text-center mt-3 mb-2 u-fs-md u-semibold"
                    style={{ color: "#797979" }}
                  >
                    {t("Inscrito em ") + Moment(user.createdAt).format(dateFormat)}
                  </p>
                </>
              )}
              {user._usuario.deleted && (
                <p
                  className="text-center mt-3 mb-2 u-fs-md u-semibold"
                  style={{ color: "#797979", height: "145px" }}
                >
                  {t("Cadastro excluído")}
                </p>
              )}

              {/* <pre>{JSON.stringify(user, null, 2)}</pre> */}

              {
                // MODAL DE INSCRIÇÃO
                this.state.inscribeModal && (
                  <Modal
                    scrollable
                    show={this.state.inscribeModal}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    onHide={() => this.setState({ inscribeModal: false })}
                  >
                    <Modal.Header>
                      <Col>
                        <Row>
                          <Col size={12} className="mb-2">
                            {t("Em que oportunidade você quer inscrever @ profissional?")}
                            <button onClick={() => this.setState({ inscribeModal: false })} type="button" className="close" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </Col>
                          <Col size={12}>
                            <h4 style={{ color: '#ed961c' }}>{t("Lembre-se de que el@ será notificad@ por e-mail.")}</h4>
                          </Col>
                        </Row>
                        <Row>
                          <p className="u-semibold mt-4">{t("Oportunidade atual")}</p>
                          <Button
                            className={
                              this.state.selectedId === this.props.opportunity.id
                                ? styles["modal-button-selected"]
                                : styles["modal-button-opp"]
                            }
                            onClick={() =>
                              this.setState({ selectedId: this.props.opportunity.id })
                            }
                          >
                            {this.props.opportunity.title}
                          </Button>
                        </Row>
                      </Col>
                    </Modal.Header>
                    <Modal.Body className={styles["style-scroll-bar-enrolled"]}>
                      <p className="u-semibold mt-2">{t("Outras oportunidades")}</p>
                      {this.state.opportunities.map((opp) => (
                        <Button
                          key={opp.id}
                          className={
                            (this.state.selectedId === opp._id
                              ? styles["modal-button-selected"]
                              : styles["modal-button-opp"]) + " mt-2"
                          }
                          onClick={() => this.setState({ selectedId: opp._id })}
                        >
                          {opp.titulo}
                        </Button>
                      ))}
                    </Modal.Body>
                    <Modal.Footer>
                      <FooterItem>
                        <Button
                          disabled={this.state.selectedId === ""}
                          variant="outline-warning"
                          onClick={() => this.becomeCandidateByRh()}
                          style={{ borderRadius: "20px", padding: "3px 25px" }}
                        >
                          {t("Salvar")}
                        </Button>
                      </FooterItem>
                    </Modal.Footer>
                  </Modal>
                )
              }

              {
                //MODAL PARA ENVIAR EMAIL DE DISPENSA DE CANDIDATO
                this.state.dismissModal && (
                  <Modal
                    show={this.state.dismissModal}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    onHide={() => this.setState({ dismissModal: false })}
                  >
                    <Modal.Header>
                      {t("Deseja realmente dispensar este profissional?")}
                    </Modal.Header>
                    <Modal.Body>
                      <Field className="mt-4">
                        <Checkbox
                          checked={this.state.sendEmail}
                          onChange={() =>
                            this.setState({ sendEmail: !this.state.sendEmail })
                          }
                        >
                          <Label>
                            {t("O profissional receberá uma mensagem por e-mail.")}
                          </Label>
                        </Checkbox>
                      </Field>
                    </Modal.Body>
                    <Modal.Footer className="justify-content-between">
                      <FooterItem>
                        <Button
                          onClick={() => this.setState({ dismissModal: false })}
                          variant="secondary"
                          style={{ padding: "3px 30px" }}
                        >
                          {t("Cancelar")}
                        </Button>
                      </FooterItem>
                      <FooterItem>
                        <Button
                          onClick={() => this.dispenseCandidate()}
                          variant="danger"
                          style={{ padding: "3px 30px" }}
                        >
                          {t("Dispensar Candidato")}
                        </Button>
                      </FooterItem>
                    </Modal.Footer>
                  </Modal>
                )
              }
            </div>
            : 
            <div className={styles["enrolled-back-flip-card"]}>
              <CandidateFlipCard user={user} oppId={this.props.opportunity.id} callback={this.setFlip}/>
            </div>
          }
        </div>
      </Col>
    );
  }
}

export default ProfessionalCardEnrolled;
