export const enterEvents = [
    '6669ae2925165f6b2dc5f569',
    '666c563e0b074841e3e4c5d8',
    '6669ae2925165f6b2dc5f56a',
    '6669ae2925165f6b2dc5f56b',
    '6669ae2925165f6b2dc5f56c',
    '6669ae2925165f6b2dc5f56d',
    '6669ae2925165f6b2dc5f56e',
    '666b388525165f6b2dc5f56f',
    '666c46260b074841e3e4c5d7',
];