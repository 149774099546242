import React from "react";
import { Droppable } from "react-beautiful-dnd";
import DraggableBody from "../draggable";

class DroppableColumn extends React.Component<any> {

  render() {
    return (
      <Droppable droppableId={this.props.id}>
        {(provided, snapshot) => (
          <DraggableBody provided={provided} snapshot={snapshot} list={this.props.list} id={this.props.id}/>
        )}
      </Droppable>
    );
  }
}

export default DroppableColumn;
