import {
  Close,
  Notification,
  Title,
  ToastProvider,
  useToast,
} from "@zendeskgarden/react-notifications";
import { DEFAULT_THEME } from "@zendeskgarden/react-theming";
import { HTMLAttributes, useEffect } from "react";
import { t } from "../translations/translation.service";
import { AlertService } from "./alert.service";

const Toasts = () => {
  const { addToast } = useToast();

  useEffect(() => {
    const subscription = AlertService.onAlert().subscribe((alert: any) => {
      if (alert.message) {
        addToast(
          ({ close }) => (
            <Notification
              type={alert.type}
              style={{ maxWidth: 450, zIndex: 10, background: "while" }}
            >
              <Title>{t(alert.type + "")}</Title>
              {alert.message}
              <Close aria-label="Close" onClick={close} />
            </Notification>
          ),
          { placement: "top" }
        );
      }
    });

    return function cleanup() {
      subscription.unsubscribe();
    };
  });

  return <></>;
};

const topProps = {
  style: { top: DEFAULT_THEME.space.base * 5 },
} as HTMLAttributes<HTMLDivElement>;
const bottomProps = {
  style: { bottom: DEFAULT_THEME.space.base * 5 },
} as HTMLAttributes<HTMLDivElement>;
const placementProps = {
  "top-start": topProps,
  top: topProps,
  "top-end": topProps,
  "bottom-start": bottomProps,
  bottom: bottomProps,
  "bottom-end": bottomProps,
};

export const Alert = () => (
  <ToastProvider placementProps={placementProps} zIndex={1}>
    <Toasts />
  </ToastProvider>
);
