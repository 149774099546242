import { Field, Hint, Label, Toggle } from '@zendeskgarden/react-forms';
import { Col } from '@zendeskgarden/react-grid';
import { ThemeProvider } from "@zendeskgarden/react-theming";
import React from 'react';
import Loading from '../../components/loading';
import { UserData } from '../../model/user.model';
import { AlertService } from '../../services/alert.service';
import { AuthenticationService } from "../../services/auth.service";
import UserService from '../../services/user.service';
import TranslationService, { t } from '../../translations/translation.service';
import { theme } from "../../util/colorButton";
import './index.css';

class Settings extends React.Component <any> {
  state = {
    user: {} as UserData,
    emailChecked: false,
    phoneChecked: false,
    offChecked: false,
    loading: false,
  }

  constructor(props: any) {
    super(props);
    this.updateSettings = this.updateSettings.bind(this);
  }

  async updateSettings() {
    delete this.state.user.password;

    if (!this.state.user.notificationsSettings || this.state.user.notificationsSettings.channels.length === 0) {
      let newNotificationsSettings = {
        isActive: true,
        channels: [
          {
            isActive: true,
            name: "E-mail",
          },
          {
            isActive: true,
            name: "Telefone",
          }
        ]
      }

      const response = await UserService.updateUser({...this.state.user, notificationsSettings: newNotificationsSettings});

      if (response.status !== 200) {
        AlertService.error(t("Opa! Houve um problema ao processar a sua requisição. Por favor, tente novamente."));
        return;
      }

      this.setState({
        emailChecked: true,
        phoneChecked: true
      })

      return;
    }

    const notificationsSettings = this.state.user.notificationsSettings;

    if (!this.state.offChecked) {
      notificationsSettings.isActive = true;
    } else {
      notificationsSettings.isActive = false;
    }
    
    notificationsSettings.channels.forEach(
      (channel: any) => {
        if (channel.name === "E-mail") channel.isActive = this.state.emailChecked
        if (channel.name === "Telefone") channel.isActive = this.state.phoneChecked
      }
    );
    
    this.setState({
      user: { ...this.state.user, notificationsSettings }
    }, async () => {
      const response = await UserService.updateUser(this.state.user);

      if (response.status !== 200) {
        AlertService.error(t("Opa! Houve um problema ao processar a sua requisição. Por favor, tente novamente."));
        return;
      }

      this.setState({ user: response.data, loading: false });
      AlertService.success(t("Configurações de comunicação atualizadas com sucesso."));
    });
  }

  componentDidMount() {
    const userId = this.props.match.params.id;
    
    AuthenticationService.getUser()._id !== userId
      ? this.props.history.push("/login")
      : this.setState({ loading: true });

    UserService.getUserWithOpportunities(userId).then((resp) =>
      this.setState({ user: resp.data.user, loading: false }, async () => {
        this.state.user.notificationsSettings.channels.forEach(
          (channel: any) => {
            if (channel.name === "E-mail")
              this.setState({ emailChecked: channel.isActive });
            if (channel.name === "Telefone")
              this.setState({ phoneChecked: channel.isActive });
          }
        );

        if(!this.state.user.notificationsSettings.isActive) {
          this.setState({
            offChecked: true
          });
        }

        if (!this.state.user.notificationsSettings || this.state.user.notificationsSettings.channels.length === 0) {
          await this.updateSettings();
        }
      })   
    );
  }

  render() {
    return (
      <div className="set-body">
        <ThemeProvider theme={theme as any}>
          <Loading show={this.state.loading} />
          <h4 className="set-title">{t("Configurações de Comunicação")}</h4>
          <hr
            style={{
              borderTop: '2px solid #707070',
            }}
          />
          <div className="mt-5">
            <Col md={8}>
              <Label isRegular>
                {TranslationService.getCurrentLanguage() === "en-US" 
                  ? "By registering, you authorized us to get in touch to talk about your candidacies, the progress of selection processes and opportunities adhering to your profile. Change these settings whenever you like by checking/unchecking the options below." 
                  : "Ao se cadastrar você autorizou que entrássemos em contato pra falar sobre suas candidaturas,  andamento de processos seletivos e oportunidades aderentes ao seu perfil. Altere essas configurações sempre que quiser, marcando/desmarcando as opções abaixo"
                }
              </Label>
            </Col>
            <div className="mt-4">
              <Col className="mb-4">
                <Field>
                  <Toggle
                    checked={this.state.emailChecked} 
                    onChange={() => {
                      this.setState({
                        emailChecked: !this.state.emailChecked,
                        offChecked: !this.state.emailChecked || this.state.phoneChecked ? false : true,
                      }, () => {
                        this.updateSettings();
                      });
                    }}
                  >
                    <Label
                      className="set-input"
                      isRegular
                    >
                      <b>{TranslationService.getCurrentLanguage() === "en-US" 
                        ? "Automatic Email Notifications"
                        : "Notificações automaticas por E-mail"
                      }</b>
                    </Label>
                    <Hint>
                      {TranslationService.getCurrentLanguage() === "en-US" 
                        ? "Keeps you informed about the progress of your applications, opportunities and updates in general."
                        : "Mantêm você informad@ sobre o andamento de suas candidaturas, oportunidades e atualizações em geral."
                      }
                    </Hint>
                  </Toggle>
                </Field>
              </Col>
              <Col className="mb-4">
                <Field>
                  <Toggle
                    checked={this.state.phoneChecked}
                    onChange={() => {
                      this.setState({
                        phoneChecked: !this.state.phoneChecked,
                        offChecked: !this.state.phoneChecked || this.state.emailChecked ? false : true,
                      }, () => {
                        this.updateSettings();
                      });
                    }}
                  >
                    <Label
                      className="set-input"
                      isRegular
                    >
                      <b>{TranslationService.getCurrentLanguage() === "en-US" 
                        ? "Contact via Phone / WhatsApp"
                        : "Contato via Telefone / WhatsApp"
                      }</b>
                    </Label>
                    <Hint>
                      {TranslationService.getCurrentLanguage() === "en-US" 
                        ? "To talk about opportunities that suit your profile, schedule interviews and provide specific information about your applications."
                        : "Para tratar de oportunidades aderentes ao seu perfil, agendar entrevistas e dar informações especificas sobre suas candidaturas."
                      }
                    </Hint>
                  </Toggle>
                </Field>
              </Col>
              <Col className="mb-5">
                <Field>
                  <Toggle
                    checked={this.state.offChecked}
                    onChange={() => {
                      this.setState({
                        offChecked: !this.state.offChecked,
                        phoneChecked: !this.state.offChecked ? false : true,
                        emailChecked: !this.state.offChecked ? false : true
                      }, () => {
                        this.updateSettings();
                      });
                    }}
                  >
                    <Label
                      className="set-input"
                      isRegular
                    >
                      <b>{
                        TranslationService.getCurrentLanguage() === "en-US" 
                          ? "I'm off"
                          : "Estou off"
                      }</b>
                    </Label>
                    <Hint>
                      {
                        TranslationService.getCurrentLanguage() === "en-US" 
                          ? "I do not want to receive any type of contact regarding the progress of selection processes and opportunities in general."
                          : "Não quero receber nenhum tipo de contato referente ao andamento de processos seletivos e oportunidades em geral. "
                      }
                    </Hint>
                  </Toggle>
                </Field>
              </Col>
            </div>
          </div>
        </ThemeProvider>
      </div>
    );
  };
}

export default Settings;
