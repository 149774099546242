import React from "react";
import "./index.css";

class ColumnHeader extends React.Component<any> {
  
  render() {
    return (
      <div className={`col column-header ${this.props.type}-header u-ta-left p-2`}>
        <div className="column-content">
          <div className="title">
            <p className="u-fs-md mb-0 ">{this.props.subtitle}</p>
            <div>
              <span className="mb-0 u-fs-lg">{this.props.title}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ColumnHeader;
