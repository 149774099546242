import { Col, Row } from "@zendeskgarden/react-grid";
import { Component } from "react";
import OpportunityService from "../../../../../services/opportunity.service";
import UserService from "../../../../../services/user.service";
import { UserData, avaliacaoUsuario } from "../../../../../model/user.model";
import { AccordionColHeader, AccordionPanel, AccordionRow, ColParagraph, ColParagraphDescription, ColSection } from "./styles";
import { t } from "../../../../../translations/translation.service";
import { Modal } from "react-bootstrap";
import OpportunityCriteria from '../../../../../components/professional/candidate-card/card/components/opportunity-criteria';
import { AuthenticationService } from "../../../../../services/auth.service";
import { AlertService } from "../../../../../services/alert.service";
import { AnyAction, Dispatch, bindActionCreators } from "redux";
import { getValidateValue } from "../../../../../actions";
import { connect } from "react-redux";

class TechnicalAdvicePanel extends Component<{
  opportunityId: string;
  userAvaliation: avaliacaoUsuario;
  user?: UserData;
  getValidateValue: any;
}> {
    constructor(props: any) {
        super(props);
        this.setAvaliationCriteria = this.setAvaliationCriteria.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
    }
    state = {
        titleOpportunity: "",
        showEditModal: false,
        opportunityInfo: {},
        avaliation: {
            avaliacao: {} as any,
            opportunity: {} as any,
            user: AuthenticationService.getUser(),
            conteudo: "",
            created: new Date(),
            _id: this.props.userAvaliation._id,
            resultado: 0,
        },
        save: false,
        evaluations: this.props.userAvaliation
  };

  async componentDidMount() {
    if(this.props.opportunityId !== undefined) {
        const { data } = await OpportunityService.getOpportunityLiteKanban(this.props.opportunityId);

        this.setState({ titleOpportunity: data?.titulo });
    }
  }

  setAvaliationCriteria(criteria: any) {
    this.setState({
      avaliation: { ...this.state.avaliation, 
        opportunity: {
          id: this.props.opportunityId,
          avaliationCriteria: criteria
        },
        resultado: undefined,
        conteudo: undefined
      },
    });
  }

  checkText(text: string): boolean {
        return text.length > 0;
  }

  async saveChanges() {
    let { user, getValidateValue } = this.props;
    const { avaliationCriteria } = this.state.avaliation?.opportunity
    if (!this.checkText(avaliationCriteria.conteudo)) {
        getValidateValue(true);
        return
    }
    if (user && user.avaliacoesUsuario) {
        const resultado = Number(avaliationCriteria.resultado);
        const created = new Date(avaliationCriteria.created).toISOString();
        avaliationCriteria.resultado = resultado;
        avaliationCriteria.created = created;
        const findIndexUserEvaluation = user.avaliacoesUsuario.findIndex(item => item._id === avaliationCriteria._id);
        user.avaliacoesUsuario.splice(findIndexUserEvaluation, 1, avaliationCriteria);
        try {
            await UserService.updateUser(user);
            this.setState({ showEditModal: false, evaluations: avaliationCriteria});
            AlertService.success(t("Critérios atualizados com sucesso!!"))
        } catch (err: any) {
            console.log("ERRO: ", err);
            this.setState({ showEditModal: false });
            AlertService.error(t("Erro ao atualizar critérios"));
        }
    }
  }

  render() {
    const avaliation = this.state.evaluations;

    if(avaliation.opportunity === undefined) {
        return (<></>);
    }

    const parseDateAvaliation = new Date(Date.parse(
        avaliation.created
    ));

    const date = `${parseDateAvaliation.getDate()}/`
        + `${parseDateAvaliation.getMonth() + 1}/`
        + `${parseDateAvaliation.getUTCFullYear()}`;

    return (
        <AccordionPanel>
            <AccordionRow>
                <AccordionColHeader>
                    <div className="d-flex justify-content-between">
                        <span>
                            {`Nota: ${avaliation.resultado}`}
                        </span>
                        <span>
                            {`Data: ${date} | ${avaliation.user?.displayName}`}
                        </span>
                    </div>
                </AccordionColHeader>
            </AccordionRow>
            <Row>
                <Col>
                    <ColSection>
                        <ColParagraph>
                            <a target="_blank" rel="noreferrer" href={`/opportunity/${avaliation.opportunity.id}`}>
                                {this.state.titleOpportunity}
                            </a>
                        </ColParagraph>
                        <div className="d-flex flex-wrap justify-content-around">
                            <div className="d-flex flex-column">
                                <p><u>Critérios necessários</u></p>
                                {avaliation.opportunity?.avaliationCriteria.map(criteria => {
                                    if (criteria.peso === 3) {
                                        return (<p>{criteria.name}</p>);
                                    }
                                    return <></>
                                })}
                            </div>
                            <div className="d-flex flex-column">
                                <p><u>Critérios desejáveis</u></p>
                                {avaliation.opportunity?.avaliationCriteria.map(criteria => {
                                    if (criteria.peso === 1) {
                                        return (<p>{criteria.name}</p>);
                                    }
                                    return <></>
                                })}
                            </div>
                        </div>
                    </ColSection>
                    <ColSection>
                        <ColParagraph>
                            <p>
                                Descrição
                            </p>
                        </ColParagraph>
                        <ColParagraphDescription>
                            {avaliation.conteudo ? avaliation.conteudo : "sem informação"}
                        </ColParagraphDescription>
                    </ColSection>
                    <ColSection className="d-flex justify-content-end">
                        <button
                            className="editButtonTechnicalAdvice"
                            onClick={() => this.setState({ showEditModal: true })}
                        >
                            {t("Editar")}
                        </button>
                    </ColSection>
                </Col>
            </Row>
            {this.state.showEditModal && (
                <Modal
                    show={this.state.showEditModal}
                    onHide={() => this.setState({ showEditModal: false })}
                    centered
                    size="lg"
                >
                    <Modal.Header>
                        <h1 className="header-modal-edit">
                            {t("Edite os critérios do Candidato")}
                        </h1>
                    </Modal.Header>
                    <Modal.Body
                        className="modal-body-edit"
                    >
                        <OpportunityCriteria
                            opportunityId={this.props.opportunityId}
                            dataAvaliationCriteria={this.setAvaliationCriteria}
                            userId={this.props.user?._id}
                            editCriteria={avaliation}
                        />

                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className="modal-footer-save-button"
                            onClick={this.saveChanges}
                        >
                            {t("Salvar")}
                        </button>
                    </Modal.Footer>
                </Modal>
            )}
        </AccordionPanel>
    )
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators(
    {
      getValidateValue,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(TechnicalAdvicePanel);