import * as React from "react";
import { Row, Col, Form, FormControl } from "react-bootstrap";
import { t } from "../../../translations/translation.service";
import { ReactComponent as CloseIcon } from "./baseline-close-24px.svg";
import { ReactComponent as  SearchStroke} from '@zendeskgarden/svg-icons/src/16/search-stroke.svg';


import styles from "./index.module.css";

export type Props = {
  onRemove: Function;
  chips: any[];
  classes: string;
  onSubmit: Function;
  propKey?: string;
  pictures?: boolean;
  overlay?: boolean
};


export default class ReactChipInput extends React.Component<Props> {
  formControlRef: any;
  state: { focused: boolean; exitingIndex: number } = {
    focused: false,
    exitingIndex: -1,
  };
  constructor(props: Readonly<Props>) {
    super(props);
    this.formControlRef = React.createRef();
  }

  removeChip = (index: number) => {
    this.setState({ exitingIndex: index });
    setTimeout(() => {
      this.props.onRemove(index);
      this.setState({ exitingIndex: null });
    }, 250);
  };

  editChip = (index: number) => {
    const chips = this.props.chips.slice();
    const editChipValue = chips[index];
    chips.splice(index, 1);
    this.setState({ chips });
    this.props.onRemove(index);
    this.formControlRef.current.value = editChipValue;
    this.formControlRef.current.focus();
  };

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (prevProps.chips.length !== this.props.chips.length) {
      this.formControlRef.current.value = "";
    }
  }

  render() {
    return (
      <div
        className={this.props.overlay ? this.props.classes : [
          this.props.classes,
          styles["chip-input"],
          "rounded",
          this.state.focused ? styles["shadow-primary"] : "border",
        ].join(" ")}
      >
        <Row className="align-items-center" noGutters>
          {this.props.chips.map((chip, index) => (
            <Col xs="auto" className="p-1" key={index}>
              <div
                className={[
                  styles["chip"],
                  styles["show"],
                  styles["cursor-pointer"],
                  this.state.exitingIndex === index ? styles["hide"] : "",
                ].join(" ")}
                onDoubleClick={() => this.editChip(index)}
              >
                {this.props.propKey? chip[this.props.propKey]: chip}
                <CloseIcon
                  className={["ml-2", styles["cursor-pointer"]].join(" ")}
                  onClick={() => this.removeChip(index)}
                />
              </div>
            </Col>
          ))}
          {this.props.pictures && (
            <Col xs="auto" className="pr-1 pl-2">
                <SearchStroke />
            </Col>
          )}
          <Col xs>
            <Form
              className="custom-form-control"
              onSubmit={(e: any) => {
                e.preventDefault();
                this.props.onSubmit(this.formControlRef.current.value);
              }}
              noValidate
            >
              <FormControl
                ref={this.formControlRef}
                name="chipInput"
                placeholder={t("Adicionar") + "..."}
                aria-label="Chip Input"
                className={["m-0", "border-0", styles["no-focus"], "mb-0"].join(
                  " "
                )}
                onFocus={() => this.setState({ focused: true })}
                onBlur={() => this.setState({ focused: false })}
              />
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}
