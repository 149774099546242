import {
  Dropdown,
  Field as FieldDrop,
  Item, Label, Menu,
  Select
} from "@zendeskgarden/react-dropdowns";
import {
  Checkbox,
  Field as FieldModal,
  Label as LabelModal
} from "@zendeskgarden/react-forms";
import { FooterItem } from "@zendeskgarden/react-modals";
import { Pagination } from "@zendeskgarden/react-pagination";
import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { withRouter } from "react-router";
import Loading from "../../../components/loading";
import ReactChipInput from "../../../components/opportunity/chip-input";
import ProfessionalCardCandidate from "../../../components/professional/candidate-card/card";
import ProfessionalCardCompatible from "../../../components/professional/compatible-card/card";
import ProfessionalCardEnrolled from "../../../components/professional/enrolled-card/card";
import { Domain } from "../../../model/domain.model";
import { Opportunity } from "../../../model/opportunity.model";
import {
  PRE_REGISTER_ROUTE, PROFESSIONAL_ROUTE
} from "../../../providers/routes";
import { AlertService } from "../../../services/alert.service";
import OpportunityService from "../../../services/opportunity.service";
import EvaluationService from "../../../services/user-evaluation.service";
import TranslationService, {
  t
} from "../../../translations/translation.service";
import { Dots } from "@zendeskgarden/react-loaders";
import style from "./index.module.scss";
const { zdColorSecondaryOrange400 } = require("@zendeskgarden/css-variables");

export type CompatibleUsers = {
  usersList: any[];
  total: number;
  triadosTotal: number;
  title: string;
  isFinished: boolean;
};

const compatiblesOrderList: Domain[] = [
  { key: 0, label: t("Percentual de Aderência") },
  { key: 1, label: t("Pessoa com Deficiência") },
  { key: 4, label: t("Talents") },
];

const enrolledFilterList: Domain[] = [
  { key: 99, label: t("Todos") },
  { key: 0, label: t("Selecionados pelo RH") },
  { key: 1, label: t("Candidaturas Próprias") },
];

const enrolledAndCandidatesOrderList: Domain[] = [
  { key: 0, label: t("Percentual de Aderência") },
  { key: 3, label: t("Pessoa com Deficiência") },
  { key: 1, label: t("Candidaturas Antigas") },
  { key: 2, label: t("Candidaturas Recentes") },
  { key: 4, label: t("Talents") },
];

const candidatesSteps: Domain[] = [
  { key: 99, label: t("Todos") },
  { key: 0, label: t("Inscrito") },
  { key: 1, label: t("Triado") },
  { key: 2, label: t("Recrutado") },
  { key: 3, label: t("Avaliado") },
  { key: 4, label: t("Apresentado") },
];

const candidatesStatus: Domain[] = [
  { key: 99, label: t("Todos") },
  { key: 0, label: t("Registrado") },
  { key: 1, label: t("Concorrendo") },
  { key: 2, label: t("Reservado") },
  { key: 3, label: t("Dispensado") },
  { key: 4, label: t("Recusou") },
  { key: 5, label: t("Desistiu") },
  { key: 6, label: t("Selecionado") },
];

class Compatible extends React.Component<any> {
  state = {
    message: undefined as unknown,
    checkCompativeis: true,
    loading: false,
    opportunity: {} as Opportunity,
    level: this.props.location.state || "compatibles",
    professionals: {} as CompatibleUsers,
    id: "",
    page: 1,
    totalPerPage: 12,
    evaluations: [] as any,
    compatiblesOrderBy: compatiblesOrderList[0],
    enrolledOrderBy: enrolledAndCandidatesOrderList[0],
    enrolledFilter: enrolledFilterList[0],
    candidatesSteps: candidatesSteps[0],
    candidatesStatus: candidatesStatus[0],
    candidatesOrderBy: enrolledAndCandidatesOrderList[0],
    dismissAllEnrolledModal: false,
    sendEmail: false,
    opportunityStep: "",
    countUser: {} as any,
    keywords: [] as string[],
    filterCandidateFlag: false,
    getAllCandidateFlag: false,
    loadingProfessionals: true,
  };

  changeLevel(level: string): void {
    this.setState({ level, filterCandidateFlag: false }, this.getProfessionals);
  }

  componentDidMount() {
    const id = this.props.match.params.id;

    const checkCompativeis = setInterval(async () => {
      var bool = await OpportunityService.getStatusCompativeis(id);
      if (bool.data === 'completed') {
        this.setState({ loadingProfessionals: false });
        console.log(bool);
        AlertService.success('Sua vaga está pronta!')
        if (id) {
          this.setState({ id: id }, async () => {
            await this.getOpportunityDetails()
            this.getProfessionals();
          });
          EvaluationService.getEvaluationList().then((evaluation) => {
            this.setState({ evaluations: evaluation.data });
          });
          OpportunityService.getOpportunityLiteKanban(id).then((opportunity) => {
            this.setState({ opportunityStep: this.getOpportunityStep(opportunity.data)})
          });
          // adiciona campos de quantidade dispensados no banco p/ vagas antigas
          OpportunityService.getStatisticsDismissedProfessionals(id);
        } else {
          this.setState({ loading: false });
          this.setState({ message: t("Vaga não encontrada") });
        }
        clearInterval(checkCompativeis);
      }
    }, 1000);
  }

  async getOpportunityDetails() {
    this.setState({ loading: true, message: undefined });
    await OpportunityService.getOpportunityPreference(this.state.id).then(
      (resp) => {
        const orderListKey = resp.data.pcdPref ? 1 : 0;
        this.setState({
          compatiblesOrderBy: compatiblesOrderList[orderListKey], 
          enrolledOrderBy: enrolledAndCandidatesOrderList[orderListKey],
          candidatesOrderBy: enrolledAndCandidatesOrderList[orderListKey]
        })
      }
    )
  }

  getProfessionals(): void {
    this.setState({ loading: true, message: undefined });
    switch (this.state.level) {
      case "compatibles":
        OpportunityService.getCompatibleProfessionals(
          this.state.id,
          this.state.totalPerPage,
          this.state.page,
          this.state.compatiblesOrderBy.key
        )
          .then((resp) => {
            this.setState({ professionals: resp.data });
            this.setState({ loading: false });
          })
          .catch((error) => {
            console.error(error);
            this.setState({ message: error });
            this.setState({ loading: false });
          });
        break;
      case "enrolled":
        OpportunityService.getEnrolledProfessionals(
          this.state.id,
          this.state.totalPerPage,
          this.state.page,
          this.state.enrolledFilter.key,
          this.state.enrolledOrderBy.key
        )
          .then((resp) => {
            this.setState({ professionals: resp.data });
            this.setState({ loading: false });
          })
          .catch((error) => {
            console.error(error);
            this.setState({ message: error });
            this.setState({ loading: false });
          });
        break;
      case "candidates":
        OpportunityService.getCandidatesProfessionals(
          this.state.id,
          this.state.totalPerPage,
          this.state.page,
          this.state.candidatesSteps.key,
          this.state.candidatesStatus.key,
          this.state.candidatesOrderBy.key
        )
          .then((resp) => {
            this.setState({ professionals: resp.data });
            this.setState({ loading: false });
          })
          .catch((error) => {
            console.error(error);
            this.setState({ message: error });
            this.setState({ loading: false });
          });
        break;

      default:
        break;
    }
  }

  setPage(index: number) {
    if (this.state.filterCandidateFlag) {
      this.setState({ page: index }, async () => await this.findCandidateByKeyword(this.state.keywords));
    } else {
      this.setState({ page: index }, this.getProfessionals);
    }
  }

  reloadPage() {
    window.location.reload();
  }

  disabledismissAllEnrolledButton() {
    const { professionals } = this.state;
    const checkUsers = professionals.usersList.every((enrolled) => {
      if (enrolled.status && enrolled.status.current) {
        return enrolled.status.current === 1;
      }
      return false;
    });
    return checkUsers;
  }

  async dismissAllEnrolled() {
    this.setState({ loading: true });
    try {
      const id = this.props.match.params.id;
      const payload = {
        activeLanguage: TranslationService.getCurrentLanguage(),
        sendDispensedMail: this.state.sendEmail,
        allProfessionals: {},
      };
      await OpportunityService.dispenseAllEnrolled(id, payload);
      this.setState({
        loading: false,
        dismissAllEnrolledModal: false,
        isDisable: true,
      });
      AlertService.success(
        this.state.sendEmail
          ? t("Email enviado com sucesso!")
          : t("Todos os inscritos foram dispensados!")
      );
      setTimeout(() => {
        this.reloadPage();
      }, 400);
    } catch (err) {
      this.setState({ loading: false, dismissAllEnrolledModal: false });
      AlertService.error(t("Erro! Não foi possível dispensar os inscritos"));
    }
  }

  nextPath(path: string): void {
    this.props.history.push(path);
  }

  redirectToHome() {
    this.props.history.push("/");
  }

  getOpportunityStep(opportunity: any) {
    if (opportunity.quantidadeVagas === 0) {
      return "Encerrada";
    } else {
      if (!opportunity.isPublic) return "Revisão";
      switch (opportunity.fase) {
        case 0:
          return "Recrutamento";
        case 1:
          return "Recrutamento";
        case 2:
          return "Avaliação";
        case 3:
          return "Seleção";
        case 4:
          return "Apresentação";
        default:
          break;
      }
    }
  }

  addChip = (value: string) => {
    const keywords = this.state.keywords.slice();
    keywords.push(value);
    this.setState({ keywords: keywords });
  };

  removeChip = (index: number) => {
    const keywords = this.state.keywords.slice();
    keywords.splice(index, 1);
    this.setState({ keywords });
    if (this.state.keywords.length < 1 && this.state.getAllCandidateFlag) {
      this.setState({
        page: 1,
        totalPerPage: 12,
        getAllCandidateFlag: false,
      },() => {
        this.getProfessionals()
      })
    }
  };

  async findCandidateByKeyword(keywords: string[]) {
    const { id, level, page } = this.state;
    this.setState({
      loading: true,
      filterCandidateFlag: true,
      getAllCandidateFlag: true,
    })
    const response = await OpportunityService.getCompatibleByKeywords(
      id,
      level,
      page,
      keywords
    );
    if (response.status !== 200) {
      console.log("erro")
    } else {
      this.setState({
        loading: false,
        professionals: response.data
      })
    }
  }

  render() {
    return (
      <div className="container">
        <Loading show={this.state.loading} />
        {this.state.loadingProfessionals && (
          <div style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center"
          }}>
            <h5 className="mt-3 mb-5">{t("Aguarde. Estamos processando as informações.")}</h5>
            <div
              style={{
                width: "4rem",
                height: '4rem',
                margin: '0 auto',
              }}
            >
              <div className={style["logo-img"]}></div>
              <div>
                <Dots
                  key="dots-loading"
                  className="loader"
                  color={zdColorSecondaryOrange400}
                  size="48px"
                />
              </div>
            </div>
          </div>
        )}
        {this.state.message && (
          <div style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center"
          }}>
            <h5 className="mt-3 mb-5">{t(this.state.message as string)}</h5>
            <Button
              variant="secondary"
              onClick={this.redirectToHome.bind(this)}
            >
              {t("Voltar para o mural de oportunidades")}
            </Button>
          </div>
        )}
        {this.state.id &&
          this.state.professionals &&
          this.state.professionals.usersList && (
            <>
              <section className={style["opp-information"]}>
                <div className="row m-0 pl-3">
                  {this.state.level === "candidates" && (
                    <p className="w-100 mt-4 mb-0">{t("Candidatos")}</p>
                  )}
                  {this.state.level === "compatibles" && (
                    <p className="w-100 mt-4 mb-0">{t("Compatíveis")}</p>
                  )}
                  {this.state.level === "enrolled" && (
                    <p className="w-100 mt-4 mb-0">{t("Inscritos")}</p>
                  )}
                  <p className={style["opp-title"]}>
                    <a href={`/opportunity/${this.props.match.params.id}`}>
                      {this.state.professionals.title}
                    </a>
                  </p>
                </div>
                <p> {`${t("Etapa")}: ${t(this.state.opportunityStep)}`}</p>
              </section>
              <div className={`${style["button-cards-container"]} row m-0`}>
                <div className="col-12 col-lg-9">
                  <React.Fragment>
                    <Row className="w-100 pb-4">
                      <div className="mr-auto ml-3">
                        {this.state.level === "compatibles" && (
                          <Dropdown
                            selectedItem={this.state.compatiblesOrderBy}
                            onSelect={(value) =>
                              this.setState(
                                { compatiblesOrderBy: value },
                                this.getProfessionals
                              )
                            }
                            downshiftProps={{
                              itemToString: (item: any) => item && item.label,
                            }}
                          >
                            <FieldDrop className="u-fs-md">
                              <Label>{t("Ordenar por")}:</Label>
                              <Select>
                                {t(this.state.compatiblesOrderBy.label)}
                              </Select>
                            </FieldDrop>
                            <Menu>
                              {compatiblesOrderList.map((option) => (
                                <Item key={option.key} value={option}>
                                  {t(option.label)}
                                </Item>
                              ))}
                            </Menu>
                          </Dropdown>
                        )}
                        {this.state.level === "enrolled" && (
                          <Row>
                            <Dropdown
                              selectedItem={this.state.enrolledFilter}
                              onSelect={(value) =>
                                this.setState(
                                  { enrolledFilter: value },
                                  this.getProfessionals
                                )
                              }
                              downshiftProps={{
                                itemToString: (item: any) => item && item.label,
                              }}
                            >
                              <FieldDrop className="u-fs-md">
                                <Label>{t("Filtrar por")}:</Label>
                                <Select>
                                  {t(this.state.enrolledFilter.label)}
                                </Select>
                              </FieldDrop>
                              <Menu>
                                {enrolledFilterList.map((option) => (
                                  <Item key={option.key} value={option}>
                                    {t(option.label)}
                                  </Item>
                                ))}
                              </Menu>
                            </Dropdown>
                            <Dropdown
                              selectedItem={this.state.enrolledOrderBy}
                              onSelect={(value) =>
                                this.setState(
                                  { enrolledOrderBy: value },
                                  this.getProfessionals
                                )
                              }
                              downshiftProps={{
                                itemToString: (item: any) => item && item.label,
                              }}
                            >
                              <FieldDrop className="u-fs-md ml-2">
                                <Label>{t("Ordenar por")}:</Label>
                                <Select>
                                  {t(this.state.enrolledOrderBy.label)}
                                </Select>
                              </FieldDrop>
                              <Menu>
                                {enrolledAndCandidatesOrderList.map(
                                  (option) => (
                                    <Item key={option.key} value={option}>
                                      {t(option.label)}
                                    </Item>
                                  )
                                )}
                              </Menu>
                            </Dropdown>
                          </Row>
                        )}
                        {this.state.level === "candidates" && (
                          <Row>
                            <Dropdown
                              selectedItem={this.state.candidatesSteps}
                              onSelect={(value) =>
                                this.setState(
                                  { candidatesSteps: value },
                                  this.getProfessionals
                                )
                              }
                              downshiftProps={{
                                itemToString: (item: any) => item && item.label,
                              }}
                            >
                              <FieldDrop className="u-fs-md">
                                <Label>{t("Etapa")}:</Label>
                                <Select>
                                  {t(this.state.candidatesSteps.label)}
                                </Select>
                              </FieldDrop>
                              <Menu>
                                {candidatesSteps.map((option) => (
                                  <Item key={option.key} value={option}>
                                    {t(option.label)}
                                  </Item>
                                ))}
                              </Menu>
                            </Dropdown>
                            <Dropdown
                              selectedItem={this.state.candidatesStatus}
                              onSelect={(value) =>
                                this.setState(
                                  { candidatesStatus: value },
                                  this.getProfessionals
                                )
                              }
                              downshiftProps={{
                                itemToString: (item: any) => item && item.label,
                              }}
                            >
                              <FieldDrop className="u-fs-md ml-2">
                                <Label>{t("Status")}:</Label>
                                <Select>
                                  {t(this.state.candidatesStatus.label)}
                                </Select>
                              </FieldDrop>
                              <Menu>
                                {candidatesStatus.map((option) => (
                                  <Item key={option.key} value={option}>
                                    {t(option.label)}
                                  </Item>
                                ))}
                              </Menu>
                            </Dropdown>
                            <Dropdown
                              selectedItem={this.state.candidatesOrderBy}
                              onSelect={(value) =>
                                this.setState(
                                  { candidatesOrderBy: value },
                                  this.getProfessionals
                                )
                              }
                              downshiftProps={{
                                itemToString: (item: any) => item && item.label,
                              }}
                            >
                              <FieldDrop className="u-fs-md ml-2">
                              <Label>{t("Ordenar por")}:</Label>
                                <Select>
                                  {t(this.state.candidatesOrderBy.label)}
                                </Select>
                              </FieldDrop>
                              <Menu>
                                {enrolledAndCandidatesOrderList.map(
                                  (option) => (
                                    <Item key={option.key} value={option}>
                                      {t(option.label)}
                                    </Item>
                                  )
                                )}
                              </Menu>
                            </Dropdown>
                          </Row>
                        )}
                      </div>
                      {this.state.filterCandidateFlag ?
                        <Pagination
                        className="ml-auto"
                        totalPages={Math.ceil(
                          this.state.professionals.total / 10
                        )}
                        currentPage={this.state.page}
                        onChange={this.setPage.bind(this)}
                      /> :
                        <Pagination
                          className="ml-auto"
                          totalPages={Math.ceil(
                            this.state.professionals.total /
                              this.state.totalPerPage
                          )}
                          currentPage={this.state.page}
                          onChange={this.setPage.bind(this)}
                        />
                      }
                    </Row>
                    <Row className="mb-4">
                      <Col sm={10}>
                        <span className={style["opp-information"]}>
                          {t("Localizar")}:
                        </span>
                          <ReactChipInput
                            classes={style["input-search-compatible"]}
                            chips={this.state.keywords}
                            onSubmit={(value: string) => this.addChip(value)}
                            onRemove={(index: number) => this.removeChip(index)}
                            pictures={true}
                          />
                      </Col>
                      <Col sm={2} className="d-flex align-items-end">
                        <button
                          disabled={this.state.loading}
                          className={`${style["button-find-compatible"]} mb-3`}
                          onClick={() => this.findCandidateByKeyword(this.state.keywords)}
                        >
                          {t("Procurar")}
                        </button>
                      </Col>
                    </Row>
                    <Row>
                      {this.state.professionals.usersList.length > 0 &&
                        this.state.professionals.usersList.map((user) => {
                          switch (this.state.level) {
                            case "compatibles":
                              return (
                                <ProfessionalCardCompatible
                                  user={user}
                                  key={user._id}
                                  opportunity={{
                                    title: this.state.professionals?.title || '',
                                    id: this.state.id,
                                    isFinished: this.state.professionals?.isFinished
                                  }}
                                  history={this.props.history}
                                ></ProfessionalCardCompatible>
                              );
                            case "enrolled":
                              return (
                                <ProfessionalCardEnrolled
                                  user={user}
                                  key={user._id}
                                  opportunity={{
                                    title: this.state.professionals.title,
                                    id: this.state.id,
                                  }}
                                  history={this.props.history}
                                  level={this.state.level}
                                ></ProfessionalCardEnrolled>
                              );
                            case "candidates":
                              return (
                                <ProfessionalCardCandidate
                                  user={user}
                                  key={user._id}
                                  opportunity={{
                                    title: this.state.professionals.title,
                                    id: this.state.id,
                                  }}
                                  evaluations={this.state.evaluations}
                                  history={this.props.history}
                                  level={this.state.level}
                                ></ProfessionalCardCandidate>
                              );
                            default:
                              return <></>;
                          }
                        })}
                    </Row>
                    <Row className="w-100 pb-4">
                      <Pagination
                        className="ml-auto"
                        totalPages={Math.ceil(
                          this.state.professionals.total /
                            this.state.totalPerPage
                        )}
                        currentPage={this.state.page}
                        onChange={this.setPage.bind(this)}
                      />
                    </Row>
                  </React.Fragment>
                </div>
                <div className="col-12 col-lg-3 pb-3">
                  <Button
                    className={`${style["button-container"]} ${
                      style["petro"]
                    } ${
                      this.state.level === "compatibles" ? style["active"] : ""
                    }`}
                    onClick={() => {
                      this.changeLevel("compatibles");
                    }}
                  >
                    <p className="m-0">
                      {t("Compatíveis")}
                      {this.state.level === "compatibles" &&
                        !this.state.loading && (
                          <span className="float-right">
                            {this.state.professionals.total}
                          </span>
                        )}
                    </p>
                  </Button>
                  <Button
                    className={`${style["button-container"]} ${
                      style["petro"]
                    } ${
                      this.state.level === "enrolled" ? style["active"] : ""
                    }`}
                    onClick={() => {
                      this.changeLevel("enrolled");
                    }}
                  >
                    <p className="m-0">
                      {t("Inscritos")}
                      {this.state.level === "enrolled" &&
                        !this.state.loading && (
                          <span className="float-right">
                            {this.state.professionals.triadosTotal || this.state.professionals.total}
                          </span>
                        )}
                    </p>
                  </Button>
                  {this.state.level === "enrolled" && (
                    <Button
                      disabled={this.disabledismissAllEnrolledButton()}
                      className={`${style["button-container"]} ${style["transp"]}`}
                      onClick={() =>
                        this.setState({ dismissAllEnrolledModal: true })
                      }
                    >
                      {t("Dispensar todos os inscritos")}
                    </Button>
                  )}
                  <Button
                    className={`${style["button-container"]} ${
                      style["petro"]
                    } ${
                      this.state.level === "candidates" ? style["active"] : ""
                    }`}
                    onClick={() => {
                      this.changeLevel("candidates");
                    }}
                  >
                    <p className="m-0">
                      {t("Candidatos")}
                      {this.state.level === "candidates" &&
                        !this.state.loading && (
                          <span className="float-right">
                            {this.state.professionals.total}
                          </span>
                        )}
                    </p>
                  </Button>
                  {this.state.level === "candidates" && (
                    <Button
                      onClick={() =>
                        this.nextPath(
                          `${PROFESSIONAL_ROUTE}${PRE_REGISTER_ROUTE}/${this.props.match.params.id}`
                        )
                      }
                      className={`${style["button-container"]} ${style["transp"]}`}
                    >
                      {t("Incluir pré cadastro")}
                    </Button>
                  )}
                </div>
              </div>
            </>
          )}
        {
          // MODAL PARA DISPENSAR TODOS OS TRIADOS
          this.state.dismissAllEnrolledModal && (
            <Modal
              show={this.state.dismissAllEnrolledModal}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              onHide={() => this.setState({ dismissAllEnrolledModal: false })}
            >
              <Modal.Header>
                {t("Tem certeza que deseja dispensar todos os inscritos?")}
              </Modal.Header>
              <Modal.Body>
                <FieldModal className="mt-4">
                  <Checkbox
                    checked={this.state.sendEmail}
                    onChange={() =>
                      this.setState({ sendEmail: !this.state.sendEmail })
                    }
                  >
                    <LabelModal>
                      {t("Os profissionais receberão uma mensagem por e-mail.")}
                    </LabelModal>
                  </Checkbox>
                </FieldModal>
              </Modal.Body>
              <Modal.Footer className="justify-content-between">
                <FooterItem>
                  <Button
                    onClick={() =>
                      this.setState({ dismissAllEnrolledModal: false })
                    }
                    variant="secondary"
                    style={{ padding: "3px 30px" }}
                  >
                    {t("Cancelar")}
                  </Button>
                </FooterItem>
                <FooterItem>
                  <Button
                    onClick={this.dismissAllEnrolled.bind(this)}
                    variant="danger"
                    style={{ padding: "3px 30px" }}
                  >
                    {t("Dispensar todos os inscritos")}
                  </Button>
                </FooterItem>
              </Modal.Footer>
            </Modal>
          )
        }
      </div>
    );
  }
}

export default withRouter(Compatible);
