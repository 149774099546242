import {
  Field as ToggleField,
  Label,
  Toggle
} from "@zendeskgarden/react-forms";
import { Col, Row } from "@zendeskgarden/react-grid";
import { Pagination } from "@zendeskgarden/react-pagination";
import { ThemeProvider } from '@zendeskgarden/react-theming';
import React from "react";
import Loading from "../../../components/loading";
import ReactChipInput from "../../../components/opportunity/chip-input";
import ProfessionalCard from "../../../components/professional/search-card/card";
import EvaluationService from "../../../services/user-evaluation.service";
import UserService from "../../../services/user.service";
import { t } from "../../../translations/translation.service";
import { theme, themeGray } from "../../../util/colorButton";
import styles from "./index.module.scss";
import { Dropdown, Menu, Item, Select, Field } from '@zendeskgarden/react-dropdowns';
import { REGISTER_PROFESSIONAL_RH } from '../../../providers/routes';
import { Accordion } from "@zendeskgarden/react-accordions";

const filters = [
  { label: 'Todas as Categorias', value: 'Todas as Categorias' },
  { label: 'Nomes/Email', value: 'Nomes/Email' },
  { label: 'Critérios', value: 'Critérios' }
];

export type SearchUsers = {
  usersList: any[];
  total: number;
};

export default class FindProfessional extends React.Component<any> {
  state = {
    loading: false,
    keywords: [] as string[],
    evaluations: [] as any,
    searchResults: {} as SearchUsers,
    pcdFilter: false,
    page: 1,
    totalPerPage: 12,
    talentFilter: false,
    selectedProfessionalId: "", 
    hideProfessionals: false,
    displayNameFromKanban: "",
    typeFilter: filters[0],
    europeanUnionCitizenshipFilter: false,
    expandedSections: [] as number[],
  };

  constructor(props: any) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.goToPage = this.goToPage.bind(this);
  }

  componentDidMount() {
    this.setState( {loading: true} );

    if (this.props.displayName) {
      this.setState({
        displayNameFromKanban: this.props.displayName
      });
    
      this.searchFromKanban();
    }

    EvaluationService.getEvaluationList().then((resp: any) => { this.setState({ evaluations: resp.data, loading: false }) });
  }

  handleSubmit() {
    this.setState({ page: 1 });
    this.getResults();
  }

  goToPage(route: string): void {
    this.props.history.push(route)
  }

  async searchFromKanban() {
    let searchResults = (
      await UserService.find(
        [this.state.displayNameFromKanban],
        1,
        1,
        false,
        false,
        false,
        this.state.typeFilter.value,
      )
    ).data as SearchUsers;

    if (searchResults.total === undefined) {
      searchResults.total = this.state.searchResults.total;
    }

    this.setState({ loading: false, searchResults });
  }

  async getResults() {
    this.setState({ loading: true, hideProfessionals: false, selectedProfessionalId: "" });
    let searchResults = (
      await UserService.find(
        this.state.keywords,
        this.state.totalPerPage,
        this.state.page,
        this.state.pcdFilter,
        this.state.talentFilter,
        this.state.europeanUnionCitizenshipFilter,
        this.state.typeFilter.value,
      )
    ).data as SearchUsers;
    if (searchResults.total === undefined) {
      searchResults.total = this.state.searchResults.total;
    }

    this.setState({ loading: false, searchResults });
  }

  addChip = (value: string) => {
    const keywords = this.state.keywords.slice();
    keywords.push(value);
    this.setState({ keywords: keywords });
  };

  removeChip = (index: number) => {
    const keywords = this.state.keywords.slice();
    keywords.splice(index, 1);
    this.setState({ keywords });
  };

  setPage(index: number) {
    this.setState({ searchResults: {}, page: index }, () => this.getResults());
  }

  render() {
    return (
      <div className="container">
        <Loading show={this.state.loading} />
        <div className={styles["professional-search-box"]}>
          <Row>
            <Col>
              <p className="u-fs-lg u-semibold" style={{ color: "white" }}>
                {t("Encontrar Profissionais")}
              </p>
            </Col>
          </Row>
          <Row>
            <Col sm={12} lg={10}>
              <Row>
                <Col sm={12} lg={8}>
                  <ReactChipInput
                    classes={styles["input-search"]}
                    chips={this.state.keywords}
                    onSubmit={(value: string) => this.addChip(value)}
                    onRemove={(index: number) => this.removeChip(index)}
                  />
                </Col>
                <Col sm={12} lg={4}>
                  <ThemeProvider theme={themeGray as any}>
                    <Dropdown
                      selectedItem={this.state.typeFilter}
                      onSelect={(value) => {
                        this.setState({ typeFilter: value })
                      }}
                      downshiftProps={{
                        itemToString: (item: any) => item && item.label,
                      }}
                    >
                      <Field>
                        <Select
                          style={{
                            width: "100%",
                            height: "3.1rem",
                            fontSize: '16px',
                            color: '#777474'
                          }}
                        >
                          {this.state.typeFilter.label}
                        </Select>
                      </Field>
                      <Menu>
                        {filters.map((filter: any) => (
                          <Item key={filter.value} value={filter}>
                            {filter.label}
                          </Item>
                        ))}
                      </Menu>
                    </Dropdown>
                  </ThemeProvider>
                </Col>
              </Row>
              <Row className={styles['accordion-container']}>
                <div className="mr-3">
                  <button
                    onClick={() => this.goToPage(REGISTER_PROFESSIONAL_RH)}
                    className={`${styles['register-button']}`}
                  >
                    Cadastrar novo profissional
                  </button>
                </div>
                <div className={styles['accordion-items']}>
                  <Accordion
                    isCompact
                    level={4}
                    expandedSections={this.state.expandedSections}
                    onChange={(index: number) => {
                      if (this.state.expandedSections.includes(index)) {
                        this.setState({expandedSections: (this.state.expandedSections.filter(n => n !== index))});
                      } else {
                        this.setState({ expandedSections: [...this.state.expandedSections, index]});
                      }
                    }}
                  >
                    <Accordion.Section>
                      <Accordion.Header className="d-flex justify-content-end" style={{color: 'white'}}>
                        + Opções
                      </Accordion.Header>
                      <Accordion.Panel>
                        <div className={styles['accordion-panel-style']}>
                          <div>
                            <ThemeProvider theme={theme as any}>
                              <ToggleField className="text-right" style={{marginTop: '13px'}}>
                                <Label className="home-office mr-2">{`${t("Talentos")}`}</Label>
                                <Toggle checked={this.state.talentFilter} name="homeOffice" onChange={(e) => this.setState({talentFilter: e.target.checked})}>
                                  <Label><p></p></Label>
                                </Toggle>
                              </ToggleField>
                            </ThemeProvider>
                          </div>
                          <div>
                            <ThemeProvider theme={theme as any}>
                              <ToggleField className="text-right" style={{marginTop: '13px'}}>
                                <Label className="home-office mr-2">{`${t("Pessoa com Deficiência")}`}</Label>
                                <Toggle checked={this.state.pcdFilter} name="homeOffice" onChange={(e) => this.setState({pcdFilter: e.target.checked})}>
                                  <Label><p></p></Label>
                                </Toggle>
                              </ToggleField>
                            </ThemeProvider>
                          </div>
                          <div>
                            <ThemeProvider theme={theme as any}>
                              <ToggleField className="text-right" style={{marginTop: '13px'}}>
                                <Label className="home-office mr-2">{`${t("Cidadania Europeia")}`}</Label>
                                <Toggle
                                  checked={this.state.europeanUnionCitizenshipFilter}
                                  name="homeOffice"
                                  onChange={
                                    (e) => this.setState(
                                      { europeanUnionCitizenshipFilter: e.target.checked }
                                    )
                                  }
                                >
                                  <Label
                                    className="home-office"
                                  >
                                    <p></p>
                                  </Label>
                                </Toggle>
                              </ToggleField>
                            </ThemeProvider>
                          </div>
                        </div>
                      </Accordion.Panel>
                    </Accordion.Section>
                  </Accordion>
                </div>
              </Row>
            </Col>
            <Col sm={12} lg={2} className="pt-3 pt-lg-0">
              <button
                disabled={this.state.keywords.length === 0}
                type="submit"
                onClick={this.handleSubmit}
                className={styles['find-button']}
              >
                {t("Encontrar")}
              </button>
            </Col>
          </Row>
        </div>
        <div className="text-right mb-4"></div>

        {this.state.searchResults && this.state.searchResults.usersList && (
          <React.Fragment>
            <div className="d-flex u-fs-lg u-semibold search-result-box">
              <div className="mr-auto">{t("Resultado da busca")}:</div>
              <div>
                {this.state.searchResults.total +
                  " " +
                  t("profissionais encontrados")}
                :
              </div>
            </div>
            <Row className="w-100 pb-4">
              <Pagination
                className="ml-auto"
                totalPages={Math.ceil(
                  this.state.searchResults.total / this.state.totalPerPage
                )}
                currentPage={this.state.page}
                onChange={this.setPage.bind(this)}
              />
            </Row>
            <Row>
              {this.state.searchResults.usersList.length > 0 &&
                (this.state.searchResults as SearchUsers).usersList.map(
                  (user, index) =>{ 
                    return (
                      <>
                        {this.state.selectedProfessionalId && 
                        this.state.selectedProfessionalId !== user._id &&
                        this.state.hideProfessionals 
                          ? <></> 
                          : (
                            <ProfessionalCard
                              onCompatibleClick={(hideProfessionals: boolean, professionalId: string) => {
                                this.setState({
                                  selectedProfessionalId: professionalId,
                                  hideProfessionals
                                });
                              }}
                              user={user}
                              key={index}
                              history={this.props.history}
                              evaluations={this.state.evaluations}
                            />
                          ) 
                        }
                      </>
                      
                    )
                  }
                )}
            </Row>
            <Row className="w-100 pb-4">
              <Pagination
                className="ml-auto"
                totalPages={Math.ceil(
                  this.state.searchResults.total / this.state.totalPerPage
                )}
                currentPage={this.state.page}
                onChange={this.setPage.bind(this)}
              />
            </Row>
          </React.Fragment>
        )}
      </div>
    );
  }
}
