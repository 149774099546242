import { Dropdown, Field as DropField, Select, Menu, Item, Hint } from "@zendeskgarden/react-dropdowns";
import { Checkbox, Field, Input, Label, Toggle } from "@zendeskgarden/react-forms";
import { Col, Row } from "@zendeskgarden/react-grid";
import {
  FooterItem
} from "@zendeskgarden/react-modals";
import { ThemeProvider } from '@zendeskgarden/react-theming';
import EditIcon from "@zendeskgarden/svg-icons/src/12/pencil-fill.svg";
import CloseIcon from "@zendeskgarden/svg-icons/src/12/x-stroke.svg";
import { EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import moment from 'moment';
import React from "react";
import { Button } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId
} from "react-google-places-autocomplete";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Loading from "../../../components/loading";
import ReactChipInput from "../../../components/opportunity/chip-input";
import Maps from "../../../components/opportunity/maps";
import PillChip from "../../../components/opportunity/pill";
import { AlertService } from "../../../services/alert.service";
import CriteriaService from "../../../services/criteria.service";
import DictionaryService from "../../../services/dictionary.service";
import OpportunityService from "../../../services/opportunity.service";
import UserEvaluationService from "../../../services/user-evaluation.service";
import ReactGA from "react-ga4";
import { t } from "../../../translations/translation.service";
import { theme } from '../../../util/colorButton';
import style from "./index.module.scss";
import { Tab, TabList, TabPanel, Tabs } from "@zendeskgarden/react-tabs";
import { coinType, priceRange } from '../../../util/opportunity';
import carreiraService from "../../../services/carreiras.service";
import { AuthenticationService } from '../../../services/auth.service';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

const defaultMaskOptions = {
  prefix:'',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: '.',
  allowDecimal: true,
  decimalSymbol: ',',
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: null,
  allowNegative: false,
  allowLeadingZeroes: false,
}

const priorityList = [
  { label: 'Alta', value: 1 },
  { label: 'Média', value: 2 },
  { label: 'Baixa', value: 3 }
]

const linkOfficeForm = process.env.REACT_APP_LINK_OFFICE_FORMS_OPP_DETAIL;

class CreateOpportunity extends React.Component<any> {
  state = {
    loading: false,
    selectedLocation: null,
    opportunityForm: {
      id: null,
      title: "",
      client: "",
      empresa: "",
      isPublic: false,
      label: "2",
      location: [] as any[],
      range: 50,
      detail: EditorState.createEmpty(),
      detailEn: EditorState.createEmpty(),
      homeOffice: false,
      criterias: [] as any[],
      interests: [] as any[],
      careers: [] as any[],
      evaluations: [] as any[],
      pcd: false,
      mosaicItems: [] as any[],
      sendToQueue: false,
      global: false,
      pretensao: {
        tipo: {
          pj: {
            moeda: "-",
            valor: "-",
            valorSalario: 0
          },
          clt: {
            moeda: "-",
            valor: "-",
            valorSalario: 0
          },
          others: {
            moeda: "-",
            valor: "-",
            valorSalario: 0
          }
         } as any,
      },
      opportunityType: {
        remote: false,
        hybrid: false,
        inPerson: false,
      },
      hiringModel: {
        clt: false,
        pj: false,
        others: false,
      },
      selectedPriority: priorityList[0],
      priorities: priorityList
    },
    criteriaList: [] as any[],
    criteriaFilter: [] as any[],
    openCriteriaModal: false,
    openInterestsModal: false,
    openCareersModal: false,
    openEvaluationsModal: false,
    newCriteriaModal: false,
    criteriaModel: null as any,
    critriaObjectIndex: -1,
    interestList: [] as any[],
    careersList: [] as any[],
    evaluationsList: [] as any[],
    idOpportunity: '',
    date: '',
    ptTemplate: {
      carreiras: [] as any,
      euquero: [] as any,
      templateOportunidade: ''
    },
    enTemplate: {
      carreiras: [] as any,
      euquero: [] as any,
      templateOportunidade: ''
    },
    activateKanban: false,
    clt: false,
    pj: false,
    others: false,
    hibrido: false,
    presencial: false,
    contractTypeArray: [] as string[],
    showPretensao: false,
    carreira: [],
    newCareer: false,
    keywords: [] as string[],
    language: ""
  };

  constructor(props: any) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.handleDescriptionEnChange = this.handleDescriptionEnChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.includeLocation = this.includeLocation.bind(this);
    this.filterCriteria = this.filterCriteria.bind(this);
    this.reactGaEvent = this.reactGaEvent.bind(this);
  }

  getContractType(contract: any): string[] {
    const contractType: string[] = [];
    for (const index in contract) {
      if (contract[index]) contractType.push(index);
    }
    return contractType;
  }

  refreshTemplate() {
    const { match: { params: { id } } } = this.props;
    const { enTemplate, ptTemplate, carreira } = this.state;
    if (!id) {
      const detail = EditorState.createWithContent(stateFromHTML(ptTemplate.templateOportunidade));
      const detailEn = EditorState.createWithContent(stateFromHTML(enTemplate.templateOportunidade));
      this.setState({
        interestList: ptTemplate.euquero,
        careersList: carreira.reverse(),
        opportunityForm: {
          ...this.state.opportunityForm,
          detail,
          detailEn
        },
      });
    } else {
      this.setState({
        interestList: ptTemplate.euquero,
        careersList: carreira.reverse(),
      });
    }
  }

  async componentDidMount() {
    if (this.props.currentLanguage().includes("pt")) {
      this.setState({ language: this.props.currentLanguage()})
    } else {
      this.setState({ language: "en"})
    }
    const id = this.props.match.params.id;
    if (id) {
      this.setState({ message: undefined });
      this.setState({ loading: true });
      OpportunityService.getOpportunityToEdit(id)
        .then((resp) => {
          let contentState = stateFromHTML(resp.data.detail);
          const detail = EditorState.createWithContent(contentState);
          let contentStateEn = stateFromHTML(resp.data.detailEn ?? '');
          const detailEn = EditorState.createWithContent(contentStateEn);
          const selectedPriority = resp.data.selectedPriority
            ? resp.data.selectedPriority
            : priorityList[0];
          const priorities = priorityList
          if (resp.data.hiringModel) {
            this.setState({
              clt: resp.data.hiringModel.clt,
              pj: resp.data.hiringModel.pj,
              others: resp.data.hiringModel.others,
            });
          }
          if (resp.data.opportunityType) {
            this.setState({
              hibrido: resp.data.opportunityType.hybrid,
              presencial: resp.data.opportunityType.inPerson,
            })
          }
          this.setState({
            opportunityForm: { ...resp.data, detail, detailEn, selectedPriority, priorities },
            date:
              resp.data.created &&
              moment.parseZone(resp.data.created).format("DD/MM/YYYY"),
            idOpportunity: resp.data.idOpportunity,
            loading: false,
          }, () => {
            if (this.state.opportunityForm.label === "1") {
              this.setState({ activateKanban: true });
            }
            if (resp.data.pretensao && resp.data.pretensao.tipo) {
              const { pretensao } = resp.data;
              this.setState({
                opportunityForm: { ...this.state.opportunityForm, pretensao },
              });
            } else if (resp.data.pretensao) {
              const pretensao = {
                tipo: {
                  pj: {
                    moeda: "-",
                    valor: "-",
                    valorSalario: 0
                  },
                  clt: {
                    moeda: "-",
                    valor: "-",
                    valorSalario: 0
                  },
                  others: {
                    moeda: "-",
                    valor: "-",
                    valorSalario: 0
                  }
                }
              }
              if (resp.data.hiringModel && resp.data.hiringModel.clt) {
                pretensao.tipo.clt.moeda = resp.data.pretensao.moeda;
                pretensao.tipo.clt.valor = resp.data.pretensao.valor;
              }
              if (resp.data.hiringModel && resp.data.hiringModel.pj) {
                pretensao.tipo.pj.moeda = resp.data.pretensao.moeda;
                pretensao.tipo.pj.valor = resp.data.pretensao.valor;
              }
              if (resp.data.hiringModel && resp.data.hiringModel.others) {
                pretensao.tipo.others.moeda = resp.data.pretensao.moeda;
                pretensao.tipo.others.valor = resp.data.pretensao.valor;
              }

              this.setState({
                opportunityForm: { ...this.state.opportunityForm, pretensao }
              }, () => {
                this.setState({
                  contractTypeArray: this.getContractType(this.state.opportunityForm.hiringModel),
                });
              });
            } else {
              const pretensao = {
                tipo: {
                  pj: {
                    moeda: "-",
                    valor: "-",
                    valorSalario: 0
                  },
                  clt: {
                    moeda: "-",
                    valor: "-",
                    valorSalario: 0
                  },
                  others: {
                    moeda: "-",
                    valor: "-",
                    valorSalario: 0
                  }
                }
              }
              this.setState({
                opportunityForm: { ...this.state.opportunityForm, pretensao }
              }, () => {
                this.setState({
                  contractTypeArray: this.getContractType(this.state.opportunityForm.hiringModel),
                });
              });
            }
          })
        })
        .catch((error) => {
          console.error(error);
          this.setState({ message: "Vaga não encontrada" });
          this.setState({ loading: false });
        });
    } else {
      this.setState({ loading: false });
      this.setState({ message: "Vaga não encontrada" });
    }

    CriteriaService.getCriteriaMatchTemplate().then((criteriaList) => {
      this.setState({
        criteriaList: criteriaList.data,
        criteriaFilter: criteriaList.data,
      });
    });

    UserEvaluationService.getEvaluationList().then((evaliationList) => {
      this.setState({
        evaluationsList: evaliationList.data,
      });
    });

    const getTemplateEn = (await DictionaryService.createTemplateEnUs());
    const getTemplatePort = (await DictionaryService.createTemplate());
    const carreira = (await carreiraService.getAllCarreiras());
    this.setState({
      ptTemplate: getTemplatePort.data,
      enTemplate: getTemplateEn.data,
      carreira: carreira.data,
    }, () => {
      this.refreshTemplate();
    });
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevProps.currentLanguage !== this.props.currentLanguage) {
      this.refreshTemplate();
    }
    if (
      prevState.clt !== this.state.clt ||
      prevState.pj !== this.state.pj ||
      prevState.others !== this.state.others
    ) {
      const hiringModel = {
        clt: this.state.clt,
        pj: this.state.pj,
        others: this.state.others,
      }
      this.setState({
        opportunityForm: { ...this.state.opportunityForm, hiringModel}
      },
      () => this.setState({
        contractTypeArray: this.getContractType(this.state.opportunityForm.hiringModel)
      }, () => {
        if (!this.state.pj) {
          const { pretensao } = this.state.opportunityForm;
          pretensao.tipo.pj = { moeda: '-', valor: '-' }
          this.setState({ opportunityForm: { ...this.state.opportunityForm, pretensao }})
        }
        if (!this.state.clt) {
          const { pretensao } = this.state.opportunityForm;
          pretensao.tipo.clt = { moeda: '-', valor: '-' }
          this.setState({ opportunityForm: { ...this.state.opportunityForm, pretensao }})
        }
        if (!this.state.others) {
          const { pretensao } = this.state.opportunityForm;
          pretensao.tipo.others = { moeda: '-', valor: '-' }
          this.setState({ opportunityForm: { ...this.state.opportunityForm, pretensao }})
        }
      }));
    }
  }

  isInValidFields = (self: any): boolean => {
    let isInvalid = false;
    self.state.contractTypeArray.forEach((tipo: string) => {
      if (self.state.opportunityForm?.pretensao?.tipo[tipo]) {
        if (
          ((self.state.opportunityForm.pretensao.tipo[tipo].moeda.includes('BRL' || 'USD' || 'EUR'))
            &&
          (self.state.opportunityForm.pretensao.tipo[tipo].valor.includes('-')))
            ||
          ((self.state.opportunityForm.pretensao.tipo[tipo].moeda.includes('-'))
            &&
          (self.state.opportunityForm.pretensao.tipo[tipo].valor.trim() !==
            "-"))
        ) {
          isInvalid = true;
          return isInvalid;
        }
      }
    });
    return isInvalid;
  };

  handleValueChange = (event: React.ChangeEvent<HTMLInputElement>, tipo: any) => {
    const { target } = event;

    this.setState({
      opportunityForm: {
        ...this.state.opportunityForm,
        pretensao: {
          ...this.state.opportunityForm.pretensao,
          tipo: {
            ...this.state.opportunityForm.pretensao.tipo,
            [tipo]: {
             ...this.state.opportunityForm.pretensao.tipo[tipo],
             valorSalario: target.value 
            }
          },
        },
      },
    })
  }

  handleIsPublic = (isPublic: boolean) => {
    if(!isPublic) {
      return this.setState({
        opportunityForm: {
          ...this.state.opportunityForm,
          isPublic: false,
        },
      });
    }

    this.setState({
      activateKanban: true,
      opportunityForm: {
        ...this.state.opportunityForm,
        isPublic: true,
      },
    });
  }

  handleInputChange(event: any) {
    const target = event.target;
    if (target) {
      const value = target.type === "checkbox" ? target.checked : target.value;
      const name = target.name;
      if (
        name === "hibrido" ||
        name === "presencial" ||
        name === "clt" ||
        name === "pj" ||
        name === "others" ||
        name === "activateKanban"
      ) {
        this.setState({
          [name]: value,
        });
      }
      this.setState({
        opportunityForm: { ...this.state.opportunityForm, [name]: value },
      });
    } else {
      this.setState({
        opportunityForm: { ...this.state.opportunityForm, detail: event },
      });
    }
  }

  handleDescriptionChange(event: any) {
    this.setState({
      opportunityForm: { ...this.state.opportunityForm, detail: event },
    });
  }
  handleDescriptionEnChange(event: any) {
    this.setState({
      opportunityForm: { ...this.state.opportunityForm, detailEn: event },
    });
  }

  handleSubmit(event: any) {
    event.preventDefault();
  }

  removeLocation = (index: number) => {
    const location = this.state.opportunityForm.location.slice();
    location.splice(index, 1);
    this.setState(
      {
        opportunityForm: { ...this.state.opportunityForm, location: [] },
      },
      () =>
        this.setState({
          opportunityForm: { ...this.state.opportunityForm, location },
        })
    );
  };

  includeLocation(data: any) {
    geocodeByPlaceId(data.value.place_id)
      .then((geocode) => {
        let location = this.state.opportunityForm.location.slice();
        if (!location.some((item) => {
          return item.place_id === geocode[0].place_id;
        })) {
          location.push(geocode[0]);
          this.setState(
            {
              opportunityForm: { ...this.state.opportunityForm, location: [] },
            },
            () =>
              this.setState({
                opportunityForm: { ...this.state.opportunityForm, location },
              })
          );
        }
      })
      .catch((error) => console.error(error));
  }

  addCriteria(criteria: any): void {
    let criterias = this.state.opportunityForm.criterias.slice();
    const findIndex = criterias.findIndex(
      (_criteria: any) => _criteria.nome === criteria.nome
    );
    if (findIndex > -1) {
      criterias.splice(findIndex, 1);
    } else {
      criterias.push(criteria);
    }
    this.setState({
      opportunityForm: { ...this.state.opportunityForm, criterias },
    });
  }

  addInterest(interest: any): void {
    let interests = this.state.opportunityForm.interests.slice();
    const findIndex = interests.findIndex(
      (_interest: any) => _interest._id === interest._id
    );
    if (findIndex > -1) {
      interests.splice(findIndex, 1);
    } else {
      interests.push(interest);
    }
    this.setState({
      opportunityForm: { ...this.state.opportunityForm, interests },
    });
  }

  addCareers(career: any): void {
    let careers = this.state.opportunityForm.careers.slice();
    const findIndex = careers.findIndex(
      (_interest: any) => _interest._id === career._id
    );
    if (findIndex > -1) {
      careers.splice(findIndex, 1);
    } else {
      careers.push(career);
    }
    this.setState({
      opportunityForm: { ...this.state.opportunityForm, careers },
    });
  }

  addEvaluations(evaluation: any): void {
    let evaluations = this.state.opportunityForm.evaluations.slice();
    const findIndex = evaluations.findIndex(
      (_evaluation: any) => _evaluation._id === evaluation._id
    );
    if (findIndex > -1) {
      evaluations.splice(findIndex, 1);
    } else {
      evaluations.push(evaluation);
    }
    this.setState({
      opportunityForm: { ...this.state.opportunityForm, evaluations },
    });
  }

  selectedCriteria(criteria: any): boolean {
    return this.state.opportunityForm.criterias.find(
      (_criteria: any) => _criteria.nome === criteria.nome
    );
  }

  selectedInterest(interest: any): boolean {
    return this.state.opportunityForm.interests.find(
      (_interest: any) => _interest._id === interest._id
    );
  }

  selectedCareer(career: any): boolean {
    return this.state.opportunityForm.careers.find(
      (_career: any) => _career._id === career._id
    );
  }

  selectedEvaluations(evaluations: any): boolean {
    return this.state.opportunityForm.evaluations.find(
      (_evaluations: any) => _evaluations._id === evaluations._id
    );
  }

  setNecessary(index: number): void {
    let criterias = this.state.opportunityForm.criterias.slice();
    criterias.map((c, i) => {
      if (i === index) {
        c.necessary = !c.necessary;
      }
      return c;
    });
    this.setState({
      opportunityForm: { ...this.state.opportunityForm, criterias },
    });
  }

  filterCriteria(event: any): void {
    const target = event.target;
    if (target) {
      const value = target.value;
      const criteriaFilter = this.state.criteriaList.filter(
        (criteria) =>
          (criteria.nome as string)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          (criteria.termos as any[]).filter((term) =>
            term.toLowerCase().includes(value.toLowerCase())
          ).length > 0
      );
      this.setState({
        criteriaFilter,
      });
    } else {
      this.setState({
        criteriaFilter: this.state.criteriaList,
      });
    }
  }

  openNewCriteria(): void {
    this.setState({
      newCriteriaModal: true,
      criteriaModel: { termos: [], nome: undefined },
      critriaObjectIndex: -1,
    });
  }

  closeNewCriteria(): void {
    this.setState({
      newCriteriaModal: false,
      criteriaModel: null,
      critriaObjectIndex: -1,
    });
  }

  saveNewCriteria(): void {
    //TODO: salvar via api e atualizar lista
    const nome = this.state.criteriaModel.nome.trim()
    this.setState({
      criteriaModel: {
        ...this.state.criteriaModel,
        nome
      }
    }, () => {
      let criterias = this.state.opportunityForm.criterias.slice();
      if (this.state.critriaObjectIndex > -1) {
        criterias[this.state.critriaObjectIndex] = this.state.criteriaModel;
      } else {
        criterias.push(this.state.criteriaModel);
      }
      this.setState({
        newCriteriaModal: false,
        opportunityForm: { ...this.state.opportunityForm, criterias },
        criteriaModel: null,
        critriaObjectIndex: -1,
      });
    })

    if (this.state.opportunityForm.global) {
      const data = {
        nome: this.state.criteriaModel.nome,
        termos: this.state.criteriaModel.termos,
      };

      CriteriaService.createCriteriaMatchTemplate(data)
        .then((resp) => {
          this.setState({
            criteriaList: [...this.state.criteriaList, resp.data],
            criteriaFilter: [...this.state.criteriaFilter, resp.data],
          });
        })
        .catch((err) => console.error(err.message));
    }
  }

  editCriteria(criteria: any, index: number): void {
    this.setState({
      newCriteriaModal: true,
      criteriaModel: criteria,
      critriaObjectIndex: index,
    });
  }

  addTerm = (value: string) => {
    const terms = this.state.criteriaModel.termos.slice();
    terms.push(value.trim());
    this.setState({
      criteriaModel: { ...this.state.criteriaModel, termos: terms },
    });
  };

  removeTerm = (index: number) => {
    const terms = this.state.criteriaModel.termos.slice();
    terms.splice(index, 1);
    this.setState({
      criteriaModel: { ...this.state.criteriaModel, termos: terms },
    });
  };

  changeRangeEvent = (range: number) => {
    this.setState({
      opportunityForm: { ...this.state.opportunityForm, range },
    });
  };

  addMosaicItem = (value: string) => {
    const mosaicItems = this.state.opportunityForm.mosaicItems.slice();
    mosaicItems.push({ nome: value, peso: 3 });
    this.setState({
      opportunityForm: { ...this.state.opportunityForm, mosaicItems },
    });
  };

  removeMosaicItem = (index: number) => {
    const mosaicItems = this.state.opportunityForm.mosaicItems.slice();
    mosaicItems.splice(index, 1);
    this.setState({
      opportunityForm: { ...this.state.opportunityForm, mosaicItems },
    });
  };

  updateSelectedItem(mosaic: any) {
    this.setState({
      opportunityForm: {
        ...this.state.opportunityForm,
        mosaicSelectedItems: mosaic,
      },
    });
  }

  addChip = (value: string) => {
    const keywords = this.state.keywords.slice();
    keywords.push(value.trim());
    this.setState({ keywords: keywords });
  };

  removeChip = (index: number) => {
    const keywords = this.state.keywords.slice();
    keywords.splice(index, 1);
    this.setState({ keywords });
  };

  deleteCarreira = async (career: string, index: number) => {
    try {
      await carreiraService.deleteCarreira(career);
      const careersList = this.state.careersList.slice();
      careersList.splice(index, 1);
      this.setState({ careersList });
    } catch(error) {
      console.error(error);
    }
  }

  checkDuplicateCarreira() {
    const check = this.state.careersList.find(
      (career: any) => this.state.keywords.find(
        (key: any) => career.nome.toUpperCase() === key.toUpperCase()
      )
    );
    return check;
  }

  saveCarreira = async () => {
    const id = AuthenticationService.getUser()._id;
    if (this.checkDuplicateCarreira()) {
      AlertService.error(t('Ops parece que essa carreira já existe'));
      this.setState({ openCareersModal: false });
      return
    }
    const keywordList = this.state.keywords.map(
      (keyword: string) => {
        return (
          {
            nome: keyword,
            created: new Date(),
            user: id,
          }
        )
      }
    )
    try {
      await carreiraService.createCarreira(id, keywordList);
      const careersList = await carreiraService.getAllCarreiras();
      this.setState({ careersList: careersList.data.reverse(), keywords: [] });
    } catch (error: any) {
      console.error(error.response);
      this.setState({ openCareersModal: false });
      AlertService.error(error.response.data.message)
    }
  }

  reactGaEvent() {
    ReactGA.event({
      category: "Recruitment-actions",
      action: "Criação de Oportunidade",
      label: "oportunidade",
    });
  }

  async saveOpp(): Promise<void> {
    if (!this.state.opportunityForm.title) {
      return AlertService.warn(t("Informe o título da oportunidade."));
    }
    if (!this.state.opportunityForm.client) {
      return AlertService.warn(t("Informe o nome da empresa."));
    }
    if (
      (this.state.opportunityForm.location === undefined ||
        this.state.opportunityForm.location.length === 0) &&
      !this.state.opportunityForm.homeOffice
    ) {
      return AlertService.warn(t("Informe o local da oportunidade."));
    }
    if (
      !this.state.hibrido &&
      !this.state.presencial &&
      !this.state.opportunityForm.homeOffice
    ) {
      return AlertService.warn(t("Informe o tipo de oportunidade."));
    }
    if (
      !(this.state.clt ||
        this.state.pj ||
        this.state.others)
    ) {
      return AlertService.warn(t("Informe o modelo de contratação."));
    }
    if (this.state.opportunityForm.selectedPriority.label === 'select') {
      return AlertService.warn(t("Informe a prioridade da oportunidade."));
    }
    const validCompensationExpectation = this.isInValidFields(this);
    if (validCompensationExpectation) {
      AlertService.error(t("Pretensão salarial obrigatória. Os campos moeda e faixa devem ser preenchidos"));
      return;
    }
    
    if (
      !this.state.opportunityForm.mosaicItems ||
      this.state.opportunityForm.mosaicItems.length === 0
    ) {
      return AlertService.warn(t("Informe pelo menos 1 peça no mosaico."));
    }
    if (!this.state.opportunityForm.detail.getCurrentContent().getPlainText()) {
      return AlertService.warn(t("Informe os detalhes da oportunidade."));
    }
    if (
      this.state.opportunityForm.criterias.length === 0 ||
      !this.state.opportunityForm.criterias.some(
        (criteria) => criteria.necessary
      )
    ) {
      return AlertService.warn(
        t("Informe pelo menos um critério necessário à oportunidade.")
      );
    }
    if (this.state.opportunityForm.interests.length === 0) {
      return AlertService.warn(t("Informe um interesse à oportunidade."));
    }
    if (this.state.opportunityForm.careers.length === 0) {
      return AlertService.warn(
        t("Informe uma área de atuação à oportunidade.")
      );
    }
    try {
      this.setState({ loading: true });
      let opportinity = { ...this.state.opportunityForm, detail: "", detailEn: "" };
      opportinity.detail = stateToHTML(
        this.state.opportunityForm.detail.getCurrentContent()
      );
      opportinity.detailEn = stateToHTML(
        this.state.opportunityForm.detailEn.getCurrentContent()
      );
      opportinity.label = this.state.activateKanban ? "1" : "2";
      opportinity.opportunityType = {
        remote: this.state.opportunityForm.homeOffice,
        hybrid: this.state.hibrido,
        inPerson: this.state.presencial,
      }
      opportinity.hiringModel = {
        clt: this.state.clt,
        pj: this.state.pj,
        others: this.state.others,
      }

      if (this.state.opportunityForm.id && this.props.location.pathname.endsWith('edit')) {
        opportinity.sendToQueue = true;
        await OpportunityService.updateOpportinity(opportinity);
        AlertService.success(t("Oportunidade alterada com sucesso!"));
        this.setState({ loading: false });
        this.props.history.push(`/opportunity/${this.state.opportunityForm.id}`);
      } else {
        const resp = await OpportunityService.createOpportinity(opportinity);
        AlertService.success(t("Oportunidade criada com sucesso!"));
        this.setState({ loading: false });
        this.reactGaEvent();
        this.props.history.push(`/opportunity/${resp.data._id}`);
        console.log(this.state.opportunityForm);
      }
    } catch (error) {
      this.setState({ loading: false });
      AlertService.error(t("Aconteceu algum erro inesperado."));
      console.error(error);
    }
  }

  render() {
    return (
      <div className="container">
        <Loading show={this.state.loading} />
        {/* <form onSubmit={this.handleSubmit}> */}
        <ThemeProvider theme={theme as any}>
          <Row className="w-100 m-0">
            <Col sm={12} md={8}>
              <Row className={style["opp-new"]}>
                <Col className="flex-column" sm={12}>
                  <div
                    className={`${style["border-bottom-details"]} d-flex align-items-center`}
                  >
                    <span className={`u-fs-lg u-semibold`}>
                      {this.state.opportunityForm.title !== ""
                        ? t("Editar Oportunidade")
                        : t("Nova Oportunidade")}
                      :
                    </span>
                    {this.state.idOpportunity && (
                      <span className="ml-auto">
                        ID: {this.state.idOpportunity}
                      </span>
                    )}
                  </div>
                  {this.state.date && (
                    <Field className="d-flex justify-content-end mb-4">
                      <span>
                        {t("Criada em")}: {this.state.date}
                      </span>
                    </Field>
                  )}
                </Col>
                <Col sm={12}>
                  <div className={style["row-title-toggle"]}>
                    <div>
                      <Field className="mt-2">
                        <Label className={style["float"]}>{t("Título")}</Label>
                        <Input
                          className={style["input-border"]}
                          value={this.state.opportunityForm.title}
                          onChange={this.handleInputChange}
                          name="title"
                        ></Input>
                      </Field>
                    </div>
                    <div className="d-flex">
                      <Field className="d-flex justify-content-end mt-4 mr-2 ml-2">
                        <Toggle
                          name="isPublic"
                          checked={this.state.opportunityForm.isPublic}
                          onChange={() => this.handleIsPublic(!this.state.opportunityForm.isPublic)}
                        >
                          <Label>{t("Exibir no Mural")}</Label>
                        </Toggle>
                      </Field>
                      <Field className="d-flex justify-content-end mt-4">
                        <Toggle
                          name="activateKanban"
                          checked={this.state.activateKanban}
                          onChange={() => this.setState({ activateKanban: !this.state.activateKanban })}
                        >
                          <Label>{t("Ativar no Kanban")}</Label>
                        </Toggle>
                      </Field>
                    </div>
                  </div>
                </Col>
                <Col sm={12}>
                  <div className={style["row-client-company"]}>
                    <div>
                      <Field className="mt-3">
                        <Label className={style["float"]}>{t("Empresa")}</Label>
                        <Input
                          value={this.state.opportunityForm.client}
                          className={style["input-border"]}
                          onChange={this.handleInputChange}
                          name="client"
                        ></Input>
                      </Field>
                    </div>
                    <div>
                      <Field className="mt-3">
                        <Label className={style["float"]}>
                          {t("Nome Cliente")}
                        </Label>
                        <Input
                          value={this.state.opportunityForm.empresa}
                          className={style["input-border"]}
                          onChange={this.handleInputChange}
                          name="empresa"
                        ></Input>
                      </Field>
                    </div>
                  </div>
                </Col>
                <Col className={style["row-opp-type-and-hiring"]}>
                  <div>
                    <span className={style["title-opp-hire"]}>
                      {t("Tipo de oportunidade")}:
                    </span>
                    <section className={style["checkbox-button-opp"]}>
                      <Field>
                        <Checkbox
                          name="hibrido"
                          checked={this.state.hibrido}
                          onChange={this.handleInputChange}
                        >
                          <Label>{t("Híbrida")}</Label>
                        </Checkbox>
                      </Field>
                      <Field>
                        <Checkbox
                          name="homeOffice"
                          checked={this.state.opportunityForm.homeOffice}
                          onChange={this.handleInputChange}
                        >
                          <Label>{t("Remota")}</Label>
                        </Checkbox>
                      </Field>
                      <Field>
                        <Checkbox
                          name="presencial"
                          checked={this.state.presencial}
                          onChange={this.handleInputChange}
                        >
                          <Label>{t("Presencial")}</Label>
                        </Checkbox>
                      </Field>
                    </section>
                  </div>
                  <div>
                    <span className={style["title-opp-hire"]}>
                      {t("Modelo de contratação")}:
                    </span>
                    <section className={style["checkbox-button-opp"]}>
                      <Field>
                        <Checkbox
                          name="clt"
                          checked={this.state.clt}
                          onChange={this.handleInputChange}
                        >
                          <Label>{t("CLT")}</Label>
                        </Checkbox>
                      </Field>
                      <Field>
                        <Checkbox
                          name="pj"
                          checked={this.state.pj}
                          onChange={this.handleInputChange}
                        >
                          <Label>{t("PJ")}</Label>
                        </Checkbox>
                      </Field>
                      <Field>
                        <Checkbox
                          name="others"
                          checked={this.state.others}
                          onChange={this.handleInputChange}
                        >
                          <Label>{t("Outros")}</Label>
                        </Checkbox>
                      </Field>
                    </section>
                  </div>
                </Col>
                <Col sm={12}>
                  <div className={"mt-2 mb-1"}>
                    <Label>Pretensão Salarial</Label>
                    {this.state.contractTypeArray.length > 0 ? (
                      <button
                        onClick={() => this.setState({ showPretensao: true })}
                        className={style["opp-button-contractType"]}
                      >
                        {this.state.opportunityForm.pretensao.tipo &&
                          this.state.contractTypeArray.map((tipo: string, index: number) => {
                            return (
                              (
                                tipo === "pj" ||
                                tipo === "clt" ||
                                tipo === "others"
                              ) &&
                              (
                                <div>
                                  <p className={style["create-info-title"]} key={(index + 20).toString()}>
                                    {tipo.toUpperCase()}:{" "}
                                    <span className={style["create-info-content"]}>
                                      {`${this.state.opportunityForm.pretensao.tipo[
                                          tipo
                                        ].moeda} 
                                        -                                      
                                        ${this.state.opportunityForm.pretensao.tipo[
                                          tipo
                                        ].valor}`
                                        } 
                                    </span>
                                  </p>
                                  <p className={[style["create-info-title"], style["undeline"]].join(" ")}>
                                    Pretensão salarial:{" "}
                                    <span className={style["create-info-content"]}>
                                      {this.state.opportunityForm.pretensao.tipo[tipo].valorSalario}
                                    </span>
                                  </p>
                                </div>
                              )
                            );
                          })}
                      </button>
                    ) : (
                      <button
                        onClick={() => this.setState({ showPretensao: true })}
                        className={style["opp-button-contractType"]}
                      >
                        {t("Sem Informação")}
                      </button>
                    )}
                  </div>
                </Col>
                <Col sm={12}>
                  <Field className="mt-1">
                    <Label className={style["float"]}>{t("Local")}</Label>
                    <div className={style["autocomplete"]}>
                      {this.state.language.length > 0 && (
                        <GooglePlacesAutocomplete
                          apiKey="AIzaSyC5_B8f8Ug5dmvdYe3QWv6N2YoKszO0gK4"
                          apiOptions={{ language: this.state.language, region: this.state.language }}
                          minLengthAutocomplete={3}
                          selectProps={{
                            value: this.state.selectedLocation,
                            onChange: this.includeLocation,
                            placeholder: t("Localidade"),                    
                          }}
                        />
                      )}
                      <div className={style["location-container"]}>
                        {this.state.opportunityForm.location.map(
                          (location: any, index: number) => (
                            <Label>
                              <PillChip
                                key={(index + 30).toString()}
                                chip={location.formatted_address}
                                index={index}
                                onRemove={this.removeLocation}
                              ></PillChip>
                            </Label>
                          )
                        )}
                      </div>
                    </div>
                    </Field>
                    <Field>
                  </Field>
                  {this.state.opportunityForm.location.length > 0 && (
                    <Maps
                      key={"maps-locations"}
                      location={this.state.opportunityForm.location}
                      range={this.state.opportunityForm.range}
                      changeRangeEvent={this.changeRangeEvent}
                    ></Maps>
                  )}
                  <div>
                      <Dropdown
                        selectedItem={this.state.opportunityForm.selectedPriority.label}
                        onSelect={(value) => {
                          const selectedPriority = value;
                          this.setState({
                            opportunityForm: {...this.state.opportunityForm, selectedPriority}
                          })
                        }}
                        downshiftProps={{itemToString: (item: any) => item && item.label}}
                      >
                        <DropField>
                          <Label className="mt-3">Prioridade:</Label>
                          <Hint>{t("Selecione a prioridade para sua oportunidade")}</Hint>
                          <Select className="mb-2">{this.state.opportunityForm.selectedPriority.label}</Select>
                        </DropField>
                        <Menu>
                          {this.state.opportunityForm.priorities?.map(option => (
                            <Item key={option.label} value={option}>
                              {option.label}
                            </Item>
                          ))}
                        </Menu>
                      </Dropdown>
                  </div>
                </Col>
                <Col sm={12}>
                  <Field className="mt-2">
                    <Label>{t("Detalhes da Oportunidade")}</Label>
                    <Label className={style['link-form-office']}>
                      <a
                        href={linkOfficeForm}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {t('(Posso te ajudar a descrever a oportunidade?)')}
                      </a>
                    </Label>
                  </Field>
                  <Tabs>
                    <TabList>
                      <Tab item="tab-pt">PT</Tab>
                      <Tab item="tab-en">EN</Tab>
                    </TabList>
                    <TabPanel item="tab-pt">
                      <Editor
                        editorState={this.state.opportunityForm.detail}
                        wrapperClassName={style["wrapper"]}
                        editorClassName={"pl-3 pr-3"}
                        onEditorStateChange={this.handleDescriptionChange}
                      />
                    </TabPanel>
                    <TabPanel item="tab-en">
                      <Editor
                        editorState={this.state.opportunityForm.detailEn}
                        wrapperClassName={style["wrapper"]}
                        editorClassName={"pl-3 pr-3"}
                        onEditorStateChange={this.handleDescriptionEnChange}
                      />
                    </TabPanel>
                  </Tabs>
                </Col>
                <Col sm={12}>
                  <Row className={style["match"] + " m-0"}>
                    <span>{t("Criterios para o Match")}</span>
                    <div className="ml-auto">
                      <span
                        className="mr-2 u-link orange"
                        onClick={() =>
                          this.setState({ openCriteriaModal: true })
                        }
                      >
                        {t("Incluir Termo Global")}
                      </span>
                      <span
                        className="u-link"
                        onClick={() => this.openNewCriteria()}
                      >
                        {t("Criar Novo Termo")}
                      </span>
                    </div>
                  </Row>
                  <div
                    key="criteria"
                    className={style["match-criteria"] + " mb-4"}
                  >
                    {this.state.opportunityForm.criterias?.map(
                      (criteria: any, index: number) => (
                        <div
                          className={style["card-criteria"]}
                          key={criteria.nome + " " + index}
                        >
                          <div
                            className={
                              style["pill"] + " m-0 " + style["active"]
                            }
                          >
                            <span>{criteria.nome}</span>
                            <span className="ml-auto">
                              <img
                                className={style["close"]}
                                alt="close"
                                src={CloseIcon}
                                onClick={() => this.addCriteria(criteria)}
                              ></img>
                            </span>
                          </div>
                          <div>
                            <p className="u-semibold mb-0">
                              {t("Sinonimos")}:
                              <img
                                className={style["close"]}
                                alt="close"
                                src={EditIcon}
                                onClick={() =>
                                  this.editCriteria(criteria, index)
                                }
                              ></img>
                            </p>
                            {criteria.termos?.map((term: any, index: number) => (
                              <p key={(index + 50).toString()} className="mb-0">{term}</p>
                            ))}
                            <Field className="mt-1">
                              <Toggle
                                checked={criteria.necessary}
                                onChange={() => this.setNecessary(index)}
                              >
                                <Label
                                  className={`${
                                    criteria.necessery ? "active" : ""
                                  }`}
                                >
                                  <span style={{ fontWeight: 700 }}>
                                    {t("Necessario")}
                                  </span>
                                </Label>
                              </Toggle>
                            </Field>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </Col>

                <Col sm={12}>
                  <Field className="text-right mb-4 mt-n1">
                    <Toggle
                      name="pcd"
                      checked={this.state.opportunityForm.pcd}
                      onChange={this.handleInputChange}
                    >
                      <Label
                        className={`home-office ${
                          this.state.opportunityForm.pcd ? "active" : ""
                        }`}
                      >
                        <span style={{ fontWeight: 700 }}>
                          {t("Preferencial para Pessoa com Deficiencia (PCD)")}
                        </span>
                      </Label>
                    </Toggle>
                  </Field>
                </Col>
              </Row>
            </Col>

            <Col sm={12} md={4}>
              <Row>
                <Col sm={12}>
                  <div className={style["float-div"]}>
                    <div className={style["opp-props"]}>
                      <p
                        className="u-fs-md u-semibold"
                        style={{ color: "white" }}
                      >
                        {t("Mosaico")}
                      </p>
                      <ReactChipInput
                        classes={""}
                        chips={this.state.opportunityForm.mosaicItems}
                        propKey="nome"
                        onSubmit={(value: string) => this.addMosaicItem(value)}
                        onRemove={(index: number) =>
                          this.removeMosaicItem(index)
                        }
                      />

                      <Row className={style["match"] + " mt-4 m-0"}>
                        <span style={{ color: "white" }}>
                          {t("Interesses")}
                        </span>
                        <div className="ml-auto">
                          <span
                            className="mr-2 u-link orange"
                            onClick={() =>
                              this.setState({ openInterestsModal: true })
                            }
                          >
                            {t("Adicionar")}
                          </span>
                        </div>
                      </Row>
                      <div
                        key="interest"
                        className={style["interests-careers"] + " mb-4"}
                      >
                        {this.state.opportunityForm.interests?.map(
                          (interest: any, index: number) => (
                            <div
                              key={interest.nome + " " + index}
                              className={style["pill"] + " m-1 " + style["active"]}
                              style={{ display: "inline-flex" }}
                            >
                              <span>{t(interest.nome)}</span>
                              <span className="ml-auto">
                                <img
                                  className={style["close"]}
                                  alt="close"
                                  src={CloseIcon}
                                  onClick={() => this.addInterest(interest)}
                                ></img>
                              </span>
                            </div>
                          )
                        )}
                      </div>
                      <Row className={style["match"] + " mt-4 m-0"}>
                        <span style={{ color: "white" }}>
                          {t("Função")}
                        </span>
                        <div className="ml-auto">
                          <span
                            className="mr-2 u-link orange"
                            onClick={() =>
                              this.setState({ openCareersModal: true })
                            }
                          >
                            {t("Adicionar")}
                          </span>
                        </div>
                      </Row>
                      <div className={style["interests-careers"] + " mb-4"}>
                        {this.state.opportunityForm.careers?.map(
                          (career: any, index: number) => (
                            <div
                              key={career.nome + " " + index++}
                              className={style["pill"] + " m-1 " + style["active"]}
                              style={{ display: "inline-flex" }}
                            >
                              <span>{t(career.nome)}</span>
                              <span className="ml-auto">
                                <img
                                  className={style["close"]}
                                  alt="close"
                                  src={CloseIcon}
                                  onClick={() => this.addCareers(career)}
                                ></img>
                              </span>
                            </div>
                          )
                        )}
                      </div>
                      <Row className={style["match"] + " m-0"}>
                        <span style={{ color: "white" }}>
                          {t("Avaliações")}
                        </span>
                        <div className="ml-auto">
                          <span
                            className="mr-2 u-link orange"
                            onClick={() =>
                              this.setState({ openEvaluationsModal: true })
                            }
                          >
                            {t("Adicionar")}
                          </span>
                        </div>
                      </Row>
                      <div
                        key="careers"
                        className={style["interests-careers"] + " mb-4"}
                      >
                        {this.state.opportunityForm.evaluations?.map(
                          (evaluation: any, index: number) => (
                            <div
                              key={evaluation._id + " " + index}
                              className={
                                style["pill"] + " m-1 " + style["active"]
                              }
                              style={{ display: "inline-flex" }}
                            >
                              <span>{evaluation.name}</span>
                              <span className="ml-auto">
                                <img
                                  className={style["close"]}
                                  alt="close"
                                  src={CloseIcon}
                                  onClick={() =>
                                    this.addEvaluations(evaluation)
                                  }
                                ></img>
                              </span>
                            </div>
                          )
                        )}
                      </div>

                      <div className="w-100 text-right mt-5 mb-4">
                        <Button
                          style={{
                            padding: "5px 50px",
                            borderRadius: "25px",
                            backgroundColor: "#EA951D",
                            border: "unset",
                          }}
                          onClick={() => this.saveOpp()}
                        >
                          {t("Salvar")}
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={12}></Col>
                <Col sm={12}></Col>
                <Col sm={12}></Col>
              </Row>
            </Col>
          </Row>
        </ThemeProvider>
        {/* </form> */}

        {
          // MODAL DE INCLUSÃO
          this.state.openCriteriaModal && (
            <Modal
              show={this.state.openCriteriaModal}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              onHide={() =>
                this.setState({
                  openCriteriaModal: false,
                  criteriaFilter: this.state.criteriaList,
                })
              }
              style={{ width: "70vw" }}
            >
              <Modal.Header>
                {t(
                  "Escolha os critérios que quer incluir para esta oportunidade"
                )}
              </Modal.Header>
              <Modal.Body style={{ overflowX: "hidden" }}>
                <Field className="mb-2 w-50">
                  <Input
                    className={style["input-border"]}
                    onChange={this.filterCriteria}
                    name="filter"
                    placeholder={t("Filtrar por...")}
                  ></Input>
                </Field>
                {this.state.criteriaFilter?.map(
                  (criteria: any, index: number) => (
                    <div key={index} className="d-inline">
                      <button
                        key={(index + 60).toString()}
                        className={[
                          style["pill"],
                          this.selectedCriteria(criteria)
                            ? style["active"]
                            : "",
                        ].join(" ")}
                        onClick={() => this.addCriteria(criteria)}
                      >
                        {criteria.nome}
                      </button>
                    </div>
                  )
                )}
              </Modal.Body>
              <Modal.Footer>
                <FooterItem>
                  <Button
                    style={{
                      padding: "5px 50px",
                      backgroundColor: "#A7AEB1",
                      border: "unset",
                    }}
                    onClick={() =>
                      this.setState({
                        openCriteriaModal: false,
                        criteriaFilter: this.state.criteriaList,
                      })
                    }
                  >
                    {t("OK")}
                  </Button>
                </FooterItem>
              </Modal.Footer>
            </Modal>
          )
        }
        {
          // MODAL DE INCLUSÃO INTERESSES
          this.state.openInterestsModal && (
            <Modal
              show={this.state.openInterestsModal}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              onHide={() =>
                this.setState({
                  openInterestsModal: false,
                })
              }
              style={{ width: "70vw" }}
            >
              <Modal.Header>
                {t(
                  "Escolha os interesses que quer incluir para esta oportunidade"
                )}
              </Modal.Header>
              <Modal.Body style={{ overflowX: "hidden" }}>
                {this.state.interestList?.map(
                  (interest: any, index: number) => (
                    <div key={(index + 70).toString()} className="d-inline">
                      <button
                        key={index}
                        className={[
                          style["pill"],
                          this.selectedInterest(interest)
                            ? style["active"]
                            : "",
                        ].join(" ")}
                        onClick={() => this.addInterest(interest)}
                      >
                        {t(interest.nome)}
                      </button>
                    </div>
                  )
                )}
              </Modal.Body>
              <Modal.Footer>
                <FooterItem>
                  <Button
                    style={{
                      padding: "5px 50px",
                      backgroundColor: "#A7AEB1",
                      border: "unset",
                    }}
                    onClick={() =>
                      this.setState({
                        openInterestsModal: false,
                      })
                    }
                  >
                    {t("OK")}
                  </Button>
                </FooterItem>
              </Modal.Footer>
            </Modal>
          )
        }
        {
          // MODAL DE INCLUSÃO CARREIRAS
          this.state.openCareersModal && (
            <Modal
              show={this.state.openCareersModal}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              onHide={() =>
                this.setState({
                  openCareersModal: false,
                })
              }
              style={{ width: "70vw" }}
            >
              <Modal.Header>
                {t(
                  "Escolha as carreiras que quer incluir para esta oportunidade"
                )}
              </Modal.Header>
              <Modal.Body style={{ overflowX: "hidden" }}>
                {!this.state.newCareer && (
                  <button
                    onClick={() => this.setState({ newCareer: true })}
                    className={style["newCareer-button"]}
                  >
                    {t("Adicionar nova carreira")}
                  </button>
                )}
                {this.state.newCareer && (
                  <div className={style["newCareer-container"]}>
                    <button
                      onClick={() => this.setState({ newCareer: false })}
                      className={style["close-newCareer-button"]}
                    >
                      &times;
                    </button>
                    <ReactChipInput
                      classes={style["input-search"]}
                      chips={this.state.keywords}
                      onSubmit={(value: string) => this.addChip(value)}
                      onRemove={(index: number) => this.removeChip(index)}
                    />
                    <button
                      className={
                        this.state.keywords.length > 0
                          ? style["newCareer-button"]
                          : style["disabled-newCareer-button"]
                      }
                      onClick={this.saveCarreira}
                      disabled={this.state.keywords.length > 0 ? false : true}
                    >
                      {t("Salvar")}
                    </button>
                  </div>
                )}
                {this.state.careersList?.map((career: any, index: number) => (
                  <div key={index} className={style["container-delete-button-carreira"]}>
                    <div
                      className={[
                        style["pill"],
                        this.selectedCareer(career) ? style["active"] : "",
                      ].join(" ")}
                    >
                      <button
                        key={index}
                        className={[
                          style["pill-delete"],
                          this.selectedCareer(career) ? style["active"] : "",
                        ].join(" ")}
                        onClick={() => this.addCareers(career)}
                      >
                        {t(career.nome)} {" "}
                      </button>
                      <button
                        className={[
                          style["pill-delete"],
                          this.selectedCareer(career) ? style["active"] : "",
                        ].join(" ")}
                        onClick={() => this.deleteCarreira(career._id, index)}
                      >
                        &times;
                      </button>
                    </div>
                  </div>
                ))}
              </Modal.Body>
              <Modal.Footer>
                <FooterItem>
                  <Button
                    style={{
                      padding: "5px 50px",
                      backgroundColor: "#A7AEB1",
                      border: "unset",
                    }}
                    onClick={() =>
                      this.setState({
                        openCareersModal: false,
                      })
                    }
                  >
                    {t("OK")}
                  </Button>
                </FooterItem>
              </Modal.Footer>
            </Modal>
          )
        }
        {
          // MODAL DE AVALIAÇÕES
          this.state.openEvaluationsModal && (
            <Modal
              show={this.state.openEvaluationsModal}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              onHide={() =>
                this.setState({
                  openEvaluationsModal: false,
                })
              }
              style={{ width: "70vw" }}
            >
              <Modal.Header>
                {t(
                  "Escolha as avaliacoes que quer incluir para esta oportunidade"
                )}
              </Modal.Header>
              <Modal.Body style={{ overflowX: "hidden" }}>
                {this.state.evaluationsList?.map(
                  (evaluation: any, index: number) => (
                    <div key={(index + 90).toString()} className="d-inline">
                      <button
                        key={index}
                        className={[
                          style["pill"],
                          this.selectedEvaluations(evaluation)
                            ? style["active"]
                            : "",
                        ].join(" ")}
                        onClick={() => this.addEvaluations(evaluation)}
                      >
                        {t(evaluation.name)}
                      </button>
                    </div>
                  )
                )}
              </Modal.Body>
              <Modal.Footer>
                <FooterItem>
                  <Button
                    style={{
                      padding: "5px 50px",
                      backgroundColor: "#A7AEB1",
                      border: "unset",
                    }}
                    onClick={() =>
                      this.setState({
                        openEvaluationsModal: false,
                      })
                    }
                  >
                    {t("OK")}
                  </Button>
                </FooterItem>
              </Modal.Footer>
            </Modal>
          )
        }
        {
          // MODAL DE CRIAÇÃO
          this.state.newCriteriaModal && (
            <Modal
              show={this.state.newCriteriaModal}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              onHide={() =>
                this.setState({
                  newCriteriaModal: false,
                  criteriaFilter: this.state.criteriaList,
                })
              }
              style={{ width: "70vw" }}
            >
              <Modal.Header>
                {t("Edite este critério para incluir na oportunidade")}
              </Modal.Header>
              <Modal.Body style={{ overflowX: "hidden" }}>
                <Field className="mb-2">
                  <Label className="mb-0 w-100 d-flex">
                    {t("Termo")}
                    <Field
                      className="ml-auto"
                      style={{ transform: "scale(0.9)" }}
                    >
                      <ThemeProvider theme={theme as any}>
                        <Toggle name="global" onChange={this.handleInputChange}>
                          <Label style={{ color: "#EA951D" }}>
                            {t("Global (disponível pra novas oportunidades)")}
                          </Label>
                        </Toggle>
                      </ThemeProvider>
                    </Field>
                  </Label>
                  <Input
                    className={style["input-border"]}
                    name="nome"
                    value={this.state.criteriaModel.nome}
                    onChange={(event) => {
                      this.setState({
                        criteriaModel: {
                          ...this.state.criteriaModel,
                          nome: event.target.value,
                        },
                      });
                    }}
                  ></Input>
                </Field>
                <Field className="mb-2">
                  <Label className="mb-2">{t("Adicionar Sinonimo")}</Label>
                  <ReactChipInput
                    classes={style["create-global-input"]}
                    chips={this.state.criteriaModel.termos}
                    onSubmit={(value: string) => this.addTerm(value)}
                    onRemove={(index: number) => this.removeTerm(index)}
                  />
                </Field>
              </Modal.Body>
              <Modal.Footer>
                <FooterItem>
                  <Button
                    disabled={
                      this.state.criteriaModel.nome &&
                      this.state.criteriaModel.termos.length > 0
                        ? false
                        : true
                    }
                    style={{
                      padding: "5px 50px",
                      backgroundColor: "#EA951D",
                      border: "unset",
                    }}
                    onClick={() => this.saveNewCriteria()}
                  >
                    {t("OK")}
                  </Button>
                </FooterItem>
              </Modal.Footer>
            </Modal>
          )
        }
        {
          // MODAL PRETENSÃO SALARIAL
          this.state.opportunityForm.pretensao &&
          this.state.opportunityForm.pretensao.tipo && (
            <Modal
              show={this.state.showPretensao}
              onHide={() => this.setState({ showPretensao: false })}
              centered
              size="lg"
            >
              <Modal.Header>
                <Label
                  style={{
                    fontSize: "1rem",
                    textAlign: "center",
                    marginTop: '1rem',
                  }}
                >
                  {t("Escolha a pretensão conforme o tipo de contratação")}
                </Label>
                <button
                  onClick={() => this.setState({ showPretensao: false })}
                  className={style['close-modal-button']}
                >
                  &times;
                </button>
              </Modal.Header>
              <Modal.Body>
                {this.state.contractTypeArray.map(
                  (tipo: string, index: number) => {
                    return (
                      (tipo === "pj" ||
                        tipo === "clt" ||
                        tipo === "others") && (
                        <ThemeProvider
                          theme={theme as any}
                          key={(index + 10).toString()}
                        >
                          <Label>
                            {tipo.toUpperCase()}:
                          </Label>
                          <div className="view-coin-container-pro-profile">
                            <div className="view-coin-field">
                              <Dropdown
                                selectedItem={
                                  this.state.opportunityForm.pretensao.tipo[
                                    tipo
                                  ].moeda
                                }
                                onSelect={(value: any) => {
                                  const pretensao =
                                    this.state.opportunityForm.pretensao;
                                  pretensao.tipo[tipo].moeda = value.label;
                                  this.setState({
                                    opportunityForm: {
                                      ...this.state.opportunityForm,
                                      pretensao,
                                    },
                                  });
                                }}
                                downshiftProps={{
                                  itemToString: (item: any) =>
                                    item && item.label,
                                }}
                              >
                                <Label className="view-label-input-value">
                                  {t("Moeda")}:
                                </Label>
                                <DropField className="view-select-button-moeda">
                                  <Select>
                                    <p className="mt-1">
                                      {tipo === "pj"
                                        ? this.state.opportunityForm.pretensao
                                            .tipo.pj.moeda
                                        : ""}
                                      {tipo === "clt"
                                        ? this.state.opportunityForm.pretensao
                                            .tipo.clt.moeda
                                        : ""}
                                      {tipo === "others"
                                        ? this.state.opportunityForm.pretensao
                                            .tipo.others.moeda
                                        : ""}
                                    </p>
                                  </Select>
                                </DropField>
                                <Menu
                                  popperModifiers={{
                                    preventOverflow: {
                                      boundariesElement: "viewport",
                                    },
                                  }}
                                >
                                  {coinType.map((item: any) => (
                                    <Item
                                      className="d-flex"
                                      key={(item.value + "110")}
                                      value={item}
                                    >
                                      {item.label}
                                    </Item>
                                  ))}
                                </Menu>
                              </Dropdown>
                            </div>
                            <div className="view-price-field">
                              <Dropdown
                                selectedItem={
                                  this.state.opportunityForm.pretensao.tipo[
                                    tipo
                                  ].valor
                                }
                                onSelect={(value: any) => {
                                  const pretensao =
                                    this.state.opportunityForm.pretensao;
                                  pretensao.tipo[tipo].valor = value.label;
                                  this.setState({
                                    opportunityForm: {
                                      ...this.state.opportunityForm,
                                      pretensao,
                                    },
                                  });
                                }}
                                downshiftProps={{
                                  itemToString: (item: any) =>
                                    item && item.label,
                                }}
                              >
                                <Label className="view-label-input-value">
                                {t("Faixa")}:
                                </Label>
                                <DropField className="view-select-button-prof-profile">
                                  <Select>
                                    <p className="mt-3">
                                      {tipo === "pj"
                                        ? this.state.opportunityForm.pretensao
                                            .tipo.pj.valor
                                        : ""}
                                      {tipo === "clt"
                                        ? this.state.opportunityForm.pretensao
                                            .tipo.clt.valor
                                        : ""}
                                      {tipo === "others"
                                        ? this.state.opportunityForm.pretensao
                                            .tipo.others.valor
                                        : ""}
                                    </p>
                                  </Select>
                                </DropField>
                                <Menu
                                  popperModifiers={{
                                    preventOverflow: {
                                      boundariesElement: "viewport",
                                    },
                                  }}
                                >
                                  {priceRange.map((item: any) => (
                                    <Item
                                      className="d-flex"
                                      key={(item.value + "100")}
                                      value={item}
                                    >
                                      {item.label}
                                    </Item>
                                  ))}
                                </Menu>
                              </Dropdown>
                            </div>
                            <div
                              className="view-price-field"
                              style={{
                                marginLeft: '0.5rem'
                              }}
                            >
                              <Label className="view-label-input-value">
                                {t("Valor Salário")}:
                              </Label>
                              <MaskedInput
                                className="view-select-button-valor form-control"
                                mask={createNumberMask(defaultMaskOptions)}
                                name="valor"
                                value={this.state.opportunityForm.pretensao.tipo[tipo].valorSalario}
                                onChange={ (e: React.ChangeEvent<HTMLInputElement>) => this.handleValueChange(e, tipo) }
                              />
                            </div>       
                          </div>
                        </ThemeProvider>
                      )
                    );
                  }
                )}
              </Modal.Body>
            </Modal>
          )
        }
      </div>
    );
  }
}

const mapStateToProps = (store: {
  changeLanguageReducer: { currentLanguage: string };
}) => ({
  currentLanguage: store.changeLanguageReducer.currentLanguage,
});

export default connect(mapStateToProps, null)(withRouter(CreateOpportunity));
