import {
  Dropdown,
  Field as Field2,
  Item,
  Label as Label2,
  Menu,
  Select
} from "@zendeskgarden/react-dropdowns";
import { Checkbox, Field, Input, Label } from "@zendeskgarden/react-forms";
// @ts-ignore
import ReactFilestack from "filestack-react";
import React from "react";
import { Col, Row } from "@zendeskgarden/react-grid";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId
} from "react-google-places-autocomplete";
import Loading from "../../../components/loading";
import { AlertService } from "../../../services/alert.service";
import service from "../../../services/api.service";
import UserService from "../../../services/user.service";
import { AuthenticationService } from "../../../services/auth.service";
import DocumentsService from "../../../services/documents.service";
import { Tooltip } from "@zendeskgarden/react-tooltips";
import { Button, Modal } from "react-bootstrap";
import {
  t
} from "../../../translations/translation.service";
import "./index.css";
import ReactGA from 'react-ga4';
import FileStackService from "../../../services/filestack.service";

const apikey = process.env.REACT_APP_FILESTACK_API;

class PreRegister extends React.Component<any> {
  state = {
    opportunity: {} as any,
    loading: false,
    firstName: "",
    lastName: "",
    email: "",
    dadosPessoais: {
      pcdUser: { isUserPCD: false, defs: [] },
      address: null,
      geolocation: null,
      preferredLanguage: { label: "pt-BR", value: "pt-BR" },
    },
    langList: [
      { label: "pt-BR", value: "pt-BR" },
      { label: "pt-PT", value: "pt-PT" },
      { label: "en-US", value: "en-US" },
    ],
    contato: "",
    files: [],
    fileName: "",
    type: { label: "Inscrito", value: "registered" },
    typeList: [
      { label: "Inscrito", value: "registered" },
      { label: "Candidato", value: "candidate" },
    ],
    edit: this.props.location.level === "candidates" ? "candidate" : "registered",
    showDeletePreRegister: false,
    disabledButton: false,
    policyString: '' as string,
    signatureString: '' as string,
    policyAndSignature: '' as string,
    europeanUnionCitizenship: false,
  };

  constructor(props: any) {
    super(props);
    this.includeLocation = this.includeLocation.bind(this);
    this.makePreRegisterEvent = this.makePreRegisterEvent.bind(this);
    this.excludePreRegister = this.excludePreRegister.bind(this);
    this.deleteCv = this.deleteCv.bind(this);
    this.policeAndSignatureString = this.policeAndSignatureString.bind(this);
  }

  async policeAndSignatureString(): Promise<void> {
    try {
      const policyAndSignature = await FileStackService.getPoliceAndSignature();
      this.setState({
        policyString: policyAndSignature.data.policy,
        signatureString: policyAndSignature.data.signature
      });
    } catch(err: any) {
      console.log("ERROR: ", err);
    }
  }

  // Autenticação por token
  async componentDidMount() {
    const path = this.props.match.path;
    if (path.endsWith("edit")) {
      this.getInfoPreRegister();
    }
    await this.policeAndSignatureString();
    this.setState({ loading: true });
    const id = this.props.match.params.id;
    let opportunity = (await service.get(`opportunity/${id}`)).data;
    this.setState({ opportunity: opportunity });
    this.setState({ loading: false });
  }

  async getInfoPreRegister() {
    const { infoUser, level } = this.props.location;
    const address = infoUser.dadosPessoais.geolocation?.formatted_address
      ? {
          label: infoUser.dadosPessoais.geolocation?.formatted_address,
          value: infoUser.dadosPessoais.geolocation?.formatted_address,
        }
      : null;
    const geolocation = infoUser.dadosPessoais.geolocation;
    const pcdUser = infoUser.dadosPessoais.pcdUser
    const preferredLanguage = {
      label: infoUser.dadosPessoais.preferredLanguage,
      value: infoUser.dadosPessoais.preferredLanguage
    };
    this.setState({
      firstName: infoUser.firstName,
      lastName: infoUser.lastName,
      email: infoUser.email,
      fileName: infoUser?.attachments[0]?.fileName,
      contato: infoUser?.contato?.lista[0]?.contato,
      type: level === "enrolled" ? { label: "Inscrito", value: "registered" } : { label: "Candidato", value: "candidate" },
      dadosPessoais: {...this.state.dadosPessoais, pcdUser, address, preferredLanguage, geolocation },
    })
  }

  async deleteCv(idCV: string): Promise<any> {
    this.setState({ disabledButton: true });
    const data = {
      id: this.props.location.infoUser._id,
      cvId: idCV,
    };
    try {
      const resp = await DocumentsService.deleteDocument(data);
      if (resp.status === 200) {
        this.setState({
          disabledButton: false,
          fileName: "",
        }, () => {
          AlertService.success(t("Arquivo excluido com sucesso."))
        });
      }
    } catch (err) {
      console.error(err);
      this.setState({
        disabledButton: false,
      }, () => {
        AlertService.error(t("Infelizmente não foi possível excluir o arquivo agora. Por favor tente de novo mais tarde."))
      });
    }
  }

  async editPreRegister() {
    const { infoUser } = this.props.location;
    if (infoUser.contato.lista.length < 1) {
      infoUser.contato.lista.push({
        locale: "pt-BR",
        contato: "",
        tipo: "Preferencial"
      })
    }
    infoUser.contato.lista[0].contato = this.state.contato;
    this.setState({ loading: true })
    const bodyObject = {
      _id: infoUser._id,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      dadosPessoais: {
        pcdUser: this.state.dadosPessoais.pcdUser,
        geolocation: this.state.dadosPessoais.geolocation,
        preferredLanguage: this.state.dadosPessoais.preferredLanguage.value,
      },
      contato: infoUser.contato,
      attachments: this.state.files,
    }
    try {
      const level = this.state.type.value === "candidate" ? "candidates" : "enrolled";
      const editResponse = await UserService.updatePreRegister(bodyObject);
      this.setState({ loading: false });
      if (editResponse.status === 400) {
        return AlertService.error(t("Não foi possível atualizar o pré-cadastro"))
      }
      AlertService.success(t("Pré-cadastro de") + " " + infoUser?.displayName + " " + t("atualizado com sucesso."));
      setTimeout(() => {
        this.props.history.push({
          pathname: `/opportunity/${this.state.opportunity._id}/professionals`,
          state: level,
        });
      }, 3000)
    } catch (err) {
      AlertService.error("erro ao tentar editar erro: " + err)
    }
  }

  async validateForm() {
    if (
      this.state.firstName === "" ||
      this.state.lastName === "" ||
      this.state.email === ""
    ) {
      AlertService.error("Preencha os campos obrigatórios");
    } else {
      this.signup();
      await UserService.setPreRegisterMade({
        recruiterId: AuthenticationService.getUser()._id,
        idOpportunity: this.props.match.params.id,
        professionalName: `${this.state.firstName} ${this.state.lastName}`
      })
    }
  }

  async excludePreRegister() {
    try {
      this.setState({ loading: true, showDeletePreRegister: false });
      const response = await UserService.deletePreRegister(this.props.location.infoUser._id);
      if (response.status === 200) {
        this.setState({ loading: false });
        this.props.history.push({
          pathname: `/opportunity/${this.state.opportunity._id}/professionals`,
          state: "compatibles",
        })
      }
    } catch (err: any) {
      AlertService.error(err.message)
    }
  }

  async signup() {
    this.setState({ loading: true }, async () => {
      let contato: any = {};
      if (this.state.contato !== "") {
        let lista = [];
        lista.push({
          locale: "pt-BR",
          contato: this.state.contato,
          tipo: "Preferencial",
        });
        contato.lista = lista;
      }
      let functionPreRegister = "";
      let typePreRegister = this.state.type;
      if (typePreRegister.value === "registered") {
        functionPreRegister =
          "opportunity/addProfessionalPreRegisterToRegisteredInOpportunity";
      } else {
        functionPreRegister =
          "opportunity/addProfessionalPreRegisterToCandidateInOpportunity";
      }

      let data = {
        user: {
          preRegister: true,
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          email: this.state.email,
          dadosPessoais: {
            pcdUser: this.state.dadosPessoais.pcdUser,
            geolocation: this.state.dadosPessoais.geolocation,
            preferredLanguage: this.state.dadosPessoais.preferredLanguage.value,
          },
          contato: contato,
          attachments: this.state.files,
          policyAndSignature: this.state.policyAndSignature,
          europeanUnionCitizenship: this.state.europeanUnionCitizenship
        },
        vaga: {
          id: this.state.opportunity._id,
          titulo: this.state.opportunity.titulo,
        },
        activeLanguage: this.state.dadosPessoais.preferredLanguage.value,
      };
      try {
        const signup = await service.post("auth/signup", data);

        if (signup.status === 200) {
          await service.post(functionPreRegister, {
            user: signup.data.user.id,
            opportunity: this.state.opportunity._id,
            password: signup.data.user.password,
          });
          if (typePreRegister.value === "registered") {
            this.props.history.push({
              pathname: `/opportunity/${this.state.opportunity._id}/professionals`,
              state: "enrolled",
            });
          } else {
            this.props.history.push({
              pathname: `/opportunity/${this.state.opportunity._id}/professionals`,
              state: "candidates",
            });
          }
          this.setState({
            loading: false,
            firstName: "",
            lastName: "",
            email: "",
            dadosPessoais: {
              preferredLanguage: { label: "pt-BR", value: "pt-BR" },
            },
            contato: "",
            fileName: "",
            files: {},
          });
          this.makePreRegisterEvent()
        } else {
          this.setState({ loading: false });
          AlertService.success(signup.data.message);
        }
      } catch (error: any) {
        this.setState({ loading: false });
        if (error.response) {
          switch (error.response.data.message) {
            case "EMAIL_ALREADY_REGISTERED":
              AlertService.error(t("Email já cadastrado"));
              break;
          }
        }
      }
    });
  }

  onFiles = async ({ filesUploaded }: any) => {
    var array = filesUploaded.map(function (item: any) {
      return {
        fileName: item.filename,
        url: item.url,
      };
    });
    const policyAndSignature = (await FileStackService.getFileStackSecreteCodeToUser(
      array[0].url, false
    )).data

     this.setState({ fileName: array[0].fileName, files: array, policyAndSignature });
  };

  makePreRegisterEvent() {
    ReactGA.event({
      category: "Recruitment-actions",
      action: "pre-cadastro realizado",
      label: "rh",
    });
  }
  
  includeLocation(data: any) {
    geocodeByPlaceId(data.value.place_id)
      .then((geocode) => {
        this.setState({
          dadosPessoais: {
            ...this.state.dadosPessoais,
            address: data,
            geolocation: geocode[0],
          },
        });
      })
      .catch((error) => console.error(error));
  }

  render() {
    const { path } = this.props.match;
    const { infoUser } = this.props.location; 
    return (
      <div className="container">
        <div className="m-3" />
        <div className="card-custom shadow">
          <Loading show={this.state.loading} />
          <div
            className="card-header container-fluid"
            style={{
              background: "white",
              borderBottom: "1px solid black",
              paddingBottom: "25px",
              paddingTop: "25px",
            }}
          >
            <div className="row">
              <div className="col-lg-8 col-md-6 col-sm-12">
                <span className="u-fs-xl u-semibold color-black">
                  {t("Pré-cadastro de Profissional")}
                </span>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <a
                  className="formControl"
                  rel="noopener noreferrer"
                  target="_blank"
                  href={`/opportunity/${this.props.match.params.id}`}
                >
                  <span className="u-fs-lg u-semibold color-black">
                    {" "}
                    #{this.state.opportunity.titulo}
                  </span>
                </a>
                <button
                  type="button"
                  onClick={this.props.history.goBack}
                  className="close"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-2 col-md-4 col-sm-12">
                  <Field>
                    <Label>{t("Nome")} *</Label>
                    <Input
                      name="nome"
                      value={this.state.firstName}
                      onChange={(event) =>
                        this.setState({ firstName: event.target.value })
                      }
                      required
                    />
                  </Field>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <Field>
                    <Label>{t("Sobrenome")} *</Label>
                    <Input
                      name="Sobrenome"
                      value={this.state.lastName}
                      onChange={(event) =>
                        this.setState({ lastName: event.target.value })
                      }
                      required
                    />
                  </Field>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <Field>
                    <Label>Email *</Label>
                    <Input
                      name="email"
                      value={this.state.email}
                      onChange={(event) =>
                        this.setState({ email: event.target.value })
                      }
                      required
                    />
                  </Field>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-12">
                  <Field>
                    <Label>{t("Telefone")}</Label>
                    <Input
                      name="telefone"
                      value={this.state.contato}
                      onChange={(event) =>
                        this.setState({ contato: event.target.value })
                      }
                    />
                  </Field>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-2 col-md-4 col-sm-12">
                  <Field>
                    <Dropdown
                      selectedItem={this.state.dadosPessoais.preferredLanguage}
                      onSelect={(value) =>
                        this.setState({
                          dadosPessoais: {
                            ...this.state.dadosPessoais,
                            preferredLanguage: value,
                          },
                        })
                      }
                      downshiftProps={{
                        itemToString: (item: any) => item && item.value,
                      }}
                    >
                      <Field2>
                        <Label2 className="pt-2">{t("Idioma Pref.")} *</Label2>
                        <Select>
                          {this.state.dadosPessoais.preferredLanguage.label}
                        </Select>
                      </Field2>
                      <Menu>
                        {this.state.langList.map((option) => (
                          <Item key={option.value} value={option}>
                            {option.label}
                          </Item>
                        ))}
                      </Menu>
                    </Dropdown>
                  </Field>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <div>
                    <Label className="mt-2">
                      <p className="mb-2">{t("Localidade")}</p>
                    </Label>
                    {/* <Input
                      value={this.state.dadosPessoais.address}
                      onChange={(event) =>
                        this.setState({
                          dadosPessoais: {
                            ...this.state.dadosPessoais,
                            address: event.target.value,
                          },
                        })
                      }
                    /> */}
                    <GooglePlacesAutocomplete
                      apiKey="AIzaSyC5_B8f8Ug5dmvdYe3QWv6N2YoKszO0gK4"
                      minLengthAutocomplete={3}
                      selectProps={{
                        value: this.state.dadosPessoais.address,
                        onChange: this.includeLocation,
                        placeholder: "Local de residência",
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <Field>
                    <Dropdown
                      selectedItem={this.state.type}
                      onSelect={(value) => this.setState({ type: value })}
                      downshiftProps={{
                        itemToString: (item: any) => item && item.value,
                      }}
                    >
                      <Field2>
                        <Label2 className="pt-2">Status</Label2>
                        <Select
                          disabled={path.endsWith("edit") ? true : false}
                        >
                          {this.state.type.label}
                        </Select>
                      </Field2>
                      <Menu>
                        {this.state.typeList.map((option) => (
                          <Item key={option.value} value={option}>
                            {option.label}
                          </Item>
                        ))}
                      </Menu>
                    </Dropdown>
                  </Field>
                </div>
                <div
                  style={{ marginTop: "3rem" }}
                  className="col-lg-2 col-md-4 col-sm-12"
                >
                  <Field>
                    <Checkbox
                      checked={this.state.dadosPessoais.pcdUser.isUserPCD}
                      onChange={(event) => {
                        this.setState({
                          dadosPessoais: {
                            ...this.state.dadosPessoais,
                            pcdUser: {
                              ...this.state.dadosPessoais.pcdUser,
                              isUserPCD: event.target.checked,
                            },
                          },
                        });
                      }}
                    >
                      <Label>{t("Pessoa com Deficiência")}</Label>
                    </Checkbox>
                  </Field>
                  <Field>
                    <Checkbox
                      checked={this.state.europeanUnionCitizenship}
                      onChange={(event) => {
                        this.setState({
                          europeanUnionCitizenship: event.target.checked
                        });
                      }}
                    >
                      <Label>{t("Cidadania Europeia")}</Label>
                    </Checkbox>
                  </Field>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-4 col-sm-12">
                  <Field>
                    <Label>{t("Currículo")}</Label>
                    {this.state.policyString.length > 0 && (
                      <ReactFilestack
                        actionOptions={{
                          accept: [".pdf", ".docx"],
                        }}
                        apikey={apikey}
                        componentDisplayMode={{
                          type: "button",
                          customText: t("Clique aqui para adicionar"),
                          customClass: "c-txt__input",
                        }}
                        clientOptions={{
                          security: {
                            policy: this.state.policyString,
                            signature: this.state.signatureString,
                          }
                        }}
                        onSuccess={this.onFiles}
                      />
                    )}
                    <Label>
                      <div className="d-flex">
                        <small  className="pt-1 c-txt__message c-txt__message--success">
                          {this.state.fileName}
                        </small>
                        {this.state.fileName && (
                          <button
                            type="button"
                            disabled={this.state.disabledButton}
                            onClick={() => {
                              infoUser
                              ?
                                this.deleteCv(infoUser?.attachments[0]?._id)
                              :
                                this.setState({ fileName: "" })
                              }
                            }
                            className="button-delete-cv-pre-register c-txt__message--success"
                          >
                            <Tooltip
                              type="light"
                              size="small"
                              content={t("Excluir")}
                            >
                              <span>x</span>
                            </Tooltip>
                          </button>
                        )}
                      </div>
                    </Label>
                  </Field>
                </div>
              </div>
              <div className="d-flex justify-content-end">
                {
                  path.endsWith("edit") && (
                    <button
                      type="button"
                      className="btn-exclude-pre-register"
                      style={{ flexGrow:"2", textAlign: "left", fontSize: "0.8rem" }}
                      onClick={ () => this.setState({ showDeletePreRegister: true })}
                    >
                      {t("Excluir Pré-Cadastro")}
                    </button>
                  )
                }
                <div
                  // className="col-lg-8 col-md-8 col-sm-8 pt-4"
                  style={{ display: "flex" }}
                >
                  <button
                    type="button"
                    onClick={this.props.history.goBack}
                    className="c-btn c-btn--basic mr-1"
                    style={{ fontSize: "0.8rem"}}
                  >
                    <span aria-hidden="true">{t("Cancelar")}</span>
                  </button>
                  {
                    path.endsWith("edit") ?
                    <button
                      type="button"
                      onClick={this.editPreRegister.bind(this)}
                      className="c-btn c-btn--primary u-bg-orange-400"
                      style={{ fontSize: "0.8rem"}}
                    >
                      <span aria-hidden="true">{t("Salvar")}</span>
                    </button> :
                    <button
                      type="button"
                      onClick={this.validateForm.bind(this)}
                      className="c-btn c-btn--primary u-bg-orange-400"
                      disabled={
                        this.state.firstName.length === 0 ||
                        this.state.lastName.length === 0 ||
                        this.state.email.length === 0
                      }
                    >
                      <span aria-hidden="true">{t("Cadastrar")}</span>
                    </button>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          centered
          show={this.state.showDeletePreRegister}
          onHide={() => this.setState({ showDeletePreRegister: false })}
        >
          <Modal.Header closeButton className="h5">
            {t("Excluir Pré-Cadastro")}
          </Modal.Header>
          <Modal.Body>
            <Col>
              {t("Confirma a exclusão do Pré-Cadastro") + " " + this.props.location?.infoUser?.displayName}
            </Col>
          </Modal.Body>
          <Modal.Footer className="col justify-content-center mb-1">
            <Row className="text-right">
              <Button
                className={"delete-user-pre-register mx-2 col"}
                onClick={() => this.excludePreRegister()}
              >
                {t("EXCLUIR")}
              </Button>
              <Button
                className={"cancel-delete-user-pre-register mx-2 col"}
                onClick={() => this.setState({ showDeletePreRegister: false })}
              >
                {t("NÃO")}
              </Button>
            </Row>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default PreRegister;
