  import React from "react";
  import { connect } from "react-redux";
  import { withRouter } from "react-router";
  import { AnyAction, bindActionCreators, Dispatch } from "redux";
  import { currentLanguageChanged } from "../../actions";
  import "./index.scss";
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
  
  class CircleProgress extends React.Component<any> {
    interval: any = 0
    timeout: any = 0
    state = {
        percentage: 0,
        displayedProgress: 0,
      };
    
    componentDidMount() {
      this.interval = this.props.duration / 100;
      this.increment();
    }
    componentWillUnmount() {
      clearTimeout(this.timeout);
    }
    easeOutQuad = (t: any) => t * (2 - t);
    increment() {
      if (this.state.percentage < this.props.finish) {
        this.timeout = setTimeout(() => {
          let { percentage, displayedProgress } = this.state;
          percentage += 1;
          displayedProgress = percentage + 1 > this.props.finish ? this.props.finish : percentage;
          this.setState({ percentage, displayedProgress });
          this.increment();
        }, this.interval);
      } else {
        clearTimeout(this.timeout);
        if (this.props.onComplete) this.props.onComplete();
      }
    }
    render() {
      const { displayedProgress } = this.state;
      const r = 34;
      const c = Math.PI * (r * 2);
      const pct = (100 - displayedProgress) / 100 * c;
      return (
        <div className="circle-progress" data-pct={displayedProgress}>
          <svg className="circle-progress-content" viewBox="0 0 74 74">
            <circle
              className="bg"
              cx="37"
              cy="37"
              r="34"
              fill="none"
              strokeWidth="3"
              strokeDasharray="213.63"
              strokeDashoffset={0}
            />
            <circle
              className="bar"
              cx="37"
              cy="37"
              r="34"
              fill="none"
              strokeWidth="3"
              strokeDasharray="213.63"
              strokeDashoffset={pct}
            />
          </svg>
        </div>
      );
    }
  }
  
  const mapStateToProps = (store: {
    changeLanguageReducer: { currentLanguage: string };
  }) => ({
    currentLanguage: store.changeLanguageReducer.currentLanguage,
  });
  
  const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
      {
        currentLanguageChanged,
      },
      dispatch
    );
  
  export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CircleProgress));
  