import {
  Dropdown,
  Field as Field2,
  Item,
  Menu,
  Select
} from "@zendeskgarden/react-dropdowns";
import { Field, Input, MediaInput, Message } from "@zendeskgarden/react-forms";
import { Dots } from "@zendeskgarden/react-loaders";
import { ReactComponent as LockIcon } from "@zendeskgarden/svg-icons/src/16/lock-locked-fill.svg";
import { ReactComponent as UnlockIcon } from "@zendeskgarden/svg-icons/src/16/lock-unlocked-fill.svg";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { currentLanguageChanged } from "../../actions";
import HeaderMobile from "../../components/header";
// import SEO from "../../components/seo";
import {
  FORGOT_PASSWORD_ROUTE,
  HOME_ROUTE,
  SIGNUP_ROUTE
} from "../../providers/routes";
import { AlertService } from "../../services/alert.service";
import { AuthenticationService } from "../../services/auth.service";
import TranslationService, { t } from "../../translations/translation.service";
import "./index.css";

const { zdColorSecondaryOrange400 } = require("@zendeskgarden/css-variables");
let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty("--vh", `${vh}px`);

class Login extends React.Component<any> {
  state = {
    currentLang: TranslationService.getCurrentLanguage,
    email: "",
    password: "",
    width: window.innerWidth,
    lock: true,
    inputPassword: "password",
    emailValid: true,
    passwordValid: true,
    formValid: false,
    loadingSubmit: false,
    vagaId: this.props.location?.vagaId,
  };

  constructor(props: any) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.lockPassword = this.lockPassword.bind(this);
  }

  componentWillMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  redirectToHome() {
    this.props.history.push("/");
  }

  async componentDidMount() {
    const { currentLanguageChanged } = this.props;
    currentLanguageChanged(this.state.currentLang);
  }

  componentDidUpdate(prevProps: Readonly<any>, _prevState: Readonly<{}>): void {
    if (
        this.state.width <= 680 &&
        prevProps.currentLanguage !== this.props.currentLanguage
        && typeof(this.props.currentLanguage) !== "function"
      ) {
      this.setState({ currentLang: this.props.currentLanguage});
    }
  }

  handleSubmit(event: any) {
    this.setState({ loadingSubmit: true }, () => this.lockPassword(true));
    event.preventDefault();
    AuthenticationService.signin(this.state.email, this.state.password)
      .then(() => this.props.history.push(HOME_ROUTE))
      .catch((error: any) => {
        if (error.response.data) {
          switch (error.response.data.message) {
            case "UNCONFIRMED_EMAIL":
              AlertService.error(t("Seu email ainda não foi validado"));
              break;
            default:
              AlertService.error(t(error.response.data.message));
              if (this.state.vagaId) {
                this.goToSignup();
              }
              break;
          }
        }
      })
      .finally(() => {
        this.setState({ loadingSubmit: false });
      });
  }

  goToSignup(): void {
    this.props.history.push({
      pathname: SIGNUP_ROUTE,
      vagaId: this.props.location.vagaId,
    });
  }

  lockPassword(value: boolean) {
    this.setState({ lock: value, inputPassword: value ? "password" : "text" });
  }

  handleInputChange(event: any) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState(
      {
        [name]: value,
      },
      () => {
        this.validateField(name, value);
      }
    );
  }

  validateField(fieldName: string, value: any) {
    let emailValid = this.state.emailValid;
    // let passwordValid = this.state.passwordValid;

    switch (fieldName) {
      case "email":
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        break;
      // case "password":
      //   passwordValid = value.length >= 6;
      //   break;
      default:
        break;
    }
    this.setState(
      {
        emailValid: emailValid,
        // passwordValid: passwordValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid:
        this.state.emailValid &&
        this.state.email &&
        this.state.passwordValid &&
        this.state.password,
    });
  }

  render() {
    const { width } = this.state;
    const isMobile = width <= 680;
    const { currentLanguageChanged } = this.props;
    const langs = TranslationService.getLanguages();

    if (!isMobile) {
      return (
        <>
          {/* <SEO title={`Enter - ${t("Entrar")}`} url='https://elumini.enter.jobs/login'/> */}
          <div className="bg-enter">
            <div className="d-flex align-items-center justify-items-center h-100">
              <div className="bg-container">
                <div className="content-login d-flex justify-content-between justify-items-center flex-column">
                  <div>
                    <div className="row">
                      <div className="col">
                        <p
                          className="enter-logo"
                          style={{ cursor: "pointer" }}
                          onClick={this.redirectToHome.bind(this)}
                        />
                      </div>
                      <div className="d-flex align-items-center mr-1">
                        <Dropdown
                          selectedItem={TranslationService.getCurrentLanguage()}
                          onSelect={(selectedLang) => {
                            TranslationService.changeLanguage(selectedLang);
                            this.setState({ currentLang: selectedLang });
                            currentLanguageChanged(selectedLang);
                          }}
                          downshiftProps={{
                            itemToString: (item: any) => item,
                          }}
                        >
                          <Field2>
                            <Select className="u-semibold u-fs-sm border-0">
                              {t(TranslationService.getCurrentLanguage())}
                            </Select>
                          </Field2>
                          <Menu className="u-fs-sm">
                            {langs.map((option: any) => (
                              <Item key={option} value={option}>
                                {t(option)}
                              </Item>
                            ))}
                          </Menu>
                        </Dropdown>
                      </div>
                    </div>

                    <form className="login-body" onSubmit={this.handleSubmit}>
                      {!this.state.vagaId ? (
                        <h1 className="login-title mb-4">{t("Entrar")}</h1>
                      ) : (
                        <h1 className="login-title mb-2">
                          {t("Para se candidatar, faça login ou cadastre-se.")}
                        </h1>
                      )}
                      <Field className="mt-2">
                        <Input
                          disabled={this.state.loadingSubmit}
                          validation={this.state.emailValid ? undefined : "error"}
                          onChange={this.handleInputChange}
                          name="email"
                          placeholder={t("E-mail")}
                        ></Input>
                        {!this.state.emailValid && (
                          <Message validation="error">
                            {t("Email inválido.")}
                          </Message>
                        )}
                      </Field>
                      <Field className="mt-2">
                        <MediaInput
                          name="password"
                          disabled={this.state.loadingSubmit}
                          validation={
                            this.state.passwordValid ? undefined : "error"
                          }
                          type={this.state.inputPassword}
                          onChange={this.handleInputChange}
                          placeholder={t("Senha")}
                          end={
                            <React.Fragment>
                              {!this.state.lock && (
                                <UnlockIcon
                                  style={{ cursor: "pointer" }}
                                  onClick={() => this.lockPassword(true)}
                                ></UnlockIcon>
                              )}
                              {this.state.lock && (
                                <LockIcon
                                  style={{ cursor: "pointer" }}
                                  onClick={() => this.lockPassword(false)}
                                ></LockIcon>
                              )}
                            </React.Fragment>
                          }
                        />
                        {!this.state.passwordValid && (
                          <Message validation="error">
                            {t("Senha inválida.")}
                          </Message>
                        )}
                      </Field>
                      <p className="text-right mt-2 u-fg-grey-500">
                        <Link
                          to={{
                            pathname: FORGOT_PASSWORD_ROUTE,
                            state: this.state.email,
                          }}
                        >
                          {t("Esqueceu a senha?")}
                        </Link>
                      </p>

                      <button
                        type="submit"
                        disabled={
                          !this.state.formValid || this.state.loadingSubmit
                        }
                        className="btn-enviar w-100 mt-2 button-entrar"
                      >
                        {!this.state.loadingSubmit && !this.state.vagaId
                          ? t("Entrar")
                          : t("Continuar")}
                        {this.state.loadingSubmit && (
                          <Dots
                            key="dots-loading"
                            color={zdColorSecondaryOrange400}
                            size="32px"
                          />
                        )}
                      </button>
                    </form>
                  </div>
                  <div>
                    <p
                      style={{ marginTop: "4rem" }}
                      className="text-center u-fg-grey-500"
                    >
                      {t("Novo no enter?")}{" "}
                      <button onClick={() => this.goToSignup()} className="u-semibold u-fg-grey-600 btn" style={{marginBottom: '4px'}}>
                        {t("Cadastre-se")}
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <HeaderMobile />
          <div className="login-body bg-enter p-4 d-flex justify-content-between flex-column">
            <div className="container">
              {!this.state.vagaId ? (
                  <h1 className="login-title mt-0 mb-1 text-light">{t("Entrar")}</h1>
                ) : (
                  <h1 className="login-title mt-0 mb-1 text-light">
                    {t("Para se candidatar, faça login ou cadastre-se.")}
                  </h1>
                )
              }
              <form className="login-body" onSubmit={this.handleSubmit}>
                <Field className="mt-2">
                  <Input
                    disabled={this.state.loadingSubmit}
                    validation={this.state.emailValid ? undefined : "error"}
                    onChange={this.handleInputChange}
                    name="email"
                    placeholder={t("E-mail")}
                  ></Input>
                  {!this.state.emailValid && (
                    <Message validation="error">{t("Email inválido.")}</Message>
                  )}
                </Field>
                <Field className="mt-2">
                  <MediaInput
                    name="password"
                    disabled={this.state.loadingSubmit}
                    validation={this.state.passwordValid ? undefined : "error"}
                    type={this.state.inputPassword}
                    onChange={this.handleInputChange}
                    placeholder={t("Senha")}
                    end={
                      <React.Fragment>
                        {!this.state.lock && (
                          <UnlockIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => this.lockPassword(true)}
                          ></UnlockIcon>
                        )}
                        {this.state.lock && (
                          <LockIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => this.lockPassword(false)}
                          ></LockIcon>
                        )}
                      </React.Fragment>
                    }
                  />
                  {!this.state.passwordValid && (
                    <Message validation="error">{t("Senha inválida.")}</Message>
                  )}
                </Field>

                <button
                  type="submit"
                  disabled={!this.state.formValid || this.state.loadingSubmit}
                  className="btn-enviar w-100 mt-4"
                >
                  {!this.state.loadingSubmit && !this.state.vagaId
                    ? t("Entrar")
                    : t("Continuar")}
                  {this.state.loadingSubmit && (
                    <Dots
                      key="dots-loading"
                      color={zdColorSecondaryOrange400}
                      size="32px"
                    />
                  )}
                </button>
              </form>
              <div className="d-flex justify-content-between flex-column">
                <Link
                  to={{
                    pathname: FORGOT_PASSWORD_ROUTE,
                    state: this.state.email,
                  }}
                >
                  <p
                    style={{ cursor: "pointer" }}
                    className="text-light text-center mt-4 u-fg-grey-500"
                  >
                    {t("Esqueceu a senha?")}
                  </p>
                </Link>
              </div>
            </div>
            <p style={{ marginTop: "5rem" }} className="text-center text-light">
              {t("Novo no enter?")}{" "}
              <button onClick={() => this.goToSignup()} className="u-semibold text-light btn" style={{marginBottom: '4px'}}>
                      {t("Cadastre-se")}
                    </button>
            </p>
          </div>
        </>
      );
    }
  }
}

const mapStateToProps = (store: {
  changeLanguageReducer: { currentLanguage: string };
}) => ({
  currentLanguage: store.changeLanguageReducer.currentLanguage,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      currentLanguageChanged,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
