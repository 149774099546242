import { Route, Switch } from 'react-router-dom';
import CandidatureDetails from '../../pages/candidatures/details';
import ProfessionalCandidatures from '../../pages/candidatures/professional';
import CardDetails from '../../pages/card-details';
import CardTalentDetails from '../../pages/card-talent-details';
import ContactUs from '../../pages/contact-us';
import Faq from '../../pages/faq';
import ForgotPassword from '../../pages/forgot-password';
import ResetPassword from '../../pages/forgot-password/change-password';
import Home from '../../pages/home';
import Kanban from '../../pages/kanban';
import Login from '../../pages/login';
import Opportunities from '../../pages/opportunities/all';
import Compatible from "../../pages/opportunities/compatible";
import CreateOpportunity from "../../pages/opportunities/create";
import OpportunityDetail from "../../pages/opportunities/detail";
import EditMosaic from '../../pages/opportunities/mosaic/edit';
import OpportunityProgress from '../../pages/opportunities/progress';
import FindProfessional from '../../pages/professional/find';
import PersonalInformation from "../../pages/professional/personal-information";
import ProfessionalPreRegister from '../../pages/professional/pre-register';
import ProfessionalProfile from '../../pages/professional/professional-profile';
import ProfessionalView from '../../pages/professional/view';
import Reports from '../../pages/reports';
import ProfessionalSettings from '../../pages/settings';
import Signup from "../../pages/signup";
import SignupLocation from "../../pages/signup-location";
import SignupSuccess from "../../pages/signup-success";
import SignupValidation from "../../pages/signup-validation";
import TalentHunterKanban from '../../pages/talent-hunter-kanban';
import RegisterByRh from '../../pages/register-professional-by-rh';
import Terms from '../../pages/terms-of-use';
import {
  CANDIDATURE_DETAILS_ROUTE, CARD_DETAILS_ROUTE, CARD_TALENT_ROUTE, COMPATIBLE_PROFESSIONALS_ROUTE,
  CONTACT_US_ROUTE,
  FAQ_ROUTE, FORGOT_PASSWORD_ROUTE, HOME_ROUTE,
  KANBAN_ROUTE,
  LOGIN_ROUTE, OPPORTUNITIES_ROUTE, OPPORTUNITY_COPY_ROUTE, OPPORTUNITY_CREATE_ROUTE, OPPORTUNITY_DETAIL_ROUTE, OPPORTUNITY_EDIT_MOSIAIC_ROUTE, OPPORTUNITY_EDIT_ROUTE, PERSONAL_INFORMATION_ROUTE, PRE_REGISTER_ROUTE, PROFESSIONAL_CANDIDATURES, PROFESSIONAL_PROFILE_ROUTE, PROFESSIONAL_RESET_PASSWORD, PROFESSIONAL_ROUTE, PROFFESIONAL_SETTINGS_ROUTE, REPORTS_ROUTE,
  RH_ROUTE,
  SEARCH_PROFESSIONAL_ROUTE, SIGNUP_CANDIDATURE_ROUTE, SIGNUP_LOCATION_ROUTE, SIGNUP_ROUTE, SIGNUP_SUCCESS_ROUTE,
  SIGNUP_VALIDATION_ROUTE, TALENT_HUNTER_KANBAN_ROUTE, TERMS_OF_USE_ROUTE, REGISTER_PROFESSIONAL_RH
} from '../../providers/routes';
import { Alert } from "../../services/notification.service";
import { ProtectedRoute } from "../../util/protectedRoute";
// import SEO from '../seo';

const Main = () => (
  <main>
    {/* <SEO /> */}
    <Alert></Alert>
    <Switch>
      <Route exact path={HOME_ROUTE} component={Home} />
      <Route exact path={CANDIDATURE_DETAILS_ROUTE} component={CandidatureDetails} />
      <Route exact path={PROFESSIONAL_CANDIDATURES} component={ProfessionalCandidatures} />

      <ProtectedRoute exact path={OPPORTUNITIES_ROUTE} component={Opportunities} />
      <ProtectedRoute
        exact
        path={OPPORTUNITY_CREATE_ROUTE}
        component={CreateOpportunity}
      />
      <ProtectedRoute
        exact
        path={OPPORTUNITY_EDIT_ROUTE}
        component={CreateOpportunity}
      />
      <ProtectedRoute
        exact
        path={OPPORTUNITY_COPY_ROUTE}
        component={CreateOpportunity}
      />
      <Route
        exact
        path={`${OPPORTUNITY_DETAIL_ROUTE}/:id`}
        component={OpportunityDetail}
      />
      <ProtectedRoute
        exact
        path={`${OPPORTUNITY_DETAIL_ROUTE}/:id${COMPATIBLE_PROFESSIONALS_ROUTE}`}
        component={Compatible}
      />
      <ProtectedRoute
        exact
        path={OPPORTUNITY_EDIT_MOSIAIC_ROUTE}
        component={EditMosaic}
      />
      <Route exact path={LOGIN_ROUTE} component={Login} />
      <Route exact path={SIGNUP_ROUTE} component={Signup} />    
      <Route
        exact path={FORGOT_PASSWORD_ROUTE} component={ForgotPassword}/>
      <Route exact path={SIGNUP_LOCATION_ROUTE} component={SignupLocation} />
      <Route exact path={SIGNUP_SUCCESS_ROUTE} component={SignupSuccess} />
      <Route
        exact
        path={SIGNUP_VALIDATION_ROUTE}
        component={SignupValidation}
      />
      <Route exact path={SIGNUP_CANDIDATURE_ROUTE} component={SignupValidation} />

      {/* <Route path={`${KANBAN_ROUTE}/:token`} component={Kanban} /> */}
      <ProtectedRoute
        exact
        path="/professional/:id"
        component={ProfessionalView}
      />
      <ProtectedRoute
        exact
        path="/opportunity/:opportunityId/application/:professionalId"
        component={OpportunityProgress}
      />
      <ProtectedRoute exact path={KANBAN_ROUTE} component={Kanban} />
      <ProtectedRoute exact path={TALENT_HUNTER_KANBAN_ROUTE} component={TalentHunterKanban} />
      <ProtectedRoute
        exact
        path="/professional/id"
        component={ProfessionalView}
      />
      <ProtectedRoute
        exact
        path={`${KANBAN_ROUTE}${CARD_DETAILS_ROUTE}/:id`}
        component={CardDetails}
      />
      <ProtectedRoute
        exact
        path={`${KANBAN_ROUTE}${CARD_TALENT_ROUTE}/:id`}
        component={CardTalentDetails}
      />
      <ProtectedRoute
        exact
        path={`${REPORTS_ROUTE}/:token`}
        component={Reports}
      />
      <ProtectedRoute exact path={`${REPORTS_ROUTE}`} component={Reports} />
      <ProtectedRoute
        exact
        path={`${PROFESSIONAL_ROUTE}${PRE_REGISTER_ROUTE}/:id`}
        component={ProfessionalPreRegister}
      />
      <ProtectedRoute
        exact
        path={`${PROFESSIONAL_ROUTE}${PRE_REGISTER_ROUTE}/:id/edit`}
        component={ProfessionalPreRegister}
      />
      <ProtectedRoute
        exact
        path={`${PROFESSIONAL_ROUTE}${RH_ROUTE}${SEARCH_PROFESSIONAL_ROUTE}`}
        component={FindProfessional}
      />
      <Route exact path={PROFESSIONAL_RESET_PASSWORD} component={ResetPassword}/>
      <Route exact path={PERSONAL_INFORMATION_ROUTE} component={PersonalInformation} />
      <Route exact path={PROFESSIONAL_PROFILE_ROUTE} component={ProfessionalProfile} />
      <Route exact path={PROFFESIONAL_SETTINGS_ROUTE} component={ProfessionalSettings} />
      <Route exact path={FAQ_ROUTE} component={Faq} />
      <Route exact path={TERMS_OF_USE_ROUTE} component={Terms} />
      <Route exact path={CONTACT_US_ROUTE} component={ContactUs} />
      <Route exact path={REGISTER_PROFESSIONAL_RH} component={RegisterByRh} />
      <Route exact path={`${REGISTER_PROFESSIONAL_RH}/:id/edit`} component={RegisterByRh} />

      <ProtectedRoute path="*" component={() => <h1>Page not found</h1>} />
    </Switch>
  </main>
);

export default Main;
