import React from "react";
import { Container, Nav, NavDropdown } from "react-bootstrap";
import Navbar from "react-bootstrap/Navbar";
import { withRouter } from "react-router";
import {
  HOME_ROUTE,
  KANBAN_ROUTE,
  LOGIN_ROUTE,
  OPPORTUNITIES_ROUTE,
  OPPORTUNITY_CREATE_ROUTE,
  PROFESSIONAL_ROUTE,
  REPORTS_ROUTE,
  RH_ROUTE,
  SEARCH_PROFESSIONAL_ROUTE,
  SIGNUP_ROUTE,
  PROFESSIONAL_CANDIDATURES,
  TALENT_HUNTER_KANBAN_ROUTE
} from "../../providers/routes";
import {
  AuthenticationService,
  isAuthenticated
} from "../../services/auth.service";
import { t } from "../../translations/translation.service";
import "./index.css";

class NavbarComponent extends React.Component<any> {
  state = {
    userName: AuthenticationService.getUser() ? AuthenticationService.getUser().firstName : null,
    width: window.innerWidth,
  };

  constructor(props: any) {
    super(props);
    this.handleWidthSizeChange = this.handleWidthSizeChange.bind(this);
    this.profileField = this.profileField.bind(this);
  }

  handleWidthSizeChange() {
    this.setState({ width: window.innerWidth });
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleWidthSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWidthSizeChange);
  }

  profileField() {
    const isRh = AuthenticationService.isRh();
    return (
      <Nav>
        <NavDropdown
          title={t("Bem-vind@, ") + `${this.state.userName}!`}
          id="collasible-nav-dropdown"
          className="professional-section-nav-bar"
        >
          {isRh && <NavDropdown.Item
            href={
              `/professional/${AuthenticationService.getUser()._id}/professinal-profile`
            }
          >
            {t("Perfil Profissional")}
          </NavDropdown.Item>}
          <NavDropdown.Item
            href={
              `/professional/${AuthenticationService.getUser()._id}/personal-information`
            }
          >
            {t("Informações Pessoais")}
          </NavDropdown.Item>
          <NavDropdown.Item
            href={
              `/professional/${AuthenticationService.getUser()._id}/settings`
            }
          >
            {t("Configurações")}
          </NavDropdown.Item>
          <NavDropdown.Item
            onClick={() => AuthenticationService.logout()}
            href={HOME_ROUTE}
          >
            {t("Sair")}
          </NavDropdown.Item>
        </NavDropdown>
      </Nav>
    )
  }

  render() {
    const isRh = AuthenticationService.isRh;
    return (
      <Navbar className="navbar-bg mb-4" collapseOnSelect variant="dark" expand="lg">
        <Container className="padding-container container">
          {this.state.width < 991 && (
            <div style={{width: '100%'}} className="d-flex justify-content-between">
              <div>
                {isAuthenticated() && this.state.userName !== null ? (
                  this.profileField()
                ) : (
                  <Nav className="flex-row">
                    <Nav.Link href={LOGIN_ROUTE}>{t("Entrar")}</Nav.Link>
                    <Nav.Link className="link-cadastro" href={SIGNUP_ROUTE}>
                      {t("Novo por aqui? Cadastre-se!")}
                    </Nav.Link>
                  </Nav>
                )}
              </div>
              <div>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              </div>
            </div>
          )}
          <Navbar.Collapse id="responsive-navbar-nav">
            {isRh() && (
              <Nav className="me-auto">
                <NavDropdown
                  title={t("OPORTUNIDADES")}
                  id="collasible-nav-dropdown"
                >
                  <NavDropdown.Item href={OPPORTUNITIES_ROUTE}>
                    {t("ENCONTRAR OPORTUNIDADES")}
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href={OPPORTUNITY_CREATE_ROUTE}>
                    {t("NOVA OPORTUNIDADE")}
                  </NavDropdown.Item>
                </NavDropdown>
                <Nav.Link
                  href={PROFESSIONAL_ROUTE + RH_ROUTE + SEARCH_PROFESSIONAL_ROUTE}
                >
                  {t("PROFISSIONAIS")}
                </Nav.Link>
                <NavDropdown
                  title={t("KANBAN")}
                  id="collasible-nav-dropdown"
                >
                  <NavDropdown.Item href={KANBAN_ROUTE}>
                    {t("OPORTUNIDADES")}
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href={TALENT_HUNTER_KANBAN_ROUTE}>
                    {t("TALENT HUNTERS")}
                  </NavDropdown.Item>
                </NavDropdown>
                <Nav.Link href={REPORTS_ROUTE}>{t("RELATÓRIOS")}</Nav.Link>
              </Nav>
            )}
            {isAuthenticated() && !isRh() && this.state.userName !== null && (
              <Nav className="me-auto">
                <Nav.Link
                  href={HOME_ROUTE}
                >
                  {t("OPORTUNIDADES")}
                </Nav.Link>
                <Nav.Link
                  href={PROFESSIONAL_CANDIDATURES}
                >
                  {t("CANDIDATURAS")}
                </Nav.Link>
                <Nav.Link
                  href={
                    `/professional/${AuthenticationService.getUser()._id}/professinal-profile`
                  }
                > 
                  {t("PERFIL PROFISSIONAL")}
                </Nav.Link>
              </Nav>
            )}
            <Nav className="ml-auto">
              {this.state.width > 991 && (
                <>
                  {isAuthenticated() && this.state.userName !== null? (
                    this.profileField()
                  ) : (
                    <>
                      <Nav.Link href="/login">{t("Entrar")}</Nav.Link>
                      <Nav.Link className="link-cadastro" href={SIGNUP_ROUTE}>
                        {t("Novo por aqui? Cadastre-se!")}
                      </Nav.Link>
                    </>
                  )}
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  }
}

export default withRouter(NavbarComponent);
