import api from "./api.service";

const jwt = require("jsonwebtoken");

export const TOKEN_KEY = "@enter-dev";
export const USER_KEY = "@user-dev";
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => localStorage.getItem(TOKEN_KEY);

export class AuthenticationService {
  static getUser = (): any => JSON.parse(localStorage.getItem(USER_KEY) || '{}');
  static isRh = () => AuthenticationService.getUser()?.roles?.includes('rh');
  static isRhManager = () => AuthenticationService.getUser()?.roles?.includes('rh_manager');

  static signin = async (email: string, password: string) => {
    const resp = await api.post('/auth/signin', { email, password });
    AuthenticationService.loginWithToken(resp.data.token);
    return resp.data;
  };

  static signup = async (user: any, activeLanguage: string) => {
    const resp = await api.post('/auth/signup', { user, activeLanguage });
    return resp.data;
  };

  static signupAdvanced = async (userAdvanced: any) => {
    const resp = await api.post('/auth/signup/advanced', userAdvanced);
    AuthenticationService.loginWithToken(resp.data.token);
    return resp.data;
  };

  static loginWithToken = (token: string) => {
    localStorage.setItem(TOKEN_KEY, token);
    const user = jwt.decode(token);
    localStorage.setItem(USER_KEY, JSON.stringify(user));
  };

  static logout = () => {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(USER_KEY);
  };

  static forgotPassword = async (email: string, language: any) => {
    const resp = await api.post('/auth/forgot', { email, activeLanguage: language });
    return resp;
  }
  
  static resendSignupMail = async (email: string, activeLanguage: string) => {
    const resp = await api.post('/auth/signup/resendmail', { email, activeLanguage });
    return resp.data;
  };

  static validateSignup = async (username: string, token: string) => {
    const resp = await api.post('/auth/signup/validate', { username, token });
    AuthenticationService.loginWithToken(resp.data.token);
    return resp.data;
  };

  static checkToken = async (token: string) => {
    const response = await api.get(`/auth/reset/${token}`);
    return response;
  };

  static resetPassword = async (token: string, newPassword: string, verifyPassword: string) => {
    const response = await api.post(`/auth/reset/${token}`, { newPassword, verifyPassword });
    return response;
  }

}
