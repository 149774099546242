import { AxiosResponse } from "axios";
import {
  HomeOpportunitiesData,
  OpportunityCardData
} from "../model/home.model";
import { SearchUsers } from "../pages/professional/find";
import service from "./api.service";

class OpportunityService {

  static getOppPublished = () => {
    return service.get("opportunity/filter/published");
  };

  static getOppUnpublished = () => {
    return service.get("opportunity/filter/unpublished");
  };

  static getOppRecruitment = () => {
    return service.get("opportunity/filter/recruitment");
  };

  static getOppRevision = () => {
    return service.get("opportunity/filter/revision");
  };

  static getOpInProgress = () => {
    return service.get("opportunity/filter/inProgress");
  };

  static getHomeOpportunities = (): Promise<
    AxiosResponse<HomeOpportunitiesData>
  > => {
    return service.get("opportunity/list/home");
  };

  static getCompatibleHomeOpportunities = (userId: string) => {
    return service.get(`opportunity/list/${userId}/compatiblesOpportunities`);
  }

  static getActiveOpportunities = () => {
    return service.get("opportunity/filter/active");
  };

  static searchOpportunities = (
    keywords: string,
    location: any,
  ): Promise<AxiosResponse<OpportunityCardData[]>> => {
    if (location.id && location.place_id) {
      return service.get(
        `opportunity/search/opp?keywords=${encodeURIComponent(keywords)}&location=${location.id}&place_id=${location.place_id}`
      );
    } else if (location.id) {
      return service.get(
        `opportunity/search/opp?keywords=${encodeURIComponent(keywords)}&location=${location.id}`
      );
    } else {
      return service.get(
        `opportunity/search/opp?keywords=${encodeURIComponent(keywords)}&place_id=${location.place_id}`
      )
    }
  };

  static getOpWithCandidate = (
    opportunityId: string,
    professionalId: string,
  ): Promise<AxiosResponse<any>> => {
    return service.get(
      `opportunity/${opportunityId}/candidate/${professionalId}`,
    );
  };

  static searchOpportunitiesRh = (
    keywords: string,
    location: any,
    status: string
  ): Promise<AxiosResponse<OpportunityCardData[]>> => {
    if (location.id && location.place_id) {
      return service.get(
        `opportunity/search/oppRh?keywords=${encodeURIComponent(keywords)}&location=${location.id}&status=${status}&place_id=${location.place_id}`
      );
    } else if (location.id) {
      return service.get(
        `opportunity/search/oppRh?keywords=${encodeURIComponent(keywords)}&location=${location.id}&status=${status}`
      );
    } else {
      return service.get(
        `opportunity/search/oppRh?keywords=${encodeURIComponent(keywords)}&status=${status}&place_id=${location.place_id}`
      );
    }
  };

  static getOpportunityLite = (id: string): Promise<AxiosResponse<any>> => {
    return service.get(`opportunity/${id}`);
  };

  static getOpportunityToEdit = (id: string): Promise<AxiosResponse<any>> => {
    return service.get(`opportunity/${id}/edit`);
  };

  static getOpportunityLiteKanban = (id: string): Promise<AxiosResponse<any>> => {
    return service.get(`opportunity/kanban/${id}`);
  };

  static getOpportunityDates = (id: string): Promise<AxiosResponse<any>> => {
    return service.get(`opportunity/dates/${id}`);
  };

  static getCriteriaUnmatch = (profissionalId: string): Promise<AxiosResponse<any>> => {
    return service.get(`opportunity/list/criteria-unmatch/${profissionalId}`);
  };

  static getCompatibleProfessionals = (
    id: string,
    qtdPerPage: number,
    pageId: number,
    filter: number
  ): Promise<AxiosResponse<SearchUsers>> => {
    return service.get(`opportunity/${id}/compatibles/${qtdPerPage}/${pageId}/${filter}`);
  };

  static getEnrolledProfessionals = (
    id: string,
    qtdPerPage: number,
    pageId: number,
    filter: number,
    order: number
  ): Promise<AxiosResponse<SearchUsers>> => {
    return service.get(`opportunity/${id}/enrolled/${qtdPerPage}/${pageId}/${filter}/${order}`);
  };

  static getCandidatesProfessionals = (
    id: string,
    qtdPerPage: number,
    pageId: number,
    step: number,
    status: number,
    order: number,
  ): Promise<AxiosResponse<SearchUsers>> => {
    return service.get(`opportunity/${id}/candidates/${qtdPerPage}/${pageId}/${step}/${status}/99/${order}`);
  };


  static getOpportunityPreference = (opportunityId: string): Promise<AxiosResponse<any>> => {
    return service.get(`opportunity/${opportunityId}/get-opportunity-preference`);
  }

  static becomeCandidateByRh = (
    id: string,
    user: any,
  ): Promise<AxiosResponse<any>> => {
    return service.post(`opportunity/${id}/becomeCandidate`, user);
  };

  static sendInviteToOpportunity = (
    id: string,
    user: any,
  ): Promise<AxiosResponse<any>> => {
    return service.post(`opportunity/${id}/sendInvite`, user);
  };

  static dispenseCandidate = (
    id: string,
    user: any,
  ): Promise<AxiosResponse<any>> => {
    return service.post(`opportunity/${id}/dispenseTriaged`, user);
  }

  static dispenseAllEnrolled = (
    id: string,
    payload: {
      activeLanguage: string,
      sendDispensedMail: boolean,
      allProfessionals: any
    }): Promise<AxiosResponse<any>> => {
    return service.post(`opportunity/${id}/dispenseAllTriaged`, payload);
  }

  static changeInfoCandidate = (
    id: string,
    user: any,
  ): Promise<AxiosResponse<any>> => {
    return service.post(`opportunity/${id}/change-info-candidate`, user);
  };

  static changeStepCandidate = (
    id: string,
    obj: any,
  ): Promise<AxiosResponse<any>> => {
    return service.post(`opportunity/${id}/change-step-candidate`, obj);
  };

  static getStatusCompativeis = (
    id: string,
  ): Promise<AxiosResponse<any>> => {
    return service.get(`opportunity/${id}/get-status-compativeis`);
  };

  static createOpportinity = (
    opportunity: any,
  ): Promise<AxiosResponse<any>> => {
    return service.post(`opportunity`, opportunity);
  };
  static updateOpportinity = (
    opportunity: any,
  ): Promise<AxiosResponse<any>> => {
    return service.put(`opportunity/${opportunity.id}/edit`, opportunity);
  };

  static updateMosaic = (
    opportunity: any,
  ): Promise<AxiosResponse<any>> => {
    return service.put(`opportunity/${opportunity._id}/mosaic`, opportunity.mosaico.escolhidas);
  };

  static finisheOpportunity = (id: string, payload: any): Promise<AxiosResponse<any>> => {
    return service.post(`opportunity/${id}/finalizar-processo-seletivo`, payload);
  };

  static deleteOpportunity = (id: string): Promise<AxiosResponse<any>> => {
    return service.delete(`opportunity/${id}`);
  };

  static linkedinKeyWords = (opportunityId: string): Promise<AxiosResponse<any>> => {
    return service.get(`opportunity/${opportunityId}/generat-key-word`);
  }

  static selectProfessional = (opportunityId: string, payload: any): Promise<AxiosResponse<any>> => {
    return service.post(`opportunity/${opportunityId}/escolher-profissional`, payload);
  }

  static professionalCandidature = (opportunityId: string, payload: any): Promise<AxiosResponse<any>> => {
    return service.post(`opportunity/${opportunityId}/euquero`, payload);
  }

  static getStatisticsDismissedProfessionals = (opportunityId: string): Promise<AxiosResponse<any>> => {
    return service.get(`opportunity/${opportunityId}/get-statistics-dismissed-professionals`);
  }

  static getCompatibleByKeywords = (
    opportunityId: string,
    level: string,
    index: number,
    keywords: string[],
    ): Promise<AxiosResponse<any>> => {
    return service.post(`opportunity/${opportunityId}/${level}/${index}/find-by-keyword`, keywords)
  }

  static changeStatusPreRegister = (id: string, user: any): Promise<AxiosResponse<any>> => {
    return service.post(`opportunity/${id}/changeStatusPreRegister`, user);
  }

  static searchOpportunityByNameOrID = (value: string): Promise<AxiosResponse<any>> => {
    return service.get(`opportunity/search/byNameOrID?value=${value}`);
  }

  static listClient = (): Promise<AxiosResponse<any>> => {
    return service.get('opportunity/list/All-clients');
  }

  static listClientByName = (clientName: string): Promise<AxiosResponse<any>> => {
    return service.get(`opportunity/list/${clientName}`);
  }
}

export default OpportunityService;
