import React from "react";
import FileStackService from "../../../services/filestack.service";
import { Timeline } from '@zendeskgarden/react-accordions';
import style from './index.module.scss';
import styled from "styled-components";
import { Span } from "@zendeskgarden/react-typography";
import { Col, Row } from "@zendeskgarden/react-grid";
import UserService from "../../../services/user.service";
import Moment from "moment";
import Modal from "react-bootstrap/Modal";
import EventBox from "../event-box";
import { ReactComponent as PencilFillIcon } from '@zendeskgarden/svg-icons/src/12/pencil-fill.svg';
import { ReactComponent as PencilStrokeIcon } from '@zendeskgarden/svg-icons/src/12/pencil-stroke.svg';
import { ToggleIconButton } from "@zendeskgarden/react-buttons";
import { Tooltip } from "@zendeskgarden/react-tooltips";
import { t } from "../../../translations/translation.service";
import { enterEvents } from "../../../util/events";
// @ts-ignore
import ReactFilestack from "filestack-react";
import { Feed, FeedAttachment, UserData } from "../../../model/user.model";
const { REACT_APP_FILESTACK_API } = process.env;

export const StyledSpan = styled(Span).attrs({ isBold: true })`
  display: block;
`;

export type Event = {
  _id: string,
  recruiter: {
    _id: string,
    displayName: string,
    userImageUrl: string,
  },
  created: Date,
  eventType: {
    _id: string,
    name: string
  },
  comment: string,
  opportunity?: string,
  attachments: Attachment[]
}

export type Attachment = {  
  fileName: string,
  url: string,
  comment: string,
  content: string
}

class ProfessionalFeedCard extends React.Component<any> {
  constructor(props: any) {
    super(props);
    this.changeModalState = this.changeModalState.bind(this);
    this.changeUpdateFeedState = this.changeUpdateFeedState.bind(this);
    this.clearEventInfo = this.clearEventInfo.bind(this);
    this.toggleText = this.toggleText.bind(this);
  }  

  state = {
    policyAndSignatureImg: "",
    policyAndSignature: [] as string[],
    userFeed: [],
    user: {} as UserData | any,
    load: [],
    helper: [],
    openModalEvents: false,
    updateFeed: false,
    edit: false,
    editEvent: {} as Event,
    isTruncated: true,
    currentIndex: null,
    policyString: '' as string,
    signatureString: '' as string,
    isTruncatedComments: [] as boolean[],
    
  }

  async getFileStackPolicyToUserImg(): Promise<void> {
    try {
      if (
        this.props.user &&
        this.props.user.userImageUrl &&
        this.getNewFileStackUrl(this.props.user.userImageUrl)
      ) {
        const policyUserImg = await FileStackService.getFileStackSecreteCode(
          this.props.user.userImageUrl, false
        );
        this.setState({ policyAndSignatureImg: policyUserImg.data });
      }
    } catch (err: any) {
      console.log("Erro ao obter credenciais - ERROR: ", err);
    }
  }

  async getFileStackPolicyToUserImgFeed(url: string): Promise<string> {
    let newURLImg: string = "";
    try {
      if (this.getNewFileStackUrl(url)) {
        const policyUserImg = await FileStackService.getFileStackSecreteCodeToUser(
          url, false
        );
        newURLImg = this.setUrlImgRecruiter(url, policyUserImg.data);
      }
    } catch (err: any) {
      console.log("Erro ao obter credenciais - ERROR: ", err);
    }
    return newURLImg;
  }

  getNewFileStackUrl(url: string): boolean {
    const domain = url.split('/')[2];
    if (domain.includes('cdn.file')) {
      return true;
    }
    return false;
  }

  toggleText(index: number) {
    this.setState((prevState: any) => ({
      isTruncatedComments: {
        ...prevState.isTruncatedComments,
        [index]: !prevState.isTruncatedComments[index],
      },
      currentIndex: index
    }));
  };

  setUrlImg(url: string): string {
    const { policyAndSignatureImg } = this.state;
    const splitedUrl = url.split('/');
    const newUrl = `https://${splitedUrl[2]}${policyAndSignatureImg}/${splitedUrl[splitedUrl.length - 1]}`
    return newUrl;
  }

  setUrlImgRecruiter(url: string, police: string): string {
    const splitedUrl = url.split('/');
    const newUrl = `https://${splitedUrl[2]}${police}/${splitedUrl[splitedUrl.length - 1]}`
    return newUrl;
  }

  formatEmail(email: string) {
    const splitEmail = email.split("@");
    const domain = splitEmail[1];
    const name = splitEmail[0];
    return name.substring(0, 2).concat("*********@").concat(domain);
  }

  formatNumber(contacts: [], hasPhoneContactPermission: boolean) {
    return contacts
      .filter((phone: any) => phone.contato !== "")
      .map((phone: any) => {
        let con = phone.contato
          .replace("(", "")
          .replace(")", "")
          .replace("-", "");

        const formated = `(${con.substring(0, 2)}) ${hasPhoneContactPermission ? con.substring(
          2,
          con.length - 4
        ) : Array(con.length - 4).join("*")}-${con.substring(con.length - 4)}`;

        return formated;
      })
      .join("\n")
  }

  async getFeed(id: string): Promise<any> {
    const userFeed = await UserService.getUserFeed(id);
    this.state.user = userFeed.data.slice();
    this.setState({ userFeed: userFeed.data }, async () => {
      await this.getUrlRecruiterImg(this.state.userFeed);
    });
  }

  clearEventInfo(value: any): void {
    this.setState({ editEvent: value })
  }

  getUrlRecruiterImg(list: any) {
    return list.map(async (eve: Event) => {
      let img;
      img = await this.getFileStackPolicyToUserImgFeed(eve.recruiter.userImageUrl);
      eve.recruiter.userImageUrl = img;
      this.setState({ helper: [...this.state.helper, eve], updateFeed: false });
    })
  }

  componentDidUpdate(_prevProps: Readonly<any>, prevState: Readonly<{ userFeed: any, updateFeed: boolean, currentIndex: number }>): void {
    if (this.state.userFeed.length > 0 && this.state.userFeed.length === this.state.helper.length) {
      this.state.helper.sort(function (date1: any, date2: any) {
        let newDate1: any = new Date(date1.created);
        let newDate2: any = new Date(date2.created);
        return newDate2 - newDate1;
      });
      this.setState({ userFeed: this.state.helper, helper: [] });
    }
    if (prevState.updateFeed !== this.state.updateFeed) {
      this.getFeed(this.props.user._id);
    }
  }

  componentDidMount(): void {
    this.getFileStackPolicyToUserImg();
    this.getFeed(this.props.user._id);
  }

  changeModalState(value: boolean): void {
    this.setState({ openModalEvents: value });
  }

  changeUpdateFeedState(value: boolean): void {
    this.setState({ updateFeed: value }, () => {
      this.getFeed(this.props.user._id)
    });
  }

  setUrlAttachment(url: string, index: number): string {
    const policyAndSignature  = this.props.policy;
    const splitedUrl = url.split('/');
    const newUrl = `https://${splitedUrl[2]}${policyAndSignature[index]}/${splitedUrl[splitedUrl.length-1]}`
    return newUrl;
  }

  render(): React.ReactNode {
    return (
      <div className={style["feed-content"]}>
              <Row className={style["feedField"]} >
                <Col sm="12" className={style["feedField-scroll"]} >
                  <Timeline className={style["img-fade-in"]} >
                    {this.state.userFeed.length < 1 && (
                      <div className={style["no-feed"]}>
                        <p>Não há eventos cadastrados</p>
                      </div>
                    )}
                    {this.state.userFeed.length > 0 && (
                      this.state.userFeed.map((feed: Event, index: number) => {

                        let text = feed.comment;
                        const parsedText = new DOMParser().parseFromString(text, "text/html");
                        const textContent = parsedText.body.textContent || '';                        
                        
                        return (
                          <Timeline.Item key={feed._id}>
                            <Timeline.Content>
                              <div>
                                <b>Data:</b> <Span >{`${Moment(feed.created).format("DD/MM/YYYY")}`}</Span>                  
                              </div>                        
                              <StyledSpan className={ `${ enterEvents.includes(feed.eventType._id) ? 
                                style["automatic-event-style"] : "automatic-event-style2" }` } 
                                style={{ display: 'inline-block' }}>
                                <b>Evento:</b> {`${feed.eventType.name}`}
                                {!enterEvents.includes(feed.eventType._id)
                                  ? <Tooltip
                                    size="medium"
                                    type="light"
                                    placement="bottom-start"
                                    content={
                                      <span>
                                        {t("Editar Evento")}
                                      </span>
                                    }>
                                    <ToggleIconButton
                                      style={{ marginLeft: '5px' }}
                                      aria-label="Editar-Evento"
                                      isPressed={this.state.edit}
                                      onClick={() => {
                                        this.setState(
                                          {
                                            edit: !this.state.edit,
                                            openModalEvents: true,
                                            editEvent: feed
                                          }
                                        )
                                        this.props.eventID(feed._id)
                                      }}
                                    >
                                      {
                                        this.state.edit
                                          ? <PencilFillIcon style={{ color: "#354a5f" }} />
                                          : <PencilStrokeIcon style={{ color: "#354a5f" }} />
                                      }

                                    </ToggleIconButton>
                                  </Tooltip>
                                  : " "
                                }
                              </StyledSpan>
                              <br/>

                              <span className={style["recuiter-field"]}>
                                <b>Recrutador(a):</b>   {`${feed.recruiter.displayName}`}
                                <img src={feed.recruiter.userImageUrl} alt="" />
                              </span>
                              
                              <div>
                                <b>Comentário:</b>
                                <span>
                                  <p  key={index} className={this.state.isTruncatedComments[index] ? '' :  style['truncate']}>
                                    { textContent }
                                  </p>
                                </span>
                              </div>

                              <div>
                                {parsedText.body.innerText.length >= 20 && (
                                  <button
                                    className={style["read-more-btn"]}
                                    name={"comment" + index}
                                    onClick={() => this.toggleText(index)}>
                                      {this.state.isTruncatedComments[index] ? '...ver menos' : '...ver mais'}
                                  </button>
                                )}
                              </div>

                            </Timeline.Content>
                          </Timeline.Item>
                        )
                      })
                    )}
                  </Timeline>
                </Col>
                <button
                  onClick={() => this.setState({ openModalEvents: true, editEvent: {} })}
                  className={style["add-event-button"]}>
                  Adicionar eventos
                </button>
              </Row>
              <Row>
              </Row>
              {this.state.openModalEvents && (
                <Modal
                  show={this.state.openModalEvents}
                  onHide={() => this.setState({ openModalEvents: false })}
                  size="lg"
                >
                  <Modal.Header>
                    Adicione Eventos ao Feed
                    <button
                      onClick={() => this.setState({
                        openModalEvents: false,
                        edit: false
                      })}
                      type="button"
                      className="close"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </Modal.Header>
                  <Modal.Body>
                    <EventBox
                      openModal={this.changeModalState}
                      updateFeed={this.changeUpdateFeedState}
                      editEventByRh={this.state.editEvent}
                      clearEventInfo={this.clearEventInfo}
                    />
                  </Modal.Body>
                </Modal>
              )}
      </div>      
    );
  }
}

export default ProfessionalFeedCard;