import React from 'react';
// import SEO from '../../components/seo';
import { t } from "../../translations/translation.service";
import './index.css';

export default class Terms extends React.Component<any> {
  render() {
    return (
      <div className="container">
        {/* <SEO title={`Enter - ${t("Termos de Uso")}`} url='https://elumini.enter.jobs/terms-of-use'/> */}
        <header className="terms-header">
          {t("Termos de Uso e Política de Privacidade - Enter")}
        </header>
        <div className="terms-body">
          <p>
            {t("As partes, ELUMINI OUTDOING IT, empresa inscrita no CNPJ sob o no. 06.041.066/0001-06, com sede na Avenida Almirante Barroso, 81 – 34º andar – Centro - Rio de Janeiro – RJ, responsável por este site, doravante denominada ENTER e de outro lado o USUÁRIO assim denominado a pessoa física cujos dados documentais constam do cadastro efetuado por meio de login e senha, doravante denominado USUÁRIO.")}
          </p>
          <p>
            {t("Os termos e condições abaixo se aplicam aos serviços disponibilizados através do banco de vagas ENTER, realizado por maiores de 18 (dezoito) anos de idade ou menores devidamente autorizados por seu responsável legal, para cadastrar e expor currículos neste site.")}
          </p>
          <ul className="terms-items">
            <ol>
              <li>
                <p className="mb-0">{t("DAS CONSIDERAÇÕES INICIAIS")}</p>
                <ul>
                  <li>
                    {t("O USUÁRIO declara ter conhecimento que a aceitação dos Termos de Uso implicará no aceite integral de todos os Termos e Condições deste Contrato.")}
                  </li>
                  <br />
                  <li>
                    {t("O ENTER poderá, a qualquer tempo, rever o presente Termo de Uso. A nova atualização será disponibilizada neste site para consulta.")}
                  </li>
                </ul>
              </li>
              <br />
              <li>
                <p className="mb-0">{t("DO OBJETO")}</p>
                <ul>
                  <li>
                    {t("O objeto do presente contrato é a prestação de serviços de armazenamento, manutenção, disponibilização de currículo, pesquisa de vagas, inscrição em vagas, visualização de dados completos de vagas e serviços que remetem ao processo de recrutamento e seleção. Pelo presente Termo, o USUÁRIO passa a ter direito de utilizar seu acesso ao site para cadastrar seu currículo, utilizar as ferramentas disponíveis para busca e monitoramento de vagas abertas e candidatar-se a processos seletivos. O USUÁRIO deverá fazer isto observando as regras e o funcionamento do sistema contido no site.")}
                  </li>
                </ul>
              </li>
              <br />
              <li>
                <p className="mb-0">{t("DAS CONDIÇÕES GERAIS")}</p>
                <ul>
                  <li>
                    {t("Os USUÁRIOS que se cadastrarem no ENTER estarão disponibilizando voluntariamente seus dados para a participação nos processos de recrutamento e seleção disponíveis no portal do ENTER e poderão ser contatados por telefone ou e-mail a qualquer momento.")}
                  </li>
                  <br />
                  <li>
                    {t("Ao cadastrar seus dados, o USUÁRIO compromete-se a fornecer informações verdadeiras, atualizadas e completas, sendo de sua responsabilidade a inserção e correção das informações fornecidas, bem como não inserir conteúdo impróprio em seu cadastro, que poderá ser excluído a qualquer momento pelo ENTER se verificada a desobediência a qualquer condição deste contrato.")}
                  </li>
                  <br />
                  <li>
                    {t("É facultado ao ENTER consultar as informações inseridas pelo USUÁRIO a fim de identificar e solucionar problemas técnicos, prestar suporte, por ordens legais e judiciais e tudo o que mais necessário for para garantir que os serviços sejam prestados dentro dos preceitos éticos e legais vigentes e aplicáveis à espécie.")}
                  </li>
                  <br />
                  <li>
                    {t("As informações pessoais e profissionais cadastradas são de uso exclusivo e poderão ser disponibilizadas apenas aos clientes do ENTER, caso o USUÁRIO tenha se candidatado e esteja participando de um processo seletivo.")}
                  </li>
                  <br />
                  <li>
                    {t("O USUÁRIO compromete-se a acessar e utilizar o siteexclusivamente para fins lícitos, segundo o sistema jurídico vigente, observando a melhor ética no uso de internet e a mais estrita boa fé, bem como todas as regras de uso do site nele divulgadas, respondendo integralmente, como único responsável pelas informações e conteúdo que veicular através de seu currículo e de seus dados cadastrais.")}
                  </li>
                  <br />
                  <li>
                    {t("O sistema será acessado pelo USUÁRIO através login que conterá o email e senha. O USUÁRIO é o único responsável pelo conteúdo das transmissões feitas no sistema a partir de sua senha e login e desta forma deve zelar pela guarda destas informações a fim de evitar violação de seus dadospor terceiros.")}
                  </li>
                  <br />
                  <li>
                    {t("O USUÁRIO está ciente de que o preenchimento dos dados e o envio de seu currículo não constitui garantia de contratação, mas, tão somente, a possibilidade de participação nos processos seletivos cadastrados.")}
                  </li>
                  <br />
                  <li>
                    {t("O USUÁRIO tem ciência de que, a partir do seu cadastro e aceitação do Termo de Uso, poderão ser enviadas mensagens para seu e-mail informando sobre vagas e processos seletivos abertos que estejam de acordo com o perfil cadastrado no site, tendo ocorrido a candidatura ou não.")}
                  </li>
                  <br />
                  <li>
                    {t("As partes declaram ter pleno conhecimento de que o uso de qualquer sistema de informática em ambiente exposto à internet estará sujeito a ataques, não se responsabilizando nenhuma das partes perante a parte contrária, ou terceiros, por quaisquer danos causados por invasões no site por hackers, ou terceiros com intenções semelhantes.")}
                  </li>
                  <br />
                  <li>
                    {t("O ENTER fará as manutenções corretivas e preventivas em seu sistema quando julgar necessário, sem necessidade de prévio aviso, podendo o acesso ao sistema ser interrompido por determinado período de tempo durante as referidas manutenções. Não caberá ao USUÁRIO qualquer direito de exigir indenização ou reparação de qualquer dano oriundo em virtude de tal interrupção no acesso ao sistema.")}
                  </li>
                  <br />
                  <li>
                    {t("O presente Termo é de duração indeterminada permanecendo válido enquanto o USUÁRIO mantiver seu currículo cadastrado no site.")}
                  </li>
                  <br />
                  <li>
                    {t("O USUÁRIO acessará o sistema ENTER através de conexão via internet, sendo de sua responsabilidade providenciar os equipamentos necessários para a conexão à Internet (computador, linha telefônica e modem) e pelos custos de conexão. O ENTER não será responsabilizado por falhas decorrentes da conexão do USUÁRIO à internet.")}
                  </li>
                  <br />
                  <li>
                    {t("O site não cobra e não autoriza nenhum tipo de cobrança para a utilização do USUÁRIO.")}
                  </li>
                  <br />
                  <li>
                    {t("O ENTER não utiliza os e-mails informados para envio de spams.")}
                  </li>
                  <br />
                  <li>
                    {t("O USUÁRIO reconhece que todo o conteúdo (escrito, falado, imagem e som) exibido no sistema, podem estar protegidos pela Lei que regulamenta os direitos autorais, marcas, patentes e demais direitos de propriedade intelectual.")}
                  </li>
                  <br />
                  <li>
                    {t("O USUÁRIO declara expressamente, por este instrumento, que, nos termos do Artigo 46 da Lei no 8.078/1990 (Código de Defesa do Consumidor), tomou conhecimento prévio deste Contrato, tendo, inclusive, a possibilidade de imprimi-lo, e que avaliou, leu e concorda com todas as disposições e cláusulas aqui descritas.")}
                  </li>
                  <br />
                  <li>
                    {t("As Partes elegem o Foro Central da Comarca do Rio de Janeiro como competente para dirimir quaisquer controvérsias oriundas do presente Contrato, à exclusão de qualquer outro, por mais privilegiado que seja.")}
                  </li>
                </ul>
              </li>
            </ol>
          </ul>
        </div>
      </div>
    );
  }
}
