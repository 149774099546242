import random1 from './random1.svg'
import random10 from './random10.svg'
import random11 from './random11.svg'
import random12 from './random12.svg'
import random2 from './random2.svg'
import random3 from './random3.svg'
import random4 from './random4.svg'
import random5 from './random5.svg'
import random6 from './random6.svg'
import random7 from './random7.svg'
import random8 from './random8.svg'
import random9 from './random9.svg'

export const random = [
    random1,
    random2,
    random3,
    random4,
    random5,
    random6,
    random7,
    random8,
    random9,
    random10,
    random11,
    random12,
];

