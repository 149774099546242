//KANBAN E TALENT HUNTER KANBAN
export const CLICK_UPDATE_SQUAD = 'CLICK_UPDATE_SQUAD';
export const CLICK_UPDATE_RESPONSABLE = 'CLICK_UPDATE_RESPONSABLE';
export const CLICK_UPDATE_GROUP = "CLICK_UPDATE_GROUP";
export const CLICK_UPDATE_SORT_LIST = "CLICK_UPDATE_SORT_LIST";
export const CLICK_UPDATE_KEYWORDS = "CLICK_UPDATE_KEYWORDS";
export const CLICK_UPDATE_DATE_FILTER = "CLICK_UPDATE_DATE_FILTER";
export const CLICK_UPDATE_PRIORITY_SORT = "CLICK_UPDATE_PRIORITY_SORT"

//REPORTS

export const CLICK_UPDATE_FILTER = 'CLICK_UPDATE_FILTER';
export const CLICK_UPDATE_START_DATE_RANGE = 'CLICK_UPDATE_START_DATE_RANGE';
export const CLICK_UPDATE_END_DATE_RANGE = 'CLICK_UPDATE_END_DATE_RANGE';
export const CLICK_UPDATE_MEDIA = 'CLICK_UPDATE_MEDIA';

//HEADER
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';

// HOME BUTTONS
export const GET_BUTTON_HOME_OPPORTINITIES = "GET_BUTTON_HOME_OPPORTINITIES";

// PARECER TÉCNICO
export const GET_VALIDATE_VALUE = "GET_VALIDATE_VALUE";

// HEADER EVENTOS
export const GET_RESPONSIBLE_EVENT = "GET_RESPONSIBLE_EVENT";
export const GET_EVENT = "GET_EVENT";
export const GET_EVENT_DATE = "GET_EVENT_DATE";
export const GET_EVENT_COMMENT = "GET_EVENT_COMMENT";
export const GET_EVENT_ATTACHMENTS = "GET_EVENT_ATTACHMENTS";
export const SAVE_EVENT = "SAVE_EVENT";
export const EDIT_EVENT = "EDIT_EVENT";