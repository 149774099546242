import api from './api.service';
import { AxiosResponse } from 'axios';

class FileStackService {
  static getFileStackSecreteCode = (url: any, isArray: boolean): Promise<AxiosResponse<any>> => {
    return api.post('filestack', { url, isArray });
  }
  static getPoliceAndSignature = (): Promise<AxiosResponse<any>> => {
    return api.get('filestack')
  }
  static getFileStackSecreteCodeToUser = (url: any, isArray: boolean): Promise<AxiosResponse<any>> => {
    return api.post('filestack/users', { url, isArray });
  }
  static getFileStackSecreteCodeToUserSignup = (url: any, isArray: boolean): Promise<AxiosResponse<any>> => {
    return api.post('filestack/users/signup', { url, isArray });
  }
}

export default FileStackService;