import * as React from "react";
import styles from "./index.module.css";

export type Props = {
  index: number;
  onRemove: Function;
  nomeCaption: string;
  classif: string;
  subclassif: string;
  selected: boolean;
};

export default class PillChipSelectable extends React.Component<Props> {
  render() {
    return (
      <div
        className={[
          styles["chip-input"],
          styles["cursor-pointer"],
          this.props.selected ? styles["selected"] : "",
        ].join(" ")}
        onClick={() => this.props.onRemove(this.props.classif, this.props.subclassif, this.props.nomeCaption)}
      >
        <div className={[styles["chip"], styles["show"]].join(" ")}>
          {this.props.nomeCaption}
        </div>
      </div>
    );
  }
}
